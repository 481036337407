import { useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import RefreshDialog from "./RefreshDialog";
import { signOutAction } from "../core";

let countdownInterval;
let timeout;

const SessionTimeout = (props) => {
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const [previousActive, setPreviousActive] = useState(null);
  const [refreshModalOpen, setRefreshModalOpen] = useState(false);

  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  const onActive = () => {
    if (!refreshModalOpen) {
      clearSessionInterval();
      clearSessionTimeout();
    }
  };

  const onIdle = () => {
    const delay = 1000 * 1;
    if (!refreshModalOpen) {
      timeout = setTimeout(() => {
        let countDown = 2;
        setRefreshModalOpen(true);
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            setTimeoutCountdown(--countDown);
          } else {
            window.location.reload();
          }
        }, 1000);
      }, delay);
    }
  };

  const onRefresh = () => {
    const delay = 1000 * 1;
    if (!refreshModalOpen) {
      timeout = setTimeout(() => {
        let countDown = 2;
        setRefreshModalOpen(true);
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            setTimeoutCountdown(--countDown);
          } else {
            window.location.reload();
          }
        }, 1000);
      }, delay);
    }
  };

  const onAction = (event) => {
    const lastActiveTime = getLastActiveTime();
    const sixHoursAgo = moment().subtract(6, "hours").valueOf();

    if (previousActive) {
      if (previousActive < sixHoursAgo) {
        // Session needs to be refreshed
        onRefresh();
      }
    }
    setPreviousActive(lastActiveTime);
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: 21600000,
    onIdle: onIdle,
    onActive: onActive,
    onAction: onAction,
    debounce: 250,
  });

  return (
    <>
      <RefreshDialog open={refreshModalOpen} />
    </>
  );
};

const mapDispatchToProps = {
  signOutAction,
};

export default connect(null, mapDispatchToProps)(SessionTimeout);
