import { useState, useEffect } from "react";
import Bugsnag from "@bugsnag/js";
import { connect } from "react-redux";
import {
  appSelector,
  userSelector,
  readSceneAction,
  ParentPartiesAPIs,
} from "../../core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircleLoader } from "react-spinners";
import {
  Grid,
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import AGGrid from "../Grid";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { NavLink } from "react-router-dom";
import InnerRenderer from "./components/innerRenderer";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const UserDialog = (props) => {
  const {
    userDialog,
    userDialogData,
    closeUserDialog,
    userDialogResponse,
    dialogError,
    handleAddUser,
    loadingDialog,
  } = props;
  const [emailToAdd, setEmailToAdd] = useState("");

  return (
    <Dialog
      open={userDialog}
      onClose={closeUserDialog}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle id="max-width-dialog-title">Add User</DialogTitle>
      <DialogContent className="py-4">
        {userDialogResponse ? (
          userDialogResponse
        ) : (
          <div>
            <div className="mb-4">
              <div className="text-black font-size-sm mb-1">
                {userDialogData ? userDialogData.venueName : ""}
              </div>
              <div className="text-black-50 font-size-xs">
                {userDialogData ? userDialogData.venueAddress : ""}
              </div>
            </div>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={emailToAdd}
              onChange={(e) => {
                setEmailToAdd(e.target.value);
              }}
            />
            {dialogError ? (
              <FormHelperText error={true}>{dialogError}</FormHelperText>
            ) : null}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <div
          className="d-flex justify-content-end align-items-center"
          style={{ width: "100%" }}
        >
          <Button
            className="btn btn-primary"
            onClick={() => handleAddUser(emailToAdd)}
            disabled={loadingDialog || userDialogResponse}
          >
            {loadingDialog ? (
              <div className="d-flex flex-row align-items-center justify-content-center">
                <CircleLoader color={"#624185"} loading={true} size={25} />
                <span className="ml-3">Adding User...</span>
              </div>
            ) : (
              <div>Add User</div>
            )}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

function AdminDistricts(props) {
  const [initialLoad, setInitialLoad] = useState(true);
  const [districts, setDistricts] = useState([]);
  useEffect(() => {
    async function getDistricts() {
      const allDistricts = await ParentPartiesAPIs.readAllDistricts();

      const extraInfo = allDistricts.map((item) => ({
        ...item,
        numberOfSchools: item.schoolIds
          ? Object.keys(item.schoolIds).length
          : 0,
      }));
      setDistricts(extraInfo);
      setInitialLoad(false);
    }

    getDistricts();
  }, []);

  const onSelectDistrict = (district) => {
    props.readSceneAction("d", district.id);
  };

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteDialogInfo, setDeleteDialogInfo] = useState(null);
  const handleDeleteClose = () => {
    setDeleteDialog(false);
  };
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleDeleteDistrict = async () => {
    try {
      if (deleteDialogInfo) {
        if (deleteDialogInfo.id) {
          setDeleteLoading(true);
          await ParentPartiesAPIs.deleteDistrict(deleteDialogInfo.id);
          setDistricts(
            districts.filter((district) => district.id !== deleteDialogInfo.id)
          );
          setDeleteLoading(false);
          setDeleteDialog(false);
        }
      }
    } catch (err) {
      setDeleteLoading(false);
      console.log(err);
      Bugsnag.notify(err);
    }
  };

  // Add User Dialog
  const [userDialog, setUserDialog] = useState(false);
  const [userDialogData, setUserDialogData] = useState(null);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [userDialogResponse, setUserDialogResponse] = useState("");
  const [dialogError, setDialogError] = useState("");
  const openUserDialog = (venueName, venueAddress, venueId) => {
    setUserDialog(true);
    setUserDialogData({ venueName, venueAddress, venueId });
    setUserDialogResponse("");
    setDialogError("");
    setLoadingDialog(false);
  };
  const closeUserDialog = () => {
    setUserDialog(false);
    setLoadingDialog(false);
    setUserDialogResponse("");
  };

  const handleAddUser = async (emailToAdd) => {
    setLoadingDialog(true);

    try {
      const userDoesNotExist = await ParentPartiesAPIs.addOwnerSchool(
        userDialogData.venueId,
        emailToAdd
      );
      if (userDoesNotExist) {
        setDialogError(`User with ${emailToAdd} does not exist.`);
        setLoadingDialog(false);
        return;
      }
      setUserDialogResponse(`Successfully added user ${emailToAdd}`);
      setLoadingDialog(false);
    } catch (err) {
      setDialogError(`Something went wrong adding user ${emailToAdd}`);
      setLoadingDialog(false);
    }
  };

  return !props.user ? null : initialLoad || props.app.isLoading ? (
    <Grid container className="my-spacing-6">
      <Grid item xs={12}>
        <div className="d-flex flex-row justify-content-center">
          <CircleLoader
            className="d-flex align-self-center"
            color={"#18a9a9"}
            loading={true}
            size={40}
          />
        </div>
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container spacing={4}>
        <Grid item md={12}>
          <AGGrid
            titleGrid={"Districts"}
            search={true}
            statusBar={true}
            exportButton={true}
            exportType={"districts"}
            createButton={
              <Button
                variant="contained"
                size="medium"
                className="btn-success shadow-none ml-4"
                component={NavLink}
                to={{
                  pathname: "/add-district",
                }}
              >
                <span className="btn-wrapper--icon mr-1">
                  <FontAwesomeIcon
                    style={{ fontSize: "12px" }}
                    icon={["fas", "plus"]}
                  />
                </span>
                <span
                  className="btn-wrapper--label"
                  style={{ fontSize: "12px" }}
                >
                  Add District
                </span>
              </Button>
            }
            rowData={districts}
            columnDefs={[
              {
                headerName: "District Name",
                field: "name",
                sortable: true,
                filter: true,
                cellRenderer: "agGroupCellRenderer",
                cellRendererParams: {
                  innerRenderer: "myInnerRenderer",
                },
              },
              {
                headerName: "Number of Schools",
                field: "numberOfSchools",
                sortable: true,
                filter: true,
              },
              {
                width: 300,
                suppressSizeToFit: true,
                field: "actions",
                headerName: "Actions",
                cellRendererFramework: (params) => {
                  return (
                    <div className="d-flex flex-row align-items-center">
                      <NavLink
                        to={{
                          pathname: `/edit-district/${params.data.id}`,
                          districtData: params.data,
                        }}
                      >
                        <button
                          className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained btn-primary shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                          tabIndex="0"
                          type="button"
                        >
                          <span className="MuiButton-label">
                            <span
                              className="btn-wrapper--label text-white"
                              style={{ fontSize: "12px" }}
                            >
                              Edit
                            </span>
                          </span>
                          <span className="MuiTouchRipple-root"></span>
                        </button>
                      </NavLink>
                      <NavLink
                        to={`/dashboard/d/${params.data.id}`}
                        onClick={() =>
                          props.readSceneAction("d", params.data.id)
                        }
                      >
                        <button
                          className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                          tabIndex="0"
                          type="button"
                          style={{
                            background: "rgba(151, 151, 151, 0.86)",
                          }}
                        >
                          <span className="MuiButton-label">
                            <span
                              className="btn-wrapper--label text-white"
                              style={{ fontSize: "12px" }}
                            >
                              View
                            </span>
                          </span>
                          <span className="MuiTouchRipple-root"></span>
                        </button>
                      </NavLink>
                      <div style={{ lineHeight: 40 }}>
                        <button
                          className="mb-0 btn-danger MuiButtonBase-root MuiButton-root MuiButton-contained shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                          type="button"
                          onClick={() => {
                            setDeleteDialogInfo(params.data);
                            setDeleteDialog(true);
                          }}
                        >
                          <span className="MuiButton-label">
                            <span
                              className="btn-wrapper--label text-white"
                              style={{ fontSize: "12px" }}
                            >
                              Delete
                            </span>
                          </span>
                          <span className="MuiTouchRipple-root"></span>
                        </button>
                      </div>
                    </div>
                  );
                },
                cellClass: "agGridCellCenter",
              },
            ]}
            // Master Detail //
            frameworkComponents={{
              myInnerRenderer: (props) => (
                <InnerRenderer
                  {...props}
                  onSelectDistrict={
                    props.agGridReact.props.extraProps.onSelectDistrict
                  }
                />
              ),
            }}
            masterDetail={true}
            detailCellRendererParams={{
              detailGridOptions: {
                masterDetail: true,
                columnDefs: [
                  {
                    headerName: "School",
                    field: "name",
                  },
                  {
                    headerName: "Address",
                    field: "location.address",
                  },
                  {
                    headerName: "City",
                    field: "location.city",
                  },
                  {
                    headerName: "State",
                    field: "location.state",
                    width: 120,
                  },
                  {
                    headerName: "Billing Notes",
                    field: "billingNotes",
                    sortable: true,
                    filter: true,
                  },
                  {
                    width: 300,
                    suppressSizeToFit: true,
                    field: "actions",
                    headerName: "Actions",
                    cellRendererFramework: (params) => (
                      <div className="d-flex flex-row align-items-center">
                        <div style={{ lineHeight: 40 }}>
                          <button
                            className="mr-2 mb-0 btn-success MuiButtonBase-root MuiButton-root MuiButton-contained shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                            type="button"
                            onClick={() => {
                              openUserDialog(
                                params.data.name,
                                params.data.location.address,
                                params.data.id
                              );
                            }}
                          >
                            <span className="MuiButton-label">
                              <span
                                className="btn-wrapper--label text-white"
                                style={{ fontSize: "12px" }}
                              >
                                Add User
                              </span>
                            </span>
                            <span className="MuiTouchRipple-root"></span>
                          </button>
                        </div>
                        <NavLink
                          to={{
                            pathname: `/edit-school/${params.data.id}`,
                            schoolData: params.data,
                          }}
                        >
                          <button
                            className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained btn-primary shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                            type="button"
                          >
                            <span className="MuiButton-label">
                              <span
                                className="btn-wrapper--label text-white"
                                style={{ fontSize: "12px" }}
                              >
                                Edit
                              </span>
                            </span>
                            <span className="MuiTouchRipple-root"></span>
                          </button>
                        </NavLink>
                        <NavLink
                          to={
                            params.data.plan
                              ? params.data.plan === "In-school Curriculum"
                                ? `/resources-in-person/s/${params.data.id}`
                                : `/guardians-analytics/s/${params.data.id}`
                              : `/guardians-analytics/s/${params.data.id}`
                          }
                          onClick={() =>
                            props.readSceneAction("s", params.data.id)
                          }
                        >
                          <button
                            className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                            type="button"
                            style={{
                              background: "rgba(151, 151, 151, 0.86)",
                            }}
                          >
                            <span className="MuiButton-label">
                              <span
                                className="btn-wrapper--label text-white"
                                style={{ fontSize: "12px" }}
                              >
                                View
                              </span>
                            </span>
                            <span className="MuiTouchRipple-root"></span>
                          </button>
                        </NavLink>
                      </div>
                    ),
                    cellClass: "agGridCellCenter",
                  },
                ],
                onFirstDataRendered(params) {
                  params.api.sizeColumnsToFit();
                },
              },
              getDetailRowData: function (params) {
                params.successCallback(Object.values(params.data.schoolData));
              },
            }}
            extraProps={{
              onSelectDistrict,
            }}
          />
        </Grid>
      </Grid>
      <Dialog
        open={deleteDialog}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
        PaperProps={{
          className: "p-3",
        }}
      >
        <DialogTitle
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            color: "rgb(42, 42, 47)",
            borderBottom: "none",
          }}
        >
          {`Are you sure you want to delete district${
            deleteDialogInfo ? `: ${deleteDialogInfo.name}` : ""
          }?`}
        </DialogTitle>
        <DialogActions style={{ borderTop: "none" }} className="mt-4">
          <Button onClick={handleDeleteClose}>Cancel</Button>
          <Button
            className="btn-danger"
            onClick={() => handleDeleteDistrict()}
            autoFocus
            disabled={deleteLoading}
          >
            {deleteLoading && (
              <div className="mr-3">
                <CircleLoader color="#fff" size={15} />
              </div>
            )}
            <span>Delete School</span>
          </Button>
        </DialogActions>
      </Dialog>
      <UserDialog
        userDialog={userDialog}
        userDialogData={userDialogData}
        closeUserDialog={closeUserDialog}
        userDialogResponse={userDialogResponse}
        dialogError={dialogError}
        handleAddUser={handleAddUser}
        loadingDialog={loadingDialog}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = {
  readSceneAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDistricts);
