// import moment from "moment";
import Bugsnag from "@bugsnag/js";

/// Pull smartlook from index.html
const smartlook = window.smartlook;

export async function identify(user) {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  } else {
    /* Prod */
    if (user) {
      const schoolName = user.schools
        ? Object.values(user.schools).length
          ? Object.values(user.schools)[0].name
          : ""
        : "";

      // Smartlook
      smartlook("identify", user.id, {
        name: `${user.firstName} ${user.lastName}`,
        email: `${user.email.address}`,
        school: schoolName,
      });

      Bugsnag.setUser(
        user.id,
        `${user.email.address}`,
        `${user.firstName} ${user.lastName}`
      );
    }
  }
}

export function page(path) {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  } else {
  }
}

export function track(eventName, properties) {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  } else {
  }
}

export function logout() {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  } else {
  }
}

export function handleSuccessConversion() {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  } else {
  }
}
