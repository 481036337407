import { all, put } from "redux-saga/effects";
import {
  Invite,
  POSITION,
  INVITE_STATUS,
  School,
  District,
  Scene,
} from "../../Entities";
import {
  updateAppAction,
  updateInviteStateAction,
  updateUserSchoolStateAction,
  updateUserDistrictStateAction,
  updateSceneStateAction,
} from "../Redux";
import { InviteService, SchoolService, DistrictService } from "../../Services";
import {
  InviteToItemInteractor,
  AcceptInviteSchoolInteractor,
  AcceptInviteDistrictInteractor,
  ReadInviteInteractor,
  ReadSchoolInteractor,
  CancelInviteInteractor,
  ReadDistrictInteractor,
} from "../../UseCases";

export const INVITE_TO_ITEM = "invite/saga/invite_to_item";
export const READ_INVITE = "invite/saga/read_invite";
export const ACCEPT_INVITE = "invite/saga/accept_invite";
export const CANCEL_INVITE = "invite/saga/cancel_invite";

interface InviteActionType {
  type: string;
  invite?: Invite;
  inviteId?: string;
  employeeId?: string;
  itemId?: number | string;
  itemType?: "school" | "district";
}

export const inviteToItemAction = ({
  senderId,
  email,
  position,
  itemId,
  itemType,
}: {
  senderId: string;
  email: string;
  position: POSITION;
  itemId: number | string;
  itemType: "school" | "district";
}): InviteActionType => ({
  type: INVITE_TO_ITEM,
  invite: new Invite({
    senderId,
    email: { address: email },
    position,
    status: INVITE_STATUS.Pending,
    timestamp: new Date().valueOf(),
    itemId,
    itemType,
  }),
});

export function* inviteToItemSaga(action: InviteActionType) {
  yield put(updateAppAction({ isLoading: true }));

  const inviteService = new InviteService();
  const inviteInteractor = new InviteToItemInteractor(inviteService);
  const schoolService = new SchoolService();
  const schoolInteractor = new ReadSchoolInteractor(schoolService);
  const districtService = new DistrictService();
  const districtInteractor = new ReadDistrictInteractor(districtService);

  const { invite } = action;
  if (!invite) {
    yield put(updateAppAction({ isLoading: false }));
  } else {
    if (invite.itemType === "school") {
      try {
        yield inviteInteractor.inviteToItem(invite);
        const updatedSchool: School = yield schoolInteractor.readSchool(
          invite.itemId
        );
        const updatedScene: Scene = {
          id: updatedSchool.id,
          type: "school",
          data: updatedSchool,
        };
        yield put(updateSceneStateAction(updatedScene));
        yield put(updateAppAction({ isLoading: false }));
      } catch (error) {
        // @ts-ignore
        yield put(updateAppAction({ isLoading: false, error }));
      }
    }
    if (invite.itemType === "district") {
      try {
        yield inviteInteractor.inviteToItem(invite);
        const updatedDistrict: District = yield districtInteractor.readDistrict(
          invite.itemId as string
        );
        const updatedScene: Scene = {
          id: updatedDistrict.id,
          type: "district",
          data: updatedDistrict,
        };
        yield put(updateSceneStateAction(updatedScene));
        yield put(updateAppAction({ isLoading: false }));
      } catch (error) {
        // @ts-ignore
        yield put(updateAppAction({ isLoading: false, error }));
      }
    }
  }
}

export const readInviteAction = (inviteId: string): InviteActionType => ({
  type: READ_INVITE,
  inviteId,
});

export function* readInviteSaga(action: InviteActionType) {
  yield put(updateAppAction({ isLoading: true }));

  const inviteService = new InviteService();
  const inviteInteractor = new ReadInviteInteractor(inviteService);

  const { inviteId } = action;
  try {
    const inviteItem = inviteId
      ? yield inviteInteractor.readInvite(inviteId)
      : undefined;
    if (inviteItem) {
      yield all([
        put(updateInviteStateAction(inviteItem)),
        put(updateAppAction({ isLoading: false })),
      ]);
    } else {
      yield put(
        updateAppAction({
          isLoading: false,
          error: { name: "error", message: "Invite does not exist." },
        })
      );
    }
  } catch (error) {
    yield put(
      updateAppAction({
        isLoading: false,
        error: { name: "error", message: "Invite does not exist." },
      })
    );
  }
}

export const acceptInviteAction = (
  inviteId: string,
  itemId: string | number,
  itemType: "school" | "district"
): InviteActionType => ({
  type: ACCEPT_INVITE,
  inviteId,
  itemId,
  itemType,
});

export function* acceptInviteSaga(action: InviteActionType) {
  yield put(updateAppAction({ isLoading: true }));

  const inviteService = new InviteService();
  const inviteSchoolInteractor = new AcceptInviteSchoolInteractor(
    inviteService
  );
  const inviteDistrictInteractor = new AcceptInviteDistrictInteractor(
    inviteService
  );

  const { inviteId, itemType } = action;
  if (!inviteId) {
    yield put(
      updateAppAction({
        isLoading: false,
        error: new Error("Invite ID cannot be undefined"),
      })
    );
  } else {
    if (itemType === "school") {
      try {
        const schoolAccepted: School = yield inviteSchoolInteractor.acceptSchoolInvite(
          inviteId
        );
        if (schoolAccepted) {
          yield all([
            put(updateUserSchoolStateAction(schoolAccepted)),
            put(updateInviteStateAction()),
            put(updateAppAction({ isLoading: false })),
          ]);
        } else {
          yield put(
            updateAppAction({
              isLoading: false,
              error: { name: "error", message: "Invite does not exist." },
            })
          );
        }
      } catch (error) {
        // @ts-ignore
        yield put(updateAppAction({ isLoading: false, error }));
      }
    }
    if (itemType === "district") {
      try {
        const districtAccepted: District = yield inviteDistrictInteractor.acceptDistrictInvite(
          inviteId
        );
        if (districtAccepted) {
          yield all([
            put(updateUserDistrictStateAction(districtAccepted)),
            put(updateInviteStateAction()),
            put(updateAppAction({ isLoading: false })),
          ]);
        } else {
          yield put(
            updateAppAction({
              isLoading: false,
              error: { name: "error", message: "Invite does not exist." },
            })
          );
        }
      } catch (error) {
        // @ts-ignore
        yield put(updateAppAction({ isLoading: false, error }));
      }
    }
  }
}

interface InviteCancelActionType {
  type: string;
  inviteId?: string;
  itemId: number | string;
  itemType: "school" | "district";
}

export const cancelInviteAction = (
  inviteId: string,
  itemType: "school" | "district",
  itemId: string | number
): InviteActionType => ({
  type: CANCEL_INVITE,
  inviteId,
  itemType,
  itemId,
});

export function* cancelInviteSaga(action: InviteCancelActionType) {
  yield put(updateAppAction({ isLoading: true }));

  const inviteService = new InviteService();
  const schoolService = new SchoolService();
  const districtService = new DistrictService();
  const readSchoolInteractor = new ReadSchoolInteractor(schoolService);
  const readDistrictInteractor = new ReadDistrictInteractor(districtService);
  const readInviteInteractor = new ReadInviteInteractor(inviteService);
  const cancelInviteInteractor = new CancelInviteInteractor(inviteService);

  const { inviteId, itemType, itemId } = action;
  if (!inviteId) {
    yield put(
      updateAppAction({
        isLoading: false,
        error: new Error("Invite ID cannot be undefined"),
      })
    );
  } else {
    try {
      if (itemType === "school") {
        const invite: Invite = yield readInviteInteractor.readInvite(inviteId);
        yield cancelInviteInteractor.cancelInvite(inviteId, itemType, itemId);
        const school: School = yield readSchoolInteractor.readSchool(
          invite.itemId
        );

        const updatedScene: Scene = {
          id: school.id,
          type: "school",
          data: school,
        };

        yield all([
          put(updateSceneStateAction(updatedScene)),
          put(updateAppAction({ isLoading: false })),
        ]);
      }
      if (itemType === "district") {
        const invite: Invite = yield readInviteInteractor.readInvite(inviteId);
        yield cancelInviteInteractor.cancelInvite(inviteId, itemType, itemId);
        const district: District = yield readDistrictInteractor.readDistrict(
          invite.itemId as string
        );

        const updatedScene: Scene = {
          id: district.id,
          type: "district",
          data: district,
        };

        yield all([
          put(updateSceneStateAction(updatedScene)),
          put(updateAppAction({ isLoading: false })),
        ]);
      }
    } catch (error) {
      // @ts-ignore
      yield put(updateAppAction({ isLoading: false, error }));
    }
  }
}
