import { useState, useEffect } from "react";
import Bugsnag from "@bugsnag/js";
import { connect } from "react-redux";
import {
  Activity,
  appSelector,
  createActivityAction,
  createDistrictActivityAction,
  updateActivityAction,
  sceneSelector,
  premiumSelector,
} from "../../core";
import clsx from "clsx";
import { Prompt } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Card,
  Button,
  FormControl,
  InputLabel,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { NavLink, useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import moment from "moment";

import { CircleLoader } from "react-spinners";

// Icons
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { ReactComponent as QuizIcon } from "../../assets/quizIcon.svg";
import { ReactComponent as PencilRulerIcon } from "../../assets/pencilRulerIcon.svg";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import FolderIcon from "@material-ui/icons/Folder";
import LinkIcon from "@material-ui/icons/Link";
import FlashIcon from "@material-ui/icons/FlashOn";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";

/// Handle Google Autocomplete
import { makeStyles } from "@material-ui/core/styles";

// Responsive //
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ActivityDetails from "./activityDetails";
import { readCurriculum } from "../../core/Services/Backend/School";

const handleThemeMonth = (schedule, themeId) => {
  switch (themeId) {
    case 0:
      return schedule === "August" ? "August" : "September";
    case 1:
      return schedule === "August" ? "September" : "October";
    case 2:
      return schedule === "August" ? "October" : "November";
    case 3:
      return schedule === "August" ? "November" : "March";
    case 4:
      return "December";
    case 5:
      return "January";
    case 6:
      return "February";
    case 7:
      return schedule === "August" ? "March" : "April";
    case 8:
      return schedule === "August" ? "April" : "May";
    case 9:
      return schedule === "August" ? "May" : "June";
    default:
      return "";
  }
};

function getSteps() {
  return ["Theme & Grade Levels", "Activity Type", "Details", "Preview"];
}

function getStepContent(step, props) {
  switch (step) {
    case 0:
      return <Step1 {...props} />;
    case 1:
      return <Step2 {...props} />;
    case 2:
      return <Step3 {...props} />;
    case 3:
      return <Step5 {...props} />;
    default:
      return <Step1 {...props} />;
  }
}

const Step1 = (props) => {
  const {
    setUnsavedChanges,
    location,
    gradeLevels,
    setGradeLevels,
    themeChoices,
    themeChoice,
    setThemeChoice,
  } = props;
  const steps = getSteps();

  return (
    <>
      <div className={"py-4"}>
        <h5 className="font-size-xl mb-2 font-weight-bold px-4">
          {location.type === "add" ? "Add Activity" : "Edit Activity"}
        </h5>
        <div className="my-4">
          <Stepper
            className="stepper-horizontal-1"
            style={{ background: "rgba(69, 170, 170, 0.07)" }}
            activeStep={0}
            connector={
              <span className="mx-3">
                <FontAwesomeIcon
                  icon={["fas", "chevron-right"]}
                  color={"rgba(0, 0, 0, 0.51)"}
                />
              </span>
            }
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className="px-4 mt-4 pt-2">
          <p
            className="text-black"
            style={{ fontSize: "14px", fontWeight: 300 }}
          >
            {themeChoices
              ? themeChoices.length
                ? `Select theme and grade levels for this activity.`
                : `Select grade levels for this activity.`
              : `Select grade levels for this activity.`}
          </p>
        </div>
        <Grid container spacing={4} className="px-4">
          {themeChoices ? (
            themeChoices.length ? (
              <Grid item md={12}>
                <div
                  style={{
                    maxWidth: "500px",
                  }}
                  className="mt-3"
                >
                  <InputLabel shrink={true} className="mb-2" required>
                    Theme
                  </InputLabel>
                  <FormControl fullWidth variant="outlined">
                    <Autocomplete
                      options={themeChoices}
                      value={themeChoice}
                      onChange={(event, newValue) => {
                        setUnsavedChanges(true);

                        setThemeChoice(newValue);
                      }}
                      getOptionLabel={(option) =>
                        `${option.name} (${handleThemeMonth(
                          props.sceneData ? props.sceneData.schedule : "August",
                          option.id
                        )})`
                      }
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </FormControl>
                </div>
              </Grid>
            ) : null
          ) : null}
          <Grid item md={12}>
            <div
              style={{
                maxWidth: "500px",
              }}
              className="mt-3"
            >
              <InputLabel shrink={true} className="mb-2" required>
                Grade levels
              </InputLabel>
              <FormControl fullWidth variant="outlined">
                <Autocomplete
                  multiple
                  id="tags-standard"
                  options={[
                    "Select all",
                    "Preschool",
                    "Kindergarten",
                    "1st grade",
                    "2nd grade",
                    "3rd grade",
                    "4th grade",
                    "5th grade",
                    "6th grade",
                    "7th grade",
                    "8th grade",
                    "9th grade",
                    "10th grade",
                    "11th grade",
                    "12th grade",
                  ]}
                  value={gradeLevels}
                  onChange={(event, newValue) => {
                    setUnsavedChanges(true);

                    if (newValue.includes("Select all")) {
                      /* Choose select all latest */
                      if (newValue[newValue.length - 1] === "Select all") {
                        setGradeLevels([
                          "Preschool",
                          "Kindergarten",
                          "1st grade",
                          "2nd grade",
                          "3rd grade",
                          "4th grade",
                          "5th grade",
                          "6th grade",
                          "7th grade",
                          "8th grade",
                          "9th grade",
                          "10th grade",
                          "11th grade",
                          "12th grade",
                        ]);
                        return;
                      } else {
                        /* Remove select all  */
                        props.setGradeLevels(
                          newValue.filter((i) => i !== "Select all")
                        );
                        return;
                      }
                    }

                    props.setGradeLevels(newValue);
                  }}
                  getOptionLabel={(option) => option}
                  renderValue={(selected) => selected.join(", ")}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
const Step2 = (props) => {
  const steps = getSteps();
  const {
    setActivityType,
    handleNext,
    setUnsavedChanges,
    location,
    thumbnail,
    setThumbnail,
    setQuiz,
  } = props;

  return (
    <>
      <div className={"py-4"}>
        <h5 className="font-size-xl mb-2 font-weight-bold px-4">
          {location.type === "add" ? "Add Activity" : "Edit Activity"}
        </h5>
        <div className="my-4">
          <Stepper
            className="stepper-horizontal-1"
            style={{ background: "rgba(69, 170, 170, 0.07)" }}
            activeStep={1}
            connector={
              <span className="mx-3">
                <FontAwesomeIcon
                  icon={["fas", "chevron-right"]}
                  color={"rgba(0, 0, 0, 0.51)"}
                />
              </span>
            }
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className="px-4 mt-4 pt-2">
          <p className="text-black">Select an activity type</p>
        </div>
        <Grid container spacing={6} className="px-4 pt-2" alignItems="stretch">
          <Grid item md={6} lg={12} xl={6}>
            <Card
              style={{ height: "100%" }}
              className="d-flex flex-column justify-content-between"
            >
              <div className="p-4">
                <Grid container spacing={0}>
                  <Grid item md={2}>
                    <div>
                      <PlayCircleFilledIcon
                        style={{ width: "2em", height: "2em" }}
                        color="primary"
                      />
                    </div>
                  </Grid>
                  <Grid item md={10} className="d-flex align-items-center">
                    <div>
                      <div className="font-size-lg font-weight-bold mb-1 text-black">
                        Video
                      </div>
                      <p className="opacity-7 font-size-md mb-0">
                        Create a video that guardians will watch then answers
                        simple questions regarding the video.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div>
                <div className="divider" />
                <div
                  onClick={() => {
                    setActivityType(0);
                    if (!thumbnail || location.type === "add") {
                      setThumbnail(
                        "https://firebasestorage.googleapis.com/v0/b/parent-parties-production.appspot.com/o/appSettings%2Fmultimedia_550x550.png?alt=media&token=167f8996-24f9-443d-bee3-00d6e7f15fa7"
                      );
                    }
                    if (location.type === "add") {
                      setQuiz([
                        {
                          question: `Your first question?`,
                          options: [
                            { id: moment().valueOf(), value: "Option 1" },
                          ],
                        },
                      ]);
                    }
                    setUnsavedChanges(true);
                    handleNext();
                  }}
                  className="px-4 py-3 text-first btn btn-white shadow-none d-flex justify-content-between align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <div>Select activity</div>
                  <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item md={6} lg={12} xl={6}>
            <Card
              style={{ height: "100%" }}
              className="d-flex flex-column justify-content-between"
            >
              <div className="p-4">
                <Grid container spacing={0}>
                  <Grid item md={2}>
                    <div>
                      <QuizIcon
                        style={{
                          width: "2em",
                          height: "2em",
                          fill: "#18A9A9",
                          display: "inline-block",
                          fontSize: "1.5rem",
                          transition:
                            "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                          flexShrink: 0,
                          userSelect: "none",
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item md={10} className="d-flex align-items-center">
                    <div>
                      <div className="font-size-lg font-weight-bold mb-1 text-black">
                        Trivia
                      </div>
                      <p className="opacity-7 font-size-md mb-0">
                        Create a trivia quiz where guardians will answer
                        multiple choice questions.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div>
                <div className="divider" />
                <div
                  onClick={() => {
                    setActivityType(1);
                    if (!thumbnail || location.type === "add") {
                      setThumbnail(
                        "https://firebasestorage.googleapis.com/v0/b/parent-parties-production.appspot.com/o/appSettings%2Fideas_550x550.png?alt=media&token=3442dc75-459f-497b-b1af-32d00b8a0399"
                      );
                    }
                    if (location.type === "add") {
                      setQuiz([
                        {
                          question: `Your first question?`,
                          options: [
                            { id: moment().valueOf(), value: "Option 1" },
                          ],
                        },
                      ]);
                    }
                    setUnsavedChanges(true);
                    handleNext();
                  }}
                  className="px-4 py-3 text-first btn btn-white shadow-none d-flex justify-content-between align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <div>Select activity</div>
                  <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item md={6} lg={12} xl={6}>
            <Card
              style={{ height: "100%" }}
              className="d-flex flex-column justify-content-between"
            >
              <div className="p-4">
                <Grid container spacing={0}>
                  <Grid item md={2}>
                    <div>
                      <PencilRulerIcon
                        style={{
                          color: "#18A9A9",
                          width: "1.7em",
                          height: "1.7em",
                          fill: "#18A9A9",
                          display: "inline-block",
                          fontSize: "1.5rem",
                          transition:
                            "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                          flexShrink: 0,
                          userSelect: "none",
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item md={10} className="d-flex align-items-center">
                    <div>
                      <div className="font-size-lg font-weight-bold mb-1 text-black">
                        Engage at Home
                      </div>
                      <p className="opacity-7 font-size-md mb-0">
                        Write instructions for guardians to play a game or
                        complete a craft project with their child.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div>
                <div className="divider" />
                <div
                  onClick={() => {
                    setActivityType(2);
                    if (!thumbnail || location.type === "add") {
                      setThumbnail(
                        "https://firebasestorage.googleapis.com/v0/b/parent-parties-production.appspot.com/o/appSettings%2Fhome_550x550.png?alt=media&token=a83e3de2-3957-4aa8-9435-ee626adbfc86"
                      );
                    }
                    setUnsavedChanges(true);
                    handleNext();
                  }}
                  className="px-4 py-3 text-first btn btn-white shadow-none d-flex justify-content-between align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <div>Select activity</div>
                  <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item md={6} lg={12} xl={6}>
            <Card
              style={{ height: "100%" }}
              className="d-flex flex-column justify-content-between"
            >
              <div className="p-4">
                <Grid container spacing={0}>
                  <Grid item md={2}>
                    <div>
                      <MenuBookIcon
                        style={{ width: "2em", height: "2em" }}
                        color="primary"
                      />
                    </div>
                  </Grid>
                  <Grid item md={10} className="d-flex align-items-center">
                    <div>
                      <div className="font-size-lg font-weight-bold mb-1 text-black">
                        Reading Rewards
                      </div>
                      <p className="opacity-7 font-size-md mb-0">
                        Provide some reading material for the guardian to read
                        along with the child.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div>
                <div className="divider" />
                <div
                  onClick={() => {
                    setActivityType(3);
                    if (!thumbnail || location.type === "add") {
                      setThumbnail(
                        "https://firebasestorage.googleapis.com/v0/b/parent-parties-production.appspot.com/o/appSettings%2Freading-book_550x550.png?alt=media&token=fee17064-ff4b-4a52-86c3-0d925a3043a9"
                      );
                    }
                    if (location.type === "add") {
                      setQuiz([
                        {
                          question: `What is the title of the reading you did with your child?`,
                          inputQuestion: true,
                        },
                      ]);
                    }
                    setUnsavedChanges(true);
                    handleNext();
                  }}
                  className="px-4 py-3 text-first btn btn-white shadow-none d-flex justify-content-between align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <div>Select activity</div>
                  <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item md={6} lg={12} xl={6}>
            <Card
              style={{ height: "100%" }}
              className="d-flex flex-column justify-content-between"
            >
              <div className="p-4">
                <Grid container spacing={0}>
                  <Grid item md={2}>
                    <div>
                      <FolderIcon
                        style={{ width: "2em", height: "2em" }}
                        color="primary"
                      />
                    </div>
                  </Grid>
                  <Grid item md={10} className="d-flex align-items-center">
                    <div>
                      <div className="font-size-lg font-weight-bold mb-1 text-black">
                        School Activity
                      </div>
                      <p className="opacity-7 font-size-md mb-0">
                        Upload files for guardians to review.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div>
                <div className="divider" />
                <div
                  onClick={() => {
                    setActivityType(4);
                    if (!thumbnail || location.type === "add") {
                      setThumbnail(
                        "https://firebasestorage.googleapis.com/v0/b/parent-parties-production.appspot.com/o/appSettings%2Ftextbook_550x550.png?alt=media&token=d705f0de-1b10-44bd-ada4-b0ff65e04a78"
                      );
                    }
                    if (location.type === "add") {
                      setQuiz([
                        {
                          question: `Your first question?`,
                          options: [
                            { id: moment().valueOf(), value: "Option 1" },
                          ],
                        },
                      ]);
                    }
                    setUnsavedChanges(true);
                    handleNext();
                  }}
                  className="px-4 py-3 text-first btn btn-white shadow-none d-flex justify-content-between align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <div>Select activity</div>
                  <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item md={6} lg={12} xl={6}>
            <Card
              style={{ height: "100%" }}
              className="d-flex flex-column justify-content-between"
            >
              <div className="p-4">
                <Grid container spacing={0}>
                  <Grid item md={2}>
                    <div>
                      <LinkIcon
                        style={{ width: "2em", height: "2em" }}
                        color="primary"
                      />
                    </div>
                  </Grid>
                  <Grid item md={10} className="d-flex align-items-center">
                    <div>
                      <div className="font-size-lg font-weight-bold mb-1 text-black">
                        Link
                      </div>
                      <p className="opacity-7 font-size-md mb-0">
                        Add URL link(s) to an external resource such as a form
                        or hosted PDF.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div>
                <div className="divider" />
                <div
                  onClick={() => {
                    setActivityType(5);
                    if (!thumbnail || location.type === "add") {
                      setThumbnail(
                        "https://firebasestorage.googleapis.com/v0/b/parent-parties-production.appspot.com/o/appSettings%2Fweb-link2_550x550.png?alt=media&token=d96d29c8-67b2-4543-9477-7c64b9b3e5c5"
                      );
                    }
                    if (location.type === "add") {
                      setQuiz([
                        {
                          question: `Did you follow the links provided in this activity?`,
                          inputQuestion: true,
                        },
                      ]);
                    }
                    setUnsavedChanges(true);
                    handleNext();
                  }}
                  className="px-4 py-3 text-first btn btn-white shadow-none d-flex justify-content-between align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <div>Select activity</div>
                  <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item md={6} lg={12} xl={6}>
            <Card
              style={{ height: "100%" }}
              className="d-flex flex-column justify-content-between"
            >
              <div className="p-4">
                <Grid container spacing={0}>
                  <Grid item md={2}>
                    <div>
                      <FlashIcon
                        style={{
                          color: "#18A9A9",
                          width: "1.7em",
                          height: "1.7em",
                          fill: "#18A9A9",
                          display: "inline-block",
                          fontSize: "1.5rem",
                          transition:
                            "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                          flexShrink: 0,
                          userSelect: "none",
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item md={10} className="d-flex align-items-center">
                    <div>
                      <div className="font-size-lg font-weight-bold mb-1 text-black">
                        Fast Facts
                      </div>
                      <p className="opacity-7 font-size-md mb-0">
                        Ask guardians to write which facts apply to the their
                        student the most.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div>
                <div className="divider" />
                <div
                  onClick={() => {
                    setActivityType(6);
                    if (!thumbnail || location.type === "add") {
                      setThumbnail(
                        "https://firebasestorage.googleapis.com/v0/b/parent-parties-production.appspot.com/o/appSettings%2FScreenshot%202023-06-16%20at%205.55.48%20PM_550x550.png?alt=media&token=75ffc64a-d812-49fc-8754-c7ac50934aef"
                      );
                    }
                    if (location.type === "add") {
                      setQuiz([
                        {
                          question: `Which of these facts applies most to your student?`,
                          inputQuestion: true,
                        },
                      ]);
                    }
                    setUnsavedChanges(true);
                    handleNext();
                  }}
                  className="px-4 py-3 text-first btn btn-white shadow-none d-flex justify-content-between align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <div>Select activity</div>
                  <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item md={6} lg={12} xl={6}>
            <Card
              style={{ height: "100%" }}
              className="d-flex flex-column justify-content-between"
            >
              <div className="p-4">
                <Grid container spacing={0}>
                  <Grid item md={2}>
                    <div>
                      <QuestionAnswer
                        style={{
                          color: "#18A9A9",
                          width: "1.7em",
                          height: "1.7em",
                          fill: "#18A9A9",
                          display: "inline-block",
                          fontSize: "1.5rem",
                          transition:
                            "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                          flexShrink: 0,
                          userSelect: "none",
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item md={10} className="d-flex align-items-center">
                    <div>
                      <div className="font-size-lg font-weight-bold mb-1 text-black">
                        More to Explore
                      </div>
                      <p className="opacity-7 font-size-md mb-0">
                        Ask guardians to speak with their student about a
                        certain topic.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div>
                <div className="divider" />
                <div
                  onClick={() => {
                    setActivityType(7);
                    if (!thumbnail || location.type === "add") {
                      setThumbnail(
                        "https://firebasestorage.googleapis.com/v0/b/parent-parties-production.appspot.com/o/appSettings%2FScreenshot%202023-06-16%20at%206.06.41%20PM_550x550.png?alt=media&token=d8435478-8b0c-4318-89c7-29130c4beb51"
                      );
                    }
                    if (location.type === "add") {
                      setQuiz([
                        {
                          question: `Did you speak to your student about this topic?`,
                          inputQuestion: true,
                        },
                      ]);
                    }
                    setUnsavedChanges(true);
                    handleNext();
                  }}
                  className="px-4 py-3 text-first btn btn-white shadow-none d-flex justify-content-between align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <div>Select activity</div>
                  <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const Step3 = (props) => {
  const steps = getSteps();
  const { location } = props;
  return (
    <>
      <div className={"py-4"}>
        <h5 className="font-size-xl mb-2 font-weight-bold px-4">
          {location.type === "add" ? "Add Activity" : "Edit Activity"}
        </h5>
        <div className="my-4">
          <Stepper
            className="stepper-horizontal-1"
            style={{ background: "rgba(69, 170, 170, 0.07)" }}
            activeStep={2}
            connector={
              <span className="mx-3">
                <FontAwesomeIcon
                  icon={["fas", "chevron-right"]}
                  color={"rgba(0, 0, 0, 0.51)"}
                />
              </span>
            }
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <ActivityDetails {...props} />
      </div>
    </>
  );
};

const Step5 = (props) => {
  const steps = getSteps();
  const { location } = props;

  return (
    <>
      <div className={"py-4"}>
        <h5 className="font-size-xl mb-2 font-weight-bold px-4">
          {location.type === "add" ? "Add Activity" : "Edit Activity"}
        </h5>
        <div className="my-4">
          <Stepper
            className="stepper-horizontal-1"
            style={{ background: "rgba(69, 170, 170, 0.07)" }}
            activeStep={3}
            connector={
              <span className="mx-3">
                <FontAwesomeIcon
                  icon={["fas", "chevron-right"]}
                  color={"rgba(0, 0, 0, 0.51)"}
                />
              </span>
            }
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <ActivityDetails {...props} preview={true} />
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  noBoxShadow: {
    boxShadow: "none !important",
  },
  selectStyles: {
    fontSize: "14px",
    background: "white",
    "&:focus": {
      backgroundColor: "white !important",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "400px",
    },
  },
  fixInput: {
    borderWidth: "1px !important",
    "& $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
    "&:hover $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
    "&$focused $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
  },
  focused: {},
  notchedOutline: {},
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "4px",
    color: theme.palette.grey[500],
    padding: "2px",
  },
}));

function AddActivity(props) {
  const classes = useStyles();
  let sceneData = props.scene ? props.scene.data : null;

  // Extra logic for adding on district level
  if (sceneData) {
    if (sceneData.schoolData) {
      if (Object.values(sceneData.schoolData).length) {
        let schoolsData = Object.values(sceneData.schoolData);
        let firstSchool = schoolsData[0];
        let firstSchoolSchedule = firstSchool.schedule;
        if (firstSchoolSchedule) {
          sceneData = { ...sceneData, schedule: firstSchoolSchedule };
        }
      }
    }
  }

  const location = useLocation();
  const { pathname } = location;
  const params = pathname.split("/");
  const sceneType = params[2];
  const sceneId = params[3];

  // Step 1
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [gradeLevels, setGradeLevels] = useState([]);
  const [themeChoices, setThemeChoices] = useState([]);
  const [themeChoice, setThemeChoice] = useState(null);

  // Step 2
  const [activityType, setActivityType] = useState(0);

  // Step 3
  const [activityName, setActivityName] = useState("");
  const [points, setPoints] = useState("");
  const [repeatable, setRepeatable] = useState(false);
  const [information, setInformation] = useState("");
  const [quiz, setQuiz] = useState([
    {
      question: `Your first question?`,
      options: [{ id: moment().valueOf(), value: "Option 1" }],
    },
  ]);
  const [thumbnail, setThumbnail] = useState(null);
  const [video, setVideo] = useState(null);
  const [videoYoutube, setVideoYoutube] = useState("");
  const [schoolFiles, setSchoolFiles] = useState([]);
  const [links, setLinks] = useState([]);

  // Create and Update handle //
  const [error, setError] = useState("");
  const [activityLoading, setActivityLoading] = useState(false);

  /// End functions handle
  const [formErrors, setFormErrors] = useState({
    name: false,
    information: false,
    points: false,
    thumbnail: false,
    video: false,
    quiz: false,
  });
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const themeCSS = useTheme();
  const mobile = useMediaQuery(themeCSS.breakpoints.down("sm"));

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [activeStep]);

  useEffect(() => {
    if (props.location.type === "edit" && props.location.activityData) {
      const { activityData } = props.location;

      // Step 1
      if (activityData.gradeLevels) {
        setGradeLevels(activityData.gradeLevels);
      }

      // Step 2
      setActivityType(activityData.type);

      // Step 3
      // En
      setActivityName(activityData.name);
      setPoints(activityData.points);
      setRepeatable(activityData.repeatable || false);
      setInformation(activityData.information);
      setQuiz(
        activityData.quiz
          ? activityData.quiz
          : [
              {
                question: `Your first question?`,
                options: [{ id: moment().valueOf(), value: "Option 1" }],
              },
            ]
      );
      setThumbnail(activityData.thumbnail ? activityData.thumbnail : null);
      setVideo(activityData.video ? activityData.video : null);
      setVideoYoutube(
        activityData.videoYoutube ? activityData.videoYoutube : ""
      );

      /// School Activity
      setSchoolFiles(activityData.schoolFiles ? activityData.schoolFiles : []);

      // Link
      setLinks(activityData.links ? activityData.links : []);

      // Fast forward to third step
      setActiveStep(2);
    }
  }, [props.location]);

  useEffect(() => {
    /* Handle Theme */
    if (sceneData) {
      /* Pull curriculum on schools */
      if (sceneData.curriculum) {
        async function handlePullCurr() {
          const curriculum = await readCurriculum(sceneData.curriculum);
          if (curriculum) {
            const themes = (curriculum.themes || []).map((i, index) => ({
              ...i,
              idIndex: index,
            }));
            const mapped = themes.filter((i) => i.name);
            setThemeChoices(mapped);
          }
        }

        handlePullCurr();
      }
    }
  }, [sceneData]);

  const finishActivity = (draft) => {
    try {
      const activityData = props.location.activityData;

      setUnsavedChanges(false);

      if (props.location.type === "edit") {
        onUpdateActivity(
          new Activity({
            ...activityData,
            type: activityType,
            points: parseInt(points),
            repeatable: repeatable || false,
            // En
            name: activityName,
            information,
            quiz,
            thumbnail,
            video,
            videoYoutube,
            // School Activity
            schoolFiles: schoolFiles,
            // Links
            links: links,

            // Draft / Live Status
            status: draft ? "draft" : "live",

            // Grade Levels
            gradeLevels,
          })
        );
        return;
      }

      // District Activity creation
      if (sceneData) {
        if (sceneData.schoolData) {
          onCreateDistrictActivity(
            Object.keys(sceneData.schoolIds),
            new Activity({
              allSchools: sceneData ? false : true,
              schoolId: sceneData ? sceneData.id : undefined,
              type: activityType,
              points: parseInt(points),
              repeatable: repeatable || false,
              // En
              name: activityName,
              information,
              thumbnail,
              quiz,
              video,
              videoYoutube,

              // School Activity
              schoolFiles: schoolFiles,

              // Links
              links: links,

              // Draft / Live Status
              status: draft ? "draft" : "live",

              // Grade Levels
              gradeLevels,
            })
          );

          return;
        }
      }

      onCreateActivity(
        new Activity({
          allSchools: sceneData ? false : true,
          schoolId: sceneData ? sceneData.id : undefined,
          type: activityType,
          points: parseInt(points),
          repeatable: repeatable || false,
          // En
          name: activityName,
          information,
          thumbnail,
          quiz,
          video,
          videoYoutube,

          // School Activity
          schoolFiles: schoolFiles,

          // Links
          links: links,

          // Draft / Live Status
          status: draft ? "draft" : "live",

          // Grade Levels
          gradeLevels,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const onCreateActivity = (activity) => {
    if (activityLoading) {
      return;
    }
    try {
      props.dispatchCreateActivity(activity, themeChoice);
    } catch (err) {
      setError(err.toString());
    }
  };
  const onCreateDistrictActivity = (schoolIds, activity) => {
    if (activityLoading) {
      return;
    }
    try {
      props.dispatchCreateDistrictActivity(schoolIds, activity);
    } catch (err) {
      setError(err.toString());
    }
  };

  const onUpdateActivity = (activity) => {
    if (activityLoading) {
      return;
    }
    try {
      props.dispatchUpdateActivity(activity, themeChoice);
    } catch (err) {
      setError(err.toString());
    }
  };

  useEffect(() => {
    if (activityLoading) {
      if (!props.app.isLoading) {
        if (props.app.error) {
          Bugsnag.notify(props.app.error);
          setError(
            activityLoading === "update"
              ? `Could not update activity. Error: ${props.app.error.message}`
              : `Could not add activity. Error: ${props.app.error.message}`
          );
          setActivityLoading(false);
        } else {
          setError("");
          setActivityLoading(false);
        }
      }
    }
  }, [props.app.error, props.app.isLoading, activityLoading]);

  const handleNext = (draft) => {
    /// Check Form Per Step ///
    let errors = {
      name: false,
      information: false,
      points: false,
      thumbnail: false,
      video: false,
      quizOneQuestion: false,
      quizValidQuestion: false,
      quizCorrectAnswer: false,
    };
    if (activeStep === 2) {
      if (!activityName) {
        errors.name = true;
      }
      if (!information) {
        errors.information = true;
      }
      if (!points) {
        errors.points = true;
      }
      if (!thumbnail) {
        errors.thumbnail = true;
      }
      if (!video && !videoYoutube && activityType === 0) {
        errors.video = true;
      }
      if (!schoolFiles.length && activityType === 4) {
        errors.schoolFiles = true;
      }
      if (!links.length && activityType === 5) {
        errors.links = true;
      }
      if ([0, 1, 3, 4, 6, 7].includes(activityType)) {
        // No Question
        if (!quiz.length) {
          errors.quiz = true;
        }
        if (quiz.length) {
          // No title
          if (quiz.filter((quizQuestion) => !quizQuestion.question).length) {
            errors.quiz = true;
          }
          // No option value
          if (
            quiz.filter(
              (quizQuestion) =>
                !quizQuestion.inputQuestion &&
                quizQuestion.options.filter((option) => !option.value).length
            ).length
          ) {
            errors.quiz = true;
          }
          // No correct answer
          if (
            quiz.filter(
              (quizQuestion) =>
                !quizQuestion.inputQuestion && !quizQuestion.correctAnswer
            ).length
          ) {
            errors.quiz = true;
          }
        }
      }
      if (
        errors.name ||
        errors.information ||
        errors.points ||
        errors.thumbnail ||
        errors.video ||
        errors.schoolFiles ||
        errors.quiz ||
        errors.links
      ) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        return setFormErrors(errors);
      } else {
        setFormErrors({
          name: false,
          information: false,
          points: false,
          thumbnail: false,
          video: false,
          quiz: false,
          schoolFiles: false,
          links: false,
        });
      }
    }

    if (draft) {
      // Run Loader
      setActivityLoading(props.location.type === "add" ? "create" : "update");
      finishActivity(draft);

      setActiveStep((prevActiveStep) => prevActiveStep + 2);
      return;
    }

    // Last Step run functions //
    if (activeStep === steps.length - 1) {
      // Run Loader
      setActivityLoading(props.location.type === "add" ? "create" : "update");
      finishActivity();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
      <Prompt
        when={unsavedChanges}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <Grid container spacing={4}>
        <Grid item md={12} xl={12}>
          <div
            className="d-flex flex-row justify-content-between align-items-center"
            style={{ height: "52px" }}
          >
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              {sceneData
                ? sceneData.schoolIds
                  ? "District Activities"
                  : "School Activities"
                : "Admin Activities"}
            </div>
          </div>
        </Grid>
        <Grid item md={12}>
          <Card
            style={{
              borderRadius: "0.25rem",
              border: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <div>
              {activeStep === steps.length ? (
                <div className="text-center p-5">
                  {activityLoading ? (
                    <>
                      <div className="avatar-icon-wrapper rounded-circle m-0">
                        <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper m-0 d-50">
                          <CircleLoader
                            className="d-flex align-self-center"
                            color={"#18a9a9"}
                            loading={true}
                            size={50}
                          />
                        </div>
                      </div>
                      <h4 className="font-weight-bold mt-4">{`${
                        props.location.type === "add"
                          ? "Adding activity."
                          : "Updating activity"
                      }`}</h4>
                    </>
                  ) : (
                    <>
                      <div className="avatar-icon-wrapper rounded-circle m-0">
                        <div
                          className={clsx(
                            "d-inline-flex",
                            "justify-content-center",
                            "p-0",
                            "rounded-circle",
                            "btn-icon",
                            "avatar-icon-wrapper",
                            "m-0",
                            "d-130",
                            error ? "bg-neutral-danger" : "bg-neutral-success",
                            error ? "text-danger" : "text-success"
                          )}
                        >
                          <FontAwesomeIcon
                            icon={["far", "lightbulb"]}
                            className="d-flex align-self-center display-3"
                          />
                        </div>
                      </div>
                      <h4 className="font-weight-bold mt-4">
                        {error
                          ? error
                          : `Activity has been successfully ${
                              props.location.type === "add"
                                ? "added"
                                : "updated"
                            }!`}
                      </h4>
                      <div className="pt-4">
                        {error ? (
                          <Button
                            onClick={handleReset}
                            className="btn-primary font-weight-bold rounded hover-scale-lg mx-1"
                            size="large"
                          >
                            <span className="btn-wrapper--label">
                              Back to Form
                            </span>
                          </Button>
                        ) : (
                          <NavLink
                            to={
                              sceneData
                                ? sceneData.schoolIds
                                  ? `/district-activities/${sceneType}/${sceneId}`
                                  : `/activities/${sceneType}/${sceneId}`
                                : `/admin-activities`
                            }
                          >
                            <Button
                              className="btn-primary font-weight-bold rounded hover-scale-lg mx-1"
                              size="large"
                            >
                              <span className="btn-wrapper--label">
                                Back to Activities
                              </span>
                            </Button>
                          </NavLink>
                        )}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div>
                  <div>
                    {getStepContent(activeStep, {
                      mobile,
                      classes,
                      setUnsavedChanges,
                      formErrors,
                      setFormErrors,
                      location: props.location,
                      sceneData,
                      // Step 1,
                      themeChoices,
                      themeChoice,
                      setThemeChoice,
                      gradeLevels,
                      setGradeLevels,
                      // Step 2
                      activityType,
                      setActivityType,
                      handleNext,
                      // Step 3
                      activityName,
                      setActivityName,
                      points,
                      setPoints,
                      repeatable,
                      setRepeatable,
                      information,
                      setInformation,
                      quiz,
                      setQuiz,
                      thumbnail,
                      setThumbnail,
                      video,
                      setVideo,
                      videoYoutube,
                      setVideoYoutube,
                      schoolFiles,
                      setSchoolFiles,
                      links,
                      setLinks,
                    })}
                  </div>
                  <div className="card-footer mt-4 p-4 d-flex align-items-center justify-content-between bg-secondary">
                    <Button
                      variant="text"
                      className={
                        activeStep === 0
                          ? "btn-pill m-2"
                          : "btn-pill m-2 btn-outline-primary"
                      }
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Back
                    </Button>
                    {activeStep === 1 ? null : (
                      <div className="d-flex flex-row align-items-center">
                        {activeStep === 3 ? (
                          props.location.type === "add" ||
                          (props.location.type === "edit"
                            ? props.location.activityData
                              ? props.location.activityData.status
                                ? props.location.activityData.status !== "live"
                                : false
                              : false
                            : false) ? (
                            <Button
                              className="btn-pill btn-dark font-weight-bold mr-4"
                              onClick={() => {
                                handleNext(true);
                              }}
                            >
                              Save as Draft
                            </Button>
                          ) : null
                        ) : null}
                        <Button
                          className="btn-pill btn-primary font-weight-bold"
                          onClick={() => handleNext()}
                          disabled={activeStep === 0 && !gradeLevels.length}
                        >
                          {activeStep === steps.length - 1
                            ? "Finish"
                            : activeStep === 0 || activeStep === 2
                            ? "Next"
                            : "Review Activity"}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  premium: premiumSelector(state),
});
const mapDispatchToProps = {
  dispatchCreateActivity: createActivityAction,
  dispatchCreateDistrictActivity: createDistrictActivityAction,
  dispatchUpdateActivity: updateActivityAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddActivity);
