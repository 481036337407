import { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useLocation, NavLink } from "react-router-dom";
import {
  sceneSelector,
  appSelector,
  userSelector,
  readSceneAction,
  ParentPartiesAPIs,
} from "../../core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Card,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import Chart from "react-apexcharts";
import AGGrid from "../Grid";
import { DayPickerRangeController } from "react-dates";

// Responsive //
import { useTheme, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  noBoxShadow: {
    boxShadow: "none !important",
  },
  selectStyles: {
    fontSize: "14px",
    background: "white",
    "&:focus": {
      backgroundColor: "white !important",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "125px",
    },
  },
  fixInput: {
    borderWidth: "1px !important",
    "& $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
    "&:hover $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
    "&$focused $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
  },
  focused: {},
  notchedOutline: {},
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "4px",
    color: theme.palette.grey[500],
    padding: "2px",
  },
}));

const themes = [
  {
    id: 0,
    name: "Off to a Good Start",
    subtext: "Starts in August or September",
  },
  {
    id: 1,
    name: "Helping with Homework",
    subtext: "Starts in September or October",
  },
  {
    id: 2,
    name: "Conference Conversations",
    subtext: "Starts in October or November",
  },
  {
    id: 3,
    name: "Doctor's Orders",
    subtext: "Starts in November or March",
  },
  {
    id: 4,
    name: "Make Time for Reading Together",
    subtext: "Starts in December",
  },
  {
    id: 5,
    name: "Spreading Kindness",
    subtext: "Starts in January",
  },
  {
    id: 6,
    name: "Arts in Education",
    subtext: "Starts in February",
  },
  {
    id: 7,
    name: "Math Matters",
    subtext: "Starts in March or April",
  },
  {
    id: 8,
    name: "Positive Parenting",
    subtext: "Starts in April or May",
  },
  {
    id: 9,
    name: "Celebrating Parents",
    subtext: "Starts in May or June",
  },
];

function DistrictActivityAnalytics(props) {
  const location = useLocation();
  const { pathname } = location;
  const params = pathname.split("/");
  const sceneType = params[2];
  const sceneId = params[3];

  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [timeFrame, setTimeFrame] = useState("August");

  const [analytics, setAnalytics] = useState(null);
  const [dateRangeDialog, setDateRangeDialog] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState({
    startDate: null,
    endDate: null,
  });
  const [dateRangeFocus, setDateRangeFocus] = useState("startDate");
  const handleCloseDateRange = () => {
    setDateRangeDialog(false);
    setDateRangeValue({
      startDate: null,
      endDate: null,
    });
  };

  useEffect(() => {
    async function fetchData(dateRange, scene) {
      try {
        const goal = moment().set({ date: 15 }).valueOf();
        const closestMonth = dateRange.reduce((prev, curr) =>
          Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev
        );

        setTimeFrame(closestMonth);

        const startDate = moment(closestMonth).startOf("month");
        const endDate = moment(closestMonth).endOf("month");

        const schoolIds = scene
          ? scene.data
            ? Object.keys(scene.data.schoolIds)
            : []
          : [];

        const result = await ParentPartiesAPIs.fetchDistrictActivityAnalytics(
          schoolIds,
          startDate,
          endDate
        );
        setAnalytics(result);
      } catch (err) {
        console.log(err);
      }
    }

    if (props.app.dateRange && props.scene) {
      /* Closest date */
      fetchData(props.app.dateRange, props.scene);
    }
  }, [props.app.dateRange, props.scene]);

  const [chartView, setChartView] = useState("Activity Views");
  const changeChartView = (chartType) => {
    setChartView(chartType);
  };
  const changeTimeFrame = async (timeFrame) => {
    if (timeFrame === "Custom" || timeFrame === "School Year") {
      return;
    }
    // Reset Date Range
    else {
      setDateRangeValue({
        startDate: null,
        endDate: null,
      });
    }
    setTimeFrame(timeFrame);

    const startDate = moment(timeFrame).startOf("month");
    const endDate = moment(timeFrame).endOf("month");

    const schoolIds = props.scene
      ? props.scene.data
        ? Object.keys(props.scene.data.schoolIds)
        : []
      : [];
    const result = await ParentPartiesAPIs.fetchDistrictActivityAnalytics(
      schoolIds,
      startDate,
      endDate
    );

    setAnalytics(result);
  };

  const handleDateRangePick = async () => {
    setTimeFrame("Custom");

    const startDate = dateRangeValue.startDate
      ? moment(dateRangeValue.startDate).startOf("day")
      : dateRangeValue.endDate;
    const endDate = dateRangeValue.endDate
      ? moment(dateRangeValue.endDate).endOf("day")
      : moment(dateRangeValue.startDate).endOf("day");

    if (startDate === null && endDate === null) {
      return handleCloseDateRange();
    }

    const schoolIds = props.scene
      ? props.scene.data
        ? Object.keys(props.scene.data.schoolIds)
        : []
      : [];
    const result = await ParentPartiesAPIs.fetchDistrictActivityAnalytics(
      schoolIds,
      startDate,
      endDate
    );

    setAnalytics(result);
    setDateRangeDialog(false);
  };
  const handleSchoolYearPick = async () => {
    setDateRangeValue({
      startDate: null,
      endDate: null,
    });
    setTimeFrame("School Year");

    const startDate = props.app.dateRange
      ? props.app.dateRange[0]
        ? moment(props.app.dateRange[0]).startOf("month")
        : null
      : null;
    const endDate = props.app.dateRange
      ? props.app.dateRange.length
        ? moment(props.app.dateRange[props.app.dateRange.length - 1]).endOf(
            "month"
          )
        : null
      : null;

    const schoolIds = props.scene
      ? props.scene.data
        ? Object.keys(props.scene.data.schoolIds)
        : []
      : [];
    const result = await ParentPartiesAPIs.fetchDistrictActivityAnalytics(
      schoolIds,
      startDate,
      endDate
    );

    setAnalytics(result);
  };

  let chartOptions = {
    stroke: {
      show: true,
      curve: "smooth",
      width: 2,
    },
    chart: {
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
      animations: {
        enabled: false,
      },
    },
    fill: {
      opacity: 1,
    },
    // labels: dateRange,
    xaxis: {
      type: "datetime",
      labels: {
        trim: false,
        rotate: 0,
        minHeight: 40,
        hideOverlappingLabels: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      strokeDashArray: "5",
      borderColor: "rgba(125, 138, 156, 0.3)",
    },
    legend: {
      show: true,
    },
    markers: {
      size: 0,
    },
    tooltip: {
      x: {
        format: "MMMM yyyy",
      },
    },
  };

  const handleRenderChart = (chartView) => {
    switch (chartView) {
      case "Activity Views":
        return (
          <Chart
            options={{
              ...chartOptions,
              chart: {
                ...chartOptions.chart,
                toolbar: {
                  show: mobile ? false : true,
                },
                zoom: {
                  enabled: mobile ? false : true,
                },
                animations: {
                  enabled: true,
                },
              },
            }}
            series={[
              {
                name: "Activity Views",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.activityViews
                      ? analytics.charts.activityViews.data
                      : []
                    : []
                  : [],
              },
            ]}
            type="line"
            height={300}
          />
        );
      case "Activites Completed by Type":
        return (
          <Chart
            options={{
              ...chartOptions,
              chart: {
                ...chartOptions.chart,
                toolbar: {
                  show: mobile ? false : true,
                },
                zoom: {
                  enabled: mobile ? false : true,
                },
                animations: {
                  enabled: true,
                },
              },
              tooltip: {
                ...chartOptions.tooltip,
                y: {
                  formatter: function (value) {
                    return Math.round(value);
                  },
                },
              },
              colors: [
                "#018FFB",
                "#01E396",
                "#FEB019",
                "#FF4460",
                "#785DD0",
                "#000",
              ],
            }}
            series={[
              {
                name: "Video",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.activityByType.activity0
                    : []
                  : [],
              },
              {
                name: "Trivia",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.activityByType.activity1
                    : []
                  : [],
              },
              {
                name: "Engage at Home",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.activityByType.activity2
                    : []
                  : [],
              },
              {
                name: "Reading Rewards",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.activityByType.activity3
                    : []
                  : [],
              },
              {
                name: "School Activity",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.activityByType.activity4
                    : []
                  : [],
              },
              {
                name: "Link",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.activityByType.activity5
                    : []
                  : [],
              },
              {
                name: "Fast Facts",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.activityByType.activity6
                    : []
                  : [],
              },
              {
                name: "More to Explore",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.activityByType.activity7
                    : []
                  : [],
              },
            ]}
            type="bar"
            height={300}
          />
        );
      case "Total Points":
        return (
          <Chart
            options={{
              ...chartOptions,
              chart: {
                ...chartOptions.chart,
                toolbar: {
                  show: mobile ? false : true,
                },
                zoom: {
                  enabled: mobile ? false : true,
                },
                animations: {
                  enabled: true,
                },
              },
            }}
            series={[
              {
                name: "Total Points",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.totalPoints
                      ? analytics.charts.totalPoints.data
                      : []
                    : []
                  : [],
              },
            ]}
            type="line"
            height={300}
          />
        );
      default:
        return null;
    }
  };

  const appActivityAvailable = props.scene
    ? props.scene.data
      ? props.scene.data.schoolData
        ? Object.values(props.scene.data.schoolData).every(
            (school) =>
              school.plan === "Premium" || school.plan === "Demo - Premium"
          )
        : false
      : false
    : false;

  return !props.user ? null : props.scene ? (
    <>
      <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
        <Grid item md={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: 13,
              paddingBottom: 13,
            }}
          >
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              District Activities
            </div>
            {appActivityAvailable ? (
              <Button
                variant="contained"
                size="medium"
                className="btn-success shadow-none ml-4"
                component={NavLink}
                to={{
                  pathname: `/add-activity/${sceneType}/${sceneId}`,
                  type: "add",
                }}
              >
                <span className="btn-wrapper--icon mr-1">
                  <FontAwesomeIcon
                    style={{ fontSize: "12px" }}
                    icon={["fas", "plus"]}
                  />
                </span>
                <span
                  className="btn-wrapper--label"
                  style={{ fontSize: "12px" }}
                >
                  Add Activity
                </span>
              </Button>
            ) : null}
          </div>
        </Grid>
        <div className="divider mt-2 mb-4 w-100" />
        <div
          className="mt-4 w-100 d-flex flex-row align-items-center justify-content-end"
          // style={{ marginTop: "3rem" }}
        >
          {/* <div
            className="heading-4 text-left"
            style={{
              textTransform: "none",
              marginRight: "10px",
              marginBottom: 0,
              color: "#000",
            }}
          >
            Analytics
          </div> */}
          {props.app.dateRange ? (
            <FormControl
              variant="outlined"
              margin="dense"
              style={{ boxShadow: "none" }}
            >
              <Select
                autoWidth={false}
                value={timeFrame}
                renderValue={(value) => {
                  return value === "Custom"
                    ? `${
                        dateRangeValue.startDate
                          ? moment(dateRangeValue.startDate).format("ll")
                          : ""
                      }${
                        dateRangeValue.endDate
                          ? `${
                              dateRangeValue.startDate
                                ? ` - ${moment(dateRangeValue.endDate).format(
                                    "ll"
                                  )}`
                                : moment(dateRangeValue.endDate).format("ll")
                            }`
                          : ""
                      }`
                    : value === "School Year"
                    ? "School Year"
                    : moment(value).format("MMMM YYYY");
                }}
                onChange={(e) => {
                  changeTimeFrame(e.target.value);
                }}
                classes={{
                  select: classes.selectStyles,
                }}
                input={
                  <OutlinedInput
                    classes={{
                      root: classes.fixInput,
                      focused: classes.focused,
                      notchedOutline: classes.notchedOutline,
                    }}
                  />
                }
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {(props.app.dateRange ? props.app.dateRange : []).map(
                  (date) => (
                    <MenuItem value={date} dense key={date}>
                      {moment(date).format("MMMM YYYY")}
                    </MenuItem>
                  )
                )}
                <MenuItem
                  value={"School Year"}
                  dense
                  onClick={() => {
                    handleSchoolYearPick();
                  }}
                >
                  School Year
                </MenuItem>
                <MenuItem
                  value={"Custom"}
                  dense
                  onClick={() => {
                    setDateRangeDialog(true);
                  }}
                >
                  Custom
                </MenuItem>
              </Select>
            </FormControl>
          ) : null}
        </div>
        <Grid container className="mt-2" spacing={4}>
          <Grid item md={12}>
            <Card
              className="p-3"
              style={{
                borderRadius: "0.25rem",
                border: "1px solid rgba(0,0,0,.1)",
              }}
            >
              <FormControl
                variant="outlined"
                margin="dense"
                style={{ boxShadow: "none" }}
              >
                <Select
                  value={chartView}
                  onChange={(e) => {
                    changeChartView(e.target.value);
                  }}
                  classes={{
                    select: classes.selectStyles,
                  }}
                  input={
                    <OutlinedInput
                      classes={{
                        root: classes.fixInput,
                        focused: classes.focused,
                        notchedOutline: classes.notchedOutline,
                      }}
                    />
                  }
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem value={"Activity Views"} dense>
                    Activity Views
                  </MenuItem>
                  <MenuItem value={"Activites Completed by Type"} dense>
                    Activites Completed by Type
                  </MenuItem>
                  <MenuItem value={"Total Points"} dense>
                    Total Points
                  </MenuItem>
                </Select>
              </FormControl>
              {handleRenderChart(chartView)}
            </Card>
          </Grid>
          <Grid item md={12}>
            <div
              className="heading-4 text-left"
              style={{
                textTransform: "none",
                marginRight: "10px",
                marginBottom: 0,
                color: "#000",
                marginTop: "3rem",
              }}
            >
              Activity Performance
            </div>
            <div className="mt-4">
              <AGGrid
                rowData={
                  analytics
                    ? analytics.activites
                      ? analytics.activites
                      : []
                    : []
                }
                search={false}
                autoHeight={true}
                columnDefs={[
                  {
                    headerName: "Name",
                    field: "name",
                    sortable: true,
                    filter: true,
                  },
                  {
                    headerName: "Theme",
                    field: "theme",
                    sortable: true,
                    filter: true,
                    valueGetter: function (params) {
                      if (params.data.theme !== undefined) {
                        return themes.find(
                          (theme) => theme.id === params.data.theme
                        )
                          ? themes.find(
                              (theme) => theme.id === params.data.theme
                            ).name
                          : "";
                      } else {
                        return null;
                      }
                    },
                  },
                  {
                    headerName: "Total points",
                    field: "totalPoints",
                    sortable: true,
                    filter: true,
                  },
                  {
                    headerName: "Views",
                    field: "views",
                    sortable: true,
                    filter: true,
                  },
                  {
                    headerName: "Times completed",
                    field: "timesCompleted",
                    sortable: true,
                    filter: true,
                  },
                ]}
              />
            </div>
          </Grid>
          <Grid item md={12}>
            <div
              className="heading-4 text-left"
              style={{
                textTransform: "none",
                marginRight: "10px",
                marginBottom: 0,
                color: "#000",
                marginTop: "3rem",
              }}
            >
              School Performance
            </div>
            <div className="mt-4">
              <AGGrid
                rowData={
                  analytics ? (analytics.schools ? analytics.schools : []) : []
                }
                search={false}
                autoHeight={true}
                columnDefs={[
                  {
                    headerName: "Name",
                    field: "name",
                    sortable: true,
                    filter: true,
                  },
                  {
                    headerName: "Total points",
                    field: "totalPointsForSchool",
                    sortable: true,
                    filter: true,
                  },
                  {
                    headerName: "Points per guardian",
                    field: "pointsPerGuardian",
                    sortable: true,
                    filter: true,
                  },
                  {
                    headerName: "Activities per guardian",
                    field: "activitiesPerGuardian",
                    sortable: true,
                    filter: true,
                  },
                ]}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={dateRangeDialog}
        onClose={handleCloseDateRange}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            color: "rgb(42, 42, 47)",
            // borderBottom: "none",
          }}
        >
          Select Date Range
        </DialogTitle>
        <DialogContent
          dividers={false}
          className="d-flex flex-row justify-content-center my-4"
        >
          <DayPickerRangeController
            numberOfMonths={2}
            onDatesChange={(dates) => {
              setDateRangeValue({
                startDate: dates.startDate,
                endDate: dates.endDate,
              });
            }}
            startDate={dateRangeValue.startDate}
            endDate={dateRangeValue.endDate}
            focusedInput={dateRangeFocus}
            onFocusChange={(focusedInput) => {
              setDateRangeFocus(!focusedInput ? "startDate" : focusedInput);
            }}
            hideKeyboardShortcutsPanel={true}
          />
        </DialogContent>
        <DialogActions className="p-3">
          <Button onClick={handleCloseDateRange}>Cancel</Button>
          <Button
            className="btn-primary"
            onClick={handleDateRangePick}
            autoFocus
          >
            Finish
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = {
  readSceneAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistrictActivityAnalytics);
