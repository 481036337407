import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";

const StyledMenu = withStyles({
  paper: {
    width: "240px",
    maxHeight: "450px",
    borderRadius: "4px",
    backgroundColor: "#fff",
    boxShadow: "0 0 18px rgb(0 0 0 / 15%)",
    marginTop: "5px",
  },
  list: {
    padding: 0,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
));

const OutlinedButton = withStyles({
  root: {
    borderRadius: "6px",
    display: "inline-block",
    fontSize: "14px",
    border: "1px solid #e9e9e9",
    padding: "8px 12px !important",
    color: "rgb(42, 42, 47) !important",
    width: "100%",
    "&:hover": {
      transform: "none !important",
      backgroundColor: "#ededfc",
      color: "rgb(42, 42, 47) !important",
    },
  },
})((props) => <Button {...props} />);

const useStyles = makeStyles((theme) => ({
  roleButton: {
    cursor: "pointer",
    marginTop: "8px",
    alignItems: "center",
    borderRadius: "9px",
    display: "flex",
    fontSize: "14px",
    fontWeight: "400",
    "&:hover": {
      backgroundColor: "#f6f6f6",
    },
  },
}));

export default function RoleSelection(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async () => {
    setAnchorEl(null);
  };

  // Show Available Roles based on Current Role
  const handleRolesList = (role) => {
    switch (role) {
      case "All Roles":
        return [
          {
            name: "Owner",
            description: "Full access to everything",
          },
          {
            name: "Admin",
            description:
              props.inviteType === "district"
                ? "Administrative controls, but no district deletion privileges"
                : "Administrative controls, but no school deletion privileges",
          },
          {
            name: "Manager",
            description:
              props.inviteType === "district"
                ? "View and edit schools within district"
                : "View and edit school",
          },
        ];
      case "Owner":
        return [
          {
            name: "Owner",
            description: "Full access to everything",
          },
          {
            name: "Admin",
            description:
              props.inviteType === "district"
                ? "Administrative controls, but no district deletion privileges"
                : "Administrative controls, but no school deletion privileges",
          },
          {
            name: "Manager",
            description: "View and edit schools within district",
          },
        ];
      case "Admin":
        return [
          {
            name: "Admin",
            description:
              "Administrative controls, but no district deletion privileges",
          },
          {
            name: "Manager",
            description:
              props.inviteType === "district"
                ? "View and edit schools within district"
                : "View and edit school",
          },
        ];
      case "Manager":
        return [];
    }
  };

  return (
    <div>
      <OutlinedButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="text"
        color="primary"
        onClick={handleClick}
        className="d-flex flex-row p-0 justify-content-between"
        style={Boolean(anchorEl) ? { backgroundColor: "#ededfc" } : {}}
        disabled={!props.editable}
      >
        <div>{props.selectedRole ? props.selectedRole : "Select role"}</div>
        <span aria-hidden="true">
          <svg width="16" height="16">
            <path d="M8 10.5a1 1 0 01-.7-.29l-3.06-3a1 1 0 111.41-1.42L8 8.1l2.35-2.31a1 1 0 111.41 1.42l-3.06 3a1 1 0 01-.7.29z"></path>
          </svg>
        </span>
      </OutlinedButton>
      <StyledMenu
        id="location-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ul style={{ margin: "0 8px", padding: 0 }}>
          {handleRolesList(
            props.allRoles ? "All Roles" : props.currentUserRole
          ).map((role, index, arr) => (
            <li
              className={classes.roleButton}
              style={arr.length - 1 === index ? { marginBottom: "8px" } : {}}
              onClick={() => {
                // No update if same role clicked
                if (props.selectedRole === role.name) {
                  handleClose();
                } else {
                  props.handleRoleSelect(
                    role.name,
                    props.member ? props.member : null
                  );
                  handleClose();
                }
              }}
            >
              <div
                style={{
                  flex: "1 1",
                  overflow: "hidden",
                  padding: "9px 8px",
                }}
              >
                <div
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                    color: "#2a2a2f",
                    lineHeight: "normal",
                    minHeight: "14px",
                    wordWrap: "break-word",
                  }}
                >
                  <span>{role.name}</span>
                </div>
                <div
                  style={{
                    color: "#8f8f91",
                    marginTop: "4px",
                    wordWrap: "break-word",
                  }}
                >
                  <span>{role.description}</span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </StyledMenu>
    </div>
  );
}
