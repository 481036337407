export interface VideoShortRecord {
  id?: string;
  allSchools: boolean;
  points: number;
  lastUpdated?: number;
  // English (Default)
  name: string;
  information?: string;
  thumbnail?: string | File | Blob | any;
  video?: any;
}

export class VideoShort {
  id?: string;
  readonly allSchools: boolean;
  readonly points: number;
  lastUpdated?: number;
  // English
  readonly name: string;
  readonly information?: string;
  public thumbnail?: string | File | Blob | any;
  public video?: any;

  constructor(videoShort: VideoShortRecord) {
    const {
      id,
      allSchools,
      points,
      lastUpdated,
      name,
      information,
      thumbnail,
      video,
    } = videoShort;

    if (this.isInvalidString(name)) {
      throw new Error("Name cannot be empty");
    }

    this.id = id;
    this.allSchools = allSchools;
    this.points = points;
    this.lastUpdated = lastUpdated;
    // English
    this.name = name;
    this.information = information;
    this.thumbnail = thumbnail;
    this.video = video;
  }

  private isInvalidString(str: string): boolean {
    return !str || str.trim().length === 0;
  }
}
