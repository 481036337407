import { all, put } from "redux-saga/effects";
import { Scene } from "../../Entities";
import {
  updateAppAction,
  updatePremiumStateAction,
  updateSceneStateAction,
} from "../Redux";
import { PremiumService, SchoolService, DistrictService } from "../../Services";
import {
  ReadSchoolInteractor,
  FetchPremiumInteractor,
  ReadDistrictInteractor,
} from "../../UseCases";

export const READ_SCENE = "scene/saga/read_scene";

interface SceneSelectionActionType {
  type: string;
  id?: string | number;
  sceneType?: string;
}

export const readSceneAction = (
  sceneType: string,
  id: string | number
): SceneSelectionActionType => ({
  type: READ_SCENE,
  sceneType,
  id,
});

export function* readSceneSaga(action: SceneSelectionActionType) {
  yield put(updateAppAction({ isLoading: true }));

  const { id, sceneType } = action;
  if (!id) {
    const scene = {
      id: undefined,
      type: "none",
      data: null,
    } as Scene;
    return yield all([
      put(updateSceneStateAction(scene)),
      put(updatePremiumStateAction(undefined)),
      put(updateAppAction({ isLoading: false })),
    ]);
  }

  const service = new SchoolService();
  const premiumService = new PremiumService();
  const interactor = new ReadSchoolInteractor(service);
  const districtService = new DistrictService();
  const districtInteractor = new ReadDistrictInteractor(districtService);
  const premiumInteractor = new FetchPremiumInteractor(premiumService);
  try {
    if (sceneType === "s") {
      const premiumObj = id
        ? yield premiumInteractor.fetchPremium(id as any)
        : undefined;

      const readSchool = yield interactor.readSchool(id);
      const updatedScene: Scene = {
        id: readSchool.id,
        type: "school",
        data: readSchool,
      };

      yield all([
        put(updateSceneStateAction(updatedScene)),
        put(updatePremiumStateAction(premiumObj || undefined)),
        put(updateAppAction({ isLoading: false })),
      ]);
    }
    if (sceneType === "d") {
      const readDistrict = yield districtInteractor.readDistrict(id as string);
      const updatedScene: Scene = {
        id: readDistrict.id,
        type: "district",
        data: readDistrict,
      };

      yield all([
        put(updateSceneStateAction(updatedScene)),
        put(updatePremiumStateAction(undefined)),
        put(updateAppAction({ isLoading: false })),
      ]);
    }
  } catch (error) {
    // @ts-ignore
    yield put(updateAppAction({ isLoading: false, error }));
  }
}
