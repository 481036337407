import { useState, useEffect } from "react";
import Bugsnag from "@bugsnag/js";
import update from "immutability-helper";
import { connect } from "react-redux";
import { appSelector, userSelector, ParentPartiesAPIs } from "../../core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircleLoader } from "react-spinners";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {
  Grid,
  Button,
  ListItem,
  List,
  Dialog,
  Typography,
  IconButton,
  DialogActions,
  Tooltip,
} from "@material-ui/core";
import AGGrid from "../Grid";
import moment from "moment";

// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import GuardianInfoDialog from "./components/GuardianInfoDialog";

function dateComparator(date1, date2) {
  if (date1 === null && date2 === null) {
    return 0;
  }
  if (date1 === null) {
    return -1;
  }
  if (date2 === null) {
    return 1;
  }

  return date1 - date2;
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function AdminGuardians(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [guardians, setGuardians] = useState([]);
  const [guardianModal, setGuardianModal] = useState(false);
  const [guardianInfo, setGuardianInfo] = useState(null);
  const [loadingAnalytics, setLoadingAnalytics] = useState(false);

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteDialogInfo, setDeleteDialogInfo] = useState(null);
  const handleDeleteClose = () => {
    setDeleteDialog(false);
  };
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleDeleteGuardian = async () => {
    try {
      if (deleteDialogInfo) {
        if (deleteDialogInfo.uid) {
          setDeleteLoading(true);
          await ParentPartiesAPIs.deleteGuardian(deleteDialogInfo.uid);
          setGuardians(
            guardians.filter(
              (guardian) => guardian.uid !== deleteDialogInfo.uid
            )
          );
          setDeleteLoading(false);
          setDeleteDialog(false);
        }
      }
    } catch (err) {
      setDeleteLoading(false);
      console.log(err);
      Bugsnag.notify(err);
    }
  };

  const [resetDialog, setResetDialog] = useState(false);
  const [resetDialogInfo, setResetDialogInfo] = useState(null);
  const handleResetClose = () => {
    setResetDialog(false);
  };

  const handleResetGuardian = async () => {
    try {
      if (resetDialogInfo) {
        if (resetDialogInfo.uid) {
          const updatedGuardian = await ParentPartiesAPIs.resetGuardian(
            resetDialogInfo.uid
          );
          const indexToUpdate = guardians.findIndex(
            (guardian) => guardian.uid === updatedGuardian.uid
          );
          const updatedGuardians = update(guardians, {
            [indexToUpdate]: {
              $set: updatedGuardian,
            },
          });
          setGuardians(updatedGuardians);
          setResetDialog(false);
        }
      }
    } catch (err) {
      console.log(err);
      Bugsnag.notify(err);
    }
  };

  useEffect(() => {
    async function getGuardians() {
      const allGuardians = await ParentPartiesAPIs.readAllGuardians();
      const arrayOfGuardians = allGuardians
        ? Object.values(allGuardians).filter(
            (guardian) => guardian.schoolIds || guardian.schoolId
          )
        : [];
      setGuardians(arrayOfGuardians);
    }

    getGuardians();
  }, []);

  const handleGuardianOpen = async (guardianInfo) => {
    setGuardianModal(true);
    setGuardianInfo(guardianInfo);
    setLoadingAnalytics(true);

    /* Pull analytics */
    const result = await ParentPartiesAPIs.guardianAnalytics(guardianInfo.uid);
    if (result) {
      setGuardianInfo({
        ...guardianInfo,
        lastActivity: result.lastActivity,
        activityFeed: result.activityFeed,
        sessions: result.sessions,
      });
    }
    setLoadingAnalytics(false);
  };

  return !props.user ? null : (
    <>
      <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
        <Grid item md={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              Admin Guardians
            </div>
          </div>
        </Grid>
        <Grid item md={12} xl={12}>
          {mobile ? null : (
            <List
              component="div"
              className="nav-line-alt nav-line d-flex align-items-center mt-3"
            >
              <ListItem button selected className="pr-2">
                <span className="font-size-sm">{"Table"}</span>
                <div className="divider" />
              </ListItem>
            </List>
          )}
        </Grid>
        <Grid item md={12}>
          <AGGrid
            smallTitle={true}
            titleGrid={"Guardians"}
            search={true}
            statusBar={true}
            exportButton={true}
            exportType={"guardians"}
            rowData={guardians}
            columnDefs={[
              {
                headerName: "Username",
                field: "username",
                sortable: true,
                filter: true,
                cellRendererFramework: (params) => (
                  <div
                    onClick={() => {
                      handleGuardianOpen(params.data);
                    }}
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    {`${
                      params.data.username
                        ? params.data.username
                        : "Not Available"
                    }`}
                  </div>
                ),
              },
              {
                headerName: "First Name",
                sortable: true,
                filter: true,
                valueGetter: function (params) {
                  if (params.data.firstName) {
                    return `${params.data.firstName}`;
                  } else {
                    return "";
                  }
                },
              },
              {
                headerName: "Last Name",
                sortable: true,
                filter: true,
                valueGetter: function (params) {
                  if (params.data.lastName) {
                    return `${params.data.lastName}`;
                  } else {
                    return "";
                  }
                },
              },
              {
                headerName: "Teacher",
                field: "teacher",
                sortable: true,
                valueFormatter: function (params) {
                  if (params.data.teacher) {
                    return `${params.data.teacher}`;
                  } else {
                    return "";
                  }
                },
              },
              {
                headerName: "Points",
                field: "points",
                sortable: true,
                filter: true,
              },
              {
                headerName: "Schools",
                field: "schoolNames",
                sortable: true,
                filter: true,
              },
              {
                headerName: "Last session",
                field: "lastSession",
                sortable: true,
                filter: "agDateColumnFilter",
                valueFormatter: function (params) {
                  if (params.data.lastSession) {
                    return moment(params.data.lastSession).format("L");
                  } else {
                    return null;
                  }
                },
                comparator: dateComparator,
              },
              {
                headerName: "Created on",
                field: "metadata.creationTime",
                sortable: true,
                filter: "agDateColumnFilter",
                valueFormatter: function (params) {
                  if (params.data.metadata.creationTime) {
                    return moment(params.data.metadata.creationTime).format(
                      "L"
                    );
                  } else {
                    return null;
                  }
                },
                comparator: dateComparator,
              },
              {
                headerName: "Actions",
                sortable: false,
                filter: false,
                cellRendererFramework: (params) => (
                  <div className="d-flex flex-row align-items-center">
                    <Tooltip title="Reset points">
                      <button
                        className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained bg-dark shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                        tabIndex="0"
                        type="button"
                        onClick={() => {
                          setResetDialogInfo(params.data);
                          setResetDialog(true);
                        }}
                      >
                        <span className="MuiButton-label">
                          <span className="btn-wrapper--icon">
                            <FontAwesomeIcon
                              style={{ fontSize: "12px" }}
                              icon={["fas", "sync"]}
                              color="#fff"
                            />
                          </span>
                        </span>
                        <span className="MuiTouchRipple-root"></span>
                      </button>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <button
                        className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained bg-danger shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                        tabIndex="0"
                        type="button"
                        onClick={() => {
                          setDeleteDialogInfo(params.data);
                          setDeleteDialog(true);
                        }}
                      >
                        <span className="MuiButton-label">
                          <span className="btn-wrapper--icon">
                            <FontAwesomeIcon
                              style={{ fontSize: "12px" }}
                              icon={["fas", "trash"]}
                              color="#fff"
                            />
                          </span>
                        </span>
                        <span className="MuiTouchRipple-root"></span>
                      </button>
                    </Tooltip>
                  </div>
                ),
                cellClass: "agGridCellCenter",
              },
            ]}
          />
        </Grid>
      </Grid>
      {/* Guardian Modal */}
      <GuardianInfoDialog
        guardianInfo={guardianInfo}
        guardianModal={guardianModal}
        loadingAnalytics={loadingAnalytics}
        setGuardianModal={setGuardianModal}
      />
      {/* GUARDIAN ADMIN ACTIONS */}
      <Dialog
        open={resetDialog}
        onClose={handleResetClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
        PaperProps={{
          className: "p-3",
        }}
      >
        <DialogTitle
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            color: "rgb(42, 42, 47)",
            borderBottom: "none",
          }}
        >
          {`Are you sure you want to reset points for guardian${
            resetDialogInfo
              ? `: ${resetDialogInfo.firstName} ${resetDialogInfo.lastName}`
              : ""
          }?`}
        </DialogTitle>
        <DialogActions style={{ borderTop: "none" }} className="mt-4">
          <Button onClick={handleResetClose}>Cancel</Button>
          <Button
            className="btn-info"
            onClick={() => handleResetGuardian()}
            autoFocus
          >
            {"Reset Points"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteDialog}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
        PaperProps={{
          className: "p-3",
        }}
      >
        <DialogTitle
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            color: "rgb(42, 42, 47)",
            borderBottom: "none",
          }}
        >
          {`Are you sure you want to delete guardian${
            deleteDialogInfo
              ? `: ${deleteDialogInfo.firstName} ${deleteDialogInfo.lastName}`
              : ""
          }?`}
        </DialogTitle>
        <DialogActions style={{ borderTop: "none" }} className="mt-4">
          <Button onClick={handleDeleteClose}>Cancel</Button>
          <Button
            className="btn-danger"
            onClick={() => handleDeleteGuardian()}
            autoFocus
            disabled={deleteLoading}
          >
            {deleteLoading && (
              <div className="mr-3">
                <CircleLoader color="#fff" size={15} />
              </div>
            )}
            <span>Delete Guardian</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
});

export default connect(mapStateToProps, null)(AdminGuardians);
