export interface CoordinatesRecord {
    latitude: number;
    longitude: number;
}

export class Coordinates {
    readonly latitude: number;
    readonly longitude: number;

    constructor(coordinates: CoordinatesRecord) {
        const { latitude, longitude } = coordinates;

        if (this.isInvalidCoordinates(latitude, longitude)) {
            throw new Error('Latitude must be from 0 to 90 and longitude must be from 0 to 180');
        }

        this.latitude = latitude;
        this.longitude = longitude;
    }

    private isInvalidCoordinates(latitude: number, longitude: number): boolean {
        return latitude < -90 || latitude > 90 || longitude < -180 || longitude > 180;
    }
}
