import React from "react";
import AccountSettings from "../../components/AccountSettings";

export default function AccountSettingsPage() {
  return (
    <>
      <AccountSettings />
    </>
  );
}
