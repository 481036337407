import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import {
  Grid,
  Dialog,
  IconButton,
  Typography,
  DialogContent,
  Box,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";

const DialogTitle = (props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      sx={(theme) => ({
        margin: 0,
        padding: theme.spacing(2),
      })}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const AddActivityDialog = (props) => {
  const {
    optionModal,
    handleOptionModalClose,
    handleSchoolActivityDialogOpen,
    schoolPlan,
    sceneType,
    sceneId,
  } = props;
  const appActivityAvailable =
    schoolPlan === "Premium" || schoolPlan === "Demo - Premium";

  return (
    <>
      <Dialog
        open={optionModal}
        onClose={handleOptionModalClose}
        fullWidth={true}
        maxWidth={"md"}
        classes={{ paper: "modal-content rounded-lg" }}
      >
        <DialogTitle
          onClose={handleOptionModalClose}
          className="text-black d-flex flex-row justify-content-between align-items-center"
        >
          What kind of activity would you like to add?
        </DialogTitle>
        <DialogContent dividers={false} className="p-4 mx-4 my-4">
          <Grid container spacing={6} justifyContent="center">
            <Grid item md={6} lg={6}>
              <div
                onClick={handleSchoolActivityDialogOpen}
                className="card bg-white card-box-hover-rise rounded"
                style={{ cursor: "pointer" }}
              >
                <div className="p-3 p-lg-4">
                  <div className="bg-deep-blue text-white font-size-xl d-60 btn-icon card-icon-wrapper rounded-circle">
                    <FontAwesomeIcon icon={["fas", "plus"]} />
                  </div>
                  <h3 className="font-weight-bold display-5 mt-4 mb-3 text-black">
                    In-school Activity
                  </h3>
                  <p className="card-text mb-4">
                    Create a record of your in-school activities for end-of-year
                    reporting.
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item md={6} lg={6}>
              <Box
                component={appActivityAvailable ? NavLink : undefined}
                to={
                  appActivityAvailable
                    ? {
                        pathname: `/add-activity/${sceneType}/${sceneId}`,
                        type: "add",
                      }
                    : undefined
                }
                className={
                  appActivityAvailable
                    ? "card bg-white card-box-hover-rise rounded"
                    : "card bg-white rounded"
                }
                style={
                  appActivityAvailable
                    ? { cursor: "pointer" }
                    : { cursor: "default" }
                }
              >
                <div className="p-3 p-lg-4">
                  <div className="bg-sunny-morning text-white font-size-xl d-60 btn-icon card-icon-wrapper rounded-circle">
                    <FontAwesomeIcon icon={["fas", "plus"]} />
                  </div>
                  <h3 className="font-weight-bold display-5 mt-4 mb-3 text-black">
                    Mobile App Actviity
                  </h3>
                  <p className="card-text mb-4">
                    {appActivityAvailable
                      ? "Create an activity that will show up in the Parent Parties Engage app for your school"
                      : "Please upgrade to the Premium plan to add mobile app activites."}
                  </p>
                </div>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddActivityDialog;
