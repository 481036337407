import React from "react";
import AdminGuardiansAnalytics from "../../components/AdminGuardiansAnalytics";

function AdminGuardiansAnalyticsPage() {
  return (
    <>
      <AdminGuardiansAnalytics />
    </>
  );
}

export default AdminGuardiansAnalyticsPage;
