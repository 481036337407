import React from "react";
import { useDropzone } from "react-dropzone";
import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
import CloudUploadTwoToneIcon from "@material-ui/icons/CloudUploadTwoTone";
import CheckIcon from "@material-ui/icons/Check";
import {
  Button,
  Grid,
  FormControl,
  TextField,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";
import ReactQuill from "react-quill";

const VideoDropzone = (props) => {
  const {
    videoUpload,
    setFormErrors,
    formErrors,
    errorField,
    setUnsavedChanges,
  } = props;

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: "video/mp4,video/x-m4v,video/*",
    multiple: false,
    onDrop: (acceptedFiles) => {
      //   const MAX_SIZE = 31457280;
      //   const bigFile = (file) => file.size > MAX_SIZE;
      //   if (acceptedFiles.some(bigFile) === true) {
      //     alert("File Size Limit: 32 MB");
      //     return;
      //   }
      const videoFile = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )[0];
      videoUpload(videoFile);
      setUnsavedChanges(true);
      setFormErrors({ ...formErrors, [errorField]: false });
    },
  });

  return (
    <div className="dropzone">
      <div
        {...getRootProps({
          className: "dropzone-upload-wrapper",
        })}
      >
        <input {...getInputProps()} />
        <div className="dropzone-inner-wrapper">
          {isDragAccept && (
            <div>
              <div className="d-100 btn-icon mb-3 hover-scale-lg bg-success shadow-success-sm rounded-circle text-white">
                <CheckIcon className="d-50" />
              </div>
              <div className="font-size-sm text-success">
                Video will be uploaded!
              </div>
            </div>
          )}
          {isDragReject && (
            <div>
              <div className="d-100 btn-icon mb-3 hover-scale-lg bg-danger shadow-danger-sm rounded-circle text-white">
                <CloseTwoToneIcon className="d-50" />
              </div>
              <div className="font-size-sm text-danger">
                File will be rejected!
              </div>
            </div>
          )}
          {!isDragActive && (
            <div>
              <div className="d-100 btn-icon mb-3 hover-scale-lg bg-white shadow-light-sm rounded-circle text-primary">
                <CloudUploadTwoToneIcon className="d-50" />
              </div>
              <div className="font-size-sm">
                Drag and drop video here{" "}
                <span className="font-size-xs text-dark">(MP4)</span>
              </div>
            </div>
          )}
          <small className="py-2 text-black-50">or</small>
          <div>
            <Button className="btn-primary hover-scale-sm font-weight-bold btn-pill px-4">
              <span className="px-2">Browse Files</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ThumbnailDropzone = (props) => {
  const {
    thumbnailUpload,
    setFormErrors,
    formErrors,
    errorField,
    setUnsavedChanges,
  } = props;

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: "image/jpeg, image/png",
    multiple: false,
    onDrop: (acceptedFiles) => {
      const MAX_SIZE = 31457280;
      const bigFile = (file) => file.size > MAX_SIZE;
      if (acceptedFiles.some(bigFile) === true) {
        alert("File Size Limit: 32 MB");
        return;
      }
      const thumbnailFile = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )[0];
      thumbnailUpload(thumbnailFile);
      setUnsavedChanges(true);
      setFormErrors({ ...formErrors, [errorField]: false });
    },
  });

  return (
    <div className="dropzone">
      <div
        {...getRootProps({
          className: "dropzone-upload-wrapper",
        })}
      >
        <input {...getInputProps()} />
        <div className="dropzone-inner-wrapper">
          {isDragAccept && (
            <div>
              <div className="d-100 btn-icon mb-3 hover-scale-lg bg-success shadow-success-sm rounded-circle text-white">
                <CheckIcon className="d-50" />
              </div>
              <div className="font-size-sm text-success">
                File will be uploaded!
              </div>
            </div>
          )}
          {isDragReject && (
            <div>
              <div className="d-100 btn-icon mb-3 hover-scale-lg bg-danger shadow-danger-sm rounded-circle text-white">
                <CloseTwoToneIcon className="d-50" />
              </div>
              <div className="font-size-sm text-danger">
                File will be rejected!
              </div>
            </div>
          )}
          {!isDragActive && (
            <div>
              <div className="d-100 btn-icon mb-3 hover-scale-lg bg-white shadow-light-sm rounded-circle text-primary">
                <CloudUploadTwoToneIcon className="d-50" />
              </div>
              <div className="font-size-sm">
                Drag and drop image here{" "}
                <span className="font-size-xs text-dark">(JPEG, PNG)</span>
              </div>
            </div>
          )}
          <small className="py-2 text-black-50">or</small>
          <div>
            <Button className="btn-primary hover-scale-sm font-weight-bold btn-pill px-4">
              <span className="px-2">Browse Files</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const VideoShortDetails = (props) => {
  const {
    formErrors,
    setFormErrors,
    videoName,
    setVideoName,
    information,
    setInformation,
    video,
    setVideo,
    thumbnail,
    setThumbnail,
    preview,
    setUnsavedChanges,
  } = props;

  return (
    <div className="px-4">
      <div className="mt-4">
        <p className="text-black" style={{ fontSize: "14px", fontWeight: 300 }}>
          {preview
            ? "Please review the video short details."
            : "Please fill out the details for this video short."}
        </p>
      </div>
      <Grid container spacing={0} className="mt-4 pt-4">
        <Grid md={7}>
          <Grid container spacing={6}>
            <Grid item md={12}>
              <InputLabel
                style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                className="mb-3"
                required={true}
              >
                {"Video name"}
              </InputLabel>
              <FormControl fullWidth error={formErrors.name} variant="outlined">
                <TextField
                  variant="outlined"
                  fullWidth
                  value={videoName}
                  error={formErrors.name}
                  type="text"
                  onChange={(e) => {
                    setUnsavedChanges(true);

                    setVideoName(e.target.value);

                    setFormErrors({ ...formErrors, name: false });
                  }}
                  disabled={preview}
                />
                {preview ? null : (
                  <FormHelperText error={true}>
                    {props.formErrors.name ? "Video name is required" : ""}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <InputLabel
                style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                className="mb-3"
                required={true}
              >
                {"Description"}
              </InputLabel>
              <ReactQuill
                theme="snow"
                value={information}
                onChange={(e) => {
                  setUnsavedChanges(true);

                  setInformation(e);

                  setFormErrors({ ...formErrors, description: false });
                }}
                readOnly={preview}
                placeholder="Example placeholder..."
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline"], // toggled buttons
                    ["link"], // link button
                  ],
                }}
              />
              {preview ? null : (
                <FormHelperText error={true}>
                  {props.formErrors.information
                    ? "Description is required"
                    : ""}
                </FormHelperText>
              )}
            </Grid>
            <Grid item md={12}>
              <InputLabel
                style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                className="mb-3"
                required={true}
              >
                {"Video thumbnail"}
              </InputLabel>

              {thumbnail ? (
                <div className="py-2 d-flex flex-column align-items-start">
                  <img
                    style={{
                      width: "300px",
                      height: "300px",
                      objectFit: "cover",
                      objectPosition: "top",
                    }}
                    src={thumbnail.preview ? thumbnail.preview : thumbnail}
                    className="rounded"
                    alt="..."
                  />
                  {preview ? null : (
                    <Button
                      style={{ width: "200px" }}
                      variant="contained"
                      className="btn-pill mt-3 btn-danger"
                      onClick={() => setThumbnail(null)}
                    >
                      Remove
                    </Button>
                  )}
                </div>
              ) : (
                <ThumbnailDropzone
                  thumbnailUpload={setThumbnail}
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                  errorField="thumbnail"
                  setUnsavedChanges={setUnsavedChanges}
                />
              )}
              {preview ? null : (
                <FormHelperText error={true}>
                  {props.formErrors.thumbnail
                    ? "Video thumbnail is required"
                    : ""}
                </FormHelperText>
              )}
            </Grid>
            <Grid item md={12}>
              <InputLabel
                style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                className="mb-3"
                required={true}
              >
                {"Video upload"}
              </InputLabel>
              {video ? (
                <div className="py-2 d-flex flex-column align-items-start">
                  <video
                    key={video.preview ? video.preview : video}
                    controls
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    className="rounded"
                  >
                    <source
                      src={video.preview ? video.preview : video}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                  {preview ? null : (
                    <Button
                      style={{ width: "200px" }}
                      variant="contained"
                      className="btn-pill mt-3 btn-danger"
                      onClick={() => setVideo(null)}
                    >
                      Remove
                    </Button>
                  )}
                </div>
              ) : (
                <VideoDropzone
                  videoUpload={setVideo}
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                  errorField="video"
                  setUnsavedChanges={setUnsavedChanges}
                />
              )}
              {preview ? null : (
                <FormHelperText error={true}>
                  {props.formErrors.video ? "Video is required" : ""}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default VideoShortDetails;
