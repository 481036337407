import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Card, Button, TextField, Tooltip } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { CircleLoader } from "react-spinners";
import { connect } from "react-redux";
import {
  userSelector,
  appSelector,
  SchoolUser,
  updateUserAction,
  resetPasswordAction,
  sendVerificationEmailAction,
  resetAppAction,
} from "../../core";

// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const validationSchema = Yup.object({
  firstName: Yup.string("").required("First name is required"),
  lastName: Yup.string("").required("Last name is required"),
  email: Yup.string("")
    .email("Enter a valid email")
    .required("Email is required"),
});

const Form = (props) => {
  const {
    values: { firstName, lastName, email },
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    isValid,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className={"p-3"}>
        <Grid container spacing={5}>
          <Grid item md={6}>
            <label className="font-weight-bold mb-2 font-size-sm">
              First name
            </label>
            <TextField
              variant="outlined"
              name="firstName"
              helperText={touched.firstName ? errors.firstName : ""}
              error={Boolean(errors.firstName) && touched.firstName}
              size="small"
              fullWidth
              value={firstName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6}>
            <label className="font-weight-bold mb-2 font-size-sm">
              Last name
            </label>
            <TextField
              variant="outlined"
              name="lastName"
              helperText={touched.lastName ? errors.lastName : ""}
              error={Boolean(errors.lastName) && touched.lastName}
              size="small"
              fullWidth
              value={lastName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={12}>
            <label className="font-weight-bold mb-2 font-size-sm">
              Email address
            </label>
            <TextField
              variant="outlined"
              name="email"
              helperText={touched.email ? errors.email : ""}
              error={Boolean(errors.email) && touched.email}
              type="email"
              size="small"
              value={email}
              onBlur={handleBlur}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item md={12}>
            <Grid item md={12}>
              <label className="font-weight-bold mb-2 font-size-sm">
                Reset password
              </label>
            </Grid>
            <Grid item md={12}>
              <Button
                className="btn-neutral-primary mr-2"
                type="submit"
                onClick={props.resetPassword}
              >
                <span>Send Password Reset</span>
              </Button>
            </Grid>
            {props.accountLoading === "passwordReset" ? (
              <Grid item md={12}>
                <CircleLoader
                  className="d-flex align-self-center"
                  color={"#18A9A9"}
                  loading={true}
                  size={20}
                />
              </Grid>
            ) : null}
            {props.pwSuccessMessage ? (
              <Grid item md={12}>
                <div className="font-size-sm text-success my-3">
                  {props.pwSuccessMessage}
                </div>
              </Grid>
            ) : null}
            {props.pwBackendError ? (
              <Grid item md={12}>
                <div className="font-size-sm text-danger my-3">
                  {props.pwBackendError}
                </div>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </div>
      <div className="divider" />
      <div className="text-center p-4">
        {props.accountLoading === "account" ? (
          <div className="my-3 d-flex justify-content-center">
            <CircleLoader
              className="d-flex align-self-center"
              color={"#18A9A9"}
              loading={true}
              size={20}
            />
          </div>
        ) : null}
        {props.successMessage ? (
          <div className="font-size-sm text-success my-3">
            {props.successMessage}
          </div>
        ) : null}
        {props.backendError ? (
          <div className="font-size-sm text-danger my-3">
            {props.backendError}
          </div>
        ) : null}
        <Button className="btn-first" type="submit" disabled={!isValid}>
          <span className="btn-wrapper--label">Save Changes</span>
        </Button>
      </div>
    </form>
  );
};

function AccountSettings(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const values = {
    firstName: props.user ? props.user.firstName : "",
    lastName: props.user ? props.user.lastName : "",
    email: props.user ? props.user.email.address : "",
  };

  const [accountLoading, setAccountLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [pwSuccessMessage, setPwSuccessMessage] = useState("");
  const [backendError, setBackendError] = useState(false);
  const [pwBackendError, setPwBackendError] = useState(false);

  const onUpdateAccount = (data) => {
    if (props.app.isLoading) {
      return;
    }
    const { firstName, lastName, email } = data;
    try {
      props.dispatchUpdateUser(
        new SchoolUser({
          ...props.user,
          firstName: firstName,
          lastName: lastName,
          email: { address: email },
        })
      );
      setSuccessMessage("");
      setPwSuccessMessage("");
      setAccountLoading("account");
    } catch (err) {
      console.error(err);
      setBackendError(err.toString());
    }
  };

  const resetPassword = () => {
    try {
      props.dispatchResetPassword(props.user.email);
      setSuccessMessage("");
      setPwSuccessMessage("");
      setAccountLoading("passwordReset");
    } catch (err) {
      console.error(err);
      setPwBackendError(err.toString());
    }
  };

  useEffect(() => {
    if (accountLoading) {
      if (!props.app.isLoading) {
        if (props.app.error) {
          setSuccessMessage("");
          if (accountLoading === "account") {
            setBackendError(
              `Could not update account. Error: ${props.app.error.message}`
            );
          } else {
            setPwBackendError(
              `Could not reset password. Error: ${props.app.error.message}`
            );
          }
          setAccountLoading(false);
        } else {
          setBackendError(false);
          if (accountLoading === "account") {
            setSuccessMessage("Account information updated.");
          } else {
            setPwSuccessMessage("Password reset sent.");
          }
          setAccountLoading(false);
        }
      }
    }
  }, [props.app.error, props.app.isLoading, accountLoading]);

  useEffect(() => {
    if (props.app.error) {
      return setBackendError(props.app.error.message);
    }
  }, [props.app.error]);

  useEffect(() => {
    /* Clear app errors on load */
    if (props.app.error) {
      props.dispatchReset();
      setBackendError(false);
    }
  }, [props.dispatchReset]);

  const keyRef = useRef(null);
  const [copied, setCopied] = useState(false);
  function copyToClipboard(e) {
    keyRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setCopied(true);
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={12}>
          <div
            className="font-weight-bold display-4"
            style={{ color: "rgb(43, 39, 60)", height: "52px" }}
          >
            Account Settings
          </div>
        </Grid>
        <Grid item md={12}>
          <Card
            className="p-3"
            style={{
              borderRadius: "0.25rem",
              border: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <Formik
              render={(formikProps) => (
                <Form
                  {...formikProps}
                  app={props.app}
                  accountLoading={accountLoading}
                  successMessage={successMessage}
                  backendError={backendError}
                  resetPassword={resetPassword}
                  pwSuccessMessage={pwSuccessMessage}
                  pwBackendError={pwBackendError}
                  mobile={mobile}
                  user={props.user}
                />
              )}
              initialValues={values}
              validationSchema={validationSchema}
              onSubmit={onUpdateAccount}
            />
          </Card>
        </Grid>
        {props.user ? (
          props.user.api_key ? (
            <Grid item md={12}>
              <Card
                className="p-3"
                style={{
                  borderRadius: "0.25rem",
                  border: "1px solid rgba(0,0,0,.1)",
                }}
              >
                <div className="p-3">
                  <div className="d-block">
                    <label className="font-weight-bold mb-2 font-size-sm">
                      API Key
                    </label>
                  </div>
                  {document.queryCommandSupported("copy") ? (
                    <Tooltip
                      TransitionProps={{
                        onExited: () => setCopied(false),
                      }}
                      title={
                        copied ? (
                          <div className="d-flex flex-row align-items-center">
                            <div>
                              <FontAwesomeIcon
                                icon={["fas", "check-circle"]}
                                className="text-success font-size-sm mr-2"
                              />
                            </div>
                            <div className="font-size-sm">Copied</div>
                          </div>
                        ) : (
                          <div className="font-size-sm">Click to copy</div>
                        )
                      }
                      placement="right"
                      arrow={true}
                    >
                      <div
                        className="font-size-sm text-black"
                        style={{ display: "inline-block" }}
                        onClick={copyToClipboard}
                      >
                        {props.user.api_key}
                      </div>
                    </Tooltip>
                  ) : (
                    <div
                      className="font-size-sm text-black"
                      style={{ display: "inline-block" }}
                    >
                      {props.user.api_key}
                    </div>
                  )}
                  {document.queryCommandSupported("copy") ? (
                    <>
                      <textarea
                        readOnly
                        style={{
                          position: "absolute",
                          opacity: 0,
                          width: 1,
                          height: 1,
                          bottom: -5000,
                        }}
                        ref={keyRef}
                        value={props.user.api_key}
                      />
                    </>
                  ) : null}
                </div>
              </Card>
            </Grid>
          ) : null
        ) : null}
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
});
const mapDispatchToProps = {
  dispatchUpdateUser: updateUserAction,
  dispatchResetPassword: resetPasswordAction,
  dispatchSendEmail: sendVerificationEmailAction,
  dispatchReset: resetAppAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
