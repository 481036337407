import React from "react";
import SchoolGenerateReport from "../../components/SchoolGenerateReport";

function SchoolGenerateReportPage() {
  return (
    <>
      <SchoolGenerateReport />
    </>
  );
}

export default SchoolGenerateReportPage;
