import { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  sceneSelector,
  appSelector,
  userSelector,
  ParentPartiesAPIs,
} from "../../core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  List,
  ListItem,
  LinearProgress,
  Button,
  Card,
  CardContent,
  Typography,
  Avatar,
  TextField,
} from "@material-ui/core";
import { NavLink, useLocation } from "react-router-dom";
import { CircleLoader } from "react-spinners";

// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: "30 0",
  },
  headerSection: {
    padding: "0 20 20 20",
    borderBottom: "1pt solid #000",
    marginBottom: "10",
  },
  section: {
    padding: 20,
  },
  row: {
    flexDirection: "row",
  },
  questionHeader: {
    padding: "0 0 20 0",
  },
  questionHeaderText: {
    fontSize: "15pt",
  },
  questionText: {
    fontSize: "12pt",
  },
  questionTextContainer: {
    padding: "0 0 15 0",
  },
  flexItem: {
    flex: 1,
  },
  scaleHeader: {
    fontSize: "12pt",
    textAlign: "center",
  },
  questionRow: {
    flexDirection: "row",
    padding: "0 0 10 0",
  },
  questionScaleBottomRow: {
    flexDirection: "row",
    padding: "0 0 20 0",
  },
});

function SchoolEvaluationAnalytics(props) {
  const location = useLocation();
  const { pathname } = location;
  const params = pathname.split("/");
  const sceneType = params[2];
  const sceneId = params[3];

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [initialLoad, setInitialLoad] = useState(true);
  const [evaluationAnswers, setEvaluationAnswers] = useState([]);
  const [totalResponses, setTotalResponses] = useState(0);
  const [comments, setComments] = useState([]);
  const [openEndedQuestions, setOpenEndedQuestions] = useState([]);

  const [guardians, setGuardians] = useState([]);

  useEffect(() => {
    async function getResponses() {
      const responses = await ParentPartiesAPIs.readSchoolEvaluationResponses(
        sceneId
      );
      const guardiansWithEvaluations = await ParentPartiesAPIs.fetchGuardainsEvaluations(
        sceneId
      );
      setGuardians(guardiansWithEvaluations);

      setTotalResponses(responses ? responses.length : 0);
      const questions = responses
        ? [
            ...new Set(
              responses
                .map((res) =>
                  res.responses
                    ? res.responses.map((res1) => res1.question)
                    : []
                )
                .flat()
            ),
          ]
        : [];
      const arrayOfResponses = responses
        ? responses.map((res) => res.responses).flat()
        : [];

      // Gen open-ended responses
      const openEndedQuestions = responses
        ? responses
            .map((res) => ({
              question: res.topic
                ? "A topic I would like to see on the Parent Parties Engage app is:"
                : res.feedbackQuestion
                ? res.feedbackQuestion
                : "",
              val: res.feedback || res.topic,
            }))
            .filter((i) => i.val)
        : [];
      let arrayedOpenEndedQuestions = [];
      openEndedQuestions.map((obj) => {
        const index = arrayedOpenEndedQuestions.findIndex(
          (z) => z.question === obj.question
        );
        if (index === -1) {
          arrayedOpenEndedQuestions.push({
            question: obj.question,
            val: [obj.val],
          });
        } else {
          arrayedOpenEndedQuestions[index].val.push(obj.val);
        }
        return null;
      });

      setOpenEndedQuestions(arrayedOpenEndedQuestions);

      // Comments
      const comments = responses
        ? [...new Set(responses.map((res) => res.comments))].filter((i) => i)
        : [];
      setComments(comments);

      const arrayOfAnswers = questions
        ? questions
            .map((question) => {
              const questionResponses = arrayOfResponses.filter((res) =>
                res ? res.question === question : false
              );

              const oneRating = questionResponses.filter((a) => a.answer === 1)
                .length;
              const twoRating = questionResponses.filter((a) => a.answer === 2)
                .length;
              const threeRating = questionResponses.filter(
                (a) => a.answer === 3
              ).length;
              const fourRating = questionResponses.filter((a) => a.answer === 4)
                .length;
              const fiveRating = questionResponses.filter((a) => a.answer === 5)
                .length;

              const totalRating = questionResponses.reduce((a, b) => {
                return a + (b.answer || 0);
              }, 0);

              const average = (totalRating / questionResponses.length).toFixed(
                1
              );
              const percent = average ? (average / 5) * 100 : 0;

              return {
                question,
                totalResponses: questionResponses.length,
                average,
                percent,
                oneRating,
                twoRating,
                threeRating,
                fourRating,
                fiveRating,
              };
            })
            .sort((a, b) => b.totalResponses - a.totalResponses)
        : [];
      setEvaluationAnswers(arrayOfAnswers);
      setInitialLoad(false);
    }

    getResponses();
  }, [props.scene, sceneId]);

  const generateExport = async () => {
    const doc = (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.headerSection}>
            <Text style={{ fontWeight: 600 }}>
              {props.scene
                ? props.scene.data
                  ? props.scene.data.name
                  : ""
                : ""}
            </Text>
            <Text style={{ fontSize: "12pt" }}>
              Parent Evaluation Responses
            </Text>
          </View>

          <View style={styles.section}>
            <View style={styles.questionHeader}>
              <Text style={styles.questionHeaderText}>Questions</Text>
            </View>
            {evaluationAnswers.map((item, index) => (
              <View>
                <View style={styles.questionTextContainer}>
                  <Text style={styles.questionText}>
                    {`${index + 1}) ${item.question}`}
                  </Text>
                </View>
                <View>
                  <View style={styles.questionRow}>
                    <View style={styles.flexItem}>
                      <Text style={styles.scaleHeader}>Strongly Disagree</Text>
                    </View>
                    <View style={styles.flexItem}>
                      <Text style={styles.scaleHeader}>Disagree</Text>
                    </View>
                    <View style={styles.flexItem}>
                      <Text style={styles.scaleHeader}>Neutral</Text>
                    </View>
                    <View style={styles.flexItem}>
                      <Text style={styles.scaleHeader}>Agree</Text>
                    </View>
                    <View style={styles.flexItem}>
                      <Text style={styles.scaleHeader}>Strongly Agree</Text>
                    </View>
                  </View>
                  <View style={styles.questionScaleBottomRow}>
                    <View style={styles.flexItem}>
                      <Text style={styles.scaleHeader}>{item.oneRating}</Text>
                    </View>
                    <View style={styles.flexItem}>
                      <Text style={styles.scaleHeader}>{item.twoRating}</Text>
                    </View>
                    <View style={styles.flexItem}>
                      <Text style={styles.scaleHeader}>{item.threeRating}</Text>
                    </View>
                    <View style={styles.flexItem}>
                      <Text style={styles.scaleHeader}>{item.fourRating}</Text>
                    </View>
                    <View style={styles.flexItem}>
                      <Text style={styles.scaleHeader}>{item.fiveRating}</Text>
                    </View>
                  </View>
                </View>
              </View>
            ))}
          </View>
          <View style={styles.section}>
            <View style={styles.questionHeader}>
              <Text style={styles.questionHeaderText}>
                Open-ended Responses
              </Text>
            </View>
            {openEndedQuestions.map((obj, index) => (
              <View style={{ marginTop: index === 0 ? 0 : 15 }}>
                <View style={{ paddingBottom: "8pt" }}>
                  <Text style={styles.questionText}>{`${obj.question}`}</Text>
                </View>
                <View style={styles.questionTextContainer}>
                  {obj.val
                    ? obj.val.map((val) => (
                        <View>
                          <Text style={styles.questionText}>{val}</Text>
                        </View>
                      ))
                    : null}
                </View>
              </View>
            ))}
          </View>
          <View style={styles.section}>
            <View style={styles.questionHeader}>
              <Text style={styles.questionHeaderText}>Additional Comments</Text>
            </View>
            {comments.map((comment) => (
              <View>
                <View style={styles.questionTextContainer}>
                  <Text style={styles.questionText}>{`- ${comment}`}</Text>
                </View>
              </View>
            ))}
          </View>
        </Page>
      </Document>
    );

    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `PPE-Evaluation-Responses.pdf`);
  };

  return !props.user ? null : props.scene ? (
    initialLoad || props.app.isLoading ? (
      <Grid container className="my-spacing-6">
        <Grid item xs={12}>
          <div className="d-flex flex-row justify-content-center">
            <CircleLoader
              className="d-flex align-self-center"
              color={"#18a9a9"}
              loading={true}
              size={40}
            />
          </div>
        </Grid>
      </Grid>
    ) : (
      <>
        <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
          <Grid item md={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <div
                className="font-weight-bold display-4"
                style={{ color: "rgb(43, 39, 60)" }}
              >
                Reporting
              </div>
            </div>
          </Grid>
          <Grid item md={12} xl={12}>
            {mobile ? null : (
              <List
                component="div"
                className="nav-line-alt nav-line d-flex align-items-center mt-3"
              >
                <ListItem
                  button
                  disableRipple
                  className="px-2"
                  component={NavLink}
                  to={`/guardians-analytics/${sceneType}/${sceneId}`}
                >
                  <span className="font-size-sm">Guardians</span>
                  <div className="divider" />
                </ListItem>
                <ListItem
                  button
                  disableRipple
                  className="px-2"
                  component={NavLink}
                  to={`/activity-analytics/${sceneType}/${sceneId}`}
                >
                  <span className="font-size-sm">Activities</span>
                  <div className="divider" />
                </ListItem>
                <ListItem button disableRipple className="px-2" selected>
                  <span className="font-size-sm">Parent Evaluations</span>
                  <div className="divider" />
                </ListItem>
                <ListItem
                  button
                  disableRipple
                  className="px-2"
                  component={NavLink}
                  to={`/weekly-scorecard-analytics/${sceneType}/${sceneId}`}
                >
                  <span className="font-size-sm">Weekly Scorecards</span>
                  <div className="divider" />
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  to={`/monthly-analytics/${sceneType}/${sceneId}`}
                  className="pr-2"
                >
                  <span className="font-size-sm">{"Monthly Analytics"}</span>
                  <div className="divider" />
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  to={`/analytics/${sceneType}/${sceneId}`}
                  className="pr-2"
                >
                  <span className="font-size-sm">{"Reports"}</span>
                  <div className="divider" />
                </ListItem>
              </List>
            )}
          </Grid>
          <Grid item md={12}>
            <div className="d-flex flex-row mb-4 align-items-center justify-content-between mt-4 pt-4">
              <div>
                <div
                  className="heading-4 text-left mb-0"
                  style={{
                    color: "#000",
                    textTransform: "none",
                  }}
                >
                  {`Total Evaluation Responses: ${
                    totalResponses ? totalResponses : 0
                  }`}
                </div>
                <div>
                  <small className="text-black-50">
                    Average response rating for each parent evaluation question.
                  </small>
                </div>
              </div>
              <Button
                className="btn-primary shadow-none ml-4"
                size="medium"
                style={{ whiteSpace: "nowrap" }}
                onClick={generateExport}
              >
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon
                    style={{ fontSize: "12px" }}
                    icon={["fas", "file-export"]}
                  />
                </span>
                <span
                  className="btn-wrapper--label"
                  style={{ fontSize: "12px" }}
                >
                  Export Responses
                </span>
              </Button>
            </div>
            <Card
              className="p-3"
              style={{
                borderRadius: "0.25rem",
                border: "1px solid rgba(0,0,0,.1)",
              }}
            >
              {evaluationAnswers.map((item, index) => (
                <ListItem className="pt-3 pb-3 d-block" key={index}>
                  <div className="align-box-row py-1">
                    <div>
                      <div className="text-black">{item.question}</div>
                    </div>
                  </div>
                  <div className="d-flex flex-row align-item-center mt-2">
                    <div
                      style={{
                        width: "100%",
                        marginRight: 16,
                      }}
                    >
                      <LinearProgress
                        variant="determinate"
                        className={`${
                          item.percent <= 40
                            ? "progress-bar-danger"
                            : item.percent > 40 && item.percent < 70
                            ? "progress-bar-warning"
                            : "progress-bar-success"
                        } w-100`}
                        value={item.percent}
                        style={{ height: ".5rem" }}
                      />
                      <div className="d-flex flex-row justify-content-between">
                        <div className="text-black-50 font-size-sm">
                          Strongly Disagree
                        </div>
                        <div className="text-black-50 font-size-sm">
                          Strongly Agree
                        </div>
                      </div>
                    </div>
                    <div style={{ minWidth: 180 }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div style={{ color: "#000" }} className="font-size-sm">
                          {`${item.average} Average Rating`}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div style={{ color: "#000" }} className="font-size-sm">
                          {`${item.totalResponses} responses`}
                        </div>
                      </div>
                    </div>
                  </div>
                </ListItem>
              ))}
            </Card>
          </Grid>
          <Grid item md={12}>
            <div className="d-flex flex-row mb-4 align-items-center justify-content-between mt-4 pt-4">
              <div>
                <div
                  className="heading-4 text-left mb-0"
                  style={{
                    color: "#000",
                    textTransform: "none",
                  }}
                >
                  Open-ended Responses
                </div>
              </div>
            </div>
            <Card>
              <CardContent>
                {openEndedQuestions.map((obj, index) => (
                  <div
                    className={index === 0 ? "text-black" : "mt-4 text-black"}
                  >
                    <div
                      style={{ fontSize: "14px", fontWeight: "bold" }}
                      className="mb-1"
                    >
                      {`${obj.question}`}
                    </div>
                    {obj.val
                      ? obj.val.map((val) => (
                          <div
                            className="text-black"
                            style={{ fontSize: "14px" }}
                          >
                            {val}
                          </div>
                        ))
                      : null}
                  </div>
                ))}
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={12}>
            <div className="d-flex flex-row mb-4 align-items-center justify-content-between mt-4 pt-4">
              <div>
                <div
                  className="heading-4 text-left mb-0"
                  style={{
                    color: "#000",
                    textTransform: "none",
                  }}
                >
                  {`Additional Comments`}
                </div>
              </div>
            </div>
            <Card>
              <CardContent>
                {comments.map((comment, index) => (
                  <div
                    className={index === 0 ? "text-black" : "mt-1 text-black"}
                    style={{ fontSize: "14px" }}
                  >
                    {comment}
                  </div>
                ))}
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={12}>
            <div className="d-flex flex-row mb-4 align-items-center justify-content-between mt-4 pt-4">
              <div>
                <div
                  className="heading-4 text-left mb-0"
                  style={{
                    color: "#000",
                    textTransform: "none",
                  }}
                >
                  {`User Responses`}
                </div>
              </div>
            </div>
            {guardians.map((guardian, index) => (
              <Card
                style={
                  index === 0
                    ? {
                        marginTop: 10,
                        border: "1px solid rgb(233 232 232)",
                      }
                    : {
                        marginTop: 20,
                        border: "1px solid rgb(233 232 232)",
                      }
                }
              >
                <CardContent>
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <div className="d-flex flex-row align-items-center">
                      {guardian ? (
                        guardian.avatar ? (
                          <Avatar
                            src={guardian.avatar}
                            style={{ width: 30, height: 30 }}
                            className="mr-2"
                          />
                        ) : null
                      ) : null}
                      <div className="text-black" style={{ fontSize: "14px" }}>
                        {guardian
                          ? guardian.username
                            ? guardian.username
                            : "Guardian"
                          : "Guardian"}
                      </div>
                    </div>
                    <Typography
                      style={{
                        color: "rgb(143, 143, 145)",
                        fontSize: "14px",
                        width: 200,
                        textAlign: "right",
                      }}
                      className="ml-2"
                    >
                      {moment(Number(guardian.start)).format("MMMM YYYY")}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 10,
                    }}
                  >
                    {guardian.response.responses
                      ? guardian.response.responses.map((question) => {
                          const percent = (question.answer / 5) * 100;
                          return (
                            <ListItem
                              className="pt-3 pb-3 d-block"
                              key={`${guardian.uid}-${question.question}-${guardian.start}`}
                            >
                              <div className="align-box-row py-1">
                                <div>
                                  <div
                                    className="text-black"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {question.question}
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex flex-row align-item-center mt-2">
                                <div
                                  style={{
                                    width: "100%",
                                    marginRight: 16,
                                  }}
                                >
                                  <LinearProgress
                                    variant="determinate"
                                    className={`${
                                      percent <= 40
                                        ? "progress-bar-danger"
                                        : percent > 40 && percent < 70
                                        ? "progress-bar-warning"
                                        : "progress-bar-success"
                                    } w-100`}
                                    value={percent}
                                    style={{ height: ".5rem" }}
                                  />
                                  <div className="d-flex flex-row justify-content-between">
                                    <div className="text-black-50 font-size-sm">
                                      Strongly Disagree
                                    </div>
                                    <div className="text-black-50 font-size-sm">
                                      Strongly Agree
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ListItem>
                          );
                        })
                      : null}
                    {guardian.response.topic ? (
                      <div className="mt-4 px-3">
                        <div
                          style={{ fontSize: 14, color: "#000" }}
                          className="mr-2"
                        >
                          A topic I would like to see on the Parent Parties
                          Engage app is:
                        </div>
                        <div className="mt-2">
                          <TextField
                            value={guardian.response.topic}
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                            hiddenLabel
                            size="small"
                            fullWidth
                          />
                        </div>
                      </div>
                    ) : null}
                    {guardian.response.feedback ? (
                      <div className="mt-4 px-3">
                        <div
                          style={{ fontSize: 14, color: "#000" }}
                          className="mr-2"
                        >
                          {guardian.response.feedbackQuestion}
                        </div>
                        <div className="mt-2">
                          <TextField
                            value={guardian.response.feedback}
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                            hiddenLabel
                            size="small"
                            fullWidth
                          />
                        </div>
                      </div>
                    ) : null}
                    {guardian.response.comments ? (
                      <div className="mt-4 px-3">
                        <div
                          style={{ fontSize: 14, color: "#000" }}
                          className="mr-2"
                        >
                          Additional Comments:
                        </div>
                        <div className="mt-2">
                          <TextField
                            value={guardian.response.comments}
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                            hiddenLabel
                            size="small"
                            fullWidth
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </CardContent>
              </Card>
            ))}
          </Grid>
        </Grid>
      </>
    )
  ) : null;
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  user: userSelector(state),
});

export default connect(mapStateToProps, null)(SchoolEvaluationAnalytics);
