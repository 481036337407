import React from "react";
import SchoolVideoShorts from "../../components/SchoolVideoShorts";

function SchoolVideoShortsPage() {
  return (
    <>
      <SchoolVideoShorts />
    </>
  );
}

export default SchoolVideoShortsPage;
