const keys = {
  // prod
  firebaseWebApi: "AIzaSyBE4sYZb8rhUb0GAMGbvKE4C0Yt4wSPmuU",
};

const firebaseDynamicLink = async (data) => {
  try {
    const res = await fetch(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${keys.firebaseWebApi}`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-type": "application/json" },
      }
    );
    const link = await res.json();
    return link.shortLink;
  } catch (err) {
    return `https://wwww.parentpartiesengage.com/`;
  }
};

export default firebaseDynamicLink;
