import { all, put } from "redux-saga/effects";
import { Scene, District } from "../../Entities";
import {
  updateAppAction,
  updateUserDistrictStateAction,
  updatePremiumStateAction,
  updateSceneStateAction,
} from "../Redux";
import {
  ReadDistrictInteractor,
  RemoveFromDistrictInteractor,
} from "../../UseCases";
import { DistrictService } from "../../Services/DistrictService";

export const CREATE_DISTRICT = "district/saga/create_district";
export const UPDATE_DISTRICT = "district/saga/update_district";
export const REMOVE_FROM_DISTRICT = "district/saga/remove_from_district";
export const DELETE_DISTRICT = "district/saga/delete_district";

interface DistrictCreateAction {
  type: string;
  district: District;
}

export const createDistrictAction = (
  district: District
): DistrictCreateAction => ({
  type: CREATE_DISTRICT,
  district,
});

export function* createDistrictSaga(action: DistrictCreateAction) {
  yield put(updateAppAction({ isLoading: true }));

  // const { district } = action;

  try {
    // const updatedScene: Scene = {
    //   id: district.id,
    //   type: "district",
    //   data: district,
    // };

    yield all([
      // put(updateUserDistrictStateAction(district)),
      // put(updateSceneStateAction(updatedScene)),
      // put(updatePremiumStateAction(undefined)),
      put(updateAppAction({ isLoading: false })),
    ]);
  } catch (error) {
    // @ts-ignore
    yield put(updateAppAction({ isLoading: false, error }));
  }
}

interface DistrictUpdateAction {
  type: string;
  district: District;
}

export const updateDistrictAction = (
  district: District
): DistrictUpdateAction => ({
  type: UPDATE_DISTRICT,
  district,
});

export function* updateDistrictSaga(action: DistrictUpdateAction) {
  yield put(updateAppAction({ isLoading: true }));

  const { district } = action;

  try {
    const updatedScene: Scene = {
      id: district.id,
      type: "district",
      data: district,
    };

    yield all([
      put(updateUserDistrictStateAction(district)),
      put(updateSceneStateAction(updatedScene)),
      put(updatePremiumStateAction(undefined)),
      put(updateAppAction({ isLoading: false })),
    ]);
  } catch (error) {
    // @ts-ignore
    yield put(updateAppAction({ isLoading: false, error }));
  }
}

export const removeFromDistrictAction = (
  employeeId: string,
  districtId: number
): any => ({
  type: REMOVE_FROM_DISTRICT,
  employeeId,
  districtId,
});

export function* removeFromDistrictSaga(action: any) {
  yield put(updateAppAction({ isLoading: true }));

  const districtService = new DistrictService();
  const removeDistrict = new RemoveFromDistrictInteractor(districtService);
  const readDistrict = new ReadDistrictInteractor(districtService);

  const { employeeId, districtId } = action;
  if (!employeeId) {
    yield put(
      updateAppAction({
        isLoading: false,
        error: new Error("Employee ID cannot be undefined"),
      })
    );
  } else if (!districtId) {
    yield put(
      updateAppAction({
        isLoading: false,
        error: new Error("District ID cannot be undefined"),
      })
    );
  } else {
    try {
      yield removeDistrict.removeFromDistrict(employeeId, districtId);
      const district: District = yield readDistrict.readDistrict(districtId);

      const updatedScene: Scene = {
        id: district.id,
        type: "district",
        data: district,
      };

      yield all([
        put(updateSceneStateAction(updatedScene)),
        put(updateAppAction({ isLoading: false })),
      ]);
    } catch (error) {
      // @ts-ignore
      yield put(updateAppAction({ isLoading: false, error }));
    }
  }
}
