import AdminResourcesGeneral from "../../components/AdminResourcesGeneral";

function AdminResourcesGeneralPage() {
  return (
    <>
      <AdminResourcesGeneral />
    </>
  );
}

export default AdminResourcesGeneralPage;
