import React from "react";
import AdminResourcesFileHosting from "../../components/AdminResourcesFileHosting";

function AdminResourcesFileHostingPage() {
  return (
    <>
      <AdminResourcesFileHosting />
    </>
  );
}

export default AdminResourcesFileHostingPage;
