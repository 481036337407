import { Auth, Functions, Database, Analytics } from "./Firebase";
import {
  SchoolUser,
  SchoolUserRecord,
  SchoolRecord,
  DistrictRecord,
} from "../../Entities";
import { readSchool } from "./School";
import { readDistrict } from "./District";
const moment = require("moment");

export const createSchoolUser = async (user: SchoolUser): Promise<any> => {
  const createSchoolUser = Functions.httpsCallable("createSchoolUser");
  const { data }: { data: SchoolUserRecord | null } = await createSchoolUser({
    user,
  });
  if (data !== null) {
    Analytics.setUserProperties({
      "Sign Up Date": moment().format("L"),
      Email: data.email.address,
      Name: `${data.firstName} ${data.lastName}`,
    });
    Analytics.logEvent("C4B_Web_Register");

    const sleep = (milliseconds: number) => {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    };

    await sleep(300);

    return data;
  }
  return user;
};

export const readUser = async (userId: string): Promise<SchoolUser | null> => {
  try {
    const user = await Database.ref(`schoolUsers/${userId}`)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val();
        return snapshotVal;
      });
    if (user !== null) {
      if (user.schools) {
        const schools = await Promise.all<any>(
          Object.keys(user.schools || {}).map(readSchool)
        );
        user.schools = schools.reduce(
          (store, school) =>
            school.id ? { ...store, [school.id]: school } : store,
          {} as { [schoolId: string]: SchoolRecord }
        );
      }
      if (user.districts) {
        const districts = await Promise.all<any>(
          Object.keys(user.districts || {}).map(readDistrict)
        );
        user.districts = districts.reduce(
          (store, district) =>
            district.id ? { ...store, [district.id]: district } : store,
          {} as { [districtId: string]: DistrictRecord }
        );
      }
      Analytics.setUserProperties({
        Email: user.email.address,
        Name: `${user.firstName} ${user.lastName}`,
      });
      return new SchoolUser(user);
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const updateUser = async (user: SchoolUser): Promise<SchoolUser> => {
  const updateUser = Functions.httpsCallable("updateSchoolUser");
  const currentUser = Auth.currentUser;

  if (!currentUser) {
    throw new Error("Must be signed in to update user.");
  }

  /* User changed email, set email verified to false */
  if (currentUser.email !== user.email.address) {
    await currentUser.updateEmail(user.email.address);
    currentUser.reload();
    currentUser.sendEmailVerification();
    user.emailVerified = false;
  }
  await updateUser({ user });
  Analytics.setUserProperties({
    Email: user.email.address,
    Name: user.name,
  });

  return user;
};

export const deleteUser = async (user: SchoolUser): Promise<void> => {
  const deleteUser = Functions.httpsCallable("deleteUser");
  await deleteUser({ user });
  return;
};

export const listenToUser = (
  userId: string,
  handler: (user: SchoolUser) => void
) => {
  Database.ref(`schoolUsers/${userId}`).on("value", async (snapshot) => {
    if (!snapshot || !snapshot.val() || !snapshot.exists()) {
      return;
    }

    const user = await readUser(userId);
    if (user) {
      handler(user);
    }
  });
};
