import { Functions } from "./Firebase";

interface Prediction {
  place_id: string;
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
}

interface Prediction2 {
  place_id: string;
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
}

export const placeAutocomplete = async (
  query: string
): Promise<Prediction[]> => {
  const placeAutocomplete = Functions.httpsCallable("placeAutocomplete");
  const { data }: { data: Prediction[] } = await placeAutocomplete({ query });
  return data;
};

export const placeAutocomplete2 = async (
  query: string
): Promise<Prediction2[]> => {
  const placeAutocomplete2 = Functions.httpsCallable("placeAutocomplete2");
  const { data }: { data: Prediction2[] } = await placeAutocomplete2({ query });
  return data;
};

interface Details {
  formatted_address: string;
  formatted_phone_number: string;
  geometry: Geometry;
  international_phone_number: string;
  name: string;
  opening_hours: OpeningHours;
  photos: Photo[];
  place_id: string;
  price_level: number;
  rating: number;
  website: string;
  url: string;
  types: string[];
}

interface Geometry {
  location: { lat: number; lng: number };
}

interface OpeningHours {
  periods: OpeningHourPeriod[];
  weekday_text: string[];
}

interface OpeningHourPeriod {
  open: { day: number; time: string };
  close: { day: number; time: string };
}

interface Photo {
  height: number;
  width: number;
  url: string;
}

export const placeDetails = async (placeId: string): Promise<Details> => {
  const placeDetails = Functions.httpsCallable("placeDetails");
  const { data }: { data: Details } = await placeDetails({ placeId });
  return data;
};

interface GeocodeLookUpResponse {
  city: string;
  state: string;
  zipcode: string;
}

export const geocodeLookUp = async ({
  latitude,
  longitude,
}: {
  latitude: number;
  longitude: number;
}): Promise<GeocodeLookUpResponse> => {
  const geocodeLookUp = Functions.httpsCallable("geocodeLookUp");
  const { data }: { data: GeocodeLookUpResponse } = await geocodeLookUp({
    latitude,
    longitude,
  });
  return data;
};
