import AdminAnalytics from "../../components/AdminAnalytics";

function AdminAnalyticsPage() {
  return (
    <>
      <AdminAnalytics />
    </>
  );
}

export default AdminAnalyticsPage;
