import React from "react";
import Team from "../../components/Team";

export default function TeamPage() {
  return (
    <>
      <Team />
    </>
  );
}
