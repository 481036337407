import { POSITION } from "./SchoolUser";
import { Email, EmailRecord } from "./Email";

export enum INVITE_STATUS {
  Pending = "Pending",
  Expired = "Expired",
}

export interface InviteRecord {
  id?: string;
  senderId: string;
  email: EmailRecord;
  position: POSITION;
  itemId: string | number;
  itemType: "school" | "district";
  status: INVITE_STATUS;
  timestamp: number;
}

export class Invite {
  readonly id?: string;
  readonly senderId: string;
  private email: Email;
  readonly position: POSITION;
  readonly itemId: string | number;
  readonly itemType: "school" | "district";
  readonly status: INVITE_STATUS;
  private timestamp: number;

  constructor(invite: InviteRecord) {
    const {
      id,
      senderId,
      email,
      position,
      itemId,
      itemType,
      status,
      timestamp,
    } = invite;

    this.id = id;
    this.senderId = senderId;
    this.email = new Email(email);
    this.position = position;
    this.itemId = itemId;
    this.itemType = itemType;
    this.status = status;
    this.timestamp = timestamp;
  }

  get emailAddress(): string {
    return this.email.address;
  }

  get timestampDate(): Date {
    return new Date(this.timestamp);
  }
}
