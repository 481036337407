import { District, DistrictRecord } from "../Entities";

export interface DistrictDBService {
  createDistrict: (
    // schoolUserId: string,
    district: DistrictRecord
  ) => Promise<District | Error>;
  readDistrict: (districtId: string) => Promise<District | Error>;
  updateDistrict: (district: DistrictRecord) => Promise<District | Error>;
  removeFromDistrict: (employeeId: string, schoolId: string) => Promise<void>;
}

class DistrictUseCase {
  districtService: DistrictDBService;

  constructor(districtService: DistrictDBService) {
    this.districtService = districtService;
  }
}

export class CreateDistrictInteractor extends DistrictUseCase {
  async createDistrict(
    // schoolUserId: string,
    district: DistrictRecord
  ): Promise<District | Error> {
    return this.districtService.createDistrict(
      // schoolUserId,
      district
    );
  }
}

export class ReadDistrictInteractor extends DistrictUseCase {
  async readDistrict(districtId: string): Promise<District | Error> {
    return this.districtService.readDistrict(districtId);
  }
}

export class UpdateDistrictInteractor extends DistrictUseCase {
  async updateDistrict(district: DistrictRecord): Promise<District | Error> {
    return this.districtService.updateDistrict(district);
  }
}

export class RemoveFromDistrictInteractor extends DistrictUseCase {
  async removeFromDistrict(
    employeeId: string,
    districtId: string
  ): Promise<void> {
    return this.districtService.removeFromDistrict(employeeId, districtId);
  }
}
