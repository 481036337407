import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { CircleLoader } from "react-spinners";
import CloseIcon from "@material-ui/icons/Close";
import {
  sceneSelector,
  appSelector,
  userSelector,
  ParentPartiesAPIs,
} from "../../core";
import {
  Grid,
  List,
  ListItem,
  FormControl,
  InputLabel,
  TextField,
  Button,
  Snackbar,
  IconButton,
  Divider,
} from "@material-ui/core";

function SchoolPushNotification(props) {
  const [initialLoad, setInitialLoad] = useState(true);
  const [guardiansWithPushEnabled, setGuardiansWithPushEnabled] = useState([]);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationText, setNotificationText] = useState("");

  const [loadingPush, setLoadingPush] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [snackBarType, setSnackBarType] = useState("success");

  useEffect(() => {
    if (props.scene) {
      async function getAvailableGuardians() {
        const guardiansAvailable = await ParentPartiesAPIs.getGuardiansForPush(
          props.scene.id
        );

        setGuardiansWithPushEnabled(guardiansAvailable);
        setInitialLoad(false);
      }

      getAvailableGuardians();
    }
  }, []);

  const sendPushNotification = async () => {
    if (loadingPush) {
      return;
    }

    setLoadingPush(true);

    const guardianItems = guardiansWithPushEnabled.map((i) => ({
      language: i.language || "en",
      fcmToken: i.fcmToken,
    }));

    await ParentPartiesAPIs.sendSchoolPushNotification(
      {
        notificationTitle,
        notificationText,
      },
      guardianItems
    );

    setToastMessage(`Push notification sent.`);
    setSnackBarType("success");
    setToastOpen(true);

    setNotificationTitle("");
    setNotificationText("");

    setLoadingPush(false);
  };

  return !props.user ? null : props.scene ? (
    initialLoad || props.app.isLoading ? (
      <Grid container className="my-spacing-6">
        <Grid item xs={12}>
          <div className="d-flex flex-row justify-content-center">
            <CircleLoader
              className="d-flex align-self-center"
              color={"#18a9a9"}
              loading={true}
              size={40}
            />
          </div>
        </Grid>
      </Grid>
    ) : (
      <>
        <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
          <Grid item md={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: 13,
                paddingBottom: 13,
              }}
            >
              <div
                className="font-weight-bold display-4"
                style={{ color: "rgb(43, 39, 60)" }}
              >
                Send Push Notification
              </div>
            </div>
          </Grid>
          <Grid item md={12}>
            <div className="mt-5 text-black">{`Send a push notification to ${guardiansWithPushEnabled.length} guardians.`}</div>
            <div className="text-black-50 mb-4 font-size-sm">
              Guardian users must have push notifications enabled to receive
              these messages.
            </div>
            <div className="mt-4 pt-4">
              <InputLabel
                style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                className="mb-2"
              >
                {"Notification title"}
              </InputLabel>
              <FormControl fullWidth variant="outlined">
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  type="text"
                  value={notificationTitle}
                  onChange={(e) => {
                    setNotificationTitle(e.target.value);
                  }}
                />
              </FormControl>
              <div className="py-3">
                <InputLabel
                  style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                  className="mt-4 mb-2"
                >
                  {"Notification text"}
                </InputLabel>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    type="text"
                    multiline
                    value={notificationText}
                    onChange={(e) => {
                      setNotificationText(e.target.value);
                    }}
                    inputProps={{
                      style: { minHeight: "50px" },
                    }}
                  />
                </FormControl>
              </div>
              <Divider className="my-4" />
              <Button
                className="btn-primary shadow-none mt-4"
                onClick={sendPushNotification}
                disabled={!notificationTitle || !notificationText}
                size="medium"
              >
                {loadingPush && (
                  <div className="mr-3">
                    <CircleLoader color="#fff" size={15} />
                  </div>
                )}
                <span
                  className="btn-wrapper--label"
                  style={{ fontSize: "14px" }}
                >
                  {loadingPush ? "Sending..." : "Send Push Notification"}
                </span>
              </Button>
            </div>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={toastOpen}
          classes={{
            root:
              snackBarType === "error"
                ? `toastr-danger`
                : snackBarType === "info"
                ? `toastr-primary`
                : `toastr-success`,
          }}
          onClose={() => setToastOpen(false)}
          message={toastMessage}
          autoHideDuration={snackBarType === "success" ? 5000 : null}
          action={
            <>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setToastOpen(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
      </>
    )
  ) : null;
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  user: userSelector(state),
});

export default connect(mapStateToProps, null)(SchoolPushNotification);
