import { useEffect, useState } from "react";
import update from "immutability-helper";
import Bugsnag from "@bugsnag/js";
import { connect } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  sceneSelector,
  appSelector,
  userSelector,
  updateSchoolAction,
  School,
} from "../../core";
import {
  Grid,
  List,
  ListItem,
  InputLabel,
  Button,
  IconButton,
  Snackbar,
  TextField,
  Dialog,
  FormControl,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
// import moment from "moment";
import { CircleLoader } from "react-spinners";

function SchoolTeachers(props) {
  const location = useLocation();
  const { pathname } = location;
  const params = pathname.split("/");
  const sceneType = params[2];
  const sceneId = params[3];

  const [initialLoad, setInitialLoad] = useState(true);

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [snackBarType, setSnackBarType] = useState("success");

  // Teachers
  const [teachers, setTeachers] = useState([]);
  const [teacherDialog, setTeacherDialog] = useState(false);
  const toggleTeacherDialog = () => {
    setTeacherDialog(!teacherDialog);
  };
  const [teacherDialogName, setTeacherDialogName] = useState("");
  const [teacherDialogGrade, setTeacherDialogGrade] = useState("");
  const [teacherLoading, setTeacherLoading] = useState(false);

  const handleAddTeacher = (teacher) => {
    setTeachers(
      update(teachers, {
        $push: [teacher],
      })
    );
  };

  const handleRemoveTeacher = (index) => {
    setTeachers(
      update(teachers, {
        $splice: [[index, 1]],
      })
    );
  };
  const handleUpdateTeachers = () => {
    setTeacherLoading(true);
    setToastMessage(`Updating teachers...`);
    setSnackBarType("info");
    setToastOpen(true);
    let school = new School({
      ...props.scene.data,
      teachers,
    });

    props.dispatchUpdateSchool(school);
  };

  useEffect(() => {
    if (props.scene) {
      if (props.scene.data && props.scene.type === "school") {
        if (props.scene.data.teachers) {
          // Teachers
          setTeachers(props.scene.data.teachers || []);
        }

        setInitialLoad(false);
      }
    }
  }, [props.scene]);

  useEffect(() => {
    if (teacherLoading) {
      if (!props.app.isLoading) {
        if (props.app.error) {
          Bugsnag.notify(props.app.error);
          setToastMessage(`Could not update teachers.`);
          setSnackBarType("error");
          setToastOpen(true);
          setTeacherLoading(false);
        } else {
          setToastMessage(`Updated teachers successfully.`);
          setSnackBarType("success");
          setToastOpen(true);
          setTeacherLoading(false);
        }
      }
    }
  }, [props.app.error, props.app.isLoading, teacherLoading]);

  return !props.user ? null : props.scene ? (
    initialLoad || props.app.isLoading ? (
      <Grid container className="my-spacing-6">
        <Grid item xs={12}>
          <div className="d-flex flex-row justify-content-center">
            <CircleLoader
              className="d-flex align-self-center"
              color={"#18a9a9"}
              loading={true}
              size={40}
            />
          </div>
        </Grid>
      </Grid>
    ) : (
      <>
        <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
          <Grid item md={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                className="font-weight-bold display-4"
                style={{ color: "rgb(43, 39, 60)" }}
              >
                Get Started
              </div>
            </div>
          </Grid>
          <Grid item md={12}>
            <List
              component="div"
              className="nav-line-alt nav-line d-flex align-items-center mt-3"
            >
              <ListItem
                button
                component={NavLink}
                to={`/resources/${sceneType}/${sceneId}`}
                className="px-2"
              >
                <span className="font-size-sm">{"Onboard Parents"}</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                className="pr-2"
                component={NavLink}
                to={`/team/${sceneType}/${sceneId}`}
              >
                <span className="font-size-sm">{"Admin Team"}</span>
                <div className="divider" />
              </ListItem>
              <ListItem button selected className="pr-2">
                <span className="font-size-sm">{"Teacher List"}</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to={`/more-info/${sceneType}/${sceneId}`}
                className="pr-2"
              >
                <span className="font-size-sm">{"Contact Info"}</span>
                <div className="divider" />
              </ListItem>
            </List>
          </Grid>
          <Grid item md={12}>
            <div className={"py-4 px-4 mt-4"}>
              <h5 className="font-size-xl mb-2 font-weight-bold">Teachers</h5>
              <p
                className="text-black mb-4"
                style={{ fontSize: "14px", fontWeight: 300 }}
              >
                Add teachers from your school for parents to select in-app
              </p>
              <Grid container spacing={4}>
                <Grid item md={8} xl={8}>
                  <div className="d-flex flex-row align-items-center mb-2">
                    <InputLabel shrink={true}>Teachers</InputLabel>
                    <div>
                      <Button
                        className="btn-pill btn-success font-weight-bold px-4"
                        onClick={toggleTeacherDialog}
                        size="small"
                      >
                        {"Add Teacher"}
                      </Button>
                    </div>
                  </div>
                  <div className="mt-4">
                    {teachers.map((teacher, index) => (
                      <div className="mb-2 d-flex flex-row align-items-center">
                        <div className="text-black">
                          {`${teacher.name}  |  ${teacher.grade}`}
                        </div>
                        <IconButton
                          onClick={() => {
                            handleRemoveTeacher(index);
                          }}
                          className="ml-2"
                        >
                          <FontAwesomeIcon
                            icon={["fas", "trash"]}
                            className="font-size-sm"
                          />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                  <div className="divider my-3" />
                </Grid>
                <Grid item md={8} xl={8}>
                  <Button
                    className="btn-pill btn-primary font-weight-bold px-4"
                    onClick={handleUpdateTeachers}
                  >
                    {"Update Teachers"}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={toastOpen}
          classes={{
            root:
              snackBarType === "error"
                ? `toastr-danger`
                : snackBarType === "info"
                ? `toastr-primary`
                : `toastr-success`,
          }}
          onClose={() => setToastOpen(false)}
          message={toastMessage}
          autoHideDuration={snackBarType === "success" ? 5000 : null}
          action={
            <>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setToastOpen(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
        <Dialog
          fullWidth
          maxWidth={"sm"}
          open={teacherDialog}
          onClose={() => toggleTeacherDialog()}
          classes={{ paper: "modal-content rounded-lg pt-4" }}
        >
          <div className="px-3 pb-1 text-left font-size-md font-weight-bold">
            {`Add teacher`}
          </div>
          <div className="divider my-2" />
          <div className="p-3">
            <Grid container spacing={6}>
              <Grid item md={12}>
                <FormControl fullWidth variant="outlined">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={teacherDialogName}
                      label="Teacher Name"
                      type="text"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        onChange: (e) => {
                          setTeacherDialogName(e.target.value);
                        },
                      }}
                    />
                  </div>
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <InputLabel shrink={true} className="mb-2">
                  Teacher Grade
                </InputLabel>
                <FormControl fullWidth variant="outlined">
                  <Autocomplete
                    id="tags-standardww"
                    options={[
                      "Preschool",
                      "Kindergarten",
                      "1st grade",
                      "2nd grade",
                      "3rd grade",
                      "4th grade",
                      "5th grade",
                      "6th grade",
                      "7th grade",
                      "8th grade",
                      "9th grade",
                      "10th grade",
                      "11th grade",
                      "12th grade",
                    ]}
                    value={teacherDialogGrade}
                    onChange={(event, newValue) => {
                      setTeacherDialogGrade(newValue);
                    }}
                    getOptionLabel={(option) => option}
                    renderValue={(selected) => selected}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className="pt-4">
              <Button
                onClick={() => toggleTeacherDialog()}
                className="btn-neutral-dark btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Cancel</span>
              </Button>
              <Button
                onClick={async () => {
                  toggleTeacherDialog();
                  handleAddTeacher({
                    name: teacherDialogName,
                    grade: teacherDialogGrade,
                  });
                }}
                className={"btn-pill mx-1 btn-success"}
              >
                <span className="btn-wrapper--label">Save</span>
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    )
  ) : null;
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = {
  dispatchUpdateSchool: updateSchoolAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SchoolTeachers);
