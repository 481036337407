import { all, takeLatest } from "redux-saga/effects";
// ThemeOptions
import {
  setPageTitleStyleSaga,
  setPageTitleBackgroundSaga,
  setPageTitleShadowSaga,
  setPageTitleIconBoxSaga,
  setPageTitleDescriptionSaga,
  setFooterBgTransparentSaga,
  setFooterShadowSaga,
  setFooterFixedSaga,
  setThemeConfiguratorToggleSaga,
  setContentBackgroundSaga,
  setHeaderDrawerToggleSaga,
  setHeaderSearchHoverSaga,
  setHeaderBgTransparentSaga,
  setHeaderShadowSaga,
  setHeaderFixedSaga,
  setSidebarUserboxSaga,
  setSidebarToggleSaga,
  setSidebarToggleMobileSaga,
  setSidebarFooterSaga,
  setSidebarStyleSaga,
  setSidebarFixedSaga,
  setSidebarShadowSaga,
} from "./ThemeOptions";
import {
  SET_PAGE_TITLE_DESCRIPTION,
  SET_PAGE_TITLE_ICON_BOX,
  SET_PAGE_TITLE_SHADOW,
  SET_PAGE_TITLE_BACKGROUND,
  SET_PAGE_TITLE_STYLE,
  SET_FOOTER_BG_TRANSPARENT,
  SET_FOOTER_SHADOW,
  SET_FOOTER_FIXED,
  SET_THEME_CONFIGURATOR_TOGGLE,
  SET_CONTENT_BACKGROUND,
  SET_HEADER_DRAWER_TOGGLE,
  SET_HEADER_SEARCH_HOVER,
  SET_HEADER_BG_TRANSPARENT,
  SET_HEADER_SHADOW,
  SET_HEADER_FIXED,
  SET_SIDEBAR_USERBOX,
  SET_SIDEBAR_TOGGLE,
  SET_SIDEBAR_TOGGLE_MOBILE,
  SET_SIDEBAR_FOOTER,
  SET_SIDEBAR_STYLE,
  SET_SIDEBAR_FIXED,
  SET_SIDEBAR_SHADOW,
} from "../Redux";
import {
  RESET_APP,
  resetAppSaga,
  DATE_RANGE_APP,
  updateDateRangeSaga,
} from "./App";
import {
  SIGN_IN,
  SIGN_UP,
  RESET_PASSWORD,
  SIGN_OUT,
  LISTEN_TO_AUTH_STATE,
  UPDATE_USER,
  SEND_EMAIL_VERIFICATION_EMAIL,
  VERIFY_EMAIL,
  signInSaga,
  signUpSaga,
  resetPasswordSaga,
  signOutSaga,
  listenToAuthStateSaga,
  updateUserSaga,
  sendVerificationEmailSaga,
  verifyEmailSaga,
} from "./SchoolUser";
import {
  CREATE_SCHOOL,
  READ_SCHOOL,
  UPDATE_SCHOOL,
  createSchoolSaga,
  readSchoolSaga,
  updateSchoolSaga,
  REMOVE_FROM_SCHOOL,
  removeFromSchoolSaga,
} from "./School";
import { READ_SCENE, readSceneSaga } from "./Scene";
import {
  INVITE_TO_ITEM,
  READ_INVITE,
  ACCEPT_INVITE,
  CANCEL_INVITE,
  inviteToItemSaga,
  readInviteSaga,
  acceptInviteSaga,
  cancelInviteSaga,
} from "./Invite";
import { TOGGLE_CHECKOUT, toggleCheckoutSaga } from "./Checkout";
import { UPDATE_PREMIUM, updatePremiumSaga } from "./Premium";
import { UPDATE_GRID, updateGridSaga } from "./Grid";
import {
  createDistrictSaga,
  CREATE_DISTRICT,
  updateDistrictSaga,
  UPDATE_DISTRICT,
  REMOVE_FROM_DISTRICT,
  removeFromDistrictSaga,
} from "./District";
import {
  createActivitySaga,
  CREATE_ACTIVITY,
  createDistrictActivitySaga,
  CREATE_DISTRICT_ACTIVITY,
  updateActivitySaga,
  UPDATE_ACTIVITY,
  deleteActivitySaga,
  DELETE_ACTIVITY,
} from "./Activity";
import {
  createVideoShortSaga,
  CREATE_VIDEO_SHORT,
  updateVideoShortSaga,
  UPDATE_VIDEO_SHORT,
  deleteVideoShortSaga,
  DELETE_VIDEO_SHORT,
} from "./VideoShort";

export { resetAppAction, updateDateRangeAction } from "./App";
export {
  signInAction,
  signUpAction,
  resetPasswordAction,
  signOutAction,
  listenToAuthStateAction,
  updateUserAction,
  sendVerificationEmailAction,
  verifyEmailAction,
} from "./SchoolUser";
export {
  createSchoolAction,
  readSchoolAction,
  updateSchoolAction,
  removeFromSchoolAction,
} from "./School";
export { readSceneAction } from "./Scene";
export {
  inviteToItemAction,
  readInviteAction,
  acceptInviteAction,
  cancelInviteAction,
} from "./Invite";
export { toggleCheckoutAction } from "./Checkout";
export { updatePremiumAction } from "./Premium";
export { updateGridAction } from "./Grid";
export {
  createDistrictAction,
  updateDistrictAction,
  removeFromDistrictAction,
} from "./District";
export {
  createActivityAction,
  createDistrictActivityAction,
  updateActivityAction,
  deleteActivityAction,
} from "./Activity";
export {
  createVideoShortAction,
  updateVideoShortAction,
  deleteVideoShortAction,
} from "./VideoShort";

export {
  setPageTitleStyle,
  setPageTitleBackground,
  setPageTitleShadow,
  setPageTitleIconBox,
  setPageTitleDescription,
  setFooterBgTransparent,
  setFooterShadow,
  setFooterFixed,
  setThemeConfiguratorToggle,
  setContentBackground,
  setHeaderDrawerToggle,
  setHeaderSearchHover,
  setHeaderBgTransparent,
  setHeaderShadow,
  setHeaderFixed,
  setSidebarUserbox,
  setSidebarToggle,
  setSidebarToggleMobile,
  setSidebarFooter,
  setSidebarStyle,
  setSidebarFixed,
  setSidebarShadow,
} from "../Redux";

export function* rootSaga() {
  yield all([
    takeLatest(RESET_APP, resetAppSaga),
    takeLatest(DATE_RANGE_APP, updateDateRangeSaga),
    takeLatest(LISTEN_TO_AUTH_STATE, listenToAuthStateSaga),
    takeLatest(UPDATE_USER, updateUserSaga),
    takeLatest(SEND_EMAIL_VERIFICATION_EMAIL, sendVerificationEmailSaga),
    takeLatest(VERIFY_EMAIL, verifyEmailSaga),
    takeLatest(SIGN_UP, signUpSaga),
    takeLatest(SIGN_IN, signInSaga),
    takeLatest(RESET_PASSWORD, resetPasswordSaga),
    takeLatest(SIGN_OUT, signOutSaga),
    takeLatest(CREATE_SCHOOL, createSchoolSaga),
    takeLatest(READ_SCHOOL, readSchoolSaga),
    takeLatest(UPDATE_SCHOOL, updateSchoolSaga),
    takeLatest(REMOVE_FROM_SCHOOL, removeFromSchoolSaga),
    takeLatest(READ_SCENE, readSceneSaga),
    takeLatest(INVITE_TO_ITEM, inviteToItemSaga),
    takeLatest(READ_INVITE, readInviteSaga),
    takeLatest(ACCEPT_INVITE, acceptInviteSaga),
    takeLatest(CANCEL_INVITE, cancelInviteSaga),
    //// CHECKOUT ////
    takeLatest(TOGGLE_CHECKOUT, toggleCheckoutSaga),
    /// Premium ////
    takeLatest(UPDATE_PREMIUM, updatePremiumSaga),
    /// GRID ////
    takeLatest(UPDATE_GRID, updateGridSaga),
    /// DISTRICT ///
    takeLatest(CREATE_DISTRICT, createDistrictSaga),
    takeLatest(UPDATE_DISTRICT, updateDistrictSaga),
    takeLatest(REMOVE_FROM_DISTRICT, removeFromDistrictSaga),
    /// ACTIVITY ///
    takeLatest(CREATE_ACTIVITY, createActivitySaga),
    takeLatest(CREATE_DISTRICT_ACTIVITY, createDistrictActivitySaga),
    takeLatest(UPDATE_ACTIVITY, updateActivitySaga),
    takeLatest(DELETE_ACTIVITY, deleteActivitySaga),
    /// VIDEO SHORT ///
    takeLatest(CREATE_VIDEO_SHORT, createVideoShortSaga),
    takeLatest(UPDATE_VIDEO_SHORT, updateVideoShortSaga),
    takeLatest(DELETE_VIDEO_SHORT, deleteVideoShortSaga),

    /// THEME OPTIONS ///
    takeLatest(SET_PAGE_TITLE_STYLE, setPageTitleStyleSaga),
    takeLatest(SET_PAGE_TITLE_BACKGROUND, setPageTitleBackgroundSaga),
    takeLatest(SET_PAGE_TITLE_SHADOW, setPageTitleShadowSaga),
    takeLatest(SET_PAGE_TITLE_ICON_BOX, setPageTitleIconBoxSaga),
    takeLatest(SET_PAGE_TITLE_DESCRIPTION, setPageTitleDescriptionSaga),
    takeLatest(SET_FOOTER_BG_TRANSPARENT, setFooterBgTransparentSaga),
    takeLatest(SET_FOOTER_SHADOW, setFooterShadowSaga),
    takeLatest(SET_FOOTER_FIXED, setFooterFixedSaga),
    takeLatest(SET_THEME_CONFIGURATOR_TOGGLE, setThemeConfiguratorToggleSaga),
    takeLatest(SET_CONTENT_BACKGROUND, setContentBackgroundSaga),
    takeLatest(SET_HEADER_DRAWER_TOGGLE, setHeaderDrawerToggleSaga),
    takeLatest(SET_HEADER_SEARCH_HOVER, setHeaderSearchHoverSaga),
    takeLatest(SET_HEADER_BG_TRANSPARENT, setHeaderBgTransparentSaga),
    takeLatest(SET_HEADER_SHADOW, setHeaderShadowSaga),
    takeLatest(SET_HEADER_FIXED, setHeaderFixedSaga),
    takeLatest(SET_SIDEBAR_USERBOX, setSidebarUserboxSaga),
    takeLatest(SET_SIDEBAR_TOGGLE, setSidebarToggleSaga),
    takeLatest(SET_SIDEBAR_TOGGLE_MOBILE, setSidebarToggleMobileSaga),
    takeLatest(SET_SIDEBAR_FOOTER, setSidebarFooterSaga),
    takeLatest(SET_SIDEBAR_STYLE, setSidebarStyleSaga),
    takeLatest(SET_SIDEBAR_FIXED, setSidebarFixedSaga),
    takeLatest(SET_SIDEBAR_SHADOW, setSidebarShadowSaga),
  ]);
}
