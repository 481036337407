import React from "react";
import AddSchool from "../../components/AddSchool";

export default function AddSchoolPage(props) {
  return (
    <>
      <AddSchool {...props} />
    </>
  );
}
