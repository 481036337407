import { useState, useEffect } from "react";
import Bugsnag from "@bugsnag/js";
import { connect } from "react-redux";
import {
  appSelector,
  userSelector,
  readSceneAction,
  SchoolServices,
  ParentPartiesAPIs,
} from "../../core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircleLoader } from "react-spinners";
import {
  Grid,
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import AGGrid from "../Grid";
import { NavLink } from "react-router-dom";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const UserDialog = (props) => {
  const {
    userDialog,
    userDialogData,
    closeUserDialog,
    userDialogResponse,
    dialogError,
    handleAddUser,
    loadingDialog,
  } = props;
  const [emailToAdd, setEmailToAdd] = useState("");

  return (
    <Dialog
      open={userDialog}
      onClose={closeUserDialog}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle id="max-width-dialog-title">Add User</DialogTitle>
      <DialogContent className="py-4">
        {userDialogResponse ? (
          userDialogResponse
        ) : (
          <div>
            <div className="mb-4">
              <div className="text-black font-size-sm mb-1">
                {userDialogData ? userDialogData.venueName : ""}
              </div>
              <div className="text-black-50 font-size-xs">
                {userDialogData ? userDialogData.venueAddress : ""}
              </div>
            </div>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={emailToAdd}
              onChange={(e) => {
                setEmailToAdd(e.target.value);
              }}
            />
            {dialogError ? (
              <FormHelperText error={true}>{dialogError}</FormHelperText>
            ) : null}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <div
          className="d-flex justify-content-end align-items-center"
          style={{ width: "100%" }}
        >
          <Button
            className="btn btn-primary"
            onClick={() => handleAddUser(emailToAdd)}
            disabled={loadingDialog || userDialogResponse}
          >
            {loadingDialog ? (
              <div className="d-flex flex-row align-items-center justify-content-center">
                <CircleLoader color={"#624185"} loading={true} size={25} />
                <span className="ml-3">Adding User...</span>
              </div>
            ) : (
              <div>Add User</div>
            )}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

function AdminSchools(props) {
  const [schools, setSchools] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  useEffect(() => {
    async function getSchools() {
      const allSchools = await ParentPartiesAPIs.readAllSchools();
      const arrayOfSchools = allSchools ? Object.values(allSchools) : [];
      setSchools(arrayOfSchools);
      setInitialLoad(false);
    }

    getSchools();
  }, []);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteDialogInfo, setDeleteDialogInfo] = useState(null);
  const handleDeleteClose = () => {
    setDeleteDialog(false);
  };
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleDeleteSchool = async () => {
    try {
      if (deleteDialogInfo) {
        if (deleteDialogInfo.id) {
          setDeleteLoading(true);
          await ParentPartiesAPIs.deleteSchool(deleteDialogInfo.id);
          setSchools(
            schools.filter((school) => school.id !== deleteDialogInfo.id)
          );
          setDeleteLoading(false);
          setDeleteDialog(false);
        }
      }
    } catch (err) {
      setDeleteLoading(false);
      console.log(err);
      Bugsnag.notify(err);
    }
  };

  // Add User Dialog
  const [userDialog, setUserDialog] = useState(false);
  const [userDialogData, setUserDialogData] = useState(null);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [userDialogResponse, setUserDialogResponse] = useState("");
  const [dialogError, setDialogError] = useState("");
  const openUserDialog = (venueName, venueAddress, venueId) => {
    setUserDialog(true);
    setUserDialogData({ venueName, venueAddress, venueId });
    setUserDialogResponse("");
    setDialogError("");
    setLoadingDialog(false);
  };
  const closeUserDialog = () => {
    setUserDialog(false);
    setLoadingDialog(false);
    setUserDialogResponse("");
  };

  const handleAddUser = async (emailToAdd) => {
    setLoadingDialog(true);

    try {
      const userDoesNotExist = await ParentPartiesAPIs.addOwnerSchool(
        userDialogData.venueId,
        emailToAdd
      );
      if (userDoesNotExist) {
        setDialogError(`User with ${emailToAdd} does not exist.`);
        setLoadingDialog(false);
        return;
      }
      setUserDialogResponse(`Successfully added user ${emailToAdd}`);
      setLoadingDialog(false);
    } catch (err) {
      setDialogError(`Something went wrong adding user ${emailToAdd}`);
      setLoadingDialog(false);
    }
  };

  return !props.user ? null : initialLoad || props.app.isLoading ? (
    <Grid container className="my-spacing-6">
      <Grid item xs={12}>
        <div className="d-flex flex-row justify-content-center">
          <CircleLoader
            className="d-flex align-self-center"
            color={"#18a9a9"}
            loading={true}
            size={40}
          />
        </div>
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container spacing={4}>
        <Grid item md={12}>
          <AGGrid
            suppressContextMenu={true}
            titleGrid={"Schools"}
            search={true}
            statusBar={true}
            exportButton={true}
            exportType={"schools"}
            createButton={
              <Button
                variant="contained"
                size="medium"
                className="btn-success shadow-none ml-4"
                component={NavLink}
                to={{
                  pathname: "/add-school",
                }}
              >
                <span className="btn-wrapper--icon mr-1">
                  <FontAwesomeIcon
                    style={{ fontSize: "12px" }}
                    icon={["fas", "plus"]}
                  />
                </span>
                <span
                  className="btn-wrapper--label"
                  style={{ fontSize: "12px" }}
                >
                  Add School
                </span>
              </Button>
            }
            rowData={schools}
            columnDefs={[
              {
                headerName: "School Name",
                field: "name",
                sortable: true,
                filter: true,
                cellRendererFramework: (params) => (
                  <NavLink
                    to={
                      params.data.plan
                        ? params.data.plan === "In-school Curriculum"
                          ? `/resources-in-person/s/${params.data.id}`
                          : `/monthly-analytics/s/${params.data.id}`
                        : `/monthly-analytics/s/${params.data.id}`
                    }
                    onClick={() => props.readSceneAction("s", params.data.id)}
                  >
                    {params.data.name}
                  </NavLink>
                ),
              },
              {
                headerName: "Plan",
                field: "plan",
                sortable: true,
                filter: true,
              },
              {
                headerName: "Students",
                field: "planStudents",
                sortable: true,
                filter: true,
                width: 120,
              },
              {
                headerName: "Billing Notes",
                field: "billingNotes",
                sortable: true,
                filter: true,
              },
              {
                width: 350,
                suppressSizeToFit: true,
                field: "actions",
                headerName: "Actions",
                cellRendererFramework: (params) => (
                  <div className="d-flex flex-row align-items-center">
                    <div style={{ lineHeight: 40 }}>
                      <button
                        className="mr-2 mb-0 btn-success MuiButtonBase-root MuiButton-root MuiButton-contained shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                        type="button"
                        onClick={() => {
                          openUserDialog(
                            params.data.name,
                            params.data.location.address,
                            params.data.id
                          );
                        }}
                      >
                        <span className="MuiButton-label">
                          <span
                            className="btn-wrapper--label text-white"
                            style={{ fontSize: "12px" }}
                          >
                            Add User
                          </span>
                        </span>
                        <span className="MuiTouchRipple-root"></span>
                      </button>
                    </div>
                    <NavLink
                      to={{
                        pathname: `/edit-school/${params.data.id}`,
                        schoolData: params.data,
                      }}
                    >
                      <button
                        className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained btn-primary shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                        type="button"
                      >
                        <span className="MuiButton-label">
                          <span
                            className="btn-wrapper--label text-white"
                            style={{ fontSize: "12px" }}
                          >
                            Edit
                          </span>
                        </span>
                        <span className="MuiTouchRipple-root"></span>
                      </button>
                    </NavLink>
                    <NavLink
                      to={
                        params.data.plan
                          ? params.data.plan === "In-school Curriculum"
                            ? `/resources-in-person/s/${params.data.id}`
                            : `/guardians-analytics/s/${params.data.id}`
                          : `/guardians-analytics/s/${params.data.id}`
                      }
                      onClick={() => props.readSceneAction("s", params.data.id)}
                    >
                      <button
                        className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                        type="button"
                        style={{
                          background: "rgba(151, 151, 151, 0.86)",
                        }}
                      >
                        <span className="MuiButton-label">
                          <span
                            className="btn-wrapper--label text-white"
                            style={{ fontSize: "12px" }}
                          >
                            View
                          </span>
                        </span>
                        <span className="MuiTouchRipple-root"></span>
                      </button>
                    </NavLink>
                    <div style={{ lineHeight: 40 }}>
                      <button
                        className="mb-0 btn-danger MuiButtonBase-root MuiButton-root MuiButton-contained shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                        type="button"
                        onClick={() => {
                          setDeleteDialogInfo(params.data);
                          setDeleteDialog(true);
                        }}
                      >
                        <span className="MuiButton-label">
                          <span
                            className="btn-wrapper--label text-white"
                            style={{ fontSize: "12px" }}
                          >
                            Delete
                          </span>
                        </span>
                        <span className="MuiTouchRipple-root"></span>
                      </button>
                    </div>
                  </div>
                ),
                cellClass: "agGridCellCenter",
              },
            ]}
            onSelectItem={(data) => {
              props.readSceneAction("s", data.id);
            }}
          />
        </Grid>
      </Grid>
      <UserDialog
        userDialog={userDialog}
        userDialogData={userDialogData}
        closeUserDialog={closeUserDialog}
        userDialogResponse={userDialogResponse}
        dialogError={dialogError}
        handleAddUser={handleAddUser}
        loadingDialog={loadingDialog}
      />
      <Dialog
        open={deleteDialog}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
        PaperProps={{
          className: "p-3",
        }}
      >
        <DialogTitle
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            color: "rgb(42, 42, 47)",
            borderBottom: "none",
          }}
        >
          {`Are you sure you want to delete school${
            deleteDialogInfo ? `: ${deleteDialogInfo.name}` : ""
          }?`}
        </DialogTitle>
        <DialogActions style={{ borderTop: "none" }} className="mt-4">
          <Button onClick={handleDeleteClose}>Cancel</Button>
          <Button
            className="btn-danger"
            onClick={() => handleDeleteSchool()}
            autoFocus
            disabled={deleteLoading}
          >
            {deleteLoading && (
              <div className="mr-3">
                <CircleLoader color="#fff" size={15} />
              </div>
            )}
            <span>Delete School</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = {
  readSceneAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSchools);
