import { useState, useEffect } from "react";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import {
  Dialog,
  Typography,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import update from "immutability-helper";
import AGGrid from "../Grid";

function dateComparator(date1, date2) {
  if (date1 === null && date2 === null) {
    return 0;
  }
  if (date1 === null) {
    return -1;
  }
  if (date2 === null) {
    return 1;
  }

  return date1 - date2;
}

const styles = (theme) => ({
  rootNormal: {
    margin: 0,
    padding: theme.spacing(2),
  },
  root: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButtonNormal: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButton: {
    position: "static",
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, row, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={row ? classes.root : classes.rootNormal}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={row ? classes.closeButton : classes.closeButtonNormal}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const AddFileDialog = (props) => {
  const {
    filePool,
    addFileDialog,
    addFileDialogInfo,
    handleAddFileDialogClose,
    themes,
    handleUpdateCurriculumThemeFiles,
  } = props;

  const themeFiles = themes
    ? themes
        .map((theme) => (theme.id !== addFileDialog ? theme.fileIds : []))
        .flat()
    : [];

  useEffect(() => {
    return () => setSelectedFiles([]);
  }, [addFileDialog]);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleFileSelect = (e) => {
    const itemId = e.data.id;
    const itemIndex = selectedFiles.findIndex((i) => i === itemId);
    if (e.node.selected) {
      setSelectedFiles([...selectedFiles, e.data.id]);
    } else {
      setSelectedFiles(
        update(selectedFiles, {
          $splice: [[itemIndex, 1]],
        })
      );
    }
  };

  const themeId = addFileDialogInfo;
  const theme = themes ? themes.find((theme) => theme.id === themeId) : null;

  return (
    <Dialog
      open={addFileDialog}
      onClose={handleAddFileDialogClose}
      fullWidth={true}
      maxWidth={"lg"}
      PaperProps={{
        className: "p-3",
      }}
    >
      <DialogTitle
        style={{
          borderBottom: "none",
        }}
        onClose={handleAddFileDialogClose}
        row={true}
      >
        <div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              color: "rgb(42, 42, 47)",
            }}
          >
            {theme ? `Edit Files: ${theme.subtext}` : ""}
          </div>
          {theme ? (
            theme.name ? (
              <div className="text-black-50">{theme.name}</div>
            ) : null
          ) : null}
        </div>
      </DialogTitle>
      <DialogContent dividers={true} className="pb-4 pt-4">
        <AGGrid
          updateSelected={theme ? theme.fileIds : []}
          rowData={filePool}
          onRowSelected={handleFileSelect}
          rowSelection={"multiple"}
          search={false}
          autoHeight={true}
          columnDefs={[
            {
              flex: 2,
              checkboxSelection: true,
              headerName: "Name",
              field: "name",
              sortable: true,
              filter: true,
            },
            {
              flex: 1,
              sort: "desc",
              headerName: "Uploaded Date",
              field: "time",
              sortable: true,
              filter: "agDateColumnFilter",
              valueFormatter: function (params) {
                if (params.data.time) {
                  return moment(params.data.time).format("L");
                } else {
                  return null;
                }
              },
              comparator: dateComparator,
            },
          ]}
        />
      </DialogContent>
      <DialogActions style={{ borderTop: "none" }} className="mt-2">
        <Button
          className="btn-primary"
          autoFocus
          onClick={() => {
            handleUpdateCurriculumThemeFiles(themeId, selectedFiles);
          }}
        >
          {`Save Files (${selectedFiles.length} selected)`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddFileDialog;
