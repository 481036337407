import React from "react";
import SchoolEvaluationAnalytics from "../../components/SchoolEvaluationAnalytics";

function SchoolEvaluationAnalyticsPage() {
  return (
    <>
      <SchoolEvaluationAnalytics />
    </>
  );
}

export default SchoolEvaluationAnalyticsPage;
