import React from "react";
import AddActivity from "../../components/AddActivity";

export default function AddActivityPage(props) {
  return (
    <>
      <AddActivity {...props} />
    </>
  );
}
