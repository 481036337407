import React from "react";
import SchoolResourcesUploads from "../../components/SchoolResourcesUploads";

function SchoolResourcesUploadsPage() {
  return (
    <>
      <SchoolResourcesUploads />
    </>
  );
}

export default SchoolResourcesUploadsPage;
