import { useState, useEffect } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import {
  appSelector,
  createActivityAction,
  createDistrictActivityAction,
  updateActivityAction,
  sceneSelector,
  premiumSelector,
  ActivityServices,
} from "../../core";
import clsx from "clsx";
import { Prompt } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Card,
  Button,
  InputLabel,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";
import { NavLink, useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import moment from "moment";

import { CircleLoader } from "react-spinners";

/// Handle Google Autocomplete
import { makeStyles } from "@material-ui/core/styles";

// Responsive //
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ActivityDetails from "./activityDetails";

import language_array from "../../utils/language/language_array";

function getSteps() {
  return ["Translations"];
}

function getStepContent(step, props) {
  switch (step) {
    case 0:
      return <Step1 {...props} />;
    default:
      return "";
  }
}

const Step1 = (props) => {
  const {
    translationDropdown,
    translationData,
    handleTranslationDropdown,
    classes,
  } = props;

  return (
    <>
      <div className={"py-4"}>
        <h5 className="font-size-xl mb-2 font-weight-bold px-4">
          {"Edit Translations"}
        </h5>
        <div className="px-4 font-size-sm">
          Update a language's generated translations by using the translation
          dropdown.
        </div>
        <div className="px-4 mt-4">
          <Grid container spacing={0}>
            <Grid md={7}>
              <InputLabel
                style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                className="mb-3"
              >
                Translations
              </InputLabel>
              {translationData ? (
                <FormControl fullWidth variant="outlined">
                  <Select
                    value={translationDropdown}
                    onChange={(e) => {
                      handleTranslationDropdown(e.target.value);
                    }}
                    classes={{
                      select: classes.selectStyles,
                    }}
                    input={
                      <OutlinedInput
                        classes={{
                          root: classes.fixInput,
                          focused: classes.focused,
                          notchedOutline: classes.notchedOutline,
                        }}
                      />
                    }
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {Object.keys(translationData).map((z) => (
                      <MenuItem value={z} dense>
                        {language_array.find((i) => i.language === z).name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}
            </Grid>
          </Grid>
        </div>
        <ActivityDetails {...props} target={translationDropdown} />
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  noBoxShadow: {
    boxShadow: "none !important",
  },
  selectStyles: {
    fontSize: "14px",
    background: "white",
    "&:focus": {
      backgroundColor: "white !important",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "400px",
    },
  },
  fixInput: {
    borderWidth: "1px !important",
    "& $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
    "&:hover $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
    "&$focused $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
  },
  focused: {},
  notchedOutline: {},
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "4px",
    color: theme.palette.grey[500],
    padding: "2px",
  },
}));

function EditTranslation(props) {
  const classes = useStyles();
  let sceneData = props.scene ? props.scene.data : null;

  const location = useLocation();
  const { pathname } = location;
  const params = pathname.split("/");
  const sceneType = params[2];
  const sceneId = params[3];

  const [activityType, setActivityType] = useState(0);

  // Step 3
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [activityName, setActivityName] = useState("");
  const [information, setInformation] = useState("");
  const [quiz, setQuiz] = useState([
    {
      question: `Your first question?`,
      options: [{ id: moment().valueOf(), value: "Option 1" }],
    },
  ]);
  const [schoolFiles, setSchoolFiles] = useState([]);
  const [links, setLinks] = useState([]);

  const [translationData, setTranslationData] = useState(null);
  const [translationDropdown, setTranslationDropdown] = useState("en");

  // Create and Update handle //
  const [error, setError] = useState("");
  const [activityLoading, setActivityLoading] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const themeCSS = useTheme();
  const mobile = useMediaQuery(themeCSS.breakpoints.down("sm"));

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [activeStep]);

  useEffect(() => {
    if (props.location.type === "activity" && props.location.data) {
      const { data: activityData } = props.location;

      setActivityType(activityData.type);

      // Step 3
      // En
      setActivityName(activityData.name);
      setInformation(activityData.information);
      setQuiz(
        activityData.quiz
          ? activityData.quiz
          : [
              {
                question: `Your first question?`,
                options: [{ id: moment().valueOf(), value: "Option 1" }],
              },
            ]
      );
      /// School Activity
      setSchoolFiles(activityData.schoolFiles ? activityData.schoolFiles : []);

      // Link
      setLinks(activityData.links ? activityData.links : []);

      if (activityData.translationData) {
        let dataItem = activityData.translationData;
        // No English on Older ones
        if (activityData.translationData.en) {
          if (!activityData.translationData.en.name) {
            dataItem = {
              ...dataItem,
              en: {
                name: activityData.name,
                information: activityData.information,
                quiz: activityData.quiz
                  ? activityData.quiz
                  : [
                      {
                        question: `Your first question?`,
                        options: [
                          { id: moment().valueOf(), value: "Option 1" },
                        ],
                      },
                    ],
                schoolFiles: activityData.schoolFiles
                  ? activityData.schoolFiles
                  : [],
                links: activityData.links ? activityData.links : [],
              },
            };
          }
        }

        setTranslationData(dataItem);
      }
    }
  }, [props.location]);

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  const handleTranslationDropdown = async (target) => {
    setTranslationDropdown(target);

    await sleep(200);
    let info = translationData[target];

    setActivityName(info.name);
    setInformation(info.information);
    setQuiz(
      info.quiz
        ? info.quiz
        : [
            {
              question: `Your first question?`,
              options: [{ id: moment().valueOf(), value: "Option 1" }],
            },
          ]
    );

    /// School Activity
    setSchoolFiles(info.schoolFiles ? info.schoolFiles : []);

    // Link
    setLinks(info.links ? info.links : []);
  };

  const finishActivity = async () => {
    try {
      setUnsavedChanges(false);

      /// Update Activity Translation Data
      const { data: activityData } = props.location;
      await ActivityServices.updateActivityTranslations(
        activityData.id,
        translationData
      );

      setError("");
      setActivityLoading(false);
    } catch (err) {
      setError(`Could not update translations.`);
      setActivityLoading(false);
      console.log(err);
    }
  };

  const handleNext = () => {
    // Last Step run functions //
    if (activeStep === steps.length - 1) {
      // Run Loader
      setActivityLoading("update");
      finishActivity();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleUpdateTranslationItem = (field, data) => {
    if (!translationData) {
      return;
    }

    const newData = update(translationData, {
      [translationDropdown]: {
        [field]: {
          $set: data,
        },
      },
    });
    setTranslationData(newData);

    switch (field) {
      case "name": {
        setActivityName(data);
        break;
      }
      case "information": {
        setInformation(data);
        break;
      }
      case "quiz": {
        setQuiz(data);
        break;
      }
      case "schoolFiles": {
        setSchoolFiles(data);
        break;
      }
      case "links": {
        setLinks(data);
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      <Prompt
        when={unsavedChanges}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <Grid container spacing={4}>
        <Grid item md={12} xl={12}>
          <div
            className="d-flex flex-row justify-content-between align-items-center"
            style={{ height: "52px" }}
          >
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              Edit Activity Translation
            </div>
          </div>
        </Grid>
        <Grid item md={12}>
          <Card
            style={{
              borderRadius: "0.25rem",
              border: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <div>
              {activeStep === steps.length ? (
                <div className="text-center p-5">
                  {activityLoading ? (
                    <>
                      <div className="avatar-icon-wrapper rounded-circle m-0">
                        <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper m-0 d-50">
                          <CircleLoader
                            className="d-flex align-self-center"
                            color={"#18a9a9"}
                            loading={true}
                            size={50}
                          />
                        </div>
                      </div>
                      <h4 className="font-weight-bold mt-4">{`${
                        props.location.type === "activity"
                          ? "Updating translations"
                          : ""
                      }`}</h4>
                    </>
                  ) : (
                    <>
                      <div className="avatar-icon-wrapper rounded-circle m-0">
                        <div
                          className={clsx(
                            "d-inline-flex",
                            "justify-content-center",
                            "p-0",
                            "rounded-circle",
                            "btn-icon",
                            "avatar-icon-wrapper",
                            "m-0",
                            "d-130",
                            error ? "bg-neutral-danger" : "bg-neutral-success",
                            error ? "text-danger" : "text-success"
                          )}
                        >
                          <FontAwesomeIcon
                            icon={["far", "lightbulb"]}
                            className="d-flex align-self-center display-3"
                          />
                        </div>
                      </div>
                      <h4 className="font-weight-bold mt-4">
                        {error ? error : "Activity translations updated."}
                      </h4>
                      <div className="pt-4">
                        {error ? (
                          <Button
                            onClick={handleReset}
                            className="btn-primary font-weight-bold rounded hover-scale-lg mx-1"
                            size="large"
                          >
                            <span className="btn-wrapper--label">
                              Back to Form
                            </span>
                          </Button>
                        ) : (
                          <NavLink
                            to={
                              sceneData
                                ? `/activities/${sceneType}/${sceneId}`
                                : `/admin-activities`
                            }
                          >
                            <Button
                              className="btn-primary font-weight-bold rounded hover-scale-lg mx-1"
                              size="large"
                            >
                              <span className="btn-wrapper--label">
                                Back to Activities
                              </span>
                            </Button>
                          </NavLink>
                        )}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div>
                  <div>
                    {getStepContent(activeStep, {
                      mobile,
                      classes,
                      setUnsavedChanges,
                      location: props.location,
                      sceneData,
                      handleNext,
                      activityType,
                      activityName,
                      information,
                      quiz,
                      schoolFiles,
                      links,
                      translationData,
                      translationDropdown,
                      handleTranslationDropdown,
                      handleUpdateTranslationItem,
                    })}
                  </div>
                  <div className="card-footer mt-4 p-4 d-flex align-items-center justify-content-between bg-secondary">
                    <Button
                      variant="text"
                      className={
                        activeStep === 0
                          ? "btn-pill m-2"
                          : "btn-pill m-2 btn-outline-primary"
                      }
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Back
                    </Button>
                    {activeStep === 1 ? null : (
                      <div className="d-flex flex-row align-items-center">
                        <Button
                          className="btn-pill btn-primary font-weight-bold"
                          onClick={() => handleNext()}
                        >
                          Finish
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  premium: premiumSelector(state),
});
const mapDispatchToProps = {
  dispatchCreateActivity: createActivityAction,
  dispatchCreateDistrictActivity: createDistrictActivityAction,
  dispatchUpdateActivity: updateActivityAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTranslation);
