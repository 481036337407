import { Location, LocationRecord } from "./Location";
import { Employee, EmployeeRecord, POSITION } from "./SchoolUser";
import { Invite, InviteRecord, INVITE_STATUS } from "./Invite";
import { Activity, ActivityRecord } from "./Activity";

export interface SchoolRecord {
  id?: number | string;
  name: string;
  location: LocationRecord;
  properties: SchoolProperties;
  activities?: { [activityId: string]: ActivityRecord | boolean };
  team?: {
    [ownerId: string]: EmployeeRecord | POSITION | InviteRecord | INVITE_STATUS;
  };
  schedule: string;
  gradeLevels: string[];
  lastUpdated?: number;
  numberOfGuardians?: number;
  prizePlan?: any;
  prizePlanActive?: boolean;
  prizePlanDescription?: string;
  plan?: string;
  planStudents?: number;
  parentEvaluationQuestions?: any;
  weeklyQuestions?: any;
  hoursOfOperation?: any;
  pocName?: any;
  pocEmail?: any;
  feedbackEmail?: any;
  curriculum?: any;
  billingNotes?: any;
  billingFiles?: any;
  schoolThemes?: any;
  teachers?: any;
}

export class School {
  readonly id?: number | string;
  readonly name: string;
  readonly location: Location;
  private properties?: SchoolProperties;
  readonly activities?: { [activityId: string]: Activity };
  readonly team: { [ownerId: string]: Employee | Invite };
  readonly lastUpdated?: number;
  readonly schedule: string;
  readonly gradeLevels: string[];
  readonly numberOfGuardians?: number;
  readonly prizePlan?: any;
  readonly prizePlanActive?: boolean;
  readonly prizePlanDescription?: string;
  readonly plan?: string;
  readonly planStudents?: number;
  readonly parentEvaluationQuestions?: any;
  readonly weeklyQuestions?: any;
  readonly hoursOfOperation?: any;
  readonly pocName?: any;
  readonly pocEmail?: any;
  readonly feedbackEmail?: any;
  readonly curriculum?: any;
  readonly billingNotes?: any;
  readonly billingFiles?: any;
  readonly schoolThemes?: any;
  readonly teachers?: any;

  constructor(school: SchoolRecord) {
    const {
      id,
      name,
      location,
      properties,
      activities,
      team,
      lastUpdated,
      schedule,
      gradeLevels,
      numberOfGuardians,
      prizePlan,
      prizePlanActive,
      prizePlanDescription,
      plan,
      planStudents,
      parentEvaluationQuestions,
      weeklyQuestions,
      hoursOfOperation,
      pocName,
      pocEmail,
      feedbackEmail,
      curriculum,
      billingNotes,
      billingFiles,
      schoolThemes,
      teachers,
    } = school;

    if (this.isEmptyOrNull(name)) {
      throw new Error("Name cannot be empty");
    }

    this.id = id;
    this.name = name;
    this.location = new Location(location);
    this.properties = properties ? new SchoolProperties(properties) : undefined;
    this.schedule = schedule;
    this.gradeLevels = gradeLevels;
    this.activities = Object.values(
      (activities as { [activityId: string]: ActivityRecord }) || {}
    ).reduce(
      (store, activityRecord) =>
        activityRecord.id
          ? { ...store, [activityRecord.id]: new Activity(activityRecord) }
          : store,
      {}
    );
    this.team = Object.values(
      (team as { [id: string]: EmployeeRecord | InviteRecord }) || {}
    ).reduce(
      (store, record) =>
        record.id
          ? {
              ...store,
              [record.id]: (record as InviteRecord).status
                ? new Invite(record as InviteRecord)
                : new Employee(record as EmployeeRecord),
            }
          : store,
      {}
    );
    this.lastUpdated = lastUpdated;
    this.numberOfGuardians = numberOfGuardians;
    this.prizePlan = prizePlan;
    this.prizePlanActive = prizePlanActive;
    this.prizePlanDescription = prizePlanDescription;
    this.plan = plan;
    this.planStudents = planStudents;
    this.parentEvaluationQuestions = parentEvaluationQuestions;
    this.weeklyQuestions = weeklyQuestions;

    this.hoursOfOperation = hoursOfOperation;
    this.pocName = pocName;
    this.pocEmail = pocEmail;
    this.feedbackEmail = feedbackEmail;
    this.curriculum = curriculum;
    this.billingNotes = billingNotes;
    this.billingFiles = billingFiles;
    this.schoolThemes = schoolThemes;
    this.teachers = teachers;
  }

  get address(): string {
    return this.location.formattedAddress;
  }

  private isEmptyOrNull(str: string): boolean {
    return !str || str.trim().length === 0;
  }
}

interface SchoolPropertiesRecord {
  placeId?: string;
  telephone?: string;
  mapsUrl: string;
  schoolLogo?: string | File | Blob | any;
}

class SchoolProperties {
  readonly placeId?: string;
  readonly telephone?: string;
  readonly mapsUrl: string;
  public schoolLogo: string | File | Blob | any;

  constructor(schoolProperties: SchoolPropertiesRecord) {
    const { placeId, telephone, mapsUrl, schoolLogo } = schoolProperties;

    this.placeId = placeId;
    this.telephone = telephone;
    this.mapsUrl = mapsUrl;
    this.schoolLogo = schoolLogo;
  }
}
