import React from "react";
import AdminAddVideoShort from "../../components/AdminAddVideoShort";

function AdminAddVideoShortPage(props) {
  return (
    <>
      <AdminAddVideoShort {...props} />
    </>
  );
}

export default AdminAddVideoShortPage;
