import { Database, Auth, Functions } from "./Firebase";
import {
  uploadSchoolLogo,
  readSchoolGuardians,
  uploadSchoolBillingFile,
} from "./ParentPartiesAPIs";
import {
  District,
  DistrictRecord,
  School,
  SchoolRecord,
  POSITION,
  SchoolUserRecord,
  INVITE_STATUS,
  EmployeeRecord,
  InviteRecord,
  ActivityRecord,
} from "../../Entities";
import { readActivity } from "./Activity";

const schoolsRef = Database.ref("schools");
const districtsRef = Database.ref("districts");
const schoolUsersRef = Database.ref("schoolUsers");
const invitesRef = Database.ref("invites");

/* Utilites */
async function getSchoolUserRef(schoolUserId: string) {
  const schoolUserRef = schoolUsersRef.child(schoolUserId);
  const schoolUserSnapshot = await new Promise<any>((resolve) =>
    schoolUserRef.once("value", resolve)
  );
  if (!schoolUserSnapshot.exists()) {
    throw new Error(`School User ${schoolUserId} does not exists`);
  }
  return schoolUserRef;
}
async function fetchSchoolUser(schoolUserRef: any) {
  const schoolUserSnapshot = await new Promise<any>((resolve) =>
    schoolUserRef.once("value", resolve)
  );
  const schoolUser: SchoolUserRecord = schoolUserSnapshot.val();
  return schoolUser;
}
async function readSchoolUser(schoolUserId: string) {
  const schoolUserRef = await getSchoolUserRef(schoolUserId);
  return fetchSchoolUser(schoolUserRef);
}
async function getInviteRef(inviteId: string) {
  const inviteRef = invitesRef.child(inviteId);
  const inviteSnapshot = await new Promise<any>((resolve) =>
    inviteRef.once("value", resolve)
  );
  if (!inviteSnapshot.exists()) {
    throw new Error(`Invite ${inviteId} does not exists`);
  }
  return inviteRef;
}
async function fetchInvite(inviteRef: any) {
  const inviteSnapshot = await new Promise<any>((resolve) =>
    inviteRef.once("value", resolve)
  );
  const invite: InviteRecord = inviteSnapshot.val();
  return invite;
}
async function readInvite(inviteId: string) {
  try {
    const inviteRef = await getInviteRef(inviteId);
    return fetchInvite(inviteRef);
  } catch (err) {
    console.log(err);
    throw err;
  }
}
async function fetchTeamMember(memberRef: any) {
  const memberSnapshot = await new Promise<any>((resolve) =>
    memberRef.once("value", resolve)
  );
  const member: EmployeeRecord = memberSnapshot.val();
  delete member.schools;
  return member;
}
async function readTeamMember(memberId: string) {
  const memberRef = await getSchoolUserRef(memberId);
  return fetchTeamMember(memberRef);
}
async function getSchoolRef(schoolId: string | number) {
  const schoolRef = schoolsRef.child(`${schoolId}`);
  const schoolSnapshot = await new Promise<any>((resolve) =>
    schoolRef.once("value", resolve)
  );
  if (!schoolSnapshot.exists()) {
    return null;
  }
  return schoolRef;
}
async function fetchSchool(schoolRef: any) {
  const schoolSnapshot = await new Promise<any>((resolve) =>
    schoolRef.once("value", resolve)
  );
  const school: SchoolRecord = schoolSnapshot.val();
  if (school.activities) {
    const activityIds = Object.keys(school.activities);
    const activities = await Promise.all<ActivityRecord>(
      activityIds.map(readActivity)
    );
    school.activities = activities.reduce(
      (store, activity) =>
        activity.id ? { ...store, [activity.id]: activity } : store,
      {}
    );
  }
  if (school.team) {
    const team = await Promise.all(
      Object.keys(school.team).map(async (id) => {
        const entry = (school.team as {
          [id: string]: POSITION | INVITE_STATUS;
        })[id];

        if (Object.keys(INVITE_STATUS).includes(entry)) {
          const invite = await readInvite(id);
          if (!invite) {
            return;
          }
          invite.status = entry as INVITE_STATUS;
          return invite;
        } else {
          let teamMember = await readTeamMember(id);
          teamMember.position = entry as POSITION;
          return teamMember;
        }
      })
    );
    school.team = team.reduce(
      (store, teamMember) =>
        (teamMember as any).id
          ? { ...store, [(teamMember as any).id]: teamMember }
          : store,
      {}
    );
  }
  return school;
}

/* Create School */
export const createSchool = async (
  school: SchoolRecord
): Promise<{ school: School; district?: District | null }> => {
  const schoolUserId = await (Auth.currentUser as any).uid;
  const schoolUser = await readSchoolUser(schoolUserId);
  const adminUser = schoolUser.adminSupport;

  const schoolUserRef = await getSchoolUserRef(schoolUserId);

  /* Removed undefined */
  const removeEmpty = (obj: SchoolRecord) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === "object") removeEmpty(obj[key]);
      // recurse
      else if (obj[key] == null) delete obj[key]; // delete
    });
  };
  removeEmpty(school);

  if (!adminUser) {
    school.team = {
      [schoolUserId]: POSITION.Owner,
    };
  }
  const schoolsRef = Database.ref("schools");
  const schoolRef = await schoolsRef.push(school);
  await new Promise<any>((resolve) => schoolRef.once("value", resolve));
  school.id = schoolRef.key as string;

  if (school.properties) {
    if (school.properties.schoolLogo) {
      if (
        school.properties.schoolLogo instanceof File ||
        school.properties.schoolLogo instanceof Blob ||
        school.properties.schoolLogo._data
      ) {
        let schoolLogo = school.properties.schoolLogo;
        const schoolLogoUrl = await uploadSchoolLogo(
          school.id as string,
          schoolLogo
        );
        school.properties.schoolLogo = schoolLogoUrl;
      }
    }
  }

  /* Update non admins user record */
  if (!adminUser) {
    await Promise.all([
      schoolUserRef.child(`schools/${school.id}`).set(true),
      schoolUserRef.child(`emailSettings/${school.id}`).set({
        id: `${school.id}`,
        analytics: "monthly",
      }),
    ]);
  }

  /* Add id to db record */
  await schoolRef.set(school);

  let district: any;
  if (!adminUser) {
    /* All Schools District */
    const districtsExist = schoolUser.districts
      ? Object.keys(schoolUser.districts).length
      : false;
    let district: any;
    if (districtsExist) {
      // /* Map through owners districts and find default all biz distrct */
      const districtIds = schoolUser.districts
        ? Object.keys(schoolUser.districts)
        : [];
      const districtItems = await Promise.all(
        districtIds.map(async (districtId) => {
          return await Database.ref(`districts/${districtId}`)
            .once("value")
            .then(function (snapshot) {
              const snapshotVal = snapshot.val();
              return snapshotVal;
            });
        })
      );

      const allSchools = districtItems.find(
        (district) => district.defaultDistrict
      );

      // /* Add school to all schools district */
      const allSchoolsDistrictRef = Database.ref(`districts/${allSchools.id}`);

      district = {
        ...allSchools,
        schoolIds: {
          ...allSchools.schoolIds,
          [school.id]: true,
        },
      };

      /* Update all school district */
      await allSchoolsDistrictRef.set(district);
    } else {
      /* No All school district make one */
      district = {
        team: {
          [schoolUserId]: POSITION.Owner,
        },
        name: "All Schools",
        schoolIds: {
          [school.id]: true,
        },
        defaultDistrict: true,
      } as DistrictRecord;
      const districtRef = await districtsRef.push(district);
      await new Promise<any>((resolve) => districtRef.once("value", resolve));
      district.id = districtRef.key as string;

      /* Add id to db record */
      await districtRef.set(district);

      /* Update user record */
      await Promise.all([
        schoolUserRef.child(`districts/${district.id}`).set(true),
      ]);
    }
  }

  /* Return completed school */
  return {
    school: new School(school),
    district: district ? new District(district) : null,
  };
};

/* Read School */
export const readSchool = async (schoolId: number | string): Promise<any> => {
  function cleanOwner(schoolUser: SchoolUserRecord) {
    delete schoolUser.schools;
  }

  function cleanSchool(school: SchoolRecord) {
    Object.values(
      (school.team as { [memberId: string]: SchoolUserRecord }) || {}
    ).forEach(cleanOwner);
  }
  const schoolRef = await getSchoolRef(schoolId);
  if (schoolRef) {
    let school = await fetchSchool(schoolRef);
    cleanSchool(school);

    return new School(school);
  }
  return null;
};

export const updateSchool = async (school: SchoolRecord): Promise<any> => {
  if (!school.id) {
    throw new Error("Invalid school ID");
  }

  if (school.properties) {
    if (school.properties.schoolLogo) {
      if (
        school.properties.schoolLogo instanceof File ||
        school.properties.schoolLogo instanceof Blob ||
        school.properties.schoolLogo._data
      ) {
        let schoolLogo = school.properties.schoolLogo;
        const schoolLogoUrl = await uploadSchoolLogo(
          school.id as string,
          schoolLogo
        );
        school.properties.schoolLogo = schoolLogoUrl;
      }
    }
  }

  if (school.billingFiles) {
    if (school.billingFiles.length) {
      school.billingFiles = await Promise.all(
        school.billingFiles.map(async (billingFile: any) => {
          if (billingFile.file) {
            if (
              billingFile.file instanceof File ||
              billingFile.file instanceof Blob ||
              billingFile.file._data
            ) {
              const fileUrl = await uploadSchoolBillingFile(
                school.id as string,
                billingFile.file
              );
              return {
                id: `${new Date().valueOf()}`,
                name: billingFile.name,
                fileType: billingFile.fileType,
                preview: fileUrl,
              };
            }
            return billingFile;
          }
          return billingFile;
        })
      );
    }
  }

  /* Remove these remove venue so they don't get updated */
  function cleanSchoolUser(schoolUser: SchoolUserRecord) {
    delete schoolUser.schools;
  }

  function cleanSchool(school: SchoolRecord) {
    Object.values(
      (school.team as { [memberId: string]: SchoolUserRecord }) || {}
    ).forEach(cleanSchoolUser);
  }

  cleanSchool(school);
  delete school.team;
  delete school.activities;
  delete school.numberOfGuardians;

  const schoolRef = await getSchoolRef(school.id);
  if (schoolRef) {
    /* Removed undefined */
    const removeEmpty = (obj: SchoolRecord) => {
      Object.keys(obj).forEach((key) => {
        if (obj[key] && typeof obj[key] === "object") removeEmpty(obj[key]);
        // recurse
        else if (obj[key] == null) delete obj[key]; // delete
      });
    };
    removeEmpty(school);

    await schoolRef.update(school);
    /* End update */

    return new School(school);
  }
  return null;
};

export const fetchSchoolAnalytics = async (
  schoolId: string,
  timeFrame: string,
  dealIds: string[]
): Promise<any> => {
  return {};
};

export const removeFromSchool = async (
  employeeId: string,
  schoolId: string | number
): Promise<void> => {
  const removeFromSchool = Functions.httpsCallable("removeFromSchool");
  await removeFromSchool({ employeeId, schoolId });
};

export const readCurriculums = async (): Promise<any> => {
  const curriculumsRef = Database.ref("curriculums");
  const curriculumsSnapshot = await new Promise<any>((resolve) =>
    curriculumsRef.once("value", resolve)
  );
  const curriculums = curriculumsSnapshot.val() || {};
  return Object.values(curriculums);
};

export const readCurriculum = async (curriculumId: any): Promise<any> => {
  const curriculumRef = Database.ref(`curriculums/${curriculumId}`);
  const curriculumSnapshot = await new Promise<any>((resolve) =>
    curriculumRef.once("value", resolve)
  );
  const curriculum = curriculumSnapshot.val() || null;
  return curriculum;
};

export const updateSchoolCurriculum = async (
  schoolId: any,
  curriculumId: any
): Promise<any> => {
  const schoolRef = await getSchoolRef(schoolId);
  if (schoolRef) {
    await schoolRef.update({ curriculum: curriculumId });
  }
};
