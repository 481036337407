import React from "react";
import { PageTitle } from "../../layout-components";
import EditTeam from "../../components/EditTeam";

export default function EditTeamPage(props) {
  return (
    <>
      <PageTitle
        titleHeading={"Add Team Member"}
        titleDescription={"Add a new team member to your buisness"}
        icon={"plus"}
      />
      <EditTeam {...props} />
    </>
  );
}
