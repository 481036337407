import React from "react";
import AdminSubmittedVideoShorts from "../../components/AdminSubmittedVideoShorts";

function AdminSubmittedVideoShortsPage() {
  return (
    <>
      <AdminSubmittedVideoShorts />
    </>
  );
}

export default AdminSubmittedVideoShortsPage;
