import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { CircleLoader } from "react-spinners";
import {
  Grid,
  Button,
  TextField,
  // FormControl,
  // FormControlLabel,
  // FormGroup,
  // Checkbox,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  appSelector,
  userSelector,
  sceneSelector,
  DistrictServices,
  ParentPartiesAPIs,
  createDistrictAction,
} from "../../core";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import SupportGrid from "./components/SupportGrid";

function AddDistrict(props) {
  const [districtName, setDistrictName] = useState("");
  const [supportSchools, setSupportSchools] = useState([]);

  useEffect(() => {
    if (props.user) {
      if (props.user.adminSupport) {
        const handleRowData = async () => {
          try {
            let schools = await ParentPartiesAPIs.readAllSchools();
            setSupportSchools(schools);
          } catch (err) {}
        };
        handleRowData();
      }
    }
  }, [props.user]);

  const handleSetDistrict = (e) => {
    setDistrictName(e.target.value);
  };

  const [errors, setErrors] = useState({
    districtName: "",
    schoolsSelected: "",
  });
  const [loading, setLoading] = useState(false);
  const [successShow, setSuccessShow] = useState(false);

  // const userSchools = props.user
  //   ? props.user.schools
  //     ? Object.values(props.user.schools)
  //     : []
  //   : [];
  const [selectedSchools, setSelectedSchools] = useState({});

  // const handleSchoolSelect = (e) => {
  //   setSelectedSchools({
  //     ...selectedSchools,
  //     [e.target.id]: e.target.checked,
  //   });
  // };
  const handleSupportSchoolSelect = (e) => {
    setSelectedSchools({
      ...selectedSchools,
      [e.data.id]: e.node.selected,
    });
  };
  // const selectAllSchools = () => {
  //   setSelectedSchools(
  //     userSchools.reduce((obj, item) => ((obj[item.id] = true), obj), {})
  //   );
  // };

  const createDistrict = async () => {
    let checkErrors = {};
    setErrors({
      districtName: "",
      schoolsSelected: "",
    });
    const selectedSchoolItems = { ...selectedSchools };
    Object.keys(selectedSchoolItems).forEach(function (key) {
      if (selectedSchoolItems[key] === false) {
        delete selectedSchoolItems[key];
      }
    });

    if (!districtName) {
      checkErrors.districtName = "District name required";
    }
    if (Object.keys(selectedSchoolItems).length === 0) {
      checkErrors.schoolsSelected = "Please select at least one school";
    }
    if (checkErrors.districtName || checkErrors.schoolsSelected) {
      return setErrors(checkErrors);
    }

    const district = {
      name: districtName,
      schoolIds: selectedSchoolItems,
      defaultDistrict: false,
    };

    setLoading(true);
    await DistrictServices.createDistrict(
      // props.user.id,
      district
    );

    setLoading(false);
    setSuccessShow(true);
  };

  return (
    <Grid container spacing={6}>
      <Grid item md={12} xl={12}>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div
            className="font-weight-bold display-4"
            style={{ color: "rgb(43, 39, 60)" }}
          >
            Add District
          </div>
        </div>
      </Grid>
      {successShow ? (
        <Grid
          item
          md={12}
          style={{
            borderTop: "1px solid rgba(0,0,0,.1)",
            borderBottom: "1px solid rgba(0,0,0,.1)",
            paddingTop: "2.5rem",
            paddingBottom: "2.5rem",
          }}
        >
          <div className={"text-center p-5"}>
            <>
              <div className="avatar-icon-wrapper rounded-circle m-0">
                <div
                  className={clsx(
                    "d-inline-flex",
                    "justify-content-center",
                    "p-0",
                    "rounded-circle",
                    "btn-icon",
                    "avatar-icon-wrapper",
                    "m-0",
                    "d-130",
                    "bg-neutral-success",
                    "text-success"
                  )}
                >
                  <FontAwesomeIcon
                    icon={["far", "lightbulb"]}
                    className="d-flex align-self-center display-3"
                  />
                </div>
              </div>
              <h4 className="font-weight-bold mt-4">
                {`Your district has been created!`}
              </h4>
              <div className="mt-4">
                <Button
                  className="btn-neutral-dark font-weight-bold rounded hover-scale-lg mx-1"
                  size="large"
                  component={NavLink}
                  to={`/admin-districts`}
                >
                  <span className="btn-wrapper--label">Back to Dashboard</span>
                </Button>
              </div>
            </>
          </div>
        </Grid>
      ) : (
        <>
          <Grid
            item
            md={12}
            style={{
              borderTop: "1px solid rgba(0,0,0,.1)",
              borderBottom: "1px solid rgba(0,0,0,.1)",
              paddingTop: "2.5rem",
              paddingBottom: "2.5rem",
            }}
          >
            <Grid container spacing={5}>
              <Grid item md={12}>
                <div className="d-flex flex-column">
                  <label className="font-weight-bold mb-3 font-size-md">
                    District Name
                  </label>
                  <TextField
                    variant="outlined"
                    helperText={errors.districtName}
                    error={Boolean(errors.districtName)}
                    size="small"
                    fullWidth
                    value={districtName}
                    onChange={handleSetDistrict}
                    style={{ width: "450px" }}
                  />
                </div>
              </Grid>
              {props.user ? (
                props.user.adminSupport ? (
                  <Grid item md={12}>
                    <div className="d-flex flex-column pb-4">
                      <div className="d-flex flex-column mb-4">
                        <div className="d-flex flex-row align-items-center">
                          <label className="font-weight-bold font-size-md">
                            Schools (
                            {selectedSchools
                              ? Object.values(selectedSchools).length
                                ? Object.values(selectedSchools).filter(
                                    (item) => item === true
                                  ).length
                                : 0
                              : 0}{" "}
                            selected)
                          </label>
                        </div>
                        {errors.schoolsSelected ? (
                          <div className="d-flex align-items-center text-danger mt-2">
                            <span className="font-size-xs">
                              {errors.schoolsSelected}
                            </span>
                          </div>
                        ) : null}
                      </div>
                      <SupportGrid
                        rowData={supportSchools}
                        onSelectSchool={handleSupportSchoolSelect}
                      />
                    </div>
                  </Grid>
                ) : // <Grid item md={12}>
                //   <div className="d-flex flex-column">
                //     <div className="d-flex flex-column mb-4">
                //       <div className="d-flex flex-row align-items-center">
                //         <label className="font-weight-bold font-size-md">
                //           Schools
                //         </label>
                //         <Button
                //           variant="text"
                //           className="btn-pill ml-3 font-size-sm btn-outline-primary"
                //           onClick={selectAllSchools}
                //           size="small"
                //           style={{ padding: "0px 16px" }}
                //         >
                //           Select all
                //         </Button>
                //       </div>
                //       {errors.venuesSelected ? (
                //         <div className="d-flex align-items-center text-danger mt-2">
                //           <span className="font-size-xs">
                //             {errors.venuesSelected}
                //           </span>
                //         </div>
                //       ) : null}
                //     </div>
                //     <FormControl component="fieldset">
                //       <FormGroup
                //         style={{
                //           display: "grid",
                //           gridTemplateColumns:
                //             "repeat(auto-fill,minmax(280px, 1fr))",
                //         }}
                //       >
                //         {userSchools.map((venue) => (
                //           <FormControlLabel
                //             control={
                //               <Checkbox
                //                 checked={selectedSchools[venue.id] === true}
                //                 onChange={handleSchoolSelect}
                //                 id={venue.id}
                //                 style={{ padding: "0px 9px 9px 0px" }}
                //                 color={"primary"}
                //               />
                //             }
                //             label={
                //               <div className="d-flex flex-column">
                //                 <div className="text-black">{venue.name}</div>
                //                 <small className="text-black-50">{`${
                //                   venue.location.address
                //                 } ${
                //                   venue.location.state
                //                     ? `, ${venue.location.state}`
                //                     : ``
                //                 }`}</small>
                //               </div>
                //             }
                //             className="mb-2"
                //             style={{
                //               marginLeft: 0,
                //               marginRight: 0,
                //               alignItems: "flex-start",
                //             }}
                //           />
                //         ))}
                //       </FormGroup>
                //     </FormControl>
                //   </div>
                // </Grid>
                null
              ) : null}
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Button
              className="btn-success font-weight-bold"
              onClick={createDistrict}
              disabled={loading}
            >
              <span className="btn-wrapper--label">Add District</span>
              {loading ? (
                <span className="btn-wrapper--icon">
                  <CircleLoader size={15} color="#fff" className="ml-2" />
                </span>
              ) : null}
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
  scene: sceneSelector(state),
});
const mapDispatchToProps = {
  createDistrictAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDistrict);
