import SchoolSubmitVideoShort from "../../components/SchoolSubmitVideoShort";

function SchoolSubmitVideoShortPage() {
  return (
    <>
      <SchoolSubmitVideoShort />
    </>
  );
}

export default SchoolSubmitVideoShortPage;
