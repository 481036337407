import AdminArchivedActivities from "../../components/AdminArchivedActivities";

function AdminArchivedActivitiesPage() {
  return (
    <>
      <AdminArchivedActivities />
    </>
  );
}

export default AdminArchivedActivitiesPage;
