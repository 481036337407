import { SchoolUser, School, District } from "../../Entities";
import { initialState, StateType } from "./State";

const UPDATE_USER = "user/update";
const UPDATE_USER_SCHOOL = "user/school/update";
const UPDATE_USER_DISTRICT = "user/district/update";
const UPDATE_USER_EMAIL_SETTINGS = "user/school/email_settings";
const DELETE_USER_SCHOOL = "user/school/delete";
const DELETE_USER_DISTRICT = "user/district/delete";

type StateSlice = StateType["user"];

export interface UpdateUserActionType {
  type: string;
  user?: SchoolUser;
  school?: School;
  district?: District;
}

export const userSelector = (state: StateType): StateSlice => state.user;
export const updateUserStateAction = (
  user?: SchoolUser
): UpdateUserActionType => ({
  type: UPDATE_USER,
  user,
});
export const updateUserSchoolStateAction = (
  school: School
): UpdateUserActionType => ({
  type: UPDATE_USER_SCHOOL,
  school,
});
export const updateUserDistrictStateAction = (
  district: District
): UpdateUserActionType => ({
  type: UPDATE_USER_DISTRICT,
  district,
});
export const updateUserEmailStateAction = (
  school: School
): UpdateUserActionType => ({
  type: UPDATE_USER_EMAIL_SETTINGS,
  school,
});
export const deleteUserSchoolStateAction = (
  school: School
): UpdateUserActionType => ({
  type: DELETE_USER_SCHOOL,
  school,
});
export const deleteUserDistrictStateAction = (
  district: District
): UpdateUserActionType => ({
  type: DELETE_USER_DISTRICT,
  district,
});

const updateHandler = (
  state: StateSlice,
  action: UpdateUserActionType
): StateType["user"] => {
  return action.user || null;
};

export const userReducer = (
  user: StateSlice = initialState.user,
  action: UpdateUserActionType
): StateSlice => {
  switch (action.type) {
    case UPDATE_USER: {
      return updateHandler(user, action);
    }
    case UPDATE_USER_SCHOOL: {
      const { school } = action;
      if (!school || !school.id || !user) {
        return user;
      }
      user.schools[school.id] = school;
      return updateHandler(user, {
        ...action,
        user: user || undefined,
      });
    }
    case UPDATE_USER_DISTRICT: {
      const { district } = action;
      if (!district || !district.id || !user) {
        return user;
      }
      if (!user.districts) {
        return user;
      }
      user.districts[district.id] = district;
      return updateHandler(user, {
        ...action,
        user: user || undefined,
      });
    }
    case UPDATE_USER_EMAIL_SETTINGS: {
      const { school } = action;
      if (!school || !school.id || !user) {
        return user;
      }
      if (user.emailSettings) {
        user.emailSettings[school.id] = {
          id: `${school.id}`,
          analytics: "monthly",
        };
      }
      return updateHandler(user, {
        ...action,
        user: user || undefined,
      });
    }
    case DELETE_USER_SCHOOL: {
      const { school } = action;
      if (!school || !school.id || !user || !user.emailSettings) {
        return user;
      }
      delete user.schools[school.id];
      delete user.emailSettings[school.id];
      return updateHandler(user, {
        ...action,
        user: user || undefined,
      });
    }
    case DELETE_USER_DISTRICT: {
      const { district } = action;
      if (!district || !district.id || !user) {
        return user;
      }
      if (!user.districts) {
        return user;
      }
      delete user.districts[district.id];
      return updateHandler(user, {
        ...action,
        user: user || undefined,
      });
    }
    default: {
      return user;
    }
  }
};
