import { Database } from "./Firebase";

export const fetchPremium = async (
  id: string
): Promise<{
  productIds: string[];
  status: string;
  current_period_end: number;
  subscriptionId: string;
} | null> => {
  try {
    let premium = await Database.ref(`premiums/${id}`)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val() as any;
        return snapshotVal;
      });
    if (!premium) {
      return null;
    }
    return {
      productIds: premium.productIds,
      status: premium.status,
      current_period_end: premium.current_period_end,
      subscriptionId: premium.subscriptionId,
    };
  } catch (err) {
    return null;
  }
};
