import { useState, memo, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AssignmentTurnedIn,
  AssignmentTurnedInOutlined,
  Check,
} from "@material-ui/icons";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import update from "immutability-helper";
import {
  Button,
  Grid,
  FormControl,
  TextField,
  FormHelperText,
  InputLabel,
  Card,
  RadioGroup,
  Radio,
  FormControlLabel,
  Divider,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  Typography,
  Table,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";
import ReactQuill from "react-quill";

const styles = (theme) => ({
  rootNormal: {
    margin: 0,
    padding: theme.spacing(2),
  },
  root: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButtonNormal: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButton: {
    position: "static",
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    width: "100%",
  },
  optionTextFieldFocused: {},
  optionTextFieldUnderline: {
    "&:after": {
      borderBottom: `1px solid transparent !important`,
    },
    "&:before": {
      borderBottom: `1px solid transparent`,
    },
    "&:hover:not($disabled):not($focused):before": {
      borderBottom: `1px solid #d3d3d3 !important`,
    },
  },

  // Switch
  disabledSwitch: {
    backgroundColor: "#c5c4c4 !important",
    border: "none !important",
  },
  disabledSwitchOn: {
    backgroundColor: "#508d5e !important",
    border: "#508d5e !important",
  },
}));

const QuizCreator = (props) => {
  const {
    quiz,
    handleUpdateTranslationItem,
    preview,
    multiple,
    setUnsavedChanges,
    inputQuiz,
  } = props;

  const [showAnswerKey, setShowAnswerKey] = useState(null);

  const [feedbackDialog, setFeedbackDialog] = useState(false);
  const [feedbackDialogInfo, setFeedbackDialogInfo] = useState(null);
  const handleFeedbackClose = () => {
    setFeedbackDialog(false);
  };

  const classes = useStyles();

  const handleCorrectAnswer = (e, index) => {
    const correctOption = quiz[index].options.find(
      (option) => option.value === e.target.value
    );

    handleUpdateTranslationItem(
      "quiz",
      update(quiz, {
        [index]: {
          $set: {
            ...quiz[index],
            correctAnswer: correctOption.id,
          },
        },
      })
    );
  };

  return preview &&
    quiz &&
    quiz.filter(
      (quizQuestion) =>
        !quizQuestion.inputQuestion && !quizQuestion.correctAnswer
    ).length ? (
    <div>N/A</div>
  ) : (
    <>
      {quiz.map((quizQuestion, index) => (
        <Card className="card card-box py-4 mb-4">
          {showAnswerKey === index ? (
            <>
              <div className="d-flex flex-row align-items-center px-4">
                <AssignmentTurnedInOutlined
                  style={{ width: 25, height: 25, color: "gray" }}
                />
                <div
                  style={{
                    fontSize: "18px",
                    lineHeight: "24px",
                    fontWeight: 400,
                    color: "#000",
                  }}
                  className="ml-3"
                >
                  Choose correct answer:
                </div>
              </div>
              <Divider className="my-4" />
              <FormControl component="fieldset" className="px-4">
                <RadioGroup
                  value={quizQuestion.correctAnswer}
                  onChange={(e) => {
                    handleCorrectAnswer(e, index);
                  }}
                >
                  {quizQuestion.options.map((option, optionIndex) => (
                    <FormControlLabel
                      key={optionIndex}
                      fullWidth={true}
                      value={option.value}
                      control={<Radio color="primary" />}
                      style={
                        option.id === quizQuestion.correctAnswer
                          ? {
                              backgroundColor: "rgba(24, 169, 169, 0.1)",
                            }
                          : {}
                      }
                      label={
                        <div className="d-flex flex-row align-items-center justify-content-between">
                          <div>{option.value}</div>
                          {option.id === quizQuestion.correctAnswer ? (
                            <div>
                              <Check
                                style={{ width: 20, height: 20 }}
                                className="mr-2"
                                color="primary"
                              />
                            </div>
                          ) : null}
                        </div>
                      }
                      checked={option.id === quizQuestion.correctAnswer}
                      classes={{
                        label: classes.formControlLabel,
                      }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <div className="d-flex flex-row align-items-center mt-4">
                {quizQuestion.incorrectFeedback ? (
                  <div
                    className="px-4 py-4"
                    style={{ backgroundColor: "#f8f9fa", width: "100%" }}
                  >
                    <div className="d-flex flex-row align-items-center justify-content-between mb-3">
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#202124",
                        }}
                      >
                        Feedback for incorrect answers
                      </div>
                      <div className="d-flex flex-row align-items-center">
                        <IconButton
                          onClick={() => {
                            setFeedbackDialog(true);
                            setFeedbackDialogInfo({
                              ...quizQuestion,
                              index,
                            });
                          }}
                        >
                          <FontAwesomeIcon
                            icon={["fas", "pen"]}
                            className="font-size-sm"
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            handleUpdateTranslationItem(
                              "quiz",
                              update(quiz, {
                                [index]: {
                                  $set: {
                                    ...quiz[index],
                                    incorrectFeedback: "",
                                  },
                                },
                              })
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            icon={["fas", "trash"]}
                            className="font-size-sm"
                          />
                        </IconButton>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "#202124",
                        paddingBottom: "10px",
                      }}
                    >
                      {quizQuestion.incorrectFeedback}
                    </div>
                  </div>
                ) : (
                  <div className="px-4">
                    <Button
                      variant="outlined"
                      startIcon={<AssignmentOutlinedIcon />}
                      color="secondary"
                      size="small"
                      onClick={() => {
                        setFeedbackDialog(true);
                        setFeedbackDialogInfo({
                          ...quizQuestion,
                          index,
                        });
                      }}
                    >
                      Add answer feedback
                    </Button>
                  </div>
                )}
              </div>
              <Divider className="my-4" />
              <div className="d-flex flex-row align-items-center px-4 justify-content-end">
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    setShowAnswerKey(null);
                  }}
                >
                  Done
                </Button>
              </div>
            </>
          ) : (
            <>
              <TextField
                className="px-4"
                variant={preview ? "standard" : "filled"}
                fullWidth
                hiddenLabel={true}
                value={quizQuestion.question}
                // error={formErrors.name}
                placeholder="Question"
                type="text"
                onChange={(e) => {
                  setUnsavedChanges(true);
                  handleUpdateTranslationItem(
                    "quiz",
                    update(quiz, {
                      [index]: {
                        $set: {
                          ...quiz[index],
                          question: e.target.value,
                        },
                      },
                    })
                  );
                }}
                disabled={preview}
                InputProps={{ disableUnderline: preview ? true : false }}
              />
              <Divider className="my-4" />
              {preview && !inputQuiz ? (
                <FormControl component="fieldset" className="px-4">
                  <RadioGroup value={quizQuestion.correctAnswer}>
                    {quizQuestion.options.map((option, optionIndex) => (
                      <FormControlLabel
                        key={optionIndex}
                        fullWidth={true}
                        value={option.value}
                        control={<Radio color="primary" />}
                        style={
                          option.id === quizQuestion.correctAnswer
                            ? {
                                backgroundColor: "rgba(24, 169, 169, 0.1)",
                              }
                            : {}
                        }
                        label={
                          <div className="d-flex flex-row align-items-center justify-content-between">
                            <div>{option.value}</div>
                            {option.id === quizQuestion.correctAnswer ? (
                              <div>
                                <Check
                                  style={{ width: 20, height: 20 }}
                                  className="mr-2"
                                  color="primary"
                                />
                              </div>
                            ) : null}
                          </div>
                        }
                        checked={option.id === quizQuestion.correctAnswer}
                        disabled
                        classes={{
                          label: classes.formControlLabel,
                        }}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              ) : !inputQuiz ? (
                <FormControl component="fieldset" className="px-4">
                  <RadioGroup value={""}>
                    {quizQuestion.options.map((option, optionIndex) => (
                      <FormControlLabel
                        fullWidth={true}
                        value={option.value}
                        disabled
                        control={<Radio />}
                        key={optionIndex}
                        label={
                          <div className="d-flex flex-row align-items-center justify-content-between">
                            <TextField
                              variant="standard"
                              fullWidth
                              hiddenLabel={true}
                              value={option.value}
                              type="text"
                              onChange={(e) => {
                                setUnsavedChanges(true);
                                handleUpdateTranslationItem(
                                  "quiz",
                                  update(quiz, {
                                    [index]: {
                                      options: {
                                        [optionIndex]: {
                                          $set: {
                                            ...quiz[index].options[optionIndex],
                                            value: e.target.value,
                                          },
                                        },
                                      },
                                    },
                                  })
                                );
                              }}
                              InputProps={{
                                classes: {
                                  underline: classes.optionTextFieldUnderline,
                                },
                              }}
                            />
                            {preview ? null : (
                              <IconButton
                                size="small"
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={() => {
                                  handleUpdateTranslationItem(
                                    "quiz",
                                    update(quiz, {
                                      [index]: {
                                        options: {
                                          $splice: [[optionIndex, 1]],
                                        },
                                      },
                                    })
                                  );
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            )}
                          </div>
                        }
                        checked={false}
                        classes={{
                          label: classes.formControlLabel,
                        }}
                      />
                    ))}
                    <FormControlLabel
                      value="disabled"
                      disabled
                      control={<Radio />}
                      label="Add option"
                      onClick={() => {
                        handleUpdateTranslationItem(
                          "quiz",
                          update(quiz, {
                            [index]: {
                              options: {
                                $push: [
                                  {
                                    id: `${moment().valueOf()}${
                                      quiz[index].options.length + 1
                                    }`,
                                    value: `Option ${
                                      quiz[index].options.length + 1
                                    }`,
                                  },
                                ],
                              },
                            },
                          })
                        );
                      }}
                      style={{ cursor: "pointer" }}
                      checked={false}
                    />
                  </RadioGroup>
                </FormControl>
              ) : (
                <div className="text-black-50 px-4">
                  Guardians will input an answer to this question.
                </div>
              )}
              {preview || inputQuiz ? null : (
                <>
                  <Divider className="my-4" />
                  <div className="d-flex flex-row align-items-center px-4 justify-content-between">
                    <Button
                      variant="outlined"
                      startIcon={<AssignmentTurnedIn />}
                      color="secondary"
                      size="small"
                      onClick={() => {
                        setShowAnswerKey(index);
                      }}
                    >
                      Answer Key
                    </Button>
                    {multiple ? (
                      <div className="d-flex flex-row align-items-center">
                        <div>
                          <Tooltip title={"Duplicate"}>
                            <Button
                              onClick={() => {
                                handleUpdateTranslationItem(
                                  "quiz",
                                  update(quiz, {
                                    $push: [quiz[index]],
                                  })
                                );
                              }}
                              className="btn-icon btn-pill d-50 p-0 mx-2"
                            >
                              <span className="btn-wrapper--icon">
                                <FontAwesomeIcon
                                  icon={["fas", "copy"]}
                                  className="font-size-sm"
                                  style={{ color: "gray" }}
                                />
                              </span>
                            </Button>
                          </Tooltip>
                        </div>
                        <div>
                          <Tooltip title={"Delete"}>
                            <Button
                              onClick={() => {
                                handleUpdateTranslationItem(
                                  "quiz",
                                  update(quiz, {
                                    $splice: [[index, 1]],
                                  })
                                );
                              }}
                              className="btn-icon btn-pill d-50 p-0 mx-2"
                            >
                              <span className="btn-wrapper--icon">
                                <FontAwesomeIcon
                                  icon={["fas", "trash"]}
                                  className="font-size-sm"
                                  style={{ color: "gray" }}
                                />
                              </span>
                            </Button>
                          </Tooltip>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </>
              )}
            </>
          )}
        </Card>
      ))}
      <Dialog
        open={feedbackDialog}
        onClose={handleFeedbackClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            color: "rgb(42, 42, 47)",
            borderBottom: "none",
          }}
          className="p-4"
        >
          {`Add incorrect answer feedback`}
        </DialogTitle>
        <TextField
          className="px-4 my-4"
          variant={"filled"}
          fullWidth
          hiddenLabel={true}
          value={feedbackDialogInfo ? feedbackDialogInfo.incorrectFeedback : ""}
          placeholder="Enter feedback"
          type="text"
          onChange={(e) => {
            setFeedbackDialogInfo({
              ...feedbackDialogInfo,
              incorrectFeedback: e.target.value,
            });
          }}
        />
        <Divider className="mt-4" />
        <DialogActions style={{ borderTop: "none" }} className="mt-2">
          <Button onClick={handleFeedbackClose}>Cancel</Button>
          <Button
            className="btn-primary"
            onClick={() => {
              if (feedbackDialogInfo) {
                setUnsavedChanges(true);
                handleUpdateTranslationItem(
                  "quiz",
                  update(quiz, {
                    [feedbackDialogInfo.index]: {
                      $set: {
                        ...quiz[feedbackDialogInfo.index],
                        incorrectFeedback:
                          feedbackDialogInfo.incorrectFeedback || "",
                      },
                    },
                  })
                );
                handleFeedbackClose();
              }
            }}
            autoFocus
          >
            {"Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ReactQuillDesc = memo(
  ({ val, target, handleChange, field }) => {
    const [value, setValue] = useState(val);

    useEffect(() => {
      setValue(val);
    }, [target, val]);

    return (
      <ReactQuill
        theme="snow"
        value={value}
        onChange={(e) => {
          setValue(e);
          handleChange(field, e);
        }}
        placeholder="Example placeholder..."
        modules={{
          toolbar: [
            ["bold", "italic", "underline"], // toggled buttons
            ["link"], // link button
          ],
        }}
      />
    );
  },
  (prevProps, nextProps) => {
    if (prevProps.target && nextProps.target) {
      return false;
    }
    return true;
  }
);

const ActivityDetails = (props) => {
  const {
    activityType,
    activityName,
    information,
    quiz,
    setUnsavedChanges,
    // School Activity
    schoolFiles,
    // Link
    links,
    handleUpdateTranslationItem,
    target,
  } = props;

  const [dialogFileName, setDialogFileName] = useState(false);
  const [dialogInfoFileName, setDialogInfoFileName] = useState(null);
  const [fileNameDialog, setFileNameDialog] = useState("");
  const toggleDialogFileName = (file) => {
    if (file) {
      setDialogInfoFileName(file);
      setFileNameDialog(file.name);
    }
    setDialogFileName(!dialogFileName);
  };
  const handleFileName = async (fileInDialog) => {
    const updatedFile = {
      ...fileInDialog,
      name: fileNameDialog,
    };

    handleUpdateTranslationItem(
      "schoolFiles",
      update(schoolFiles, {
        $push: [updatedFile],
      })
    );

    setUnsavedChanges(true);
  };

  // Link
  const [dialogLink, setDialogLink] = useState(false);
  const [linkTextDialog, setLinkTextDialog] = useState("");
  const [linkURLDialog, setLinkURLDialog] = useState("");
  const toggleDialogLink = () => {
    setDialogLink(!dialogLink);
    setLinkTextDialog("");
    setLinkURLDialog("");
  };
  const handleLink = async () => {
    const link = {
      text: linkTextDialog,
      url: linkURLDialog,
    };

    handleUpdateTranslationItem(
      "links",
      update(links, {
        $push: [link],
      })
    );

    setUnsavedChanges(true);
  };

  switch (activityType) {
    case 0:
      return (
        <div className="px-4">
          <Grid container spacing={0} className="mt-4 pt-4">
            <Grid md={7}>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Activity name"}
                  </InputLabel>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={activityName}
                      type="text"
                      onChange={(e) => {
                        setUnsavedChanges(true);

                        handleUpdateTranslationItem("name", e.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Description"}
                  </InputLabel>
                  <ReactQuillDesc
                    val={information}
                    handleChange={handleUpdateTranslationItem}
                    field="information"
                    target={target}
                  />
                  {/* <ReactQuill
                    theme="snow"
                    value={information}
                    onChange={(e) => {
                      setUnsavedChanges(true);

                      handleUpdateTranslationItem("information", e);
                    }}
                    placeholder="Example placeholder..."
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline"], // toggled buttons
                        ["link"], // link button
                      ],
                    }}
                  /> */}
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Question"}
                  </InputLabel>
                  <QuizCreator
                    quiz={quiz}
                    handleUpdateTranslationItem={handleUpdateTranslationItem}
                    multiple={false}
                    setUnsavedChanges={setUnsavedChanges}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    case 1:
      return (
        <div className="px-4">
          <Grid container spacing={0} className="mt-4 pt-4">
            <Grid md={7}>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Activity name"}
                  </InputLabel>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="text"
                      value={activityName}
                      onChange={(e) => {
                        setUnsavedChanges(true);

                        handleUpdateTranslationItem("name", e.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Description"}
                  </InputLabel>
                  <ReactQuillDesc
                    val={information}
                    handleChange={handleUpdateTranslationItem}
                    field="information"
                    target={target}
                  />
                  {/* <ReactQuill
                    theme="snow"
                    value={information}
                    onChange={(e) => {
                      setUnsavedChanges(true);

                      handleUpdateTranslationItem("information", e);
                    }}
                    placeholder="Example placeholder..."
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline"], // toggled buttons
                        ["link"], // link button
                      ],
                    }}
                  /> */}
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Question"}
                  </InputLabel>
                  <div className="mb-4">
                    <QuizCreator
                      quiz={quiz}
                      handleUpdateTranslationItem={handleUpdateTranslationItem}
                      multiple={true}
                      setUnsavedChanges={setUnsavedChanges}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    case 2:
      return (
        <div className="px-4">
          <Grid container spacing={0} className="mt-4 pt-4">
            <Grid md={7}>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Activity name"}
                  </InputLabel>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="text"
                      value={activityName}
                      onChange={(e) => {
                        setUnsavedChanges(true);

                        handleUpdateTranslationItem("name", e.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Instructions"}
                  </InputLabel>
                  <ReactQuillDesc
                    val={information}
                    handleChange={handleUpdateTranslationItem}
                    field="information"
                    target={target}
                  />
                  {/* <ReactQuill
                    theme="snow"
                    value={information}
                    onChange={(e) => {
                      setUnsavedChanges(true);

                      handleUpdateTranslationItem("information", e);
                    }}
                    placeholder="Example placeholder..."
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline"], // toggled buttons
                        ["link"], // link button
                      ],
                    }}
                  /> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    case 3:
      return (
        <div className="px-4">
          <Grid container spacing={0} className="mt-4 pt-4">
            <Grid md={7}>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Activity name"}
                  </InputLabel>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="text"
                      value={activityName}
                      onChange={(e) => {
                        setUnsavedChanges(true);

                        handleUpdateTranslationItem("name", e.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Reading material or link"}
                  </InputLabel>
                  <ReactQuillDesc
                    val={information}
                    handleChange={handleUpdateTranslationItem}
                    field="information"
                    target={target}
                  />
                  {/* <ReactQuill
                    theme="snow"
                    value={information}
                    onChange={(e) => {
                      setUnsavedChanges(true);

                      handleUpdateTranslationItem("information", e);
                    }}
                    placeholder="Example placeholder..."
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline"], // toggled buttons
                        ["link"], // link button
                      ],
                    }}
                  /> */}
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Question"}
                  </InputLabel>
                  <QuizCreator
                    quiz={quiz}
                    handleUpdateTranslationItem={handleUpdateTranslationItem}
                    multiple={false}
                    setUnsavedChanges={setUnsavedChanges}
                    inputQuiz={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    case 4:
      return (
        <>
          <div className="px-4">
            <Grid container spacing={0} className="mt-4 pt-4">
              <Grid md={7}>
                <Grid container spacing={6}>
                  <Grid item md={12}>
                    <InputLabel
                      style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                      className="mb-3"
                      required={true}
                    >
                      {"Activity name"}
                    </InputLabel>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={activityName}
                        type="text"
                        onChange={(e) => {
                          setUnsavedChanges(true);

                          handleUpdateTranslationItem("name", e.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel
                      style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                      className="mb-3"
                      required={true}
                    >
                      {"Description"}
                    </InputLabel>
                    <ReactQuillDesc
                      val={information}
                      handleChange={handleUpdateTranslationItem}
                      field="information"
                      target={target}
                    />
                    {/* <ReactQuill
                      theme="snow"
                      value={information}
                      onChange={(e) => {
                        setUnsavedChanges(true);

                        handleUpdateTranslationItem("information", e);
                      }}
                      placeholder="Example placeholder..."
                      modules={{
                        toolbar: [
                          ["bold", "italic", "underline"], // toggled buttons
                          ["link"], // link button
                        ],
                      }}
                    /> */}
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel
                      style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                      className="mb-3"
                      required={true}
                    >
                      {"File uploads (3 max)"}
                    </InputLabel>

                    <div className="py-2">
                      <div className="table-responsive-md mb-4">
                        <Table
                          className="table table-striped table-bordered"
                          style={{
                            tableLayout: "fixed",
                          }}
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">File Name</th>
                              <th scope="col" style={{ width: "125px" }}>
                                Type
                              </th>
                              <th scope="col" style={{ width: "125px" }}>
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {schoolFiles.map((file) => {
                              return (
                                <tr style={{ height: "60px" }}>
                                  <td
                                    style={{
                                      overFlow: "hidden",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {file.name}
                                  </td>
                                  <td
                                    style={{
                                      overFlow: "hidden",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {file.fileType
                                      ? file.fileType.includes("pdf")
                                        ? "PDF"
                                        : file.fileType.includes("mp4")
                                        ? "Video"
                                        : "File"
                                      : "File"}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                      <div className="mt-2">
                        <small className="text-black-50">
                          Accepts JPEG, PNG, PDF, and MP4 files.
                        </small>
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel
                      style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                      className="mb-3"
                      required={true}
                    >
                      {"Question"}
                    </InputLabel>
                    <QuizCreator
                      quiz={quiz}
                      handleUpdateTranslationItem={handleUpdateTranslationItem}
                      multiple={false}
                      setUnsavedChanges={setUnsavedChanges}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Dialog
            fullWidth
            maxWidth={"sm"}
            open={dialogFileName}
            onClose={() => toggleDialogFileName()}
            classes={{ paper: "modal-content rounded-lg pt-4" }}
          >
            <div className="px-3 pb-1 text-left font-size-md font-weight-bold">
              {`Add file name`}
            </div>
            <div className="divider my-2" />
            <div className="p-3">
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <FormControl fullWidth variant="outlined">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={fileNameDialog}
                        label="Menu Name"
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          onChange: (e) => {
                            setFileNameDialog(e.target.value);
                          },
                        }}
                      />
                    </div>
                    <FormHelperText>Ex: School Calendar</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <div className="pt-4">
                <Button
                  onClick={() => toggleDialogFileName()}
                  className="btn-neutral-dark btn-pill mx-1"
                >
                  <span className="btn-wrapper--label">Cancel</span>
                </Button>
                <Button
                  onClick={async () => {
                    toggleDialogFileName();
                    handleFileName(dialogInfoFileName);
                  }}
                  className={"btn-pill mx-1 btn-success"}
                >
                  <span className="btn-wrapper--label">Save</span>
                </Button>
              </div>
            </div>
          </Dialog>
        </>
      );
    case 5:
      return (
        <>
          <div className="px-4">
            <Grid container spacing={0} className="mt-4 pt-4">
              <Grid md={7}>
                <Grid container spacing={6}>
                  <Grid item md={12}>
                    <InputLabel
                      style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                      className="mb-3"
                      required={true}
                    >
                      {"Activity name"}
                    </InputLabel>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={activityName}
                        type="text"
                        onChange={(e) => {
                          setUnsavedChanges(true);

                          handleUpdateTranslationItem("name", e.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel
                      style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                      className="mb-3"
                      required={true}
                    >
                      {"Description"}
                    </InputLabel>
                    <ReactQuillDesc
                      val={information}
                      handleChange={handleUpdateTranslationItem}
                      field="information"
                      target={target}
                    />
                    {/* <ReactQuill
                      theme="snow"
                      value={information}
                      onChange={(e) => {
                        setUnsavedChanges(true);
                        handleUpdateTranslationItem("information", e);
                      }}
                      placeholder="Example placeholder..."
                      modules={{
                        toolbar: [
                          ["bold", "italic", "underline"], // toggled buttons
                        ],
                      }}
                    /> */}
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel
                      style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                      className="mb-3"
                      required={true}
                    >
                      {"Links"}
                    </InputLabel>

                    <div className="py-2">
                      <div className="table-responsive-md mb-4">
                        <Table
                          className="table table-striped table-bordered"
                          style={{
                            tableLayout: "fixed",
                          }}
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Link text</th>
                              <th scope="col">URL</th>
                              <th scope="col" style={{ width: "125px" }}>
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {links.map((link) => {
                              return (
                                <tr style={{ height: "60px" }}>
                                  <td
                                    style={{
                                      overFlow: "hidden",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {link.text}
                                  </td>
                                  <td
                                    style={{
                                      overFlow: "hidden",
                                      fontSize: "14px",
                                    }}
                                  >
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={link.url}
                                      style={{
                                        color: "#0073bb",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      {link.url}
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel
                      style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                      className="mb-3"
                      required={true}
                    >
                      {"Question"}
                    </InputLabel>
                    <QuizCreator
                      quiz={quiz}
                      handleUpdateTranslationItem={handleUpdateTranslationItem}
                      multiple={false}
                      setUnsavedChanges={setUnsavedChanges}
                      inputQuiz={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Dialog
            fullWidth
            maxWidth={"sm"}
            open={dialogLink}
            onClose={() => toggleDialogLink()}
            classes={{ paper: "modal-content rounded-lg pt-4" }}
          >
            <div className="px-3 pb-1 text-left font-size-md font-weight-bold">
              {`Add link`}
            </div>
            <div className="divider my-2" />
            <div className="p-4">
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className="mb-4 pb-2"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={linkTextDialog}
                        label="Link text"
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          onChange: (e) => {
                            setLinkTextDialog(e.target.value);
                          },
                        }}
                      />
                    </div>
                    <FormHelperText>Ex: Sign-up Form</FormHelperText>
                  </FormControl>
                  <FormControl fullWidth variant="outlined">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={linkURLDialog}
                        label="Link URL"
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          onChange: (e) => {
                            setLinkURLDialog(e.target.value);
                          },
                        }}
                      />
                    </div>
                    <FormHelperText>Ex: https://www.google.com</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <div className="pt-4 mt-2">
                <Button
                  onClick={() => toggleDialogLink()}
                  className="btn-neutral-dark btn-pill mx-1"
                >
                  <span className="btn-wrapper--label">Cancel</span>
                </Button>
                <Button
                  onClick={async () => {
                    toggleDialogLink();
                    handleLink();
                  }}
                  className={"btn-pill mx-1 btn-success"}
                >
                  <span className="btn-wrapper--label">Add Link</span>
                </Button>
              </div>
            </div>
          </Dialog>
        </>
      );
    case 6:
    case 7:
      return (
        <div className="px-4">
          <Grid container spacing={0} className="mt-4 pt-4">
            <Grid md={7}>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Activity name"}
                  </InputLabel>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="text"
                      value={activityName}
                      onChange={(e) => {
                        setUnsavedChanges(true);

                        handleUpdateTranslationItem("name", e.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Description"}
                  </InputLabel>
                  <ReactQuillDesc
                    val={information}
                    handleChange={handleUpdateTranslationItem}
                    field="information"
                    target={target}
                  />
                  {/* <ReactQuill
                    theme="snow"
                    value={information}
                    onChange={(e) => {
                      // setUnsavedChanges(true);
                      // handleUpdateTranslationItem("information", e);
                    }}
                    placeholder="Example placeholder..."
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline"], // toggled buttons
                        ["link"], // link button
                      ],
                    }}
                  /> */}
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Question"}
                  </InputLabel>
                  <QuizCreator
                    quiz={quiz}
                    handleUpdateTranslationItem={handleUpdateTranslationItem}
                    multiple={false}
                    setUnsavedChanges={setUnsavedChanges}
                    inputQuiz={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    default:
      return null;
  }
};
export default ActivityDetails;
