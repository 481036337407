import { createTheme } from "@material-ui/core/styles";
import shadows from "./shadows";

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: "#18A9A9",
    },
    grey: {
      300: "#fefefe",
      A100: "#f8f9ff",
    },
    secondary: {
      main: "#4191ff",
    },
    error: {
      main: "#cd3d64",
    },
    success: {
      main: "#1bc943",
    },
    info: {
      main: "#11c5db",
    },
    warning: {
      main: "#f4772e",
    },
    helpers: {
      primary: "#624185",
      main: "rgba(25, 46, 91, .035)",
    },
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1100,
      xl: 1381,
    },
  },
  shape: {
    borderRadius: "0.2rem",
  },
  overrides: {
    MuiTab: {
      root: {
        fontWeight: "bold",
      },
    },
    MuiButton: {
      sizeSmall: {
        padding: "6px 20px",
        fontSize: 14,
      },
      outlined: {
        padding: "10px 22px",
        fontSize: 14,
      },
      text: {
        padding: "10px 22px",
        fontSize: 14,
      },

      sizeLarge: {
        padding: "16px 28px",
        fontSize: 16,
      },

      root: {
        textTransform: "none",
        fontWeight: "normal",
        padding: "10px 22px",
        fontSize: 14,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#070919",
        padding: "8px 16px",
        fontSize: "13px",
      },
      arrow: {
        color: "#070919",
      },
    },
  },
  typography: {
    fontFamily: ["Heebo", "sans-serif"].join(","),
    fontSize: 14,
  },
  shadows,
});

export default MuiTheme;
