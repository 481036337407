import React from "react";

import Unsub from "../../components/Unsub";
export default function UnsubPage() {
  return (
    <>
      <Unsub />
    </>
  );
}
