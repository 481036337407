import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { appSelector, userSelector, ActivityServices } from "../../core";
import {
  Grid,
  Button,
  List,
  ListItem,
  Dialog,
  DialogActions,
  Typography,
  IconButton,
  DialogContent,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { NavLink } from "react-router-dom";
import { CircleLoader } from "react-spinners";
import PreviewActivity from "./components/previewActivity";
import AGGrid from "../Grid";

// Responsive //
import { useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Bugsnag from "@bugsnag/js";

const styles = (theme) => ({
  rootNormal: {
    margin: 0,
    padding: theme.spacing(2),
  },
  root: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButtonNormal: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButton: {
    position: "static",
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, row, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={row ? classes.root : classes.rootNormal}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={row ? classes.closeButton : classes.closeButtonNormal}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function AdminArchivedActivities(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [initialLoad, setInitialLoad] = useState(true);
  const [archivedAdminActivities, setArchivedAdminActivities] = useState([]);
  const [addDialog, setAddDialog] = useState(false);
  const [addDialogInfo, setAddDialogInfo] = useState(null);
  const handleAddClose = () => {
    setAddDialog(false);
  };

  const [previewDialog, setPreviewDialog] = useState(false);
  const [previewDialogInfo, setPreviewDialogInfo] = useState(null);
  const handlePreviewClose = () => {
    setPreviewDialog(false);
  };

  const handleAddActivity = async () => {
    try {
      if (addDialogInfo) {
        if (addDialogInfo.id) {
          await ActivityServices.addActivityToCurrent(addDialogInfo);
          setAddDialog(false);
        }
      }
    } catch (err) {
      console.log(err);
      Bugsnag.notify(err);
    }
  };

  useEffect(() => {
    async function getAdminArchivedActivities() {
      const adminArchivedActivitiesData = await ActivityServices.readArchivedAdminActivities();
      setArchivedAdminActivities(adminArchivedActivitiesData);
      setInitialLoad(false);
    }

    getAdminArchivedActivities();
  }, []);

  const themes = [
    {
      id: 0,
      name: "Off to a Good Start",
      subtext: "Starts in August or September",
    },
    {
      id: 1,
      name: "Helping with Homework",
      subtext: "Starts in September or October",
    },
    {
      id: 2,
      name: "Conference Conversations",
      subtext: "Starts in October or November",
    },
    {
      id: 3,
      name: "Doctor's Orders",
      subtext: "Starts in November or March",
    },
    {
      id: 4,
      name: "Make Time for Reading Together",
      subtext: "Starts in December",
    },
    {
      id: 5,
      name: "Spreading Kindness",
      subtext: "Starts in January",
    },
    {
      id: 6,
      name: "Arts in Education",
      subtext: "Starts in February",
    },
    {
      id: 7,
      name: "Math Matters",
      subtext: "Starts in March or April",
    },
    {
      id: 8,
      name: "Positive Parenting",
      subtext: "Starts in April or May",
    },
    {
      id: 9,
      name: "Celebrating Parents",
      subtext: "Starts in May or June",
    },
  ];

  const handleActivityType = (type) => {
    switch (type) {
      case 0:
        return "Video";
      case 1:
        return "Trivia";
      case 2:
        return "Engage at Home";
      case 3:
        return "Reading Rewards";
      case 4:
        return "School Activity";
      case 5:
        return "Link";
      case 6:
        return "Fast Facts";
      case 7:
        return "More to Explore";
      default:
        return null;
    }
  };

  return !props.user ? null : initialLoad || props.app.isLoading ? (
    <Grid container className="my-spacing-6">
      <Grid item xs={12}>
        <div className="d-flex flex-row justify-content-center">
          <CircleLoader
            className="d-flex align-self-center"
            color={"#18a9a9"}
            loading={true}
            size={40}
          />
        </div>
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
        <Grid item md={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: 13,
              paddingBottom: 13,
            }}
          >
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              Admin Activities
            </div>
          </div>
        </Grid>
        <Grid item md={12} xl={12}>
          {mobile ? null : (
            <List
              component="div"
              className="nav-line-alt nav-line d-flex align-items-center mt-3"
            >
              <ListItem
                button
                component={NavLink}
                to={`/admin-activities`}
                className="pr-2"
              >
                <span className="font-size-sm">{"Activities"}</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-parent-evaluations`}
              >
                <span className="font-size-sm">Parent Evaluations</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-weekly-scorecard`}
              >
                <span className="font-size-sm">Weekly Scorecard</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-resources-uploads`}
              >
                <span className="font-size-sm">Guardian Uploads</span>
                <div className="divider" />
              </ListItem>
              {/* <ListItem button disableRipple className="px-2" selected>
                <span className="font-size-sm">Archive</span>
                <div className="divider" />
              </ListItem> */}
            </List>
          )}
        </Grid>
        <Grid item md={12} className="mt-4 pt-4">
          <AGGrid
            rowData={archivedAdminActivities}
            search={false}
            autoHeight={true}
            columnDefs={[
              {
                headerName: "School Year",
                field: "schoolYear",
                sortable: true,
                filter: true,
                pinned: "left",
                cellRenderer: "agGroupCellRenderer",
              },
            ]}
            masterDetail={true}
            detailCellRendererParams={{
              detailGridOptions: {
                masterDetail: true,
                defaultColDef: {
                  resizable: true,
                },
                columnDefs: [
                  {
                    headerName: "Activity Name",
                    field: "name",
                    sortable: true,
                    filter: true,
                  },
                  {
                    headerName: "Theme",
                    field: "theme",
                    sortable: true,
                    filter: true,
                    valueGetter: function (params) {
                      if (params.data.theme !== undefined) {
                        return themes[params.data.theme].name;
                      } else {
                        return null;
                      }
                    },
                  },
                  {
                    headerName: "Activity Type",
                    field: "type",
                    sortable: true,
                    filter: true,
                    valueGetter: function (params) {
                      if (params.data.type !== undefined) {
                        return handleActivityType(params.data.type);
                      } else {
                        return null;
                      }
                    },
                  },
                  {
                    headerName: "Points",
                    field: "points",
                    sortable: true,
                    filter: true,
                    width: 100,
                  },
                  {
                    width: 280,
                    suppressSizeToFit: true,
                    field: "actions",
                    headerName: "Actions",
                    cellRendererFramework: (params) => (
                      <div className="d-flex flex-row align-items-center">
                        <button
                          className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained bg-dark shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                          tabIndex="0"
                          type="button"
                          onClick={() => {
                            setPreviewDialogInfo(params.data);
                            setPreviewDialog(true);
                          }}
                        >
                          <span className="MuiButton-label">
                            <span
                              className="btn-wrapper--label text-white"
                              style={{ fontSize: "12px" }}
                            >
                              Preview
                            </span>
                          </span>
                          <span className="MuiTouchRipple-root"></span>
                        </button>

                        <button
                          className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained btn-primary shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                          tabIndex="0"
                          type="button"
                          onClick={() => {
                            setAddDialogInfo(params.data);
                            setAddDialog(true);
                          }}
                        >
                          <span className="MuiButton-label">
                            <span
                              className="btn-wrapper--label text-white"
                              style={{ fontSize: "12px" }}
                            >
                              Add to Current Year
                            </span>
                          </span>
                          <span className="MuiTouchRipple-root"></span>
                        </button>
                      </div>
                    ),
                    cellClass: "agGridCellCenter",
                  },
                ],
                onFirstDataRendered(params) {
                  params.api.sizeColumnsToFit();
                },
              },
              getDetailRowData: function (params) {
                params.successCallback(params.data.activities);
              },
            }}
          />
        </Grid>
      </Grid>
      <Dialog
        open={addDialog}
        onClose={handleAddClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
        PaperProps={{
          className: "p-3",
        }}
      >
        <DialogTitle
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            color: "rgb(42, 42, 47)",
            borderBottom: "none",
          }}
        >
          {`Are you sure you want to add activity${
            addDialogInfo ? `: ${addDialogInfo.name}` : ""
          } to the current school year?`}
        </DialogTitle>
        <DialogActions style={{ borderTop: "none" }} className="mt-4">
          <Button onClick={handleAddClose}>Cancel</Button>
          <Button
            className="btn-primary"
            onClick={() => handleAddActivity()}
            autoFocus
          >
            {"Add Activity"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={previewDialog}
        onClose={handlePreviewClose}
        fullWidth={true}
        maxWidth={"md"}
        PaperProps={{
          className: "p-3",
        }}
      >
        <DialogTitle
          style={{
            borderBottom: "none",
          }}
          onClose={handlePreviewClose}
          row={true}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              color: "rgb(42, 42, 47)",
            }}
          >
            {previewDialogInfo ? `Preview: ${previewDialogInfo.name}` : ""}
          </div>
        </DialogTitle>
        <DialogContent dividers={true} className="pb-4 pt-4">
          {previewDialogInfo ? (
            <PreviewActivity activity={previewDialogInfo} />
          ) : null}
        </DialogContent>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
});

export default connect(mapStateToProps, null)(AdminArchivedActivities);
