import React from "react";

import Recover from "../../components/Recover";
export default function RecoverPage() {
  return (
    <>
      <Recover />
    </>
  );
}
