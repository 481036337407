import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  Dialog,
  Button,
  Typography,
  IconButton,
  FormControlLabel,
  FormControl,
  Checkbox,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { CircleLoader } from "react-spinners";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  userSelector,
  appSelector,
  updateUserAction,
  resetPasswordAction,
  sendVerificationEmailAction,
  resetAppAction,
  SchoolUser,
} from "../../core";
import Table from "./components/table";

/* Dialog */
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  labelFontSize: {
    fontSize: "13px",
  },
}));

function Notifications(props) {
  const classes = useStyles();

  const [editDialog, setEditDialog] = useState(false);
  const [selected, setSelected] = useState([]);
  const [dialogValue, setDialogValue] = useState({
    analytics: "monthly",
  });

  const handleChange = (event) => {
    setDialogValue({
      ...dialogValue,
      [event.target.name]:
        event.target.name === "analytics"
          ? event.target.value === dialogValue.analytics
            ? ""
            : event.target.value
          : event.target.checked,
    });
  };

  const [accountLoading, setAccountLoading] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const handleUpdateNotifications = () => {
    try {
      let userEmailSettings = props.user.emailSettings;
      const idsToUpdate = selected.map((item) => item.id);
      idsToUpdate.map((id) => {
        userEmailSettings[id] = {
          id,
          ...dialogValue,
        };
        return id;
      });

      setToastType("");
      setToastMessage("");
      props.dispatchUpdateUser(
        new SchoolUser({
          ...props.user,
          emailSettings: userEmailSettings,
        })
      );
      setAccountLoading("account");
    } catch (err) {
      console.error(err);
      setToastType("error");
      setToastMessage("Something went wrong! Please contact support");
    }
  };

  useEffect(() => {
    if (accountLoading) {
      if (!props.app.isLoading) {
        if (props.app.error) {
          setToastType("error");
          setToastMessage("Something went wrong! Please contact support");
          setAccountLoading(false);
        } else {
          setSelected([]);
          setToastType("success");
          setToastMessage("Notification settings updated.");
          setAccountLoading(false);
          setEditDialog(false);
        }
      }
    }
  }, [props.app.error, props.app.isLoading, accountLoading]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={12}>
          <div
            className="font-weight-bold display-4"
            style={{ color: "rgb(43, 39, 60)", height: "52px" }}
          >
            Email Notifications
          </div>
        </Grid>
        <Grid item md={12}>
          <Card
            className="p-3"
            style={{
              borderRadius: "0.25rem",
              border: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <Table
              rows={
                props.user
                  ? props.user.emailSettings
                    ? Object.values(props.user.emailSettings).length
                      ? Object.values(props.user.emailSettings).map(
                          (schoolSetting) => ({
                            ...schoolSetting,
                            name: props.user.schools[schoolSetting.id]
                              ? props.user.schools[schoolSetting.id].name
                              : "Unknown",
                          })
                        )
                      : []
                    : []
                  : []
              }
              setEditDialog={setEditDialog}
              selected={selected}
              setSelected={setSelected}
              setDialogValue={setDialogValue}
            />
          </Card>
        </Grid>
      </Grid>
      {/* Edit Notifications Modal */}
      <Dialog
        fullWidth
        maxWidth="md"
        open={editDialog}
        classes={{ paper: "modal-content rounded-lg" }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setEditDialog(false)}
        >
          Edit Notifications
          <p className="text-black-50 mb-0 font-size-sm font-weight-normal">
            {`You are editing notifications for ${selected.length} school${
              selected.length > 1 ? "s" : ""
            }.`}
          </p>
        </DialogTitle>
        <DialogContent className="p-4">
          <Grid container spacing={5}>
            <Grid item md={12}>
              <div className="d-flex flex-column mb-2">
                <label className="font-weight-bold mb-2 font-size-sm">
                  Analytic Emails
                </label>
                <small className="text-black-50 mb-0 font-weight-normal">
                  Summary of Parent Parties Engage analytics each month
                </small>
              </div>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={dialogValue.analytics === "monthly"}
                      onChange={handleChange}
                      size="small"
                    />
                  }
                  name="analytics"
                  value="monthly"
                  label="Monthly"
                  classes={{
                    label: classes.labelFontSize,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <>
            <Button
              onClick={() => setEditDialog(false)}
              className="btn-neutral-dark btn-pill mx-1"
            >
              <span className="btn-wrapper--label">Cancel</span>
            </Button>
            <Button
              className={clsx("btn-pill", "mx-1", "btn-primary")}
              onClick={handleUpdateNotifications}
            >
              <span className="btn-wrapper--label">Save</span>
              {accountLoading ? (
                <span className="btn-wrapper--icon">
                  <CircleLoader size={15} color="#fff" className="ml-2" />
                </span>
              ) : null}
            </Button>
          </>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        key={"bottom,center"}
        open={toastType}
        classes={{
          root: toastType
            ? toastType !== "success"
              ? `toastr-danger`
              : `toastr-success`
            : `toastr-success`,
        }}
        onClose={() => setToastType("")}
        message={toastMessage}
        autoHideDuration={toastType === "success" ? 4000 : null}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setToastType("")}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
});
const mapDispatchToProps = {
  dispatchUpdateUser: updateUserAction,
  dispatchResetPassword: resetPasswordAction,
  dispatchSendEmail: sendVerificationEmailAction,
  dispatchReset: resetAppAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
