import { useEffect, useState, useRef } from "react";
import moment from "moment";
import { CircleLoader } from "react-spinners";
import update from "immutability-helper";
import {
  Grid,
  FormControl,
  RadioGroup,
  Dialog,
  Typography,
  IconButton,
  DialogContent,
  Avatar,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  Button,
  Divider,
  DialogActions,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Check } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    width: "100%",
  },
  optionTextFieldFocused: {},
  optionTextFieldUnderline: {
    "&:after": {
      borderBottom: `1px solid transparent !important`,
    },
    "&:before": {
      borderBottom: `1px solid transparent`,
    },
    "&:hover:not($disabled):not($focused):before": {
      borderBottom: `1px solid #d3d3d3 !important`,
    },
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const FeedbackModal = (props) => {
  const { open, onClose, response, sendFeedback } = props;

  const [responseLoading, setResponseLoading] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState(false);
  const [feedback, setFeedback] = useState("");

  useEffect(() => {
    setFeedback("");
    setResponseSuccess(false);
    setResponseLoading(false);
  }, [open]);

  const handleText = (e) => {
    setFeedback(e.target.value);
  };

  const handleClose = () => {
    onClose();
  };
  const handleSubmit = async () => {
    setResponseLoading(true);

    await sendFeedback(response, feedback);

    /* Show success msg */
    setResponseSuccess(true);
    setResponseLoading(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      classes={{ paper: "modal-content rounded-lg" }}
    >
      <DialogContent
        className="p-4"
        style={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={12}>
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div className="d-flex flex-row align-items-center">
                {response ? (
                  response.avatar ? (
                    <Avatar
                      src={response.avatar}
                      style={{ width: 30, height: 30 }}
                      className="mr-2"
                    />
                  ) : null
                ) : null}
                <div className="text-black" style={{ fontSize: "14px" }}>
                  {response
                    ? response.username
                      ? response.username
                      : "Guardian"
                    : "Guardian"}
                </div>
              </div>
              {response ? (
                <Typography
                  style={{
                    color: "rgb(143, 143, 145)",
                    fontSize: "14px",
                    width: 200,
                    textAlign: "right",
                  }}
                  className="ml-2"
                >
                  {moment(Number(response.time)).format("LLL")}
                </Typography>
              ) : null}
            </div>
            {responseSuccess ? (
              <Alert icon={false} className="mt-4 mb-4" severity="success">
                <div className="d-flex align-items-center align-content-center">
                  <span className="font-size-lg d-block btn-icon d-40 mr-3 text-center bg-white rounded-sm text-success">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span>
                    <strong className="d-block">
                      Your feedback has been sent.
                    </strong>
                  </span>
                </div>
              </Alert>
            ) : null}
            <div
              style={{
                color: "#1d2b3b",
                fontSize: ".93333rem",
                fontWeight: "400",
                lineHeight: ".93333rem",
                marginBottom: ".53333rem",
              }}
              className="mt-4"
            >
              {`Feedback`}
            </div>
            <div>
              <textarea
                value={feedback}
                onChange={handleText}
                className="responseTextArea"
                disabled={responseSuccess || responseLoading}
                name="textArea"
                placeholder="Your reply here..."
                maxLength="500"
                required
              />
            </div>
          </Grid>
          <Grid item sm={12}>
            <CardContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {response ? (
                  response.responses.url ? (
                    <div>
                      <a
                        href={response.responses.url}
                        rel="noopener noreferrer"
                        target="_blank"
                        style={{
                          color: "#0073bb",
                          textDecoration: "underline",
                        }}
                      >
                        View Upload
                      </a>
                    </div>
                  ) : (
                    response.responses.map((question, index, arr) => (
                      <div className={index !== 0 ? "mt-3" : ""}>
                        <div
                          style={{ fontSize: 14, color: "#000" }}
                          className="mb-1"
                        >
                          {question.question}
                        </div>
                        {question.options ? (
                          <FormControl
                            component="fieldset"
                            className="px-2 w-100"
                          >
                            <RadioGroup value={question.answer}>
                              {question.options.map((option, optionIndex) => (
                                <FormControlLabel
                                  key={optionIndex}
                                  fullWidth={true}
                                  value={option.value}
                                  control={<Radio color="primary" />}
                                  style={
                                    option.id === question.answer
                                      ? {
                                          backgroundColor:
                                            "rgba(24, 169, 169, 0.1)",
                                          width: "100%",
                                        }
                                      : { width: "100%" }
                                  }
                                  label={
                                    <div className="d-flex flex-row align-items-center justify-content-between">
                                      <div
                                        style={{ fontSize: 14 }}
                                        className="text-black-50"
                                      >
                                        {option.value}
                                      </div>
                                      {option.id === question.answer ? (
                                        <div>
                                          <Check
                                            style={{
                                              width: 20,
                                              height: 20,
                                            }}
                                            className="mr-2"
                                            color="primary"
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  }
                                  checked={option.id === question.answer}
                                  disabled
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        ) : (
                          <div
                            style={{ fontSize: 14 }}
                            className="text-black-50"
                          >
                            {question.answer}
                          </div>
                        )}
                        <Divider className="my-3" />
                      </div>
                    ))
                  )
                ) : null}
              </div>
            </CardContent>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {responseSuccess ? (
          <Button
            className={"btn-pill mx-1 btn-success"}
            // onClick={() => {
            //   setReviewRespondModal(false);
            //   setTemplate("");
            //   setResponseSuccess(false);
            //   setResponseError(false);
            //   setResponseLoading(false);
            //   setMarkAvailable(false);
            // }}
            onClick={handleClose}
          >
            <span className="btn-wrapper--label">Close</span>
          </Button>
        ) : (
          <>
            <Button
              // onClick={() => {
              //   setReviewRespondModal(false);
              //   setTemplate("");
              //   setResponseSuccess(false);
              //   setResponseError(false);
              //   setResponseLoading(false);
              //   setMarkAvailable(false);
              // }}
              className="btn-neutral-dark btn-pill mx-1"
              onClick={handleClose}
            >
              <span className="btn-wrapper--label">Cancel</span>
            </Button>
            <Button
              className={"btn-pill mx-1 btn-success"}
              disabled={!feedback || responseLoading}
              onClick={handleSubmit}
            >
              <span className="btn-wrapper--label">Submit</span>
              {responseLoading ? (
                <span className="btn-wrapper--icon">
                  <CircleLoader size={15} color="#fff" className="ml-2" />
                </span>
              ) : null}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

const ResponsesModal = (props) => {
  const {
    responseModalTitle,
    responses,
    responseModal,
    handleResponsesClose,
    handleSendFeedback,
  } = props;

  const [responseItems, setResponseItems] = useState([]);
  useEffect(() => {
    setResponseItems(responses);
  }, [responses]);

  const classes = useStyles();

  const [feedbackModal, setFeedbackModal] = useState(false);
  const [feedbackResponse, setFeedbackResponse] = useState(null);

  const openFeedback = (response) => {
    setFeedbackModal(true);
    setFeedbackResponse(response);
  };

  const closeFeedback = () => {
    setFeedbackModal(false);
  };

  const sendFeedback = async (response, feedback) => {
    /* Update analytics chart to hold new response */
    await handleSendFeedback(response, feedback);

    /* Update dialog frontend */
    const responseIndex = responseItems.findIndex(
      (res) => res.id === response.id
    );
    if (responseIndex > -1) {
      const newResponses = update(responseItems, {
        [responseIndex]: {
          $set: {
            ...responseItems[responseIndex],
            feedback,
            feedbackEmail: props.user ? props.user.email.address : "",
          },
        },
      });

      setResponseItems(newResponses);
    }
  };

  /* HACK TO FIX DIALOG OPENING AT BOTTOM OF SCROLL */
  const contentRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (contentRef) {
        if (contentRef.current) {
          contentRef.current.scroll({ top: 0 });
        }
      }
    }, 100);
  }, [responseModal]);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={responseModal}
        onClose={handleResponsesClose}
        classes={{ paper: "modal-content rounded-lg" }}
      >
        <DialogTitle onClose={handleResponsesClose}>
          <div className="pb-1">
            <div className="text-black">Activity Responses</div>
            <small className="text-black-50">
              {responseModalTitle}{" "}
              {responseItems
                ? responseItems.length
                  ? `(${responseItems.length} response${
                      responseItems.length > 1 ? "s" : ""
                    })`
                  : ""
                : ""}
            </small>
          </div>
        </DialogTitle>
        <DialogContent
          dividers={false}
          className="pb-4 pt-3"
          style={{ height: 600 }}
          ref={contentRef}
        >
          {responseItems.map((response, index) => (
            <Card
              style={
                index === 0
                  ? {
                      marginTop: 10,
                      border: "1px solid rgb(233 232 232)",
                    }
                  : {
                      marginTop: 20,
                      border: "1px solid rgb(233 232 232)",
                    }
              }
              key={`${response.id}`}
            >
              <CardContent>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <div className="d-flex flex-row align-items-center">
                    {response ? (
                      response.avatar ? (
                        <Avatar
                          src={response.avatar}
                          style={{ width: 30, height: 30 }}
                          className="mr-2"
                        />
                      ) : null
                    ) : null}
                    <div className="text-black" style={{ fontSize: "14px" }}>
                      {response
                        ? response.username
                          ? response.username
                          : "Guardian"
                        : "Guardian"}
                    </div>
                  </div>
                  <Typography
                    style={{
                      color: "rgb(143, 143, 145)",
                      fontSize: "14px",
                      width: 200,
                      textAlign: "right",
                    }}
                    className="ml-2"
                  >
                    {moment(Number(response.time)).format("LLL")}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 20,
                  }}
                >
                  {response ? (
                    response.responses.url ? (
                      <div>
                        <a
                          href={response.responses.url}
                          rel="noopener noreferrer"
                          target="_blank"
                          style={{
                            color: "#0073bb",
                            textDecoration: "underline",
                          }}
                        >
                          View Upload
                        </a>
                      </div>
                    ) : (
                      response.responses.map((question, index) => (
                        <div className={index !== 0 ? "mt-3" : ""}>
                          <div
                            style={{ fontSize: 14, color: "#000" }}
                            className="mb-1"
                          >
                            {question.question}
                          </div>
                          {question.options ? (
                            <FormControl
                              component="fieldset"
                              className="px-2 w-100"
                            >
                              <RadioGroup value={question.answer}>
                                {question.options.map((option, optionIndex) => (
                                  <FormControlLabel
                                    key={optionIndex}
                                    fullWidth={true}
                                    value={option.value}
                                    control={<Radio color="primary" />}
                                    style={
                                      option.id === question.answer
                                        ? {
                                            backgroundColor:
                                              "rgba(24, 169, 169, 0.1)",
                                            width: "100%",
                                          }
                                        : { width: "100%" }
                                    }
                                    label={
                                      <div className="d-flex flex-row align-items-center justify-content-between">
                                        <div
                                          style={{ fontSize: 14 }}
                                          className="text-black-50"
                                        >
                                          {option.value}
                                        </div>
                                        {option.id === question.answer ? (
                                          <div>
                                            <Check
                                              style={{
                                                width: 20,
                                                height: 20,
                                              }}
                                              className="mr-2"
                                              color="primary"
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    }
                                    checked={option.id === question.answer}
                                    disabled
                                    classes={{
                                      label: classes.formControlLabel,
                                    }}
                                  />
                                ))}
                              </RadioGroup>
                            </FormControl>
                          ) : (
                            <div
                              style={{ fontSize: 14 }}
                              className="text-black-50"
                            >
                              {question.answer}
                            </div>
                          )}
                          <Divider className="my-3" />
                        </div>
                      ))
                    )
                  ) : null}
                  {response.feedback ? (
                    <div className="mt-2">
                      <div style={{ fontSize: 14, color: "#000" }}>
                        Feedback: {response.feedback}
                      </div>
                      {response.feedbackEmail ? (
                        <div
                          className="text-black-50"
                          style={{
                            fontStyle: "italic",
                            fontSize: 14,
                          }}
                        >
                          {response.feedbackEmail}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div className="mt-2 d-flex flex-row justify-content-end">
                      <Button
                        className="btn-primary"
                        autoFocus
                        size="small"
                        onClick={() => openFeedback(response)}
                      >
                        {"Send Feedback"}
                      </Button>
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>
          ))}
        </DialogContent>
        <div
          style={{ width: "100%", height: "10px", backgroundColor: "#18A9A9" }}
        />
      </Dialog>
      <FeedbackModal
        open={feedbackModal}
        response={feedbackResponse}
        onClose={closeFeedback}
        sendFeedback={sendFeedback}
      />
    </>
  );
};

export default ResponsesModal;
