import { useEffect, useState } from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Dialog,
  IconButton,
  Typography,
  DialogContent,
  InputLabel,
  FormControl,
  TextField,
  Button,
  Divider,
  DialogActions,
  Table,
  FormHelperText,
} from "@material-ui/core";
import moment from "moment";
import update from "immutability-helper";
import { useDropzone } from "react-dropzone";
import { CircleLoader } from "react-spinners";

const SchoolFileButton = (props) => {
  const { toggleDialogFileName } = props;

  const { open, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png, application/pdf",
    multiple: false,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )[0];
      const resourceFile = {
        file: file,
        name: file.name,
        fileType: file.type,
        hosting: true,
        tempId: moment().valueOf(),
        preview: file.preview,
      };
      toggleDialogFileName(resourceFile);
    },
  });

  return (
    <div>
      <input {...getInputProps()} style={{ display: "none" }} />
      <Button
        variant="contained"
        size="small"
        className="btn-success shadow-none mt-2 py-2"
        onClick={open}
      >
        <span className="btn-wrapper--icon mr-1">
          <FontAwesomeIcon
            style={{ fontSize: "12px" }}
            icon={["fas", "plus"]}
          />
        </span>
        <span className="btn-wrapper--label" style={{ fontSize: "12px" }}>
          Add File
        </span>
      </Button>
    </div>
  );
};

const DialogTitle = (props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      sx={(theme) => ({
        margin: 0,
        padding: theme.spacing(2),
      })}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const ethnicityDefault = [
  {
    label: "Asian or Pacific Islander",
    value: "",
  },
  {
    label: "Black or African American",
    value: "",
  },
  {
    label: "Hispanic or Latino",
    value: "",
  },
  {
    label: "Native American or Alaskan Native",
    value: "",
  },
  {
    label: "White or Caucasian",
    value: "",
  },
  {
    label: "Multiracial or Biracial",
    value: "",
  },
  {
    label: "Other",
    value: "",
  },
];

const SchoolActivityDialog = (props) => {
  const {
    loadingActivity,
    schoolActivityDialog,
    handleSchoolActivityDialogClose,
    addSchoolActivity,
    editSchoolActivity,
    schoolActivityItem,
  } = props;

  const [date, setDate] = useState("");
  const [topic, setTopic] = useState("");
  const [activity, setActivity] = useState("");
  const [attendees, setAttendees] = useState("");
  const [ethnicityBreakdown, setEhnicityBreakdown] = useState(ethnicityDefault);
  const [evidenceFiles, setEvidenceFiles] = useState([]);

  const handleUpdateEthnicity = (label, value) => {
    const index = ethnicityBreakdown.findIndex((i) => label === i.label);
    const updatedEthnicity = update(ethnicityBreakdown, {
      [index]: {
        $set: { ...ethnicityBreakdown[index], value },
      },
    });
    setEhnicityBreakdown(updatedEthnicity);
  };

  useEffect(() => {
    if (schoolActivityItem) {
      setDate(
        schoolActivityItem.date
          ? moment(schoolActivityItem.date, "MM-DD-YYYY").format("YYYY-MM-DD")
          : ""
      );
      setTopic(schoolActivityItem.topic || "");
      setActivity(schoolActivityItem.activity || "");
      setAttendees(schoolActivityItem.attendees || "");
      if (schoolActivityItem.ethnicityBreakdown) {
        setEhnicityBreakdown(schoolActivityItem.ethnicityBreakdown);
      }
      if (schoolActivityItem.evidenceFiles) {
        setEvidenceFiles(schoolActivityItem.evidenceFiles);
      }
    } else {
      setDate("");
      setTopic("");
      setActivity("");
      setAttendees("");
      setEhnicityBreakdown(ethnicityDefault);
      setEvidenceFiles([]);
    }
  }, [schoolActivityItem]);

  const addSchoolActivitySubmit = () => {
    if (!date) {
      return;
    }

    addSchoolActivity({
      date,
      topic,
      activity,
      attendees,
      ethnicityBreakdown,
      evidenceFiles,
    });

    setDate("");
    setTopic("");
    setActivity("");
    setAttendees("");
    setEhnicityBreakdown(ethnicityDefault);
    setEvidenceFiles([]);
  };

  const editSchoolActivitySubmit = () => {
    if (!date) {
      return;
    }

    editSchoolActivity({
      id: schoolActivityItem.id,
      date,
      topic,
      activity,
      attendees,
      ethnicityBreakdown,
      evidenceFiles,
    });

    setDate("");
    setTopic("");
    setActivity("");
    setAttendees("");
    setEhnicityBreakdown(ethnicityDefault);
    setEvidenceFiles([]);
  };

  const [dialogFileName, setDialogFileName] = useState(false);
  const [dialogInfoFileName, setDialogInfoFileName] = useState(null);
  const [fileNameDialog, setFileNameDialog] = useState("");
  const toggleDialogFileName = (file) => {
    if (file) {
      setDialogInfoFileName(file);
      setFileNameDialog(file.name);
    }
    setDialogFileName(!dialogFileName);
  };
  const handleFileName = async (fileInDialog) => {
    const updatedFile = {
      ...fileInDialog,
      name: fileNameDialog,
    };

    setEvidenceFiles(
      update(evidenceFiles, {
        $push: [updatedFile],
      })
    );
  };

  return (
    <>
      <Dialog
        open={schoolActivityDialog}
        onClose={handleSchoolActivityDialogClose}
        fullWidth={true}
        maxWidth={"md"}
        classes={{ paper: "modal-content rounded-lg" }}
      >
        <DialogTitle
          onClose={handleSchoolActivityDialogClose}
          className="text-black d-flex flex-row justify-content-between align-items-center"
        >
          {schoolActivityItem
            ? "Edit In-school Activity"
            : "Add In-school Activity"}
        </DialogTitle>
        <DialogContent dividers={false} className="p-4 mx-4 mb-4">
          {loadingActivity ? (
            <CircleLoader size={30} loading={true} color="#18a9a9" />
          ) : (
            <Grid container spacing={6} justifyContent="center">
              <Grid item md={12}>
                <div>
                  <InputLabel
                    style={{ fontSize: "14px", color: "rgba(0,0,0,.9)" }}
                    className="mb-4"
                  >
                    {"Activity Information"}
                  </InputLabel>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-2"
                    required
                  >
                    {"Date"}
                  </InputLabel>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      variant="outlined"
                      fullWidth
                      size="small"
                      type="date"
                      value={date}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                    />
                  </FormControl>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mt-3 mb-2"
                  >
                    {"Topic"}
                  </InputLabel>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      variant="outlined"
                      fullWidth
                      size="small"
                      type="text"
                      value={topic}
                      onChange={(e) => {
                        setTopic(e.target.value);
                      }}
                    />
                  </FormControl>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mt-3 mb-2"
                  >
                    {"Activity"}
                  </InputLabel>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      variant="outlined"
                      fullWidth
                      size="small"
                      type="text"
                      value={activity}
                      onChange={(e) => {
                        setActivity(e.target.value);
                      }}
                    />
                  </FormControl>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mt-3 mb-2"
                  >
                    {"Number of Attendees"}
                  </InputLabel>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      variant="outlined"
                      fullWidth
                      size="small"
                      type="number"
                      value={attendees}
                      onChange={(e) => {
                        setAttendees(e.target.value);
                      }}
                    />
                  </FormControl>
                  <Divider className="my-4" />
                  <InputLabel
                    style={{ fontSize: "14px", color: "rgba(0,0,0,.9)" }}
                    className="mt-3 mb-4"
                  >
                    {"Ethnicity / Race Breakdown"}
                  </InputLabel>
                  {ethnicityBreakdown.map((ethnicity) => (
                    <div key={ethnicity.label}>
                      <InputLabel
                        style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                        className="mt-3 mb-2"
                      >
                        {ethnicity.label}
                      </InputLabel>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          variant="outlined"
                          fullWidth
                          size="small"
                          type="number"
                          value={ethnicity.value}
                          onChange={(e) => {
                            handleUpdateEthnicity(
                              ethnicity.label,
                              e.target.value
                            );
                          }}
                        />
                      </FormControl>
                    </div>
                  ))}
                  <Divider className="my-4" />
                  <InputLabel
                    style={{ fontSize: "14px", color: "rgba(0,0,0,.9)" }}
                    className="mt-3 mb-4"
                  >
                    {"Evidence Upload"}
                  </InputLabel>
                  <div className="py-2">
                    <div className="table-responsive-md mb-4">
                      <Table
                        className="table table-striped table-bordered"
                        style={{
                          tableLayout: "fixed",
                        }}
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">File Name</th>
                            <th scope="col" style={{ width: "125px" }}>
                              Type
                            </th>
                            <th scope="col" style={{ width: "125px" }}>
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {evidenceFiles.map((file) => {
                            return (
                              <tr style={{ height: "60px" }}>
                                <td
                                  style={{
                                    overFlow: "hidden",
                                    fontSize: "14px",
                                  }}
                                >
                                  {file.name}
                                </td>
                                <td
                                  style={{
                                    overFlow: "hidden",
                                    fontSize: "14px",
                                  }}
                                >
                                  {file.fileType
                                    ? file.fileType.includes("pdf")
                                      ? "PDF"
                                      : file.fileType.includes("mp4")
                                      ? "Video"
                                      : "File"
                                    : "File"}
                                </td>
                                <td
                                  style={{
                                    overFlow: "hidden",
                                    fontSize: "14px",
                                  }}
                                >
                                  <div className="d-flex flex-row align-items-center">
                                    <Button
                                      className="mr-2 px-2 btn-transition-none border-0 btn-outline-primary"
                                      variant="outlined"
                                      component={"a"}
                                      target="_blank"
                                      href={file.preview}
                                    >
                                      <span className="btn-wrapper--icon">
                                        <FontAwesomeIcon
                                          icon={["fas", "eye"]}
                                          className="font-size-sm"
                                        />
                                      </span>
                                    </Button>
                                    <Button
                                      className="px-2 btn-transition-none border-0 btn-outline-danger"
                                      variant="outlined"
                                      onClick={() => {
                                        const fileIndex = evidenceFiles.findIndex(
                                          (schoolFile) =>
                                            file.id
                                              ? file.id === schoolFile.id
                                              : file.tempId ===
                                                schoolFile.tempId
                                        );

                                        setEvidenceFiles(
                                          update(evidenceFiles, {
                                            $splice: [[fileIndex, 1]],
                                          })
                                        );
                                      }}
                                    >
                                      <span className="btn-wrapper--icon">
                                        <FontAwesomeIcon
                                          icon={["fas", "trash"]}
                                          className="font-size-sm"
                                        />
                                      </span>
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                    <SchoolFileButton
                      toggleDialogFileName={toggleDialogFileName}
                    />
                    <div className="mt-2">
                      <small className="text-black-50">
                        Accepts JPEG, PNG, or PDF.
                      </small>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        {loadingActivity ? null : (
          <DialogActions className="py-2">
            <Button
              variant="contained"
              size="medium"
              className="btn-success shadow-none"
              onClick={
                schoolActivityItem
                  ? editSchoolActivitySubmit
                  : addSchoolActivitySubmit
              }
            >
              <span className="btn-wrapper--label" style={{ fontSize: "12px" }}>
                {schoolActivityItem ? "Save Changes" : "Add Activity"}
              </span>
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={dialogFileName}
        onClose={() => toggleDialogFileName()}
        classes={{ paper: "modal-content rounded-lg pt-4" }}
      >
        <div className="px-3 pb-1 text-left font-size-md font-weight-bold">
          {`Add file name`}
        </div>
        <div className="divider my-2" />
        <div className="p-3">
          <Grid container spacing={6}>
            <Grid item md={12}>
              <FormControl fullWidth variant="outlined">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={fileNameDialog}
                    label="Menu Name"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      onChange: (e) => {
                        setFileNameDialog(e.target.value);
                      },
                    }}
                  />
                </div>
                <FormHelperText>Ex: Sign-in Sheet</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <div className="pt-4">
            <Button
              onClick={() => toggleDialogFileName()}
              className="btn-neutral-dark btn-pill mx-1"
            >
              <span className="btn-wrapper--label">Cancel</span>
            </Button>
            <Button
              onClick={async () => {
                toggleDialogFileName();
                handleFileName(dialogInfoFileName);
              }}
              className={"btn-pill mx-1 btn-success"}
            >
              <span className="btn-wrapper--label">Save</span>
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default SchoolActivityDialog;
