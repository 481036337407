import { Database, Functions } from "./Firebase";
import {
  District,
  DistrictRecord,
  POSITION,
  INVITE_STATUS,
  InviteRecord,
  EmployeeRecord,
} from "../../Entities";
import { readSchool } from "./School";

// const schoolUsersRef = Database.ref("schoolUsers");
const districtsRef = Database.ref("districts");

/// INVITE
const invitesRef = Database.ref("invites");
async function getInviteRef(inviteId: string) {
  const inviteRef = invitesRef.child(inviteId);
  const inviteSnapshot = await new Promise<any>((resolve) =>
    inviteRef.once("value", resolve)
  );
  if (!inviteSnapshot.exists()) {
    throw new Error(`Invite ${inviteId} does not exists`);
  }
  return inviteRef;
}
async function fetchInvite(inviteRef: any) {
  const inviteSnapshot = await new Promise<any>((resolve) =>
    inviteRef.once("value", resolve)
  );
  const invite: InviteRecord = inviteSnapshot.val();
  return invite;
}
async function readInvite(inviteId: string) {
  try {
    const inviteRef = await getInviteRef(inviteId);
    return fetchInvite(inviteRef);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export const createDistrict = async (
  // schoolUserId: string,
  district: DistrictRecord
): Promise<District | Error> => {
  try {
    // const schoolUserRef = schoolUsersRef.child(schoolUserId);
    // district = {
    //   ...district,
    //   team: {
    //     [schoolUserId]: POSITION.Owner,
    //   },
    // } as DistrictRecord;

    const districtRef = await districtsRef.push(district);
    await new Promise<any>((resolve) => districtRef.once("value", resolve));
    district.id = districtRef.key as string;

    /* Add id to db record */
    await districtRef.set(district);

    // /* Update user record */
    // await Promise.all([
    //   schoolUserRef.child(`districts/${district.id}`).set(true),
    // ]);

    const createdDistrict = await readDistrict(district.id as string);
    return createdDistrict;
  } catch (err) {
    // @ts-ignore
    return err;
  }
};

export const readDistrict = async (
  districtId: string
): Promise<District | Error> => {
  try {
    let district = await Database.ref(`districts/${districtId}`)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val() as any;
        return snapshotVal;
      });

    if (district.team) {
      const team = await Promise.all(
        Object.keys(district.team).map(async (id) => {
          const entry = (district.team as {
            [id: string]: POSITION | INVITE_STATUS;
          })[id];

          if (Object.keys(INVITE_STATUS).includes(entry)) {
            const invite = await readInvite(id);
            if (!invite) {
              return;
            }
            invite.status = entry as INVITE_STATUS;
            return invite;
          } else {
            let teamMember = await readTeamMember(id);
            teamMember.position = entry as POSITION;
            return teamMember;
          }
        })
      );
      district.team = team.reduce(
        (store, teamMember) =>
          (teamMember as any).id
            ? { ...store, [(teamMember as any).id]: teamMember }
            : store,
        {}
      );
    }
    if (district.schoolIds) {
      const schoolData = await Promise.all(
        Object.keys(district.schoolIds).map(async (id) => await readSchool(id))
      );
      district.schoolData = schoolData
        .filter((schoolItem: any) => schoolItem !== null)
        .reduce(
          (store, school) =>
            (school as any).id
              ? { ...store, [(school as any).id]: school }
              : store,
          {}
        );
    }

    return district;
  } catch (err) {
    // @ts-ignore
    return err;
  }
};

export const updateDistrict = async (
  district: DistrictRecord
): Promise<District | Error> => {
  try {
    // Making call here to add any locations to users on district team
    // Removing locations from district WILL NOT remove access to school for users on team
    const updateDistrictCall = Functions.httpsCallable("updateDistrict");
    await updateDistrictCall({ districtId: district.id, district });
    const updatedDistrict = await readDistrict(district.id as string);
    return updatedDistrict;
  } catch (err) {
    // @ts-ignore
    return err;
  }
};

/// TEAM
async function getSchoolUserRef(schoolUserId: string) {
  const schoolUserRef = Database.ref("schoolUsers").child(schoolUserId);
  const schoolUserSnapshot = await new Promise<any>((resolve) =>
    schoolUserRef.once("value", resolve)
  );
  if (!schoolUserSnapshot.exists()) {
    throw new Error(`School User ${schoolUserId} does not exists`);
  }
  return schoolUserRef;
}

async function fetchTeamMember(memberRef: any) {
  const memberSnapshot = await new Promise<any>((resolve) =>
    memberRef.once("value", resolve)
  );
  const member: EmployeeRecord = memberSnapshot.val();
  delete member.schools;
  return member;
}
async function readTeamMember(memberId: string) {
  const memberRef = await getSchoolUserRef(memberId);
  return fetchTeamMember(memberRef);
}

export const removeFromDistrict = async (
  employeeId: string,
  districtId: string
): Promise<void> => {
  const removeFromDistrictCall = Functions.httpsCallable("removeFromDistrict");
  await removeFromDistrictCall({ employeeId, districtId });
};

export const updateDistrictTeamRole = async (
  districtId: string,
  memberId: string,
  role: string
): Promise<District | Error> => {
  try {
    const districtTeamRef = districtsRef.child(`${districtId}/team`);

    /* Update district team member */
    await districtTeamRef.update({
      [memberId]: role,
    });

    const updatedDistrict = await readDistrict(districtId as string);
    return updatedDistrict;
  } catch (err) {
    // @ts-ignore
    return err;
  }
};
