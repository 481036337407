import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  sceneSelector,
  appSelector,
  userSelector,
  readSceneAction,
  ParentPartiesAPIs,
  School,
  updateSchoolAction,
} from "../../core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@material-ui/icons/Close";
import {
  Grid,
  List,
  ListItem,
  Card,
  Button,
  TextField,
  IconButton,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  Divider,
} from "@material-ui/core";
import { NavLink, useLocation } from "react-router-dom";
import moment from "moment";
import update from "immutability-helper";
import { CircleLoader } from "react-spinners";

// Responsive //
import { useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const styles = (theme) => ({
  rootNormal: {
    margin: 0,
    padding: theme.spacing(2),
  },
  root: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButtonNormal: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButton: {
    position: "static",
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, row, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={row ? classes.root : classes.rootNormal}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={row ? classes.closeButton : classes.closeButtonNormal}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function SchoolParentEvaluations(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [initialLoad, setInitialLoad] = useState(true);
  const [englishQuestions, setEnglishQuestions] = useState([]);
  const [editingQuestions, setEditingQuestions] = useState(null);

  const [feedbackEnglish, setFeedbackEnglish] = useState(
    "A topic I would like to see on the Parent Parties Engage app is:"
  );
  const [editingFeedback, setEditingFeedback] = useState("");

  const [resetDialog, setResetDialog] = useState(false);
  const handleResetClose = () => {
    setResetDialog(false);
  };

  const location = useLocation();
  const { pathname } = location;
  const params = pathname.split("/");
  const sceneType = params[2];
  const sceneId = params[3];

  async function resetQuestions() {
    const schoolToUpdate = new School({
      ...props.scene.data,
      parentEvaluationQuestions: null,
    });
    props.updateSchoolAction(schoolToUpdate);

    const appSettings = await ParentPartiesAPIs.readAppSettings();
    if (appSettings.parentEvaluationQuestionsEnglish) {
      setEnglishQuestions(
        appSettings.parentEvaluationQuestionsEnglish
          ? appSettings.parentEvaluationQuestionsEnglish
          : []
      );
    }
    setResetDialog(false);
  }

  useEffect(() => {
    async function getQuestions() {
      if (props.scene.data) {
        if (props.scene.data.parentEvaluationQuestions) {
          if (props.scene.data.parentEvaluationQuestions.english) {
            setEnglishQuestions(
              props.scene.data.parentEvaluationQuestions.english
            );
          }

          /* Feedback */
          if (props.scene.data.parentEvaluationQuestions.feedbackEnglish) {
            setFeedbackEnglish(
              props.scene.data.parentEvaluationQuestions.feedbackEnglish
            );
          }

          setInitialLoad(false);
          return;
        }
      }
      const appSettings = await ParentPartiesAPIs.readAppSettings();
      if (appSettings.parentEvaluationQuestionsEnglish) {
        setEnglishQuestions(
          appSettings.parentEvaluationQuestionsEnglish
            ? appSettings.parentEvaluationQuestionsEnglish
            : []
        );
      }

      setInitialLoad(false);
    }

    getQuestions();
  }, [props.scene, sceneId]);

  const saveCurrentQuestions = async () => {
    const schoolToUpdate = new School({
      ...props.scene.data,
      parentEvaluationQuestions: {
        english: editingQuestions,
        feedbackEnglish: editingFeedback,
      },
    });
    props.updateSchoolAction(schoolToUpdate);

    setEnglishQuestions(editingQuestions);

    await ParentPartiesAPIs.updateSchoolEvalTranslationTargets(sceneId);

    setEditingQuestions(null);
  };

  const schoolPlan = props.scene
    ? props.scene.data
      ? props.scene.data.plan
      : "None"
    : "None";

  return !props.user ? null : props.scene ? (
    initialLoad || props.app.isLoading ? (
      <Grid container className="my-spacing-6">
        <Grid item xs={12}>
          <div className="d-flex flex-row justify-content-center">
            <CircleLoader
              className="d-flex align-self-center"
              color={"#18a9a9"}
              loading={true}
              size={40}
            />
          </div>
        </Grid>
      </Grid>
    ) : (
      <>
        <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
          <Grid item md={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: 13,
                paddingBottom: 13,
              }}
            >
              <div
                className="font-weight-bold display-4"
                style={{ color: "rgb(43, 39, 60)" }}
              >
                Create App Content
              </div>
            </div>
          </Grid>
          <Grid item md={12} xl={12}>
            {mobile ? null : (
              <List
                component="div"
                className="nav-line-alt nav-line d-flex align-items-center mt-3"
              >
                <ListItem
                  button
                  component={NavLink}
                  to={`/activities/${sceneType}/${sceneId}`}
                  className="pr-2"
                >
                  <span className="font-size-sm">{"Your Content"}</span>
                  <div className="divider" />
                </ListItem>
                <ListItem button disableRipple className="px-2" selected>
                  <span className="font-size-sm">Evaluations</span>
                  <div className="divider" />
                </ListItem>
                <ListItem
                  button
                  disableRipple
                  className="px-2"
                  component={NavLink}
                  to={`/weekly-scorecard/${sceneType}/${sceneId}`}
                >
                  <span className="font-size-sm">Weekly Scorecard</span>
                  <div className="divider" />
                </ListItem>
                <ListItem
                  button
                  disableRipple
                  className="px-2"
                  component={NavLink}
                  to={`/resources-file-hosting/${sceneType}/${sceneId}`}
                >
                  <span className="font-size-sm">School Files</span>
                  <div className="divider" />
                </ListItem>
              </List>
            )}
          </Grid>
          <Grid item md={12}>
            <div
              style={{
                marginTop: 40,
              }}
              className="mb-4"
            >
              <div
                className="heading-4 text-left mb-0"
                style={{
                  color: "#000",
                  textTransform: "none",
                }}
              >
                Parent Evaluation Questions
              </div>
              <div>
                <small className="text-black-50">
                  Guardians answer questions on scale of 1 to 5. Strongly
                  Disagree to Strongly Agree.
                </small>
              </div>
            </div>
            <Card
              className="p-3 mt-4"
              style={{
                borderRadius: "0.25rem",
                border: "1px solid rgba(0,0,0,.1)",
              }}
            >
              {editingQuestions ? (
                <>
                  <List component="div" className="list-group-flush">
                    {editingQuestions.map((question, index) => (
                      <ListItem
                        className="py-4 d-block"
                        key={index}
                        style={{
                          border:
                            editingQuestions.length - 1 === index ? "none" : "",
                        }}
                      >
                        <div className="d-flex flex-row align-items-center">
                          <TextField
                            size="small"
                            variant={"outlined"}
                            fullWidth
                            hiddenLabel={true}
                            value={question.question}
                            placeholder="Question"
                            type="text"
                            onChange={(e) => {
                              setEditingQuestions(
                                update(editingQuestions, {
                                  [index]: {
                                    $set: {
                                      ...editingQuestions[index],
                                      question: e.target.value,
                                    },
                                  },
                                })
                              );
                            }}
                          />
                          <div className="ml-4">
                            <IconButton
                              size="medium"
                              aria-label="close"
                              onClick={() => {
                                setEditingQuestions(
                                  update(editingQuestions, {
                                    $splice: [[index, 1]],
                                  })
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                icon={["fas", "trash"]}
                                className="font-size-sm"
                              />
                            </IconButton>
                          </div>
                        </div>
                      </ListItem>
                    ))}
                    <ListItem
                      className="py-4 d-block"
                      style={{ border: "none" }}
                    >
                      <div className="d-flex flex-row justify-content-end">
                        <Button
                          variant="contained"
                          size="small"
                          className={"btn-success shadow-none mt-2 py-2"}
                          onClick={() => {
                            setEditingQuestions(
                              update(editingQuestions, {
                                $push: [
                                  {
                                    id: moment().valueOf(),
                                    question: `Question ${
                                      editingQuestions.length + 1
                                    }?`,
                                  },
                                ],
                              })
                            );
                          }}
                        >
                          <span className="btn-wrapper--icon mr-1">
                            <FontAwesomeIcon
                              style={{ fontSize: "12px" }}
                              icon={["fas", "plus"]}
                            />
                          </span>
                          <span
                            className="btn-wrapper--label"
                            style={{ fontSize: "12px" }}
                          >
                            {`Add Question`}
                          </span>
                        </Button>
                      </div>
                    </ListItem>
                    <ListItem className="py-4 d-block">
                      <div
                        className="heading-4 text-left mb-1"
                        style={{
                          color: "#000",
                          textTransform: "none",
                          fontSize: "15px",
                        }}
                      >
                        Feedback Question
                      </div>
                      <TextField
                        size="small"
                        variant={"outlined"}
                        fullWidth
                        hiddenLabel={true}
                        value={editingFeedback}
                        placeholder="Question"
                        type="text"
                        onChange={(e) => {
                          setEditingFeedback(e.target.value);
                        }}
                      />
                    </ListItem>
                  </List>
                  <Divider className="my-4" />
                  <div className="px-4 py-3 d-flex flex-row align-items-center justify-content-between">
                    <div className="d-flex flex-row align-items-center">
                      <Button
                        variant="contained"
                        size="small"
                        className={"btn-dark shadow-none mt-2 py-2 mr-2"}
                        onClick={() => setEditingQuestions(null)}
                      >
                        <span
                          className="btn-wrapper--label"
                          style={{ fontSize: "12px" }}
                        >
                          {`Cancel`}
                        </span>
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        className={"btn-primary shadow-none mt-2 py-2"}
                        onClick={saveCurrentQuestions}
                        disabled={!editingQuestions.length}
                      >
                        <span
                          className="btn-wrapper--label"
                          style={{ fontSize: "12px" }}
                        >
                          {`Save Questions`}
                        </span>
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <List component="div" className="list-group-flush">
                    {englishQuestions.map((question, index) => (
                      <ListItem className="py-4 d-block" key={index}>
                        <div className="text-black">{question.question}</div>
                      </ListItem>
                    ))}
                    <ListItem className="py-4 mt-4 d-block">
                      <div
                        className="heading-4 text-left mb-1"
                        style={{
                          color: "#000",
                          textTransform: "none",
                          fontSize: "15px",
                        }}
                      >
                        Feedback Question
                      </div>
                      <div className="text-black">{feedbackEnglish}</div>
                    </ListItem>
                  </List>
                  <div className="py-3 px-4 d-flex flex-row align-items-center">
                    {schoolPlan === "Premium" ||
                    schoolPlan === "Demo - Premium" ? (
                      <div className="d-flex flex-row align-items-center mt-2">
                        <Button
                          variant="contained"
                          size="small"
                          className={"btn-primary shadow-none py-2 mr-2"}
                          onClick={() => {
                            setEditingQuestions(englishQuestions);
                            setEditingFeedback(feedbackEnglish);
                          }}
                        >
                          <span className="btn-wrapper--icon mr-1">
                            <FontAwesomeIcon
                              style={{ fontSize: "12px" }}
                              icon={["fas", "edit"]}
                            />
                          </span>
                          <span
                            className="btn-wrapper--label"
                            style={{ fontSize: "12px" }}
                          >
                            {`Edit Questions`}
                          </span>
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          className={"btn-neutral-dark shadow-none py-2"}
                          onClick={() => setResetDialog(true)}
                        >
                          <span className="btn-wrapper--icon mr-1">
                            <FontAwesomeIcon
                              style={{ fontSize: "12px" }}
                              icon={["fas", "trash"]}
                            />
                          </span>
                          <span
                            className="btn-wrapper--label"
                            style={{ fontSize: "12px" }}
                          >
                            {`Reset to Default`}
                          </span>
                        </Button>
                      </div>
                    ) : (
                      <Tooltip
                        title="Upgrade to premium to edit parent evaluation questions."
                        arrow
                        placement="right"
                      >
                        <div>
                          <Button
                            variant="contained"
                            size="small"
                            className={"btn-primary shadow-none mt-2 py-2"}
                            disabled={true}
                          >
                            <span className="btn-wrapper--icon mr-1">
                              <FontAwesomeIcon
                                style={{ fontSize: "12px" }}
                                icon={["fas", "edit"]}
                              />
                            </span>
                            <span
                              className="btn-wrapper--label"
                              style={{ fontSize: "12px" }}
                            >
                              {`Edit Questions`}
                            </span>
                          </Button>
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
        <Dialog
          open={resetDialog}
          onClose={handleResetClose}
          fullWidth={true}
          maxWidth={"sm"}
          PaperProps={{
            className: "p-3",
          }}
        >
          <DialogTitle
            style={{
              fontWeight: "bold",
              fontSize: "24px",
              color: "rgb(42, 42, 47)",
              borderBottom: "none",
            }}
          >
            {`Are you sure you want to reset questions to default?`}
          </DialogTitle>
          <DialogActions style={{ borderTop: "none" }} className="mt-4">
            <Button onClick={handleResetClose}>Cancel</Button>
            <Button
              className="btn-dark"
              onClick={() => resetQuestions()}
              autoFocus
            >
              {"Reset Questions"}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  ) : null;
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = {
  readSceneAction,
  updateSchoolAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolParentEvaluations);
