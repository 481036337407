import SchoolArchivedActivities from "../../components/SchoolArchivedActivities";

function SchoolArchivedActivitiesPage() {
  return (
    <>
      <SchoolArchivedActivities />
    </>
  );
}

export default SchoolArchivedActivitiesPage;
