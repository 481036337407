import React from "react";

import Login from "../../components/Login";
export default function LoginPage(props) {
  return (
    <>
      <Login {...props} />
    </>
  );
}
