import { put } from "redux-saga/effects";
import { VideoShort } from "../../Entities";
import { updateAppAction } from "../Redux";
import { VideoShortService } from "../../Services";
import {
  CreateVideoShortInteractor,
  UpdateVideoShortInteractor,
  DeleteVideoShortInteractor,
} from "../../UseCases";

export const CREATE_VIDEO_SHORT = "video_short/saga/create_video_short";
export const READ_VIDEO_SHORT = "video_short/saga/read_video_short";
export const UPDATE_VIDEO_SHORT = "video_short/saga/update_video_short";
export const DELETE_VIDEO_SHORT = "video_short/saga/delete_video_short";

interface VideoShortActionType {
  type: string;
  videoShort: VideoShort;
}

export const createVideoShortAction = (
  videoShort: VideoShort
): VideoShortActionType => ({
  type: CREATE_VIDEO_SHORT,
  videoShort,
});

export function* createVideoShortSaga(action: VideoShortActionType) {
  yield put(updateAppAction({ isLoading: true }));

  const { videoShort } = action;

  const videoShortService = new VideoShortService();
  const videoShortInteractor = new CreateVideoShortInteractor(
    videoShortService
  );

  try {
    yield videoShortInteractor.createVideoShort(videoShort);
    yield put(updateAppAction({ isLoading: false }));
  } catch (error) {
    // @ts-ignore
    yield put(updateAppAction({ isLoading: false, error }));
  }
}

export const updateVideoShortAction = (
  videoShort: VideoShort
): VideoShortActionType => ({
  type: UPDATE_VIDEO_SHORT,
  videoShort,
});

export function* updateVideoShortSaga(action: VideoShortActionType) {
  yield put(updateAppAction({ isLoading: true }));

  const { videoShort } = action;

  const videoShortService = new VideoShortService();
  const videoShortInteractor = new UpdateVideoShortInteractor(
    videoShortService
  );

  try {
    yield videoShortInteractor.updateVideoShort(videoShort);

    yield put(updateAppAction({ isLoading: false }));
  } catch (error) {
    // @ts-ignore
    yield put(updateAppAction({ isLoading: false, error }));
  }
}

export const deleteVideoShortAction = (
  videoShort: VideoShort
): VideoShortActionType => ({
  type: DELETE_VIDEO_SHORT,
  videoShort,
});

export function* deleteVideoShortSaga(action: VideoShortActionType) {
  yield put(updateAppAction({ isLoading: true }));

  const { videoShort } = action;

  const videoShortService = new VideoShortService();
  const videoShortInteractor = new DeleteVideoShortInteractor(
    videoShortService
  );

  try {
    yield videoShortInteractor.deleteVideoShort(videoShort);
    yield put(updateAppAction({ isLoading: false }));
  } catch (error) {
    // @ts-ignore
    yield put(updateAppAction({ isLoading: false, error }));
  }
}
