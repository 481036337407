import { Email } from "./Email";

export class Credential {
  readonly email: Email;
  readonly password: string;

  constructor(email: string, password: string) {
    if (this.isInvalidPassword(password)) {
      throw new Error("Invalid email or password.");
    }

    this.email = new Email({ address: email });
    this.password = password;
  }

  private isInvalidPassword(password: string): boolean {
    const passwordRegex = /^[\w\d\S]{8,}$/g;
    return !passwordRegex.test(password);
  }
}
