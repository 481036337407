import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CircleLoader } from "react-spinners";
import {
  sceneSelector,
  appSelector,
  userSelector,
  readSceneAction,
  ParentPartiesAPIs,
} from "../../core";
import {
  Grid,
  ListItem,
  List,
  Card,
  LinearProgress,
  CardContent,
} from "@material-ui/core";
import { NavLink, useLocation } from "react-router-dom";
import Chart from "react-apexcharts";

// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function SchoolGuardiansAnalytics(props) {
  const location = useLocation();
  const { pathname } = location;
  const params = pathname.split("/");
  const sceneType = params[2];
  const sceneId = params[3];

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [initialLoad, setInitialLoad] = useState(true);
  const [analytics, setAnalytics] = useState(null);

  useEffect(() => {
    async function getGuardians(sceneId) {
      const result = await ParentPartiesAPIs.schoolGuardianAnalytics(sceneId);

      setAnalytics(result);
      setInitialLoad(false);
    }
    if (props.scene) {
      if (props.scene.id) {
        getGuardians(props.scene.id);
      }
    }
  }, [props.scene]);

  return !props.user ? null : props.scene ? (
    initialLoad || props.app.isLoading ? (
      <Grid container className="my-spacing-6">
        <Grid item xs={12}>
          <div className="d-flex flex-row justify-content-center">
            <CircleLoader
              className="d-flex align-self-center"
              color={"#18a9a9"}
              loading={true}
              size={40}
            />
          </div>
        </Grid>
      </Grid>
    ) : (
      <>
        <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
          <Grid item md={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <div
                className="font-weight-bold display-4"
                style={{ color: "rgb(43, 39, 60)" }}
              >
                Reporting
              </div>
            </div>
          </Grid>
          <Grid item md={12} xl={12}>
            {mobile ? null : (
              <List
                component="div"
                className="nav-line-alt nav-line d-flex align-items-center mt-3"
              >
                <ListItem button disableRipple className="px-2" selected>
                  <span className="font-size-sm">Guardians</span>
                  <div className="divider" />
                </ListItem>
                <ListItem
                  button
                  disableRipple
                  className="px-2"
                  component={NavLink}
                  to={`/activity-analytics/${sceneType}/${sceneId}`}
                >
                  <span className="font-size-sm">Activities</span>
                  <div className="divider" />
                </ListItem>
                <ListItem
                  button
                  disableRipple
                  className="px-2"
                  component={NavLink}
                  to={`/evaluation-analytics/${sceneType}/${sceneId}`}
                >
                  <span className="font-size-sm">Parent Evaluations</span>
                  <div className="divider" />
                </ListItem>
                <ListItem
                  button
                  disableRipple
                  className="px-2"
                  component={NavLink}
                  to={`/weekly-scorecard-analytics/${sceneType}/${sceneId}`}
                >
                  <span className="font-size-sm">Weekly Scorecards</span>
                  <div className="divider" />
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  to={`/monthly-analytics/${sceneType}/${sceneId}`}
                  className="pr-2"
                >
                  <span className="font-size-sm">{"Monthly Analytics"}</span>
                  <div className="divider" />
                </ListItem>
                <ListItem
                  button
                  className="pr-2"
                  component={NavLink}
                  to={`/analytics/${sceneType}/${sceneId}`}
                >
                  <span className="font-size-sm">{"Reports"}</span>
                  <div className="divider" />
                </ListItem>
              </List>
            )}
          </Grid>
          <Grid item md={12}>
            <div
              style={{
                color: "#000",
                fontSize: "16px",
                lineHeight: "16px",
                fontWeight: "bold",
                marginTop: "3.5rem",
                marginBottom: "2rem",
              }}
            >
              Guardian Analytics
            </div>
            <Grid container spacing={4} alignItems="stretch" direction={"row"}>
              <Grid item md={8}>
                <Card className="card-box">
                  <div className="card-header">
                    <div className="card-header--title">
                      <h4 className="font-size-sm mb-0 py-2 font-weight-bold text-black">
                        Race/ethnicity
                      </h4>
                    </div>
                  </div>
                  <CardContent>
                    <div className="d-flex justify-content-center">
                      <Chart
                        options={{
                          dataLabels: {
                            enabled: false,
                          },
                          plotOptions: {
                            pie: {
                              donut: {
                                labels: {
                                  show: true,
                                  total: {
                                    show: true,
                                    showAlways: true,
                                    color: "#000",
                                    fontFamily: "Heebo",
                                  },
                                },
                              },
                            },
                          },
                          colors: [
                            "#008FFB",
                            "#00E396",
                            "#FEB019",
                            "#3F51B5",
                            "#775DD0",
                            "#A5978B",
                            "#546E7A",
                            "#FF4560",
                          ],
                          labels: analytics
                            ? analytics.ethnicity
                              ? analytics.ethnicity.map((item) => item.label)
                              : []
                            : [],
                          legend: {
                            show: true,
                            position: "right",
                            formatter: function (seriesName, opts) {
                              return `${seriesName} (${
                                opts.w.globals.series[opts.seriesIndex]
                              })`;
                            },
                          },
                        }}
                        series={
                          analytics
                            ? analytics.ethnicity
                              ? analytics.ethnicity.map((item) => item.amount)
                              : []
                            : []
                        }
                        type="donut"
                        width="600"
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4}>
                <Card className="card-box" style={{ height: "100%" }}>
                  <div className="card-header">
                    <div className="card-header--title">
                      <h4 className="font-size-sm mb-0 py-2 font-weight-bold text-black">
                        Relationship to child
                      </h4>
                    </div>
                  </div>
                  <CardContent className="px-0">
                    <div>
                      <List component="div" className="list-group-flush">
                        {analytics
                          ? analytics.relationship
                            ? analytics.relationship.map((item) => (
                                <ListItem className="py-3" key={item.label}>
                                  <Grid container spacing={0}>
                                    <Grid
                                      item
                                      md={12}
                                      className="d-flex align-items-center"
                                    >
                                      <div className="d-flex align-items-center">
                                        <div>
                                          <div
                                            className="text-black"
                                            style={{ fontSize: "12px" }}
                                          >
                                            {item.label}
                                          </div>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid
                                      item
                                      md={8}
                                      className="pt-xl-0 d-flex align-items-center"
                                    >
                                      <div className="align-box-row flex-grow-1">
                                        <div className="d-flex flex-row flex-grow-1">
                                          <LinearProgress
                                            variant="determinate"
                                            className="progress-sm progress-bar-rounded progress-bar-primary w-100"
                                            value={
                                              (item.amount /
                                                analytics.relationship
                                                  .map((item) => item.amount)
                                                  .reduce((a, b) => a + b, 0)) *
                                              100
                                            }
                                          />
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid
                                      item
                                      md={4}
                                      className="pt-xl-0 d-flex align-items-center"
                                    >
                                      <div className="d-flex justify-content-between text-dark">
                                        <div className="ml-4">
                                          <div style={{ fontSize: "12px" }}>
                                            {item.amount}
                                          </div>
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </ListItem>
                              ))
                            : null
                          : null}
                      </List>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={12}>
                <Card className="card-box">
                  <div className="card-header">
                    <div className="card-header--title">
                      <h4 className="font-size-sm mb-0 py-2 font-weight-bold text-black">
                        Grade levels
                      </h4>
                    </div>
                  </div>
                  <CardContent>
                    <div>
                      <Chart
                        options={{
                          chart: {
                            toolbar: {
                              show: false,
                            },
                            sparkline: {
                              enabled: false,
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          colors: ["#1A9A9A"],
                          grid: {
                            strokeDashArray: "5",
                            borderColor: "rgba(125, 138, 156, 0.3)",
                          },
                          stroke: {
                            show: true,
                            width: 2,
                            colors: ["transparent"],
                          },
                          legend: {
                            show: false,
                          },
                          labels: analytics
                            ? analytics.gradeLevel
                              ? analytics.gradeLevel.map((item) => item.label)
                              : []
                            : [],
                        }}
                        series={
                          analytics
                            ? analytics.gradeLevel
                              ? [
                                  {
                                    name: "Guardians",
                                    data: analytics.gradeLevel.map(
                                      (item) => item.amount
                                    ),
                                  },
                                ]
                              : []
                            : []
                        }
                        type="bar"
                        height={280}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={12}>
                <Card className="card-box">
                  <div className="card-header">
                    <div className="card-header--title">
                      <h4 className="font-size-sm mb-0 py-2 font-weight-bold text-black">
                        Language
                      </h4>
                    </div>
                  </div>
                  <CardContent>
                    <div>
                      <Chart
                        options={{
                          chart: {
                            toolbar: {
                              show: false,
                            },
                            sparkline: {
                              enabled: false,
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          colors: ["#1A9A9A"],
                          grid: {
                            strokeDashArray: "5",
                            borderColor: "rgba(125, 138, 156, 0.3)",
                          },
                          stroke: {
                            show: true,
                            width: 2,
                            colors: ["transparent"],
                          },
                          legend: {
                            show: false,
                          },
                          labels: analytics
                            ? analytics.language
                              ? analytics.language.map((item) => item.label)
                              : []
                            : [],
                        }}
                        series={
                          analytics
                            ? analytics.language
                              ? [
                                  {
                                    name: "Guardians",
                                    data: analytics.language.map(
                                      (item) => item.amount
                                    ),
                                  },
                                ]
                              : []
                            : []
                        }
                        type="bar"
                        height={280}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  ) : null;
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = {
  readSceneAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolGuardiansAnalytics);
