import { Database } from "./Firebase";
import {
  uploadVideoShortThumbnail,
  uploadVideoShortVideo,
} from "./ParentPartiesAPIs";
import { VideoShort, VideoShortRecord } from "../../Entities";
const moment = require("moment");

const videoShortsRef = Database.ref(`videoShorts`);

export const createVideoShort = async (
  videoShort: VideoShort
): Promise<VideoShort> => {
  /* Remove tempId */
  delete (videoShort as any).tempId;

  /* Removed undefined */
  const removeEmpty = (obj: VideoShortRecord) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === "object") removeEmpty(obj[key]);
      // recurse
      else if (obj[key] == null) delete obj[key]; // delete
    });
  };
  removeEmpty(videoShort);

  const videoShortRef = await videoShortsRef.push();
  (videoShort as any).id = videoShortRef.key;

  videoShort.lastUpdated = moment().valueOf();

  await videoShortRef.set(videoShort);

  // Thumbnail
  if (videoShort.thumbnail) {
    if (
      videoShort.thumbnail instanceof File ||
      videoShort.thumbnail instanceof Blob ||
      videoShort.thumbnail._data
    ) {
      let videoThumbnail = videoShort.thumbnail;
      const videoThumbnailUrl = await uploadVideoShortThumbnail(
        videoShort.id as string,
        videoThumbnail
      );
      videoShort.thumbnail = videoThumbnailUrl;
    }
  }

  // Video
  if (videoShort.video) {
    if (
      videoShort.video instanceof File ||
      videoShort.video instanceof Blob ||
      videoShort.video._data
    ) {
      let videoShortVideo = videoShort.video;
      const videoShortVideoUrl = await uploadVideoShortVideo(
        videoShort.id as string,
        videoShortVideo
      );
      videoShort.video = videoShortVideoUrl;
    }
  }

  await videoShortRef.update(videoShort);

  const videoShortSnapshot = await new Promise<any>((resolve) =>
    videoShortRef.once("value", resolve)
  );
  const data = videoShortSnapshot.val() as any;

  return data;
};

export const readVideoShort = async (
  videoShortId: string
): Promise<VideoShort> => {
  const videoShortRef = videoShortsRef.child(videoShortId);
  const videoShortSnapshot = await new Promise<any>((resolve) =>
    videoShortRef.once("value", resolve)
  );
  const data = videoShortSnapshot.val() as any;

  return new VideoShort(data);
};

export const updateVideoShort = async (
  videoShort: VideoShort
): Promise<VideoShort> => {
  if (!videoShort.id) {
    throw new Error("Invalid activity ID");
  }

  /* Removed undefined */
  const removeEmpty = (obj: VideoShortRecord) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === "object") removeEmpty(obj[key]);
      // recurse
      else if (obj[key] == null) delete obj[key]; // delete
    });
  };
  removeEmpty(videoShort);

  // Thumbnail
  if (videoShort.thumbnail) {
    if (
      videoShort.thumbnail instanceof File ||
      videoShort.thumbnail instanceof Blob ||
      videoShort.thumbnail._data
    ) {
      let videoShortThumbnail = videoShort.thumbnail;
      const videoThumbnailUrl = await uploadVideoShortThumbnail(
        videoShort.id as string,
        videoShortThumbnail
      );
      videoShort.thumbnail = videoThumbnailUrl;
    }
  }

  // Video
  if (videoShort.video) {
    if (
      videoShort.video instanceof File ||
      videoShort.video instanceof Blob ||
      videoShort.video._data
    ) {
      let videoShortVideo = videoShort.video;
      const videoShortVideoUrl = await uploadVideoShortVideo(
        videoShort.id as string,
        videoShortVideo
      );
      videoShort.video = videoShortVideoUrl;
    }
  }

  videoShort.lastUpdated = moment().valueOf();

  const videoShortRef = videoShortsRef.child(videoShort.id);
  await videoShortRef.update(videoShort);

  const videoShortSnapshot = await new Promise<any>((resolve) =>
    videoShortRef.once("value", resolve)
  );

  const data = videoShortSnapshot.val() as any;

  return data;
};

export const deleteVideoShort = async (
  videoShort: VideoShort
): Promise<void> => {
  const videoShortRef = videoShortsRef.child(videoShort.id as string);
  videoShortRef.remove();
};

export const readAllAdminVideoShorts = async (): Promise<any> => {
  try {
    let videoShorts = await Database.ref(`videoShorts`)
      .orderByChild("allSchools")
      .equalTo(true)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val();
        return snapshotVal ? Object.values(snapshotVal) : [];
      });
    return videoShorts;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const deleteSubmittedVideoShort = async (
  videoShort: VideoShort
): Promise<void> => {
  const submittedVideoShortRef = Database.ref("submittedVideoShorts").child(
    videoShort.id as string
  );
  submittedVideoShortRef.remove();
};

export const readAllAdminSubmittedVideoShorts = async (): Promise<any> => {
  try {
    let videoShorts = await Database.ref(`submittedVideoShorts`)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val();
        return snapshotVal ? Object.values(snapshotVal) : [];
      });
    return videoShorts;
  } catch (err) {
    console.error(err);
    return [];
  }
};
