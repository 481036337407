import { Invite, School, District } from "../Entities";

export interface InviteDBService {
  inviteToItem: (invite: Invite) => Promise<void>;
  readInvite: (inviteId: string) => Promise<Invite | null>;
  acceptSchoolInvite: (inviteId: string) => Promise<School | null>;
  acceptDistrictInvite: (inviteId: string) => Promise<District | null>;
  cancelInvite: (
    inviteId: string,
    itemType: "school" | "district",
    itemId: string | number
  ) => Promise<void>;
}

class InviteUserCase {
  inviteService: InviteDBService;

  constructor(inviteService: InviteDBService) {
    this.inviteService = inviteService;
  }
}

export class InviteToItemInteractor extends InviteUserCase {
  async inviteToItem(invite: Invite): Promise<void> {
    return this.inviteService.inviteToItem(invite);
  }
}

export class ReadInviteInteractor extends InviteUserCase {
  async readInvite(inviteId: string): Promise<Invite | null> {
    return this.inviteService.readInvite(inviteId);
  }
}

export class AcceptInviteSchoolInteractor extends InviteUserCase {
  async acceptSchoolInvite(inviteId: string): Promise<School | null> {
    return this.inviteService.acceptSchoolInvite(inviteId);
  }
}

export class AcceptInviteDistrictInteractor extends InviteUserCase {
  async acceptDistrictInvite(inviteId: string): Promise<District | null> {
    return this.inviteService.acceptDistrictInvite(inviteId);
  }
}

export class CancelInviteInteractor extends InviteUserCase {
  async cancelInvite(
    inviteId: string,
    itemType: "school" | "district",
    itemId: string | number
  ): Promise<void> {
    return this.inviteService.cancelInvite(inviteId, itemType, itemId);
  }
}
