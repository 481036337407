import SchoolPushNotification from "../../components/SchoolPushNotification";

function SchoolPushNotificationPage() {
  return (
    <>
      <SchoolPushNotification />
    </>
  );
}

export default SchoolPushNotificationPage;
