import { Email, EmailRecord } from "./Email";
import { School, SchoolRecord } from "./School";
import { District, DistrictRecord } from "./District";

export interface SchoolUserRecord {
  id?: string;
  firstName: string;
  lastName?: string;
  email: EmailRecord;
  emailVerified: boolean;
  adminSupport?: boolean;
  stripeCustomerId?: string;
  schools?: { [schoolId: string]: SchoolRecord | boolean };
  districts?: { [districtId: string]: DistrictRecord | boolean };
  emailSettings?: {
    [schoolId: string]: {
      id: string;
      analytics?: string;
    };
  };
}

export class SchoolUser {
  readonly id?: string;
  readonly firstName: string;
  readonly lastName?: string;
  readonly email: Email;
  emailVerified: boolean;
  adminSupport?: boolean;
  readonly stripeCustomerId?: string;
  readonly emailSettings?: {
    [schoolId: string]: {
      id: string;
      analytics?: string;
    };
  };
  readonly schools: { [schoolId: string]: School };
  readonly districts?: { [districtId: string]: District };

  constructor(user: SchoolUserRecord) {
    const {
      id,
      firstName,
      lastName,
      email,
      emailVerified,
      adminSupport,
      stripeCustomerId,
      emailSettings,
      schools,
      districts,
    } = user;
    if (this.isEmptyOrNull(firstName)) {
      throw new Error("First and last name cannot be empty");
    }

    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = new Email(email);
    this.emailVerified = emailVerified;
    this.adminSupport = adminSupport;
    this.stripeCustomerId = stripeCustomerId;
    this.emailSettings = emailSettings;
    this.schools = Object.values(
      (schools as { [venueId: number]: SchoolRecord }) || {}
    ).reduce(
      (store, schoolRecord) =>
        schoolRecord.id
          ? { ...store, [schoolRecord.id]: new School(schoolRecord) }
          : store,
      {}
    );
    this.districts = Object.values(
      (districts as { [districtId: number]: DistrictRecord }) || {}
    ).reduce(
      (store, districtRecord) =>
        districtRecord.id
          ? { ...store, [districtRecord.id]: new District(districtRecord) }
          : store,
      {}
    );
  }

  get name(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get emailAddress(): string {
    return this.email.address;
  }

  private isEmptyOrNull(str: string): boolean {
    return !str || str.trim().length === 0;
  }
}

export enum POSITION {
  Admin = "Admin",
  Owner = "Owner",
  Manager = "Manager",
}

export interface EmployeeRecord extends SchoolUserRecord {
  position: POSITION;
}

export class Employee extends SchoolUser {
  readonly position: POSITION;

  constructor(employee: EmployeeRecord) {
    super(employee);

    const { position } = employee;

    this.position = position;
  }
}
