export interface EmailRecord {
  address: string;
}

export class Email {
  readonly address: string;

  constructor(email: EmailRecord) {
    const { address } = email;
    if (this.isInvalidAddress(address)) {
      throw new Error(`Invalid email address${address ? `: ${address}` : "."}`);
    }

    this.address = address;
  }

  private isInvalidAddress(address: string): boolean {
    const validAddressRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !validAddressRegex.test(address);
  }
}
