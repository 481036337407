import React from "react";

import Register from "../../components/Register";
export default function RegisterPage(props) {
  return (
    <>
      <Register {...props} />
    </>
  );
}
