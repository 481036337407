import { initialState, StateType } from "./State";

export type AppState = {
  isLoading?: boolean;
  error?: Error | null;
  venueLoading?: boolean | null;
  dateRange?: any[];
  authChecked?: boolean;
};

const UPDATE_APP_STATE = "app/update";

type StateSlice = StateType["app"];

export interface UpdateAppActionType {
  type: string;
  app: AppState;
}

export const appSelector = (state: StateType): StateSlice => state.app;
export const updateAppAction = (app: AppState): UpdateAppActionType => {
  return {
    type: UPDATE_APP_STATE,
    app,
  };
};

const updateHandler = (
  state: StateSlice,
  action: UpdateAppActionType
): StateType["app"] => {
  return {
    ...state,
    ...action.app,
  };
};

export const appReducer = (
  app: StateSlice = initialState.app,
  action: UpdateAppActionType
): StateSlice => {
  switch (action.type) {
    case UPDATE_APP_STATE:
      return updateHandler(app, action);
    default:
      return app;
  }
};
