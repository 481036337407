import {
  setPageTitleStyle,
  setPageTitleBackground,
  setPageTitleShadow,
  setPageTitleIconBox,
  setPageTitleDescription,
  setFooterBgTransparent,
  setFooterShadow,
  setFooterFixed,
  setThemeConfiguratorToggle,
  setContentBackground,
  setHeaderDrawerToggle,
  setHeaderSearchHover,
  setHeaderBgTransparent,
  setHeaderShadow,
  setHeaderFixed,
  setSidebarUserbox,
  setSidebarToggle,
  setSidebarToggleMobile,
  setSidebarFooter,
  setSidebarStyle,
  setSidebarFixed,
  setSidebarShadow,
} from "../Redux";
import { put } from "redux-saga/effects";

export const setSidebarShadowSaga = (sidebarShadow) => {
  put(setSidebarShadow(sidebarShadow));
};
export const setSidebarFixedSaga = (sidebarFixed) => {
  put(setSidebarFixed(sidebarFixed));
};
export const setSidebarStyleSaga = (sidebarStyle) => {
  put(setSidebarStyle(sidebarStyle));
};
export const setSidebarFooterSaga = (sidebarFooter) => {
  put(setSidebarFooter(sidebarFooter));
};
export const setSidebarToggleMobileSaga = (sidebarToggleMobile) => {
  put(setSidebarToggleMobile(sidebarToggleMobile));
};
export const setSidebarToggleSaga = (sidebarToggle) => {
  put(setSidebarToggle(sidebarToggle));
};
export const setSidebarUserboxSaga = (sidebarUserbox) => {
  put(setSidebarUserbox(sidebarUserbox));
};

// Header
export const setHeaderFixedSaga = (headerFixed) => {
  put(setHeaderFixed(headerFixed));
};
export const setHeaderShadowSaga = (headerShadow) => {
  put(setHeaderShadow(headerShadow));
};
export const setHeaderBgTransparentSaga = (headerBgTransparent) => {
  put(setHeaderBgTransparent(headerBgTransparent));
};
export const setHeaderSearchHoverSaga = (headerSearchHover) => {
  put(setHeaderSearchHover(headerSearchHover));
};
export const setHeaderDrawerToggleSaga = (headerDrawerToggle) => {
  put(setHeaderDrawerToggle(headerDrawerToggle));
};

// Main content
export const setContentBackgroundSaga = (contentBackground) => {
  put(setContentBackground(contentBackground));
};
export const setThemeConfiguratorToggleSaga = (themeConfiguratorToggle) => {
  put(setThemeConfiguratorToggle(themeConfiguratorToggle));
};

// Footer
export const setFooterFixedSaga = (footerFixed) => {
  put(setFooterFixed(footerFixed));
};
export const setFooterShadowSaga = (footerShadow) => {
  put(setFooterShadow(footerShadow));
};
export const setFooterBgTransparentSaga = (footerBgTransparent) => {
  put(setFooterBgTransparent(footerBgTransparent));
};

// Page title
export const setPageTitleStyleSaga = (pageTitleStyle) => {
  put(setPageTitleStyle(pageTitleStyle));
};
export const setPageTitleBackgroundSaga = (pageTitleBackground) => {
  put(setPageTitleBackground(pageTitleBackground));
};
export const setPageTitleShadowSaga = (pageTitleShadow) => {
  put(setPageTitleShadow(pageTitleShadow));
};
export const setPageTitleIconBoxSaga = (pageTitleIconBox) => {
  put(setPageTitleIconBox(pageTitleIconBox));
};
export const setPageTitleDescriptionSaga = (pageTitleDescription) => {
  put(setPageTitleDescription(pageTitleDescription));
};
