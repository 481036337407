import { put } from "redux-saga/effects";
import { toggleCheckoutStateAction } from "../Redux";

export const TOGGLE_CHECKOUT = "checkout/saga/toggle_checkout";

interface CheckoutActionType {
  type: string;
}

export const toggleCheckoutAction = (): CheckoutActionType => ({
  type: TOGGLE_CHECKOUT,
});

export function* toggleCheckoutSaga() {
  yield put(toggleCheckoutStateAction());
}
