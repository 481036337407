import { StateType, initialState } from "./State";

const UPDATE_GRID = "grid/update";

type StateSlice = StateType["grid"];

export interface GridUpdateActionType {
  type: string;
  params: any;
}

export const gridSelector = (state: StateType): StateSlice => state.grid;
export const updateGridStateAction = (params: any): GridUpdateActionType => ({
  type: UPDATE_GRID,
  params,
});

const updateHandler = (params: any): StateType["grid"] => {
  return params;
};

export const gridReducer = (
  grid: StateSlice = initialState.grid,
  action: GridUpdateActionType
): StateSlice => {
  switch (action.type) {
    case UPDATE_GRID:
      return updateHandler(action.params ? action.params : null);
    default:
      return grid;
  }
};
