import React, { useState, useEffect } from "react";
import Bugsnag from "@bugsnag/js";
import { connect } from "react-redux";
import {
  VideoShort,
  appSelector,
  createVideoShortAction,
  updateVideoShortAction,
} from "../../core";
import clsx from "clsx";
import { Prompt } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Card, Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import { CircleLoader } from "react-spinners";

/// Handle Google Autocomplete
import { makeStyles } from "@material-ui/core/styles";

// Responsive //
import useMediaQuery from "@material-ui/core/useMediaQuery";

import VideoShortDetails from "./videoShortDetails";

function getSteps() {
  return ["Details", "Preview"];
}

function getStepContent(step, props) {
  switch (step) {
    case 0:
      return <Step1 {...props} />;
    case 1:
      return <Step2 {...props} />;
    default:
      return <Step1 {...props} />;
  }
}

const Step1 = (props) => {
  const steps = getSteps();
  const { location } = props;
  return (
    <>
      <div className={"py-4"}>
        <h5 className="font-size-xl mb-2 font-weight-bold px-4">
          {location.type === "add" ? "Add Video Short" : "Edit Video Short"}
        </h5>
        <div className="my-4">
          <Stepper
            className="stepper-horizontal-1"
            style={{ background: "rgba(69, 170, 170, 0.07)" }}
            activeStep={0}
            connector={
              <span className="mx-3">
                <FontAwesomeIcon
                  icon={["fas", "chevron-right"]}
                  color={"rgba(0, 0, 0, 0.51)"}
                />
              </span>
            }
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <VideoShortDetails {...props} />
      </div>
    </>
  );
};

const Step2 = (props) => {
  const steps = getSteps();
  const { location } = props;
  return (
    <>
      <div className={"py-4"}>
        <h5 className="font-size-xl mb-2 font-weight-bold px-4">
          {location.type === "add" ? "Add Video Short" : "Edit Video Short"}
        </h5>
        <div className="my-4">
          <Stepper
            className="stepper-horizontal-1"
            style={{ background: "rgba(69, 170, 170, 0.07)" }}
            activeStep={1}
            connector={
              <span className="mx-3">
                <FontAwesomeIcon
                  icon={["fas", "chevron-right"]}
                  color={"rgba(0, 0, 0, 0.51)"}
                />
              </span>
            }
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <VideoShortDetails {...props} preview={true} />
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  noBoxShadow: {
    boxShadow: "none !important",
  },
  selectStyles: {
    fontSize: "14px",
    background: "white",
    "&:focus": {
      backgroundColor: "white !important",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "400px",
    },
  },
  fixInput: {
    borderWidth: "1px !important",
    "& $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
    "&:hover $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
    "&$focused $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
  },
  focused: {},
  notchedOutline: {},
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "4px",
    color: theme.palette.grey[500],
    padding: "2px",
  },
}));

function AddVideoShort(props) {
  const classes = useStyles();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [videoName, setVideoName] = useState("");
  const [information, setInformation] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [video, setVideo] = useState(null);

  // Create and Update handle //
  const [error, setError] = useState("");
  const [videoLoading, setVideoLoading] = useState(false);

  /// End functions handle
  const [formErrors, setFormErrors] = useState({
    name: false,
    information: false,
    thumbnail: false,
    video: false,
  });
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const themeCSS = useTheme();
  const mobile = useMediaQuery(themeCSS.breakpoints.down("sm"));

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [activeStep]);

  useEffect(() => {
    if (props.location.type === "edit" && props.location.videoData) {
      const { videoData } = props.location;

      // En
      setVideoName(videoData.name);
      setInformation(videoData.information);
      setThumbnail(videoData.thumbnail ? videoData.thumbnail : null);
      setVideo(videoData.video ? videoData.video : null);
    }
  }, [props.location]);

  const finishVideoShort = () => {
    try {
      const videoData = props.location.videoData;

      setUnsavedChanges(false);

      if (props.location.type === "edit") {
        onUpdateVideoShort(
          new VideoShort({
            ...videoData,
            // En
            name: videoName,
            information,
            thumbnail,
            video,
          })
        );
      } else {
        onCreateVideoShort(
          new VideoShort({
            allSchools: true,
            points: 1,
            // En
            name: videoName,
            information,
            thumbnail,
            video,
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onCreateVideoShort = (videoShort) => {
    if (videoLoading) {
      return;
    }
    try {
      props.dispatchCreateVideoShort(videoShort);
    } catch (err) {
      setError(err.toString());
    }
  };

  const onUpdateVideoShort = (videoShort) => {
    if (videoLoading) {
      return;
    }
    try {
      props.dispatchUpdateVideoShort(videoShort);
    } catch (err) {
      setError(err.toString());
    }
  };

  useEffect(() => {
    if (videoLoading) {
      if (!props.app.isLoading) {
        if (props.app.error) {
          Bugsnag.notify(props.app.error);
          setError(
            videoLoading === "update"
              ? `Could not update video short. Error: ${props.app.error.message}`
              : `Could not add video short. Error: ${props.app.error.message}`
          );
          setVideoLoading(false);
        } else {
          setError("");
          setVideoLoading(false);
        }
      }
    }
  }, [props.app.error, props.app.isLoading, setVideoLoading]);

  const handleNext = () => {
    /// Check Form Per Step ///
    let errors = {
      name: false,
      information: false,
      thumbnail: false,
      video: false,
    };
    if (!videoName) {
      errors.name = true;
    }
    if (!information) {
      errors.information = true;
    }
    if (!thumbnail) {
      errors.thumbnail = true;
    }
    if (!video) {
      errors.video = true;
    }
    if (errors.name || errors.information || errors.thumbnail || errors.video) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      return setFormErrors(errors);
    } else {
      setFormErrors({
        name: false,
        information: false,
        thumbnail: false,
        video: false,
      });
    }

    // Last Step run functions //
    if (activeStep === steps.length - 1) {
      // Run Loader
      setVideoLoading(props.location.type === "add" ? "create" : "update");
      finishVideoShort();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
      <Prompt
        when={unsavedChanges}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <Grid container spacing={4}>
        <Grid item md={12} xl={12}>
          <div
            className="d-flex flex-row justify-content-between align-items-center"
            style={{ height: "52px" }}
          >
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              {"Admin Video Shorts"}
            </div>
          </div>
        </Grid>
        <Grid item md={12}>
          <Card
            style={{
              borderRadius: "0.25rem",
              border: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <div>
              {activeStep === steps.length ? (
                <div className="text-center p-5">
                  {videoLoading ? (
                    <>
                      <div className="avatar-icon-wrapper rounded-circle m-0">
                        <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper m-0 d-50">
                          <CircleLoader
                            className="d-flex align-self-center"
                            color={"#18a9a9"}
                            loading={true}
                            size={50}
                          />
                        </div>
                      </div>
                      <h4 className="font-weight-bold mt-4">{`${
                        props.location.type === "add"
                          ? "Adding video short."
                          : "Updating video short."
                      }`}</h4>
                    </>
                  ) : (
                    <>
                      <div className="avatar-icon-wrapper rounded-circle m-0">
                        <div
                          className={clsx(
                            "d-inline-flex",
                            "justify-content-center",
                            "p-0",
                            "rounded-circle",
                            "btn-icon",
                            "avatar-icon-wrapper",
                            "m-0",
                            "d-130",
                            error ? "bg-neutral-danger" : "bg-neutral-success",
                            error ? "text-danger" : "text-success"
                          )}
                        >
                          <FontAwesomeIcon
                            icon={["far", "lightbulb"]}
                            className="d-flex align-self-center display-3"
                          />
                        </div>
                      </div>
                      <h4 className="font-weight-bold mt-4">
                        {error
                          ? error
                          : `Video short has been successfully ${
                              props.location.type === "add"
                                ? "added"
                                : "updated"
                            }!`}
                      </h4>
                      <div className="pt-4">
                        {error ? (
                          <Button
                            onClick={handleReset}
                            className="btn-primary font-weight-bold rounded hover-scale-lg mx-1"
                            size="large"
                          >
                            <span className="btn-wrapper--label">
                              Back to Form
                            </span>
                          </Button>
                        ) : (
                          <NavLink to={`/admin-video-shorts`}>
                            <Button
                              className="btn-primary font-weight-bold rounded hover-scale-lg mx-1"
                              size="large"
                            >
                              <span className="btn-wrapper--label">
                                Back to Activities
                              </span>
                            </Button>
                          </NavLink>
                        )}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div>
                  <div>
                    {getStepContent(activeStep, {
                      mobile,
                      classes,
                      setUnsavedChanges,
                      formErrors,
                      setFormErrors,
                      location: props.location,
                      handleNext,
                      // English
                      videoName,
                      setVideoName,
                      information,
                      setInformation,
                      thumbnail,
                      setThumbnail,
                      video,
                      setVideo,
                    })}
                  </div>
                  <div className="card-footer mt-4 p-4 d-flex align-items-center justify-content-between bg-secondary">
                    <Button
                      variant="text"
                      className={
                        activeStep === 0
                          ? "btn-pill m-2"
                          : "btn-pill m-2 btn-outline-primary"
                      }
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Back
                    </Button>
                    <Button
                      className="btn-pill btn-primary font-weight-bold"
                      onClick={handleNext}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
});
const mapDispatchToProps = {
  dispatchCreateVideoShort: createVideoShortAction,
  dispatchUpdateVideoShort: updateVideoShortAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddVideoShort);
