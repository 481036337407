import React from "react";
import AdminResources from "../../components/AdminResources";

function AdminResourcesPage() {
  return (
    <>
      <AdminResources />
    </>
  );
}

export default AdminResourcesPage;
