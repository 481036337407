import { all, put } from "redux-saga/effects";
import { Activity, School, Scene } from "../../Entities";
import { updateAppAction, updateSceneStateAction } from "../Redux";
import { ActivityService, SchoolService } from "../../Services";
import {
  CreateActivityInteractor,
  UpdateActivityInteractor,
  DeleteActivityInteractor,
  ReadSchoolInteractor,
} from "../../UseCases";

export const CREATE_ACTIVITY = "activity/saga/create_activity";
export const CREATE_DISTRICT_ACTIVITY =
  "activity/saga/create_district_activity";
export const READ_ACTIVITY = "activity/saga/read_activity";
export const UPDATE_ACTIVITY = "activity/saga/update_activity";
export const DELETE_ACTIVITY = "activity/saga/delete_activity";

interface ActivityActionType {
  type: string;
  activity: Activity;
  themeChoice?: any;
}

export const createActivityAction = (
  activity: Activity,
  themeChoice?: any
): ActivityActionType => ({
  type: CREATE_ACTIVITY,
  activity,
  themeChoice,
});

export function* createActivitySaga(action: ActivityActionType) {
  yield put(updateAppAction({ isLoading: true }));

  const { activity, themeChoice } = action;

  const activityService = new ActivityService();
  const activityInteractor = new CreateActivityInteractor(activityService);
  const schoolService = new SchoolService();
  const schoolInteractor = new ReadSchoolInteractor(schoolService);

  try {
    const newActivity: Activity = yield activityInteractor.createActivity(
      activity,
      themeChoice
    );
    // Activity Added to Specific School
    if (!activity.allSchools) {
      const updatedSchool: School = yield schoolInteractor.readSchool(
        newActivity.schoolId as string
      );

      const updatedScene: Scene = {
        id: updatedSchool.id,
        type: "school",
        data: updatedSchool,
      };

      yield all([
        put(updateSceneStateAction(updatedScene)),
        put(updateAppAction({ isLoading: false })),
      ]);
    }
    // Alls Schools Activity
    else {
      yield put(updateAppAction({ isLoading: false }));
    }
  } catch (error) {
    // @ts-ignore
    yield put(updateAppAction({ isLoading: false, error }));
  }
}

interface DistrictActivityActionType {
  type: string;
  activity: Activity;
  schoolIds: any;
}

export const createDistrictActivityAction = (
  schoolIds: any,
  activity: Activity
): DistrictActivityActionType => ({
  type: CREATE_DISTRICT_ACTIVITY,
  schoolIds,
  activity,
});

export function* createDistrictActivitySaga(
  action: DistrictActivityActionType
) {
  yield put(updateAppAction({ isLoading: true }));

  const { activity, schoolIds } = action;

  const activityService = new ActivityService();
  const activityInteractor = new CreateActivityInteractor(activityService);

  try {
    // Add to each school
    const activites = schoolIds.map((schoolId) => ({ ...activity, schoolId }));

    yield all(
      activites.map((activity: any) => {
        activityInteractor.createActivity(activity);
        return null;
      })
    );

    yield put(updateAppAction({ isLoading: false }));
  } catch (error) {
    // @ts-ignore
    yield put(updateAppAction({ isLoading: false, error }));
  }
}

export const updateActivityAction = (
  activity: Activity,
  themeChoice?: any
): ActivityActionType => ({
  type: UPDATE_ACTIVITY,
  activity,
  themeChoice,
});

export function* updateActivitySaga(action: ActivityActionType) {
  yield put(updateAppAction({ isLoading: true }));

  const { activity, themeChoice } = action;

  const activityService = new ActivityService();
  const activityInteractor = new UpdateActivityInteractor(activityService);
  const schoolService = new SchoolService();
  const schoolInteractor = new ReadSchoolInteractor(schoolService);

  try {
    const updatedActivity: Activity = yield activityInteractor.updateActivity(
      activity
    );
    // Activity Attached to Specific School
    if (!activity.allSchools) {
      const updatedSchool: School = yield schoolInteractor.readSchool(
        updatedActivity.schoolId as string
      );

      const updatedScene: Scene = {
        id: updatedSchool.id,
        type: "school",
        data: updatedSchool,
      };

      yield all([
        put(updateSceneStateAction(updatedScene)),
        put(updateAppAction({ isLoading: false })),
      ]);
    } else {
      yield put(updateAppAction({ isLoading: false }));
    }
  } catch (error) {
    // @ts-ignore
    yield put(updateAppAction({ isLoading: false, error }));
  }
}

export const deleteActivityAction = (
  activity: Activity
): ActivityActionType => ({
  type: DELETE_ACTIVITY,
  activity,
});

export function* deleteActivitySaga(action: ActivityActionType) {
  yield put(updateAppAction({ isLoading: true }));

  const { activity } = action;

  const activityService = new ActivityService();
  const activityInteractor = new DeleteActivityInteractor(activityService);
  const schoolService = new SchoolService();
  const schoolInteractor = new ReadSchoolInteractor(schoolService);

  try {
    yield activityInteractor.deleteActivity(activity);
    // Activity Attached to Specific School
    if (!activity.allSchools) {
      const updatedSchool: School = yield schoolInteractor.readSchool(
        activity.schoolId as string
      );

      const updatedScene: Scene = {
        id: updatedSchool.id,
        type: "school",
        data: updatedSchool,
      };

      yield all([
        put(updateSceneStateAction(updatedScene)),
        put(updateAppAction({ isLoading: false })),
      ]);
    } else {
      yield put(updateAppAction({ isLoading: false }));
    }
  } catch (error) {
    // @ts-ignore
    yield put(updateAppAction({ isLoading: false, error }));
  }
}
