import { useState } from "react";
import { AssignmentTurnedInOutlined, Check } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Card,
  RadioGroup,
  Radio,
  FormControlLabel,
  Divider,
} from "@material-ui/core";
import parse from "html-react-parser";
import moment from "moment";
import PDFStock from "../../../assets/images/pdfStock.png";

const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    width: "100%",
  },
  optionTextFieldFocused: {},
  optionTextFieldUnderline: {
    "&:after": {
      borderBottom: `1px solid transparent !important`,
    },
    "&:before": {
      borderBottom: `1px solid transparent`,
    },
    "&:hover:not($disabled):not($focused):before": {
      borderBottom: `1px solid #d3d3d3 !important`,
    },
  },
}));

const QuizCreator = (props) => {
  const { quiz, preview, inputQuiz } = props;
  const [showAnswerKey, setShowAnswerKey] = useState(null);
  const classes = useStyles();

  return (quiz || []).map((quizQuestion, index) => (
    <Card className="card card-box py-4 mb-4">
      {showAnswerKey === index ? (
        <>
          <div className="d-flex flex-row align-items-center px-4">
            <AssignmentTurnedInOutlined
              style={{ width: 25, height: 25, color: "gray" }}
            />
            <div
              style={{
                fontSize: "18px",
                lineHeight: "24px",
                fontWeight: 400,
                color: "#000",
              }}
              className="ml-3"
            >
              Choose correct answer:
            </div>
          </div>
          <Divider className="my-4" />
          <FormControl component="fieldset" className="px-4">
            <RadioGroup value={quizQuestion.correctAnswer}>
              {quizQuestion.options.map((option, optionIndex) => (
                <FormControlLabel
                  key={optionIndex}
                  fullWidth={true}
                  value={option.value}
                  control={<Radio color="primary" />}
                  style={
                    option.id === quizQuestion.correctAnswer
                      ? {
                          backgroundColor: "rgba(24, 169, 169, 0.1)",
                        }
                      : {}
                  }
                  label={
                    <div className="d-flex flex-row align-items-center justify-content-between">
                      <div>{option.value}</div>
                      {option.id === quizQuestion.correctAnswer ? (
                        <div>
                          <Check
                            style={{ width: 20, height: 20 }}
                            className="mr-2"
                            color="primary"
                          />
                        </div>
                      ) : null}
                    </div>
                  }
                  checked={option.id === quizQuestion.correctAnswer}
                  classes={{
                    label: classes.formControlLabel,
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <Divider className="my-4" />
          <div className="d-flex flex-row align-items-center px-4 justify-content-end">
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => {
                setShowAnswerKey(null);
              }}
            >
              Done
            </Button>
          </div>
        </>
      ) : (
        <>
          <TextField
            className="px-4"
            variant={preview ? "standard" : "filled"}
            fullWidth
            hiddenLabel={true}
            value={quizQuestion.question}
            // error={formErrors.name}
            placeholder="Question"
            type="text"
            disabled={preview}
            InputProps={{ disableUnderline: preview ? true : false }}
          />
          <Divider className="my-4" />
          {!inputQuiz ? (
            <FormControl component="fieldset" className="px-4">
              <RadioGroup value={quizQuestion.correctAnswer}>
                {quizQuestion.options.map((option, optionIndex) => (
                  <FormControlLabel
                    key={optionIndex}
                    fullWidth={true}
                    value={option.value}
                    control={<Radio color="primary" />}
                    style={
                      option.id === quizQuestion.correctAnswer
                        ? {
                            backgroundColor: "rgba(24, 169, 169, 0.1)",
                          }
                        : {}
                    }
                    label={
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <div>{option.value}</div>
                        {option.id === quizQuestion.correctAnswer ? (
                          <div>
                            <Check
                              style={{ width: 20, height: 20 }}
                              className="mr-2"
                              color="primary"
                            />
                          </div>
                        ) : null}
                      </div>
                    }
                    checked={option.id === quizQuestion.correctAnswer}
                    disabled
                    classes={{
                      label: classes.formControlLabel,
                    }}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          ) : (
            <div className="text-black-50 px-4">
              Guardians will input an answer to this question.
            </div>
          )}
        </>
      )}
    </Card>
  ));
};

const ActivityDetails = (props) => {
  const { activity } = props;

  switch (activity.type) {
    case 0:
      return (
        <div>
          <Grid container spacing={0} className="mt-4">
            <Grid md={7}>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Activity name
                  </InputLabel>
                  <div className="text-black">{activity.name}</div>
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Description
                  </InputLabel>
                  <div className="text-black">
                    {parse(activity.information)}
                  </div>
                </Grid>
                <Grid item md={12}>
                  {activity.video ? (
                    <>
                      <InputLabel className="mb-1" shrink={true}>
                        Video upload
                      </InputLabel>
                      {activity.video ? (
                        <div className="py-2 d-flex flex-column align-items-start">
                          <video
                            key={
                              activity.video.preview
                                ? activity.video.preview
                                : activity.video
                            }
                            controls
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            className="rounded"
                          >
                            <source
                              src={
                                activity.video.preview
                                  ? activity.video.preview
                                  : activity.video
                              }
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                          <a
                            href={activity.video}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: "underline",
                              color: "blue",
                            }}
                            className="mt-2"
                          >
                            Link to video
                          </a>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <div className="pb-4">
                      <InputLabel className="mb-1" shrink={true}>
                        Video YouTube
                      </InputLabel>
                      <iframe
                        title={`YouTubeLink`}
                        src={`https://youtube.com/embed/${activity.videoYoutube}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        className="rounded my-2"
                      />
                      <a
                        href={`https://youtube.com/embed/${activity.videoYoutube}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "underline",
                          color: "blue",
                        }}
                        className="mt-2"
                      >
                        Link to video
                      </a>
                    </div>
                  )}
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Points
                  </InputLabel>
                  <div className="text-black">{activity.points}</div>
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Video thumbnail
                  </InputLabel>
                  {activity.thumbnail ? (
                    <div className="py-2 d-flex flex-column align-items-start">
                      <img
                        style={{
                          width: "300px",
                          height: "300px",
                          objectFit: "cover",
                          objectPosition: "top",
                        }}
                        src={
                          activity.thumbnail.preview
                            ? activity.thumbnail.preview
                            : activity.thumbnail
                        }
                        className="rounded"
                        alt="..."
                      />
                    </div>
                  ) : null}
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-2" shrink={true}>
                    Question
                  </InputLabel>
                  <QuizCreator
                    quiz={activity.quiz}
                    preview={true}
                    multiple={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    case 1:
      return (
        <div>
          <Grid container spacing={0} className="mt-4">
            <Grid md={7}>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Activity name
                  </InputLabel>
                  <div className="text-black">{activity.name}</div>
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Description
                  </InputLabel>
                  <div className="text-black">
                    {parse(activity.information)}
                  </div>
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Points
                  </InputLabel>
                  <div className="text-black">{activity.points}</div>
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Activity thumbnail
                  </InputLabel>
                  {activity.thumbnail ? (
                    <div className="py-2 d-flex flex-column align-items-start">
                      <img
                        style={{
                          width: "300px",
                          height: "300px",
                          objectFit: "cover",
                          objectPosition: "top",
                        }}
                        src={
                          activity.thumbnail.preview
                            ? activity.thumbnail.preview
                            : activity.thumbnail
                        }
                        className="rounded"
                        alt="..."
                      />
                    </div>
                  ) : null}
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-2" shrink={true}>
                    Questions
                  </InputLabel>
                  <div className="mb-4">
                    <QuizCreator
                      quiz={activity.quiz}
                      preview={true}
                      multiple={true}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    case 2:
      return (
        <div>
          <Grid container spacing={0} className="mt-4">
            <Grid md={7}>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Activity name
                  </InputLabel>
                  <div className="text-black">{activity.name}</div>
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Instructions
                  </InputLabel>
                  <div className="text-black">
                    {parse(activity.information)}
                  </div>
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Points
                  </InputLabel>
                  <div className="text-black">{activity.points}</div>
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Activity thumbnail
                  </InputLabel>
                  {activity.thumbnail ? (
                    <div className="py-2 d-flex flex-column align-items-start">
                      <img
                        style={{
                          width: "300px",
                          height: "300px",
                          objectFit: "cover",
                          objectPosition: "top",
                        }}
                        src={
                          activity.thumbnail.preview
                            ? activity.thumbnail.preview
                            : activity.thumbnail
                        }
                        className="rounded"
                        alt="..."
                      />
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    case 3:
      return (
        <div>
          <Grid container spacing={0} className="mt-4">
            <Grid md={7}>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Activity name
                  </InputLabel>
                  <div className="text-black">{activity.name}</div>
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Reading material or link
                  </InputLabel>
                  <div className="text-black">
                    {parse(activity.information)}
                  </div>
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Points
                  </InputLabel>
                  <div className="text-black">{activity.points}</div>
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Activity thumbnail
                  </InputLabel>
                  {activity.thumbnail ? (
                    <div className="py-2 d-flex flex-column align-items-start">
                      <img
                        style={{
                          width: "300px",
                          height: "300px",
                          objectFit: "cover",
                          objectPosition: "top",
                        }}
                        src={
                          activity.thumbnail.preview
                            ? activity.thumbnail.preview
                            : activity.thumbnail
                        }
                        className="rounded"
                        alt="..."
                      />
                    </div>
                  ) : null}
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-2" shrink={true}>
                    Question
                  </InputLabel>
                  <QuizCreator
                    quiz={activity.quiz}
                    preview={true}
                    multiple={false}
                    inputQuiz={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    case 4:
      return (
        <div>
          <Grid container spacing={0} className="mt-4">
            <Grid md={7}>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Activity name
                  </InputLabel>
                  <div className="text-black">{activity.name}</div>
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Description
                  </InputLabel>
                  <div className="text-black">
                    {parse(activity.information)}
                  </div>
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-2" shrink={true}>
                    Files
                  </InputLabel>
                  {activity.schoolFiles
                    ? activity.schoolFiles.map((file) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            marginBottom: "20px",
                          }}
                        >
                          <div style={{ paddingRight: 15 }}>
                            <img
                              src={
                                file.fileType.includes("application/pdf")
                                  ? PDFStock
                                  : file.fileType.includes("video/mp4")
                                  ? "https://firebasestorage.googleapis.com/v0/b/parent-parties-production.appspot.com/o/appSettings%2Fmultimedia_550x550.png?alt=media&token=167f8996-24f9-443d-bee3-00d6e7f15fa7"
                                  : file.preview
                              }
                              alt="file img"
                              style={{ height: "40px", width: "40px" }}
                            />
                          </div>
                          <div>
                            <p className="text-black mb-0">{`${file.name}`}</p>
                            <p className="font-size-sm text-black-50 mb-0">{`Added on ${moment(
                              file.time
                            ).format("L")}`}</p>
                          </div>
                        </div>
                      ))
                    : null}
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Points
                  </InputLabel>
                  <div className="text-black">{activity.points}</div>
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Activity thumbnail
                  </InputLabel>
                  {activity.thumbnail ? (
                    <div className="py-2 d-flex flex-column align-items-start">
                      <img
                        style={{
                          width: "300px",
                          height: "300px",
                          objectFit: "cover",
                          objectPosition: "top",
                        }}
                        src={
                          activity.thumbnail.preview
                            ? activity.thumbnail.preview
                            : activity.thumbnail
                        }
                        className="rounded"
                        alt="..."
                      />
                    </div>
                  ) : null}
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-2" shrink={true}>
                    Question
                  </InputLabel>
                  <QuizCreator
                    quiz={activity.quiz}
                    preview={true}
                    multiple={false}
                    inputQuiz={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    case 5:
      return (
        <div>
          <Grid container spacing={0} className="mt-4">
            <Grid md={7}>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Activity name
                  </InputLabel>
                  <div className="text-black">{activity.name}</div>
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Description
                  </InputLabel>
                  <div className="text-black">
                    {parse(activity.information)}
                  </div>
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-2" shrink={true}>
                    Links
                  </InputLabel>
                  {activity.links
                    ? activity.links.map((link) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            marginBottom: "20px",
                          }}
                        >
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={link.url}
                            style={{
                              color: "#0073bb",
                              textDecoration: "underline",
                            }}
                          >
                            {link.url}
                          </a>
                        </div>
                      ))
                    : null}
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Points
                  </InputLabel>
                  <div className="text-black">{activity.points}</div>
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Activity thumbnail
                  </InputLabel>
                  {activity.thumbnail ? (
                    <div className="py-2 d-flex flex-column align-items-start">
                      <img
                        style={{
                          width: "300px",
                          height: "300px",
                          objectFit: "cover",
                          objectPosition: "top",
                        }}
                        src={
                          activity.thumbnail.preview
                            ? activity.thumbnail.preview
                            : activity.thumbnail
                        }
                        className="rounded"
                        alt="..."
                      />
                    </div>
                  ) : null}
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-2" shrink={true}>
                    Question
                  </InputLabel>
                  <QuizCreator
                    quiz={activity.quiz}
                    preview={true}
                    multiple={false}
                    inputQuiz={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    case 6:
    case 7:
      return (
        <div>
          <Grid container spacing={0} className="mt-4">
            <Grid md={7}>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Activity name
                  </InputLabel>
                  <div className="text-black">{activity.name}</div>
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Description
                  </InputLabel>
                  <div className="text-black">
                    {parse(activity.information)}
                  </div>
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Points
                  </InputLabel>
                  <div className="text-black">{activity.points}</div>
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-1" shrink={true}>
                    Activity thumbnail
                  </InputLabel>
                  {activity.thumbnail ? (
                    <div className="py-2 d-flex flex-column align-items-start">
                      <img
                        style={{
                          width: "300px",
                          height: "300px",
                          objectFit: "cover",
                          objectPosition: "top",
                        }}
                        src={
                          activity.thumbnail.preview
                            ? activity.thumbnail.preview
                            : activity.thumbnail
                        }
                        className="rounded"
                        alt="..."
                      />
                    </div>
                  ) : null}
                </Grid>
                <Grid item md={12}>
                  <InputLabel className="mb-2" shrink={true}>
                    Question
                  </InputLabel>
                  <QuizCreator
                    quiz={activity.quiz}
                    preview={true}
                    multiple={false}
                    inputQuiz={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    default:
      return null;
  }
};
export default ActivityDetails;
