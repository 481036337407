import React from "react";
import AdminWeeklyScorecard from "../../components/AdminWeeklyScorecard";

function AdminWeeklyScorecardPage() {
  return (
    <>
      <AdminWeeklyScorecard />
    </>
  );
}

export default AdminWeeklyScorecardPage;
