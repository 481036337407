import React from "react";
import EditSchool from "../../components/EditSchool";

export default function EditSchoolPage(props) {
  return (
    <>
      <EditSchool {...props} />
    </>
  );
}
