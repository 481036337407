import { School } from "../../Entities";
import { initialState, StateType } from "./State";

const UPDATE_SCHOOL = "school/update";
const FETCH_SCHOOL_ANALYTICS = "school/fetch_school_analytics";

type StateSlice = StateType["school"];

export interface UpdateSchoolActionType {
  type: string;
  school: School | null;
  skipLoading?: Boolean;
}
export interface UpdateSchoolAnalyticsActionType {
  type: string;
  analytics: any;
}

export const schoolSelector = (state: StateType): StateSlice => state.school;
export const updateSchoolStateAction = (
  school?: School,
  skipLoading?: Boolean
): UpdateSchoolActionType => ({
  type: UPDATE_SCHOOL,
  school: school || null,
  skipLoading: skipLoading ? true : false,
});
export const updateSchoolAnalyticsStateAction = (
  analytics?: any
): UpdateSchoolAnalyticsActionType => ({
  type: FETCH_SCHOOL_ANALYTICS,
  analytics: analytics,
});

const updateHandler = (
  state: StateSlice,
  action: UpdateSchoolActionType
): StateType["school"] => {
  return action.school
    ? {
        ...action.school,
        analytics: state ? (state.analytics ? state.analytics : null) : null,
      }
    : null;
};

const updateSchoolAnalyticsHandler = (
  state: StateSlice,
  action: UpdateSchoolAnalyticsActionType
): StateType["school"] => {
  return {
    ...state,
    analytics: action.analytics,
  };
};

export const schoolReducer = (
  school: StateSlice = initialState.school,
  action: any
): StateSlice => {
  switch (action.type) {
    case UPDATE_SCHOOL:
      return updateHandler(school, action);
    case FETCH_SCHOOL_ANALYTICS:
      return updateSchoolAnalyticsHandler(school, action);
    default:
      return school;
  }
};
