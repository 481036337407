import SchoolGuardians from "../../components/SchoolGuardians";

function SchoolGuardiansPage() {
  return (
    <>
      <SchoolGuardians />
    </>
  );
}

export default SchoolGuardiansPage;
