import React from "react";
import SchoolResourcesInPerson from "../../components/SchoolResourcesInPerson";

function SchoolResourcesInPersonPage() {
  return (
    <>
      <SchoolResourcesInPerson />
    </>
  );
}

export default SchoolResourcesInPersonPage;
