import React from "react";
import AddDistrict from "../../components/AddDistrict";

function AddDistrictPage() {
  return (
    <>
      <AddDistrict />
    </>
  );
}

export default AddDistrictPage;
