import AdminScorecardAnalytics from "../../components/AdminScorecardAnalytics";

function AdminScorecardAnalyticsPage() {
  return (
    <>
      <AdminScorecardAnalytics />
    </>
  );
}

export default AdminScorecardAnalyticsPage;
