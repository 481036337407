import { put } from "redux-saga/effects";
import { updateAppAction } from "../Redux";

export const RESET_APP = "app/saga/reset_app";
export const DATE_RANGE_APP = "app/saga/date_range_app";

interface AppActionType {
  type: string;
}

interface AppActionDateRangeType {
  type: string;
  dateRange: any[];
}

export const resetAppAction = (): AppActionType => ({ type: RESET_APP });

export function* resetAppSaga(action: AppActionType) {
  yield put(updateAppAction({ isLoading: false, error: null }));
}

export const updateDateRangeAction = (
  dateRange: any
): AppActionDateRangeType => ({ dateRange, type: DATE_RANGE_APP });

export function* updateDateRangeSaga(action: AppActionDateRangeType) {
  const { dateRange } = action;
  yield put(updateAppAction({ dateRange }));
}
