import SchoolTeachers from "../../components/SchoolTeachers";

function SchoolPushNotificationPage() {
  return (
    <>
      <SchoolTeachers />
    </>
  );
}

export default SchoolPushNotificationPage;
