import { Suspense, useState, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  withRouter,
  useHistory,
} from "react-router-dom";
import { connect } from "react-redux";
import {
  appSelector,
  userSelector,
  listenToAuthStateAction,
  sceneSelector,
  updateUserAction,
  signOutAction,
  setSidebarToggle,
  readSceneAction,
  ParentPartiesAPIs,
  updateDateRangeAction,
} from "./core";

import { AnimatePresence, motion } from "framer-motion";
import { ClimbingBoxLoader } from "react-spinners";
import { ThemeProvider } from "@material-ui/styles";

import MuiTheme from "./theme";

// Layout Blueprints

import { LeftSidebar, MinimalLayout } from "./layout-blueprints";

// Pages
import AccountSettings from "./pages/AccountSettings";
import Team from "./pages/Team";
import EditTeam from "./pages/EditTeam";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Recover from "./pages/Recover";
import Unsub from "./pages/Unsub";
import Notifications from "./pages/Notifications";
import AddDistrict from "./pages/AddDistrict";

/* SUPPORT */
// import AdminDashboard from "./pages/AdminDashboard";
import AdminAnalytics from "./pages/AdminAnalytics";
import AdminEvaluationAnalytics from "./pages/AdminEvaluationAnalytics";
import AdminScorecardAnalytics from "./pages/AdminScorecardAnalytics";
import AdminSchools from "./pages/AdminSchools";
import AdminDistricts from "./pages/AdminDistricts";
import AddSchool from "./pages/AddSchool";
import EditSchool from "./pages/EditSchool";
import EditDistrict from "./pages/EditDistrict";
import AdminActivities from "./pages/AdminActivities";
import AdminWeeklyScorecard from "./pages/AdminWeeklyScorecard";
import AdminParentEvaluations from "./pages/AdminParentEvaluations";
import AdminArchivedActivities from "./pages/AdminArchivedActivities";
import AdminActivityAnalytics from "./pages/AdminActivityAnalytics";
import AddActivity from "./pages/AddActivity";
import EditTranslation from "./pages/EditTranslation";
import AdminGuardians from "./pages/AdminGuardians";
import AdminGuardiansAnalytics from "./pages/AdminGuardiansAnalytics";
import AdminResources from "./pages/AdminResources";
import AdminResourcesGeneral from "./pages/AdminResourcesGeneral";
import AdminResourcesMarketing from "./pages/AdminResourcesMarketing";
import AdminResourcesFileHosting from "./pages/AdminResourcesFileHosting";
import AdminResourcesUploads from "./pages/AdminResourcesUploads";
import AdminResourcesOnboardingVideo from "./pages/AdminResourcesOnboardingVideo";
import AdminVideoShorts from "./pages/AdminVideoShorts";
import AdminAddVideoShort from "./pages/AdminAddVideoShort";
import AdminSubmittedVideoShorts from "./pages/AdminSubmittedVideoShorts";
import AdminEmail from "./pages/AdminEmail";

/* SCHOOL */
import SchoolReports from "./pages/SchoolReports";
import SchoolEvaluationAnalytics from "./pages/SchoolEvaluationAnalytics";
import SchoolScorecardAnalytics from "./pages/SchoolScorecardAnalytics";
import SchoolGenerateReport from "./pages/SchoolGenerateReport";
import SchoolActivities from "./pages/SchoolActivities";
import SchoolMonthlyAnalytics from "./pages/SchoolMonthlyAnalytics";
import SchoolWeeklyScorecard from "./pages/SchoolWeeklyScorecard";
import SchoolParentEvaluations from "./pages/SchoolParentEvaluations";
import SchoolArchivedActivities from "./pages/SchoolArchivedActivities";
import SchoolActivityAnalytics from "./pages/SchoolActivityAnalytics";
import SchoolGuardians from "./pages/SchoolGuardians";
import SchoolGuardiansAnalytics from "./pages/SchoolGuardiansAnalytics";
import SchoolGuardiansLeaderboard from "./pages/SchoolGuardiansLeaderboard";
import SchoolResourcesFileHosting from "./pages/SchoolResourcesFileHosting";
import SchoolResources from "./pages/SchoolResources";
import SchoolResourcesInPerson from "./pages/SchoolResourcesInPerson";
import SchoolResourcesInPersonGeneral from "./pages/SchoolResourcesInPersonGeneral";
import SchoolResourcesUploads from "./pages/SchoolResourcesUploads";
import SchoolPushNotification from "./pages/SchoolPushNotification";
import SchoolContactInfo from "./pages/SchoolContactInfo";
import SchoolTeachers from "./pages/SchoolTeachers";
import SchoolPrizePlan from "./pages/SchoolPrizePlan";
import SchoolVideoShorts from "./pages/SchoolVideoShorts";
import SchoolSubmitVideoShort from "./pages/SchoolSubmitVideoShort";
import DistrictActivityAnalytics from "./pages/DistrictActivityAnalytics";
import DistrictGuardianAnalytics from "./pages/DistrictGuardianAnalytics";
import AdminEditCurriculum from "./pages/AdminEditCurriculum";
import SchoolParentResponses from "./pages/SchoolParentResponses";

import ApprovalStage from "./pages/ApprovalStage";

import { page, identify } from "./utils/Analytics";
import SessionTimeout from "./utils/SessionTimeout";

const Routes = (props) => {
  const { updateDateRangeAction, user } = props;
  const authChecked = props.app.authChecked;

  // Start Auth Listener
  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const inviteId = params.get("inviteId");
    const itemId = params.get("itemId");
    const itemType = params.get("itemType");
    if (!inviteId) {
      props.dispatchAuthStateListener(
        undefined,
        itemId ? `${itemId}` : undefined,
        itemType ? itemType : undefined
      );
    } else {
      /* If inviteId, sign them out */
      props.signOutAction();
    }
  }, []);

  /// Identify ///
  useEffect(() => {
    if (props.user) {
      identify(props.user);

      ParentPartiesAPIs.fetchDateRange().then((dateRange) => {
        updateDateRangeAction(dateRange);
      });
    }
  }, [props.user, updateDateRangeAction]);

  const location = useLocation();

  useEffect(() => {
    /* Utility Functions on Route Change */
    if (location.pathname) {
      page(location.pathname);
    }
  }, [location.pathname]);

  // Handle Redirect
  // Handle Pull Scene Data from URL
  const history = useHistory();
  useEffect(() => {
    function handleRedirect({ user, pathname }) {
      /* Unsub page don't redirect */
      if (pathname === "/unsub") {
        return null;
      }
      /* User Logged In Already */
      if (user) {
        if (user.adminSupport) {
          if (["/login"].includes(pathname)) {
            return history.push("/admin-schools");
          } else {
            return null;
          }
        }

        /* Check for inviteId */
        const params = new URLSearchParams(window.location.search);
        const inviteId = params.get("inviteId");
        const senderId = params.get("senderId");
        const itemType = params.get("itemType");
        const itemId = params.get("itemId");

        if (inviteId && senderId) {
          if (itemType === "school") {
            return history.push(`/guardians-analytics/s/${itemId}`);
          }
          if (itemType === "district") {
            return history.push(`/dashboard/d/${itemId}`);
          }
        }
        /* Check if user has schools */
        if (user.schools) {
          if (Object.values(user.schools).length) {
            // const openAnalytics = params.get("openAnalytics");
            // if (openAnalytics) {
            //   return history.push(`/monthly-analytics/s/${itemId}`);
            // }

            if (Object.values(user.schools).length > 1) {
              const district = Object.values(props.user.districts)[0];
              return history.push(`/dashboard/d/${district.id}`);
            } else {
              return history.push(
                `/guardians-analytics/s/${
                  Object.values(props.user.schools)[0].id
                }`
              );
            }
          }
        }

        return history.push(`/approval-stage`);
      }

      /* Return to login if not logged in and not on correct pages */
      return history.push("/login");
    }

    if (authChecked) {
      const pathname = window.location.pathname;
      handleRedirect({
        user,
        pathname: pathname,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, authChecked]);

  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "linear",
    duration: 0.3,
  };

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}
            >
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                  <ClimbingBoxLoader color={"#3c44b1"} loading={true} />
                </div>
                <div className="text-muted font-size-xl text-center pt-3">
                  Ensuring Everything Works Perfektly
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Redirect exact from="/" to="/login" />
            <Route
              path={[
                "/login",
                "/register",
                "/recover",
                "/unsub",
                "/approval-stage",
              ]}
            >
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    <Route path="/login" component={Login} />
                    <Route path="/register" component={Register} />
                    <Route path="/recover" component={Recover} />
                    <Route path="/unsub" component={Unsub} />
                    <Route path="/approval-stage" component={ApprovalStage} />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>

            <Route
              path={[
                /* New */
                "/account-settings",
                "/notifications",
                "/dashboard",
                "/team",
                // "/admin-dashboard",
                "/admin-analytics",
                "/admin-evaluation-analytics",
                "/admin-weekly-scorecard-analytics",
                "/admin-schools",
                "/admin-districts",
                "/add-school",
                "/add-district",
                "/edit-school",
                "/edit-district",
                "/admin-activities",
                "/admin-add-activity",
                "/admin-edit-activity",
                "/admin-edit-translation",
                "/admin-weekly-scorecard",
                "/admin-parent-evaluations",
                "/admin-archived-activities",
                "/admin-activity-analytics",
                "/admin-guardians",
                "/admin-guardians-analytics",
                "/admin-resources",
                "/admin-resources-general",
                "/admin-resources-marketing",
                "/admin-resources-file-hosting",
                "/admin-resources-uploads",
                "/admin-resources-onboarding-video",
                "/admin-video-shorts",
                "/admin-add-video-short",
                "/admin-edit-video-short",
                "/admin-submitted-video-shorts",
                "/admin-edit-curriculum",
                "/admin-email",
                /* School */
                "/analytics",
                "/generate-report",
                "/evaluation-analytics",
                "/weekly-scorecard-analytics",
                "/activities",
                "/add-activity",
                "/edit-activity",
                "/edit-translation",
                "/weekly-scorecard",
                "/parent-evaluations",
                "/parent-responses",
                "/archived-activities",
                "/activity-analytics",
                "/guardians",
                "/guardians-analytics",
                "/guardians-leaderboard",
                "/resources-file-hosting",
                "/resources-uploads",
                "/resources-in-person",
                "/resources-in-person-general",
                "/resources",
                "/more-info",
                "/teachers",
                "/push-notification",
                "/prize-plan",
                "/video-shorts",
                "/submit-video-short",
                /* District Specific */
                "/district-activities",
                "/district-guardians",
                "/monthly-analytics",
              ]}
            >
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    <Route path="/dashboard/:type/:id" component={Dashboard} />
                    <Route
                      path="/analytics/:type/:id"
                      component={SchoolReports}
                    />
                    <Route
                      path="/generate-report/:type/:id"
                      component={SchoolGenerateReport}
                    />
                    <Route
                      path="/evaluation-analytics/:type/:id"
                      component={SchoolEvaluationAnalytics}
                    />
                    <Route
                      path="/weekly-scorecard-analytics/:type/:id"
                      component={SchoolScorecardAnalytics}
                    />
                    <Route
                      path="/monthly-analytics/:type/:id"
                      component={SchoolMonthlyAnalytics}
                    />
                    <Route
                      path="/activities/:type/:id"
                      component={SchoolActivities}
                    />
                    <Route
                      path="/weekly-scorecard/:type/:id"
                      component={SchoolWeeklyScorecard}
                    />
                    <Route
                      path="/parent-evaluations/:type/:id"
                      component={SchoolParentEvaluations}
                    />
                    <Route
                      path="/archived-activities/:type/:id"
                      component={SchoolArchivedActivities}
                    />
                    <Route
                      path="/activity-analytics/:type/:id"
                      component={SchoolActivityAnalytics}
                    />
                    <Route
                      path="/video-shorts/:type/:id"
                      component={SchoolVideoShorts}
                    />
                    <Route
                      path="/submit-video-short/:type/:id"
                      component={SchoolSubmitVideoShort}
                    />
                    <Route
                      path="/guardians/:type/:id"
                      component={SchoolGuardians}
                    />
                    <Route
                      path="/guardians-analytics/:type/:id"
                      component={SchoolGuardiansAnalytics}
                    />
                    <Route
                      path="/guardians-leaderboard/:type/:id"
                      component={SchoolGuardiansLeaderboard}
                    />
                    <Route
                      path="/resources-file-hosting/:type/:id"
                      component={SchoolResourcesFileHosting}
                    />
                    <Route
                      path="/resources-uploads/:type/:id"
                      component={SchoolResourcesUploads}
                    />
                    <Route
                      path="/resources/:type/:id"
                      component={SchoolResources}
                    />
                    <Route
                      path="/resources-in-person/:type/:id"
                      component={SchoolResourcesInPerson}
                    />
                    <Route
                      path="/resources-in-person-general/:type/:id"
                      component={SchoolResourcesInPersonGeneral}
                    />
                    <Route
                      path="/push-notification/:type/:id"
                      component={SchoolPushNotification}
                    />
                    <Route
                      path="/more-info/:type/:id"
                      component={SchoolContactInfo}
                    />
                    <Route
                      path="/teachers/:type/:id"
                      component={SchoolTeachers}
                    />
                    <Route
                      path="/parent-responses/:type/:id"
                      component={SchoolParentResponses}
                    />
                    <Route
                      path="/prize-plan/:type/:id"
                      component={SchoolPrizePlan}
                    />
                    <Route path="/team/:type/:id" component={Team} />
                    <Route path="/edit-team/:type/:id" component={EditTeam} />
                    {/* District Specific */}
                    <Route
                      path="/district-activities/:type/:id"
                      component={DistrictActivityAnalytics}
                    />
                    <Route
                      path="/district-guardians/:type/:id"
                      component={DistrictGuardianAnalytics}
                    />
                    {/* Universal */}
                    <Route
                      path="/account-settings"
                      component={AccountSettings}
                    />
                    <Route path="/notifications" component={Notifications} />
                    {/* Support */}
                    {/* <Route path="/admin-dashboard" component={AdminDashboard} /> */}
                    <Route path="/admin-analytics" component={AdminAnalytics} />
                    <Route
                      path="/admin-evaluation-analytics"
                      component={AdminEvaluationAnalytics}
                    />
                    <Route
                      path="/admin-weekly-scorecard-analytics"
                      component={AdminScorecardAnalytics}
                    />
                    <Route path="/admin-schools" component={AdminSchools} />
                    <Route path="/admin-districts" component={AdminDistricts} />
                    <Route path={"/add-school"} component={AddSchool} />
                    <Route path={"/add-district"} component={AddDistrict} />
                    <Route path={"/edit-school/:id"} component={EditSchool} />
                    <Route
                      path={"/edit-district/:id"}
                      component={EditDistrict}
                    />
                    <Route path="/admin-guardians" component={AdminGuardians} />
                    <Route
                      path="/admin-guardians-analytics"
                      component={AdminGuardiansAnalytics}
                    />
                    <Route
                      path="/admin-activities"
                      component={AdminActivities}
                    />
                    <Route
                      path="/admin-edit-curriculum/:id"
                      component={AdminEditCurriculum}
                    />
                    <Route
                      path={[
                        "/admin-add-activity",
                        "/admin-edit-activity",
                        "/add-activity/:type/:id",
                        "/edit-activity/:type/:id",
                      ]}
                      component={AddActivity}
                    />
                    <Route
                      path={[
                        "/admin-edit-translation",
                        "/edit-translation/:type/:id",
                      ]}
                      component={EditTranslation}
                    />
                    <Route path={["/admin-email"]} component={AdminEmail} />
                    <Route
                      path="/admin-weekly-scorecard"
                      component={AdminWeeklyScorecard}
                    />
                    <Route
                      path="/admin-parent-evaluations"
                      component={AdminParentEvaluations}
                    />
                    <Route
                      path="/admin-archived-activities"
                      component={AdminArchivedActivities}
                    />
                    <Route
                      path="/admin-activity-analytics"
                      component={AdminActivityAnalytics}
                    />
                    <Route path="/admin-resources" component={AdminResources} />
                    <Route
                      path="/admin-resources-general"
                      component={AdminResourcesGeneral}
                    />
                    <Route
                      path="/admin-resources-marketing"
                      component={AdminResourcesMarketing}
                    />
                    <Route
                      path="/admin-resources-file-hosting"
                      component={AdminResourcesFileHosting}
                    />
                    <Route
                      path="/admin-resources-uploads"
                      component={AdminResourcesUploads}
                    />
                    <Route
                      path="/admin-resources-onboarding-video"
                      component={AdminResourcesOnboardingVideo}
                    />
                    <Route
                      path="/admin-video-shorts"
                      component={AdminVideoShorts}
                    />
                    <Route
                      path={[
                        "/admin-add-video-short",
                        "/admin-edit-video-short",
                      ]}
                      component={AdminAddVideoShort}
                    />
                    <Route
                      path="/admin-submitted-video-shorts"
                      component={AdminSubmittedVideoShorts}
                    />
                    {/* Utils */}
                    <Route path="/" component={SessionTimeout} />
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
  scene: sceneSelector(state),
  sidebarToggle: state.ThemeOptions.sidebarToggle,
});
const mapDispatchToProps = {
  dispatchAuthStateListener: listenToAuthStateAction,
  dispatchUpdateUser: updateUserAction,
  signOutAction,
  setSidebarToggle,
  readSceneAction,
  updateDateRangeAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Routes));
