import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import { NavLink } from "react-router-dom";

const StyledMenu = withStyles({
  paper: {
    width: "282px",
    borderRadius: "4px",
    backgroundColor: "#fff",
    boxShadow: "0 0 18px rgb(0 0 0 / 15%)",
    marginTop: "5px",
  },
  list: {
    padding: 0,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const LocationButton = withStyles({
  root: {
    "&:hover": {
      transform: "none !important",
    },
  },
})((props) => <Button {...props} />);

const UnderlineButton = withStyles({
  root: {
    "&:hover": {
      transform: "none !important",
      backgroundColor: "transparent",
      textDecoration: "underline",
      color: "rgb(0,131,143)",
    },
  },
})((props) => <Button {...props} />);

export default function LocationSelection(props) {
  const { handleClose: closeMain } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuType, setMenuType] = useState("Schools");

  const handleClick = (event) => {
    setMenuType(event.currentTarget.name);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async () => {
    setAnchorEl(null);
    props.toggleSidebarMobile();
  };

  const userSchools =
    props.user && props.scene
      ? props.user.schools && props.scene.data
        ? Object.values(props.user.schools)
            .map((school) => ({
              ...school,
              active: props.scene.data.id === school.id,
            }))
            .sort((a, b) =>
              a.name.toLowerCase().localeCompare(b.name.toLowerCase())
            )
        : []
      : [];
  const userDistricts =
    props.user && props.scene
      ? props.user.districts && props.scene.data
        ? Object.values(props.user.districts)
            .filter((district) =>
              userSchools.find((school) =>
                Object.keys(district.schoolIds).includes(school.id)
              )
            )
            .map((district) => ({
              ...district,
              active: props.scene.data.id === district.id,
            }))
            .sort((a, b) =>
              a.name.toLowerCase().localeCompare(b.name.toLowerCase())
            )
        : []
      : [];

  return (
    <div>
      <div className="d-flex flex-row align-items-center">
        <UnderlineButton
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="text"
          color="primary"
          onClick={handleClick}
          className="d-flex flex-row p-0"
          name="Schools"
        >
          <p
            style={{
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: "bold",
              color: "rgba(0,131,143,1)",
              textAlign: "left",
            }}
            className="mb-0"
          >
            {`Schools`}
          </p>
        </UnderlineButton>
        <div
          className="mx-2"
          style={{
            backgroundColor: "#d3d3d3",
            width: "1px",
            height: "15px",
          }}
        />
        <UnderlineButton
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="text"
          color="primary"
          onClick={handleClick}
          className="d-flex flex-row p-0"
          name="Districts"
        >
          <p
            style={{
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: "bold",
              color: "rgba(0,131,143,1)",
              textAlign: "left",
            }}
            className="mb-0"
          >
            {`Districts`}
          </p>
        </UnderlineButton>
      </div>
      <StyledMenu
        id="location-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div
          className="d-flex flex-row justify-content-between align-items-center"
          style={{
            paddingTop: "12px",
            paddingBottom: "12px",
            paddingLeft: "16px",
            paddingRight: "16px",
            borderBottom: "1px solid #eeeeef",
          }}
        >
          <div>
            <h5
              style={{
                color: "rgb(43, 39, 60)",
                fontWeight: "bold",
                fontSize: "16px",
                lineHeight: "22px",
                wordBreak: "break-word !important",
                overflowWrap: "break-word !important",
              }}
              className="mb-0"
            >
              {menuType}
            </h5>
          </div>
        </div>
        {userSchools.length ? (
          <div
            style={{
              maxHeight: "250px",
            }}
          >
            {menuType === "Schools"
              ? userSchools.map((school) => (
                  <LocationButton
                    style={
                      school.active
                        ? {
                            padding: "16px",
                            borderBottom: "1px solid #eeeeef",
                            backgroundColor: "rgba(20,165,215,.2)",
                            borderRadius: 0,
                          }
                        : {
                            padding: "16px",
                            borderBottom: "1px solid #eeeeef",
                            borderRadius: 0,
                          }
                    }
                    fullWidth
                    component={NavLink}
                    to={`/guardians-analytics/s/${school.id}`}
                    onClick={() => {
                      props.readSceneAction("s", `${school.id}`);
                      handleClose();
                      closeMain();
                    }}
                    key={school.id}
                  >
                    <div className="d-flex flex-column align-items-start w-100">
                      <p
                        className="mb-0"
                        style={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          fontWeight: "600",
                          color: "rgba(43,39,60,1)",
                          textAlign: "left",
                        }}
                      >
                        {school.name}
                        {` `}
                        {school.active ? (
                          <span
                            aria-hidden="true"
                            style={{
                              fill: "rgb(10, 179, 201)",
                            }}
                          >
                            <svg width="24" height="24">
                              <path d="M9.46 17.52a1 1 0 01-.71-.29l-4-4a1.004 1.004 0 111.42-1.42l3.25 3.26 8.33-8.34a1.004 1.004 0 011.42 1.42l-9 9a1 1 0 01-.71.37z"></path>
                            </svg>
                          </span>
                        ) : null}
                      </p>
                      <div style={{ paddingTop: "4px" }}>
                        <p
                          className="mb-0"
                          style={{
                            fontWeight: "normal",
                            fontSize: "12px",
                            lineHeight: "18px",
                            color: "rgba(117,114,128,1)",
                            textAlign: "left",
                          }}
                        >
                          {school.location.address}
                        </p>
                        <p
                          className="mb-0"
                          style={{
                            fontWeight: "normal",
                            fontSize: "12px",
                            lineHeight: "18px",
                            color: "rgba(117,114,128,1)",
                            textAlign: "left",
                          }}
                        >
                          {`${school.location.city}${
                            school.location.state
                              ? `, ${school.location.state} ${school.location.zipcode}`
                              : ""
                          }`}
                        </p>
                      </div>
                    </div>
                  </LocationButton>
                ))
              : null}
            {/* Districts */}
            {menuType === "Districts"
              ? userDistricts.map((district) => (
                  <LocationButton
                    style={
                      district.active
                        ? {
                            padding: "16px",
                            borderBottom: "1px solid #eeeeef",
                            backgroundColor: "rgba(20,165,215,.2)",
                            borderRadius: 0,
                          }
                        : {
                            padding: "16px",
                            borderBottom: "1px solid #eeeeef",
                            borderRadius: 0,
                          }
                    }
                    fullWidth
                    component={NavLink}
                    to={`/dashboard/d/${district.id}`}
                    onClick={() => {
                      props.readSceneAction("d", `${district.id}`);
                      handleClose();
                      closeMain();
                    }}
                    key={district.id}
                  >
                    <div className="d-flex flex-column align-items-start w-100">
                      <p
                        className="mb-0"
                        style={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          fontWeight: "600",
                          color: "rgba(43,39,60,1)",
                          textAlign: "left",
                        }}
                      >
                        {district.name}
                        {` `}
                        {district.active ? (
                          <span
                            aria-hidden="true"
                            style={{
                              fill: "rgb(10, 179, 201)",
                            }}
                          >
                            <svg width="24" height="24">
                              <path d="M9.46 17.52a1 1 0 01-.71-.29l-4-4a1.004 1.004 0 111.42-1.42l3.25 3.26 8.33-8.34a1.004 1.004 0 011.42 1.42l-9 9a1 1 0 01-.71.37z"></path>
                            </svg>
                          </span>
                        ) : null}
                      </p>
                      <div style={{ paddingTop: "4px" }}>
                        <p
                          className="mb-0"
                          style={{
                            fontWeight: "normal",
                            fontSize: "12px",
                            lineHeight: "18px",
                            color: "rgba(117,114,128,1)",
                            textAlign: "left",
                          }}
                        >
                          {district.defaultDistrict
                            ? "Default District"
                            : "District"}
                        </p>
                        <p
                          className="mb-0"
                          style={{
                            fontWeight: "normal",
                            fontSize: "12px",
                            lineHeight: "18px",
                            color: "rgba(117,114,128,1)",
                            textAlign: "left",
                          }}
                        >
                          {`Contains ${
                            Object.keys(district.schoolIds).length
                          } location${
                            Object.keys(district.schoolIds).length > 1
                              ? "s"
                              : ""
                          }`}
                        </p>
                      </div>
                    </div>
                  </LocationButton>
                ))
              : null}
          </div>
        ) : null}
      </StyledMenu>
    </div>
  );
}
