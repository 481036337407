import React from "react";
import SchoolWeeklyScorecard from "../../components/SchoolWeeklyScorecard";

function SchoolWeeklyScorecardPage() {
  return (
    <>
      <SchoolWeeklyScorecard />
    </>
  );
}

export default SchoolWeeklyScorecardPage;
