import { useState } from "react";
import { connect } from "react-redux";
import { userSelector, signOutAction } from "../../core";
import { NavLink } from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {
  ListItemText,
  Menu,
  Button,
  List,
  ListItem,
  Divider,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withStyles } from "@material-ui/core/styles";

import { logout } from "../../utils/Analytics";
import SidebarUserbox from "../SidebarUserbox";
import SidebarUserboxRight from "../SidebarUserboxRight";

const StyledMenu = withStyles({
  paper: {
    width: "300px",
    borderRadius: "4px",
    backgroundColor: "#fff",
    boxShadow: "0 0 18px rgb(0 0 0 / 15%)",
    marginTop: "5px",
  },
  list: {
    padding: 0,
    color: "#232323",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    disableScrollLock={true}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const HeaderUserbox = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="text"
        onClick={handleClick}
        className="ml-3 btn-transition-none text-left p-0 bg-transparent d-flex align-items-center"
        disableRipple
      >
        <div className="p-0 avatar-icon-wrapper bg-light rounded">
          <div
            className="btn-transition-none bg-primary text-white font-size-lg px-2 d-inline-block shadow-none border-0 text-center rounded position-relative d-flex flex-row align-items-center"
            style={{ height: "44px", lineHeight: "44px" }}
          >
            <span style={{ color: "rgba(255, 255, 255, 0.6)" }}>
              <PersonIcon />
            </span>
            {mobile ? null : (
              <span className="text-white font-size-sm mx-2">
                {props.user ? props.user.email.address : ""}
              </span>
            )}
          </div>
        </div>
      </Button>
      <StyledMenu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className="overflow-hidden p-0">
          <List component="nav" dense className="px-2">
            <ListItem className="px-2">
              <SidebarUserboxRight handleClose={handleClose} />
            </ListItem>
            <Divider className="my-2" />
            <ListItem
              button
              component={NavLink}
              to={`/account-settings`}
              onClick={handleClose}
              className="px-2"
            >
              <SettingsIcon />
              <ListItemText primary="Account Settings" className="ml-3" />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to={`/notifications`}
              onClick={handleClose}
              className="px-2"
            >
              <MailOutlineIcon />
              <ListItemText primary="Notifications" className="ml-3" />
            </ListItem>
            <Divider className="my-2" />
            <ListItem
              button
              onClick={() => {
                logout();
                props.signOutAction();
              }}
              className="px-2"
            >
              <ExitToAppIcon />
              <ListItemText primary="Logout" className="ml-3" />
            </ListItem>
          </List>
        </div>
      </StyledMenu>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: userSelector(state),
});

const mapDispatchToProps = {
  signOutAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserbox);
