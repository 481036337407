import React from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import {
  sceneSelector,
  userSelector,
  setSidebarToggleMobile,
  premiumSelector,
  readSceneAction,
} from "../../core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LocationSelection from "./components/locationSelection";

const SidebarUserbox = (props) => {
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down("md"));

  const toggleSidebarMobile = () => {
    props.setSidebarToggleMobile(false);
  };

  const { scene } = props;

  const handleStyles = (plan) => {
    if (plan === "Basic") {
      return {
        buttonBG: "bg-skim-blue",
        buttonStyle: {
          textShadow: "#5990bf 1px 1px 5px",
          cursor: "default",
        },
      };
    }
    if (plan === "In-school Curriculum") {
      return {
        buttonBG: "bg-skim-blue",
        buttonStyle: {
          textShadow: "#5990bf 1px 1px 5px",
          cursor: "default",
        },
      };
    }
    if (plan === "Demo - Premium") {
      return {
        buttonBG: "bg-plum-plate",
        buttonStyle: {
          cursor: "default",
        },
      };
    }
    if (plan === "Premium") {
      return {
        buttonBG: "bg-sunny-morning",
        buttonStyle: {
          textShadow: "0px 1px 5px rgb(206 134 45)",
          cursor: "default",
        },
      };
    }
    return {
      buttonBG: "bg-grow-early",
      buttonStyle: {
        cursor: "default",
      },
    };
  };

  const businessPlanBanner = () => {
    if (tablet) {
      return null;
    }
    if (!props.scene) {
      return null;
    }
    if (props.scene) {
      if (props.scene.type === "district" || props.scene.type === "none") {
        return null;
      }
      if (!props.scene.data) {
        return null;
      }
    }
    const schoolPlan = props.scene.data.plan;
    const stylesObj = handleStyles(schoolPlan);

    return (
      <Button
        size="small"
        variant="contained"
        disableRipple
        className={`shadow-none btn-transition-none ${stylesObj.buttonBG}`}
        style={stylesObj.buttonStyle}
      >
        <span
          className="btn-wrapper--label text-white font-weight-bold text-uppercase"
          style={{ fontSize: "11px" }}
        >
          {schoolPlan ? schoolPlan : "None"}
        </span>
      </Button>
    );
  };

  return (
    <div className="app-sidebar--userbox">
      <div
        className="userbox-details"
        style={{
          marginBottom: "8px",
          paddingBottom: "16px",
          borderBottom: "1px solid #eeeeef",
          marginTop: ".5rem",
          marginLeft: "1.5rem",
          marginRight: "1.5rem",
        }}
      >
        <div>
          <div>
            <div>
              {scene ? (
                scene.data ? (
                  scene.type === "school" ? (
                    <div>
                      <h4
                        style={{
                          wordWrap: "break-word !important",
                          wordBreak: "break-word !important",
                          overflowWrap: "break-word !important",
                          color: "rgba(43,39,60,1)",
                          fontWeight: "bold",
                          fontSize: "20px",
                          lineHeight: "26px",
                        }}
                        className="mb-0"
                      >
                        {scene.data.name}
                      </h4>
                      {/* <div style={{ paddingTop: "8px" }}>
                        {businessPlanBanner()}
                      </div> */}
                      <div style={{ paddingTop: "8px" }}>
                        <p
                          className="mb-0"
                          style={{
                            fontWeight: "normal",
                            fontSize: "12px",
                            lineHeight: "18px",
                            color: "rgba(117,114,128,1)",
                            textAlign: "left",
                          }}
                        >
                          {scene.data.location.address}
                        </p>
                        <p
                          className="mb-0"
                          style={{
                            fontWeight: "normal",
                            fontSize: "12px",
                            lineHeight: "18px",
                            color: "rgba(117,114,128,1)",
                            textAlign: "left",
                          }}
                        >
                          {`${scene.data.location.city}${
                            scene.data.location.state
                              ? `, ${scene.data.location.state} ${scene.data.location.zipcode}`
                              : null
                          }`}
                        </p>
                      </div>
                    </div>
                  ) : scene.type === "district" ? (
                    <div>
                      <h4
                        style={{
                          wordWrap: "break-word !important",
                          wordBreak: "break-word !important",
                          overflowWrap: "break-word !important",
                          color: "rgba(43,39,60,1)",
                          fontWeight: "bold",
                          fontSize: "20px",
                          lineHeight: "26px",
                        }}
                        className="mb-0"
                      >
                        {scene.data.name}
                      </h4>
                      <div style={{ paddingTop: "8px" }}>
                        <p
                          className="mb-0"
                          style={{
                            fontWeight: "normal",
                            fontSize: "12px",
                            lineHeight: "18px",
                            color: "rgba(117,114,128,1)",
                            textAlign: "left",
                          }}
                        >
                          {scene.data.defaultDistrict
                            ? "Default District"
                            : "District"}
                        </p>
                        <p
                          className="mb-0"
                          style={{
                            fontWeight: "normal",
                            fontSize: "12px",
                            lineHeight: "18px",
                            color: "rgba(117,114,128,1)",
                            textAlign: "left",
                          }}
                        >
                          {`Contains ${
                            Object.keys(scene.data.schoolIds).length
                          } location${
                            Object.keys(scene.data.schoolIds).length > 1
                              ? "s"
                              : ""
                          }`}
                        </p>
                      </div>
                    </div>
                  ) : null
                ) : null
              ) : null}
            </div>
          </div>
          {/* <div style={{ paddingTop: "8px" }}>
            <LocationSelection
              user={props.user}
              scene={props.scene ? props.scene : null}
              readSceneAction={props.readSceneAction}
              toggleSidebarMobile={toggleSidebarMobile}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  scene: sceneSelector(state),
  user: userSelector(state),
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  premium: premiumSelector(state),
});
const mapDispatchToProps = {
  setSidebarToggleMobile,
  readSceneAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarUserbox);
