import { VideoShortDBService } from "../UseCases/VideoShort";
import { VideoShort } from "../Entities/VideoShort";
import { VideoShortServices } from "./Backend";

export class VideoShortService implements VideoShortDBService {
  async createVideoShort(videoShort: VideoShort): Promise<VideoShort> {
    return VideoShortServices.createVideoShort(videoShort);
  }
  async updateVideoShort(videoShort: VideoShort): Promise<VideoShort> {
    return VideoShortServices.updateVideoShort(videoShort);
  }
  async deleteVideoShort(activity: VideoShort): Promise<void> {
    return VideoShortServices.deleteVideoShort(activity);
  }
}
