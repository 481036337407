import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class MyInnerRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <NavLink
        to={`/dashboard/d/${this.props.data.id}`}
        onClick={() => this.props.onSelectDistrict(this.props.data)}
      >
        {this.props.data.name}
      </NavLink>
    );
  }
}

export default MyInnerRenderer;
