import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { schoolSelector, appSelector, userSelector } from "../../core";
import { CircleLoader } from "react-spinners";

import BallotTwoToneIcon from "@material-ui/icons/BallotTwoTone";
import MenuBookTwoToneIcon from "@material-ui/icons/MenuBookTwoTone";
import HomeWorkTwoToneIcon from "@material-ui/icons/HomeWorkTwoTone";
import FastfoodTwoToneIcon from "@material-ui/icons/FastfoodTwoTone";
import AssessmentTwoToneIcon from "@material-ui/icons/AssessmentTwoTone";
import AddTwoTone from "@material-ui/icons/AddTwoTone";
import TeamTwoToneIcon from "@material-ui/icons/SupervisorAccountTwoTone";
import PersonTwoToneIcon from "@material-ui/icons/PersonTwoTone";
import SyncAltTwoToneIcon from "@material-ui/icons/SyncAltTwoTone";

import EmailTwoToneIcon from "@material-ui/icons/EmailTwoTone";

// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const PageTitle = (props) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));

  const {
    pageTitleStyle,
    pageTitleBackground,
    pageTitleShadow,
    pageTitleIconBox,
    pageTitleDescription,
    titleHeading,
    titleDescription,
    children,
    icon,
  } = props;

  return (
    <>
      <div
        className={clsx("app-page-title", pageTitleStyle, pageTitleBackground, {
          "app-page-title--shadow": pageTitleShadow,
        })}
      >
        <div>
          <div
            className={
              tablet
                ? "app-page-title--first mb-1 mt-1"
                : "app-page-title--first"
            }
          >
            {!tablet && pageTitleIconBox && (
              <div className="app-page-title--iconbox d-70">
                <div className="d-70 d-flex align-items-center justify-content-center display-1">
                  {icon === "Dashboard" ? (
                    <BallotTwoToneIcon className="text-primary" />
                  ) : icon === "Menu" ? (
                    <MenuBookTwoToneIcon className="text-primary" />
                  ) : icon === "Deals" ? (
                    <FastfoodTwoToneIcon className="text-primary" />
                  ) : icon === "Analytics" ? (
                    <AssessmentTwoToneIcon className="text-primary" />
                  ) : icon === "plus" ? (
                    <AddTwoTone className="text-primary" />
                  ) : icon === "account-settings" ? (
                    <PersonTwoToneIcon className="text-primary" />
                  ) : icon === "team" ? (
                    <TeamTwoToneIcon className="text-primary" />
                  ) : icon === "BizSync" ? (
                    <SyncAltTwoToneIcon className="text-primary" />
                  ) : icon === "Email" ? (
                    <EmailTwoToneIcon className="text-primary" />
                  ) : (
                    <HomeWorkTwoToneIcon className="text-primary" />
                  )}
                </div>
              </div>
            )}
            <div className="app-page-title--heading">
              <div
                className={
                  tablet
                    ? "d-flex align-items-center justify-content-center"
                    : "d-flex align-items-center"
                }
              >
                <h1 className="mr-3">{titleHeading}</h1>
                {/* {titleHeading === "Review Management" ? (
                  <sup
                    style={{
                      display: "inline",
                      fontSize: "10px",
                      textAlign: "center",
                      fontWeight: "600",
                      lineHeight: 1,
                    }}
                  >
                    NEW
                  </sup>
                ) : null} */}
                {props.app.venueLoading ||
                  (props.app.isLoading && (
                    <CircleLoader
                      className="d-flex align-self-center"
                      color={"#624185"}
                      loading={true}
                      size={25}
                    />
                  ))}
              </div>
              {pageTitleDescription && (
                <div className="app-page-title--description">
                  {titleDescription}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  pageTitleStyle: state.ThemeOptions.pageTitleStyle,
  pageTitleBackground: state.ThemeOptions.pageTitleBackground,
  pageTitleShadow: state.ThemeOptions.pageTitleShadow,
  pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
  pageTitleDescription: state.ThemeOptions.pageTitleDescription,
  school: schoolSelector(state),
  user: userSelector(state),
  app: appSelector(state),
});

export default connect(mapStateToProps)(PageTitle);
