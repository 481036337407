import { VideoShort } from "../Entities/VideoShort";

export interface VideoShortDBService {
  createVideoShort: (videoShort: VideoShort) => Promise<VideoShort>;
  updateVideoShort: (videoShort: VideoShort) => Promise<VideoShort>;
  deleteVideoShort: (videoShort: VideoShort) => Promise<void>;
}

class VideoShortUseCase {
  videoShortService: VideoShortDBService;

  constructor(videoShortService: VideoShortDBService) {
    this.videoShortService = videoShortService;
  }
}

export class CreateVideoShortInteractor extends VideoShortUseCase {
  async createVideoShort(videoShort: VideoShort): Promise<VideoShort> {
    return this.videoShortService.createVideoShort(videoShort);
  }
}

export class UpdateVideoShortInteractor extends VideoShortUseCase {
  async updateVideoShort(videoShort: VideoShort): Promise<VideoShort> {
    return this.videoShortService.updateVideoShort(videoShort);
  }
}

export class DeleteVideoShortInteractor extends VideoShortUseCase {
  async deleteVideoShort(videoShort: VideoShort): Promise<void> {
    return this.videoShortService.deleteVideoShort(videoShort);
  }
}
