import { appReducer } from "./App";
import { userReducer } from "./SchoolUser";
import { schoolReducer } from "./School";
import { inviteReducer } from "./Invite";
import { checkoutReducer } from "./Checkout";
import { premiumReducer } from "./Premium";
import { gridReducer } from "./Grid";
import { sceneReducer } from "./Scene";
import ThemeOptions from "./ThemeOptions";

export { appSelector, updateAppAction } from "./App";
export {
  userSelector,
  updateUserStateAction,
  updateUserSchoolStateAction,
  updateUserDistrictStateAction,
  updateUserEmailStateAction,
  deleteUserSchoolStateAction,
  deleteUserDistrictStateAction,
} from "./SchoolUser";
export {
  schoolSelector,
  updateSchoolStateAction,
  updateSchoolAnalyticsStateAction,
} from "./School";
export { inviteSelector, updateInviteStateAction } from "./Invite";
export { checkoutSelector, toggleCheckoutStateAction } from "./Checkout";
export { premiumSelector, updatePremiumStateAction } from "./Premium";
export { gridSelector, updateGridStateAction } from "./Grid";

export { sceneSelector, updateSceneStateAction } from "./Scene";

export {
  SET_PAGE_TITLE_DESCRIPTION,
  SET_PAGE_TITLE_ICON_BOX,
  SET_PAGE_TITLE_SHADOW,
  SET_PAGE_TITLE_BACKGROUND,
  SET_PAGE_TITLE_STYLE,
  SET_FOOTER_BG_TRANSPARENT,
  SET_FOOTER_SHADOW,
  SET_FOOTER_FIXED,
  SET_THEME_CONFIGURATOR_TOGGLE,
  SET_CONTENT_BACKGROUND,
  SET_HEADER_DRAWER_TOGGLE,
  SET_HEADER_SEARCH_HOVER,
  SET_HEADER_BG_TRANSPARENT,
  SET_HEADER_SHADOW,
  SET_HEADER_FIXED,
  SET_SIDEBAR_USERBOX,
  SET_SIDEBAR_TOGGLE,
  SET_SIDEBAR_TOGGLE_MOBILE,
  SET_SIDEBAR_FOOTER,
  SET_SIDEBAR_STYLE,
  SET_SIDEBAR_FIXED,
  SET_SIDEBAR_SHADOW,
  setPageTitleStyle,
  setPageTitleBackground,
  setPageTitleShadow,
  setPageTitleIconBox,
  setPageTitleDescription,
  setFooterBgTransparent,
  setFooterShadow,
  setFooterFixed,
  setThemeConfiguratorToggle,
  setContentBackground,
  setHeaderDrawerToggle,
  setHeaderSearchHover,
  setHeaderBgTransparent,
  setHeaderShadow,
  setHeaderFixed,
  setSidebarUserbox,
  setSidebarToggle,
  setSidebarToggleMobile,
  setSidebarFooter,
  setSidebarStyle,
  setSidebarFixed,
  setSidebarShadow,
} from "./ThemeOptions";

export const rootReducer = {
  app: appReducer,
  user: userReducer,
  school: schoolReducer,
  invite: inviteReducer,
  checkout: checkoutReducer,
  premium: premiumReducer,
  grid: gridReducer,
  scene: sceneReducer,
  ThemeOptions,
};
