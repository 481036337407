import AdminEvaluationAnalytics from "../../components/AdminEvaluationAnalytics";

function AdminEvaluationAnalyticsPage() {
  return (
    <>
      <AdminEvaluationAnalytics />
    </>
  );
}

export default AdminEvaluationAnalyticsPage;
