import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { appSelector, userSelector, ParentPartiesAPIs } from "../../core";
import { Grid, List, ListItem, LinearProgress, Card } from "@material-ui/core";
import { NavLink } from "react-router-dom";

// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { CircleLoader } from "react-spinners";

function AdminEvaluationAnalytics(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [initialLoad, setInitialLoad] = useState(true);
  const [evaluationAnswers, setEvaluationAnswers] = useState([]);
  const [totalResponses, setTotalResponses] = useState(0);

  const [englishQuestions, setEnglishQuestions] = useState([]);

  useEffect(() => {
    async function getResponses() {
      const responses = await ParentPartiesAPIs.readAllEvaluationResponses();
      setTotalResponses(responses ? responses.length : 0);
      const questions = responses
        ? [
            ...new Set(
              responses
                .map((res) =>
                  res.responses
                    ? res.responses.map((res1) => res1.question)
                    : []
                )
                .flat()
            ),
          ]
        : [];
      const arrayOfResponses = responses
        ? responses.map((res) => res.responses).flat()
        : [];

      const arrayOfAnswers = questions
        ? questions
            .map((question) => {
              const questionResponses = arrayOfResponses.filter((res) =>
                res ? res.question === question : false
              );

              const totalRating = questionResponses.reduce((a, b) => {
                return a + (b.answer || 0);
              }, 0);

              const average = (totalRating / questionResponses.length).toFixed(
                1
              );
              const percent = average ? (average / 5) * 100 : 0;

              return {
                question,
                totalResponses: questionResponses.length,
                average,
                percent,
              };
            })
            .sort((a, b) => b.totalResponses - a.totalResponses)
        : [];

      setEvaluationAnswers(arrayOfAnswers);

      const appSettings = await ParentPartiesAPIs.readAppSettings();
      if (appSettings.parentEvaluationQuestionsEnglish) {
        setEnglishQuestions(
          appSettings.parentEvaluationQuestionsEnglish
            ? appSettings.parentEvaluationQuestionsEnglish
            : []
        );
      }

      setInitialLoad(false);
    }

    getResponses();
  }, []);

  return !props.user ? null : initialLoad || props.app.isLoading ? (
    <Grid container className="my-spacing-6">
      <Grid item xs={12}>
        <div className="d-flex flex-row justify-content-center">
          <CircleLoader
            className="d-flex align-self-center"
            color={"#18a9a9"}
            loading={true}
            size={40}
          />
        </div>
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
        <Grid item md={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              Admin Reporting
            </div>
          </div>
        </Grid>
        <Grid item md={12} xl={12}>
          {mobile ? null : (
            <List
              component="div"
              className="nav-line-alt nav-line d-flex align-items-center mt-3"
            >
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-guardians-analytics`}
              >
                <span className="font-size-sm">Guardians</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-activity-analytics`}
              >
                <span className="font-size-sm">Activities</span>
                <div className="divider" />
              </ListItem>
              <ListItem button disableRipple className="px-2" selected>
                <span className="font-size-sm">Parent Evaluations</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-weekly-scorecard-analytics`}
              >
                <span className="font-size-sm">Weekly Scorecards</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to={`/admin-analytics`}
                className="pr-2"
              >
                <span className="font-size-sm">{"Reports"}</span>
                <div className="divider" />
              </ListItem>
            </List>
          )}
        </Grid>
        <Grid item md={12}>
          <div
            style={{
              marginBottom: 15,
              marginTop: 40,
            }}
            className="mb-4"
          >
            <div
              className="heading-4 text-left mb-0"
              style={{
                color: "#000",
                textTransform: "none",
              }}
            >
              {`Total Evaluation Responses: ${
                totalResponses ? totalResponses : 0
              }`}
            </div>
            <div>
              <small className="text-black-50">
                The table below shows the average response rating for each of
                your current parent evaluation questions.
              </small>
            </div>
          </div>
          <Card
            className="p-3"
            style={{
              borderRadius: "0.25rem",
              border: "1px solid rgba(0,0,0,.1)",
            }}
          >
            {evaluationAnswers
              .filter((item) =>
                englishQuestions.map((z) => z.question).includes(item.question)
              )
              .map((item, index) => (
                <ListItem className="pt-3 pb-3 d-block" key={index}>
                  <div className="align-box-row py-1">
                    <div>
                      <div className="text-black">{item.question}</div>
                    </div>
                  </div>
                  <div className="d-flex flex-row align-item-center mt-2">
                    <div
                      style={{
                        width: "100%",
                        marginRight: 16,
                      }}
                    >
                      <LinearProgress
                        variant="determinate"
                        className={`${
                          item.percent <= 40
                            ? "progress-bar-danger"
                            : item.percent > 40 && item.percent < 70
                            ? "progress-bar-warning"
                            : "progress-bar-success"
                        } w-100`}
                        value={item.percent}
                        style={{ height: ".5rem" }}
                      />
                      <div className="d-flex flex-row justify-content-between">
                        <div className="text-black-50 font-size-sm">
                          Strongly Disagree
                        </div>
                        <div className="text-black-50 font-size-sm">
                          Strongly Agree
                        </div>
                      </div>
                    </div>
                    <div style={{ minWidth: 180 }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div style={{ color: "#000" }} className="font-size-sm">
                          {`${item.average} Average Rating`}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div style={{ color: "#000" }} className="font-size-sm">
                          {`${item.totalResponses} responses`}
                        </div>
                      </div>
                    </div>
                  </div>
                </ListItem>
              ))}
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
});

export default connect(mapStateToProps, null)(AdminEvaluationAnalytics);
