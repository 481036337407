import React from "react";
import AdminResourcesUploads from "../../components/AdminResourcesUploads";

function AdminResourcesUploadsPage() {
  return (
    <>
      <AdminResourcesUploads />
    </>
  );
}

export default AdminResourcesUploadsPage;
