import { AuthService } from "../UseCases/SchoolUser";
import { Credential, Email, SchoolUser, SchoolUserRecord } from "../Entities";
import { AuthServices, SchoolUserServices } from "./Backend";

export class SchoolUserService implements AuthService {
  async signUp(
    userRecord: SchoolUserRecord,
    credential: Credential,
    emailConfirmed: Boolean
  ): Promise<SchoolUser> {
    const userId = await AuthServices.signUp(credential);
    const user = new SchoolUser({
      ...userRecord,
      emailVerified: emailConfirmed ? true : userRecord.emailVerified,
      id: userId,
    });

    return SchoolUserServices.createSchoolUser(user);
  }

  async signIn(credential: Credential): Promise<SchoolUser | null> {
    const userId = await AuthServices.signIn(credential);
    let user = await SchoolUserServices.readUser(userId);
    if (user) {
      const checkEmailVerfied = await AuthServices.checkEmailVerified();

      if (checkEmailVerfied && !user.emailVerified) {
        user.emailVerified = true;
        user = await SchoolUserServices.updateUser(user);
      }

      return user;
    }
    return null;
  }

  async resetPassword(email: Email): Promise<void> {
    return AuthServices.resetPassword(email);
  }

  async sendVerificationEmail(): Promise<void> {
    return AuthServices.sendEmailVerification();
  }

  async verifyEmail(user: SchoolUser): Promise<SchoolUser> {
    await AuthServices.refreshUser();
    const checkEmailVerfied = await AuthServices.checkEmailVerified();

    if (checkEmailVerfied && !user.emailVerified) {
      user.emailVerified = true;
      user = await SchoolUserServices.updateUser(user);
    }

    return user;
  }

  async signOut(): Promise<void> {
    return AuthServices.signOut();
  }

  onAuthStateChange(handler: (user: SchoolUser | null) => void): void {
    AuthServices.listenToAuthState(async (userId) => {
      if (userId) {
        let user = await SchoolUserServices.readUser(userId);
        if (user) {
          const checkEmailVerfied = await AuthServices.checkEmailVerified();

          if (checkEmailVerfied && !user.emailVerified) {
            user.emailVerified = true;
            user = await SchoolUserServices.updateUser(user);
          }
        }
      }

      return userId
        ? SchoolUserServices.listenToUser(userId, handler)
        : handler(null);
    });
  }

  async updateUser(user: SchoolUser): Promise<SchoolUser> {
    return SchoolUserServices.updateUser(user);
  }

  async readUser(userId: string): Promise<SchoolUser | null> {
    return SchoolUserServices.readUser(userId);
  }
}
