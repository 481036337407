import { all, put } from "redux-saga/effects";
import { Scene, District, School, SchoolRecord } from "../../Entities";
import {
  updateAppAction,
  updateSchoolStateAction,
  updateUserSchoolStateAction,
  updateUserDistrictStateAction,
  updatePremiumStateAction,
  updateUserEmailStateAction,
  updateSceneStateAction,
} from "../Redux";
import { PremiumService, SchoolService, DistrictService } from "../../Services";
import {
  CreateSchoolInteractor,
  ReadSchoolInteractor,
  UpdateSchoolInteractor,
  FetchPremiumInteractor,
  ReadDistrictInteractor,
  RemoveFromSchoolInteractor,
} from "../../UseCases";

export const CREATE_SCHOOL = "school/saga/create_school";
export const READ_SCHOOL = "school/saga/read_school";
export const UPDATE_SCHOOL = "school/saga/update_school";
export const DELETE_SCHOOL = "school/saga/delete_school";
export const FETCH_SCHOOL_ANALYTICS = "school/saga/fetch_school_analytics";
export const REMOVE_FROM_SCHOOL = "invite/saga/remove_from_school";

interface SchoolActionCreate {
  type: string;
  school: SchoolRecord;
}

interface SchoolActionUpdate {
  type: string;
  school: SchoolRecord;
  skipLoading?: Boolean;
}

export const createSchoolAction = (
  school: SchoolRecord
): SchoolActionCreate => ({
  type: CREATE_SCHOOL,
  school,
});

export function* createSchoolSaga(action: SchoolActionCreate) {
  yield put(updateAppAction({ isLoading: true }));

  const { school: tempSchool } = action;

  const service = new SchoolService();
  const interactor = new CreateSchoolInteractor(service);
  const interactor2 = new ReadSchoolInteractor(service);
  const premiumService = new PremiumService();
  const premiumInteractor = new FetchPremiumInteractor(premiumService);
  const districtService = new DistrictService();
  const districtInteractor = new ReadDistrictInteractor(districtService);

  try {
    const {
      school,
      district,
    }: {
      school: School;
      district?: District | null;
    } = yield interactor.createSchool(tempSchool);
    // Admin created school
    if (!district) {
      yield put(updateAppAction({ isLoading: false }));
      return;
    }

    const premiumObj = school.id
      ? yield premiumInteractor.fetchPremium(school.id as any)
      : undefined;

    if (school.id) {
      const readSchool = yield interactor2.readSchool(school.id);
      const readDistrict = yield districtInteractor.readDistrict(
        district.id as string
      );
      const updatedScene: Scene = {
        id: readSchool.id,
        type: "school",
        data: readSchool,
      };

      yield all([
        put(updateUserEmailStateAction(readSchool)),
        put(updateUserSchoolStateAction(readSchool)),
        put(updateUserDistrictStateAction(readDistrict)),
        put(updateSceneStateAction(updatedScene)),
        put(updatePremiumStateAction(premiumObj || undefined)),
        put(updateAppAction({ isLoading: false })),
      ]);
    } else {
      yield put(updateAppAction({ isLoading: false }));
    }
  } catch (error) {
    // @ts-ignore
    yield put(updateAppAction({ isLoading: false, error }));
  }
}

interface SchoolSelectionActionType {
  type: string;
  schoolId?: number | string;
  adminSupport?: boolean;
}

export const readSchoolAction = (
  schoolId?: number | string
): SchoolSelectionActionType => ({
  type: READ_SCHOOL,
  schoolId,
});

export function* readSchoolSaga(action: SchoolSelectionActionType) {
  yield put(updateAppAction({ isLoading: true }));

  const { schoolId } = action;
  if (!schoolId) {
    return yield all([
      put(updateSchoolStateAction()),
      put(updatePremiumStateAction(undefined)),
      put(updateAppAction({ isLoading: false })),
    ]);
  }

  const service = new SchoolService();
  const premiumService = new PremiumService();
  const interactor = new ReadSchoolInteractor(service);
  const premiumInteractor = new FetchPremiumInteractor(premiumService);

  try {
    const premiumObj = schoolId
      ? yield premiumInteractor.fetchPremium(schoolId as any)
      : undefined;

    const readSchool = yield interactor.readSchool(schoolId);
    const updatedScene: Scene = {
      id: readSchool.id,
      type: "school",
      data: readSchool,
    };

    yield all([
      put(updateSceneStateAction(updatedScene)),
      put(updatePremiumStateAction(premiumObj || undefined)),
      put(updateAppAction({ isLoading: false })),
    ]);
  } catch (error) {
    // @ts-ignore
    yield put(updateAppAction({ isLoading: false, error }));
  }
}

export const updateSchoolAction = (
  school: SchoolRecord,
  skipLoading?: Boolean
): SchoolActionUpdate => ({
  type: UPDATE_SCHOOL,
  school,
  skipLoading,
});

export function* updateSchoolSaga(action: SchoolActionUpdate) {
  const { school: tempSchool, skipLoading } = action;
  if (!skipLoading) {
    yield put(updateAppAction({ isLoading: true }));
  }
  if (!tempSchool.id) {
    yield put(
      updateAppAction({
        isLoading: false,
        error: new Error("Cannot update school: Invalid school ID"),
      })
    );
    return;
  }

  const service = new SchoolService();
  const interactor = new ReadSchoolInteractor(service);
  const updateInteractor = new UpdateSchoolInteractor(service);

  try {
    yield updateInteractor.updateSchool(tempSchool);
    const readSchool = yield interactor.readSchool(tempSchool.id);
    const updatedScene: Scene = {
      id: readSchool.id,
      type: "school",
      data: readSchool,
    };

    yield all([
      put(updateSceneStateAction(updatedScene)),
      put(updateAppAction({ isLoading: false })),
    ]);
  } catch (error) {
    // @ts-ignore
    yield put(updateAppAction({ isLoading: false, error }));
  }
}

export const removeFromSchoolAction = (
  employeeId: string,
  schoolId: number | string
): any => ({
  type: REMOVE_FROM_SCHOOL,
  employeeId,
  schoolId,
});

export function* removeFromSchoolSaga(action: any) {
  yield put(updateAppAction({ isLoading: true }));

  const schoolService = new SchoolService();
  const readSchoolInteractor = new ReadSchoolInteractor(schoolService);
  const removeSchoolInteractor = new RemoveFromSchoolInteractor(schoolService);

  const { employeeId, schoolId } = action;
  if (!employeeId) {
    yield put(
      updateAppAction({
        isLoading: false,
        error: new Error("Employee ID cannot be undefined"),
      })
    );
  } else if (!schoolId) {
    yield put(
      updateAppAction({
        isLoading: false,
        error: new Error("School ID cannot be undefined"),
      })
    );
  } else {
    try {
      yield removeSchoolInteractor.removeFromSchool(employeeId, schoolId);
      const school: School = yield readSchoolInteractor.readSchool(schoolId);

      const updatedScene: Scene = {
        id: school.id,
        type: "school",
        data: school,
      };

      yield all([
        put(updateSceneStateAction(updatedScene)),
        put(updateAppAction({ isLoading: false })),
      ]);
    } catch (error) {
      // @ts-ignore
      yield put(updateAppAction({ isLoading: false, error }));
    }
  }
}
