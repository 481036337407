import React from "react";
import AdminDistricts from "../../components/AdminDistricts";

function AdminDistrictsPage() {
  return (
    <>
      <AdminDistricts />
    </>
  );
}

export default AdminDistrictsPage;
