import { Scene } from "../../Entities";
import { initialState, StateType } from "./State";

const UPDATE_SCENE = "scene/update";

type StateSlice = StateType["scene"];

export interface UpdateSceneActionType {
  type: string;
  scene: Scene | null;
}

export const sceneSelector = (state: StateType): StateSlice => state.scene;

export const updateSceneStateAction = (
  scene: Scene
): UpdateSceneActionType => ({
  type: UPDATE_SCENE,
  scene: scene,
});

const updateSceneHandler = (
  state: StateSlice,
  action: UpdateSceneActionType
): StateType["scene"] => {
  return action.scene;
};

export const sceneReducer = (
  scene: StateSlice = initialState.scene,
  action: any
): StateSlice => {
  switch (action.type) {
    case UPDATE_SCENE:
      return updateSceneHandler(scene, action);
    default:
      return scene;
  }
};
