import React from "react";
import SchoolResources from "../../components/SchoolResources";

function SchoolResourcesPage() {
  return (
    <>
      <SchoolResources />
    </>
  );
}

export default SchoolResourcesPage;
