import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  sceneSelector,
  appSelector,
  userSelector,
  readSceneAction,
  ParentPartiesAPIs,
} from "../../core";
import { Grid, List, ListItem } from "@material-ui/core";
import AGGrid from "../Grid";
import { NavLink } from "react-router-dom";

// Responsive //
import { CircleLoader } from "react-spinners";

function SchoolResourcesGeneral(props) {
  const [initialLoad, setInitialLoad] = useState(true);

  const [generalFiles, setGeneralFiles] = useState([]);

  const schoolCurriculumId = props.scene
    ? props.scene.data
      ? props.scene.data.curriculum
        ? props.scene.data.curriculum
        : ""
      : ""
    : "";
  useEffect(() => {
    async function getFiles() {
      const generalFiles = await ParentPartiesAPIs.readAdminGeneralFiles();
      const arrayOfGenFiles = generalFiles ? Object.values(generalFiles) : [];
      setGeneralFiles(arrayOfGenFiles);

      setInitialLoad(false);
    }

    getFiles();
  }, [schoolCurriculumId, props.scene]);

  return !props.user ? null : props.scene ? (
    initialLoad || props.app.isLoading ? (
      <Grid container className="my-spacing-6">
        <Grid item xs={12}>
          <div className="d-flex flex-row justify-content-center">
            <CircleLoader
              className="d-flex align-self-center"
              color={"#18a9a9"}
              loading={true}
              size={40}
            />
          </div>
        </Grid>
      </Grid>
    ) : (
      <>
        <Grid container spacing={4} style={{ marginBottom: "6rem" }}>
          <Grid item md={12}>
            <div
              style={{
                paddingTop: 10,
              }}
            >
              <div
                className="font-weight-bold display-4"
                style={{ color: "rgb(43, 39, 60)" }}
              >
                Host a Parent Party
              </div>
              <small className="font-size-md text-black-50 mt-4">
                In-School Curriculum
              </small>
            </div>
          </Grid>
          <Grid item md={12} xl={12}>
            <List
              component="div"
              className="nav-line-alt nav-line d-flex align-items-center mt-3"
            >
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/resources-in-person/s/${props.scene.id}`}
              >
                <span className="font-size-sm">Monthly</span>
                <div className="divider" />
              </ListItem>
              <ListItem button disableRipple selected>
                <span className="font-size-sm">General Files</span>
                <div className="divider" />
              </ListItem>
            </List>
          </Grid>
          <Grid item md={12}>
            <AGGrid
              smallTitle={true}
              titleGrid={
                <div>
                  <div>General Files</div>
                </div>
              }
              search={true}
              statusBar={false}
              rowData={generalFiles}
              columnDefs={[
                {
                  headerName: "File Name",
                  field: "name",
                  sortable: true,
                  filter: true,
                },
                {
                  width: 280,
                  suppressSizeToFit: true,
                  field: "actions",
                  headerName: "Actions",
                  cellRendererFramework: (params) => (
                    <div className="d-flex flex-row align-items-center">
                      <a
                        href={params.data.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained bg-dark shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                        tabIndex="0"
                        type="button"
                      >
                        <span className="MuiButton-label">
                          <span
                            className="btn-wrapper--label text-white"
                            style={{ fontSize: "12px" }}
                          >
                            Preview
                          </span>
                        </span>
                        <span className="MuiTouchRipple-root"></span>
                      </a>
                    </div>
                  ),
                  cellClass: "agGridCellCenter",
                },
              ]}
            />
          </Grid>
        </Grid>
      </>
    )
  ) : null;
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = {
  readSceneAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolResourcesGeneral);
