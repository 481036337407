import React from "react";
import SchoolResourcesInPersonGeneral from "../../components/SchoolResourcesInPersonGeneral";

function SchoolResourcesInPersonGeneralPage() {
  return (
    <>
      <SchoolResourcesInPersonGeneral />
    </>
  );
}

export default SchoolResourcesInPersonGeneralPage;
