import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { appSelector, userSelector, ParentPartiesAPIs } from "../../core";
import { Grid, List, ListItem, Card, LinearProgress } from "@material-ui/core";
import { NavLink } from "react-router-dom";

// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { CircleLoader } from "react-spinners";

function AdminScorecardAnalytics(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [initialLoad, setInitialLoad] = useState(true);
  const [scorecardAnswers, setScorecardAnswers] = useState([]);
  const [totalResponses, setTotalResponses] = useState(0);

  useEffect(() => {
    async function getResponses() {
      const responses = await ParentPartiesAPIs.readAllResponses();
      setTotalResponses(responses ? responses.length : 0);
      const questions = responses
        ? [
            ...new Set(
              responses
                .map((res) => res.responses.map((res1) => res1.question))
                .flat()
            ),
          ]
        : [];
      const arrayOfResponses = responses
        ? responses.map((res) => res.responses).flat()
        : [];
      const arrayOfAnswers = questions
        ? questions
            .map((question) => {
              const trueAmount = arrayOfResponses.filter(
                (res) => res.question === question && res.answer === true
              ).length;
              const falseAmount = arrayOfResponses.filter(
                (res) => res.question === question && res.answer === false
              ).length;
              const unansweredAmount = arrayOfResponses.filter(
                (res) => res.question === question && res.answer === "none"
              ).length;
              const totalAmount = trueAmount + falseAmount + unansweredAmount;
              const truePercent = trueAmount
                ? (trueAmount / totalAmount) * 100
                : 0;
              const falsePercent = falseAmount
                ? (falseAmount / totalAmount) * 100
                : 0;
              const unansweredPercent = unansweredAmount
                ? (unansweredAmount / totalAmount) * 100
                : 0;
              const percentage = totalAmount
                ? totalAmount / arrayOfResponses.length
                : 0;

              return {
                question,
                trueAmount,
                falseAmount,
                unansweredAmount,
                totalAmount,
                percentage,
                truePercent,
                falsePercent,
                unansweredPercent,
              };
            })
            .sort((a, b) => b.totalAmount - a.totalAmount)
        : [];
      setScorecardAnswers(arrayOfAnswers);

      setInitialLoad(false);
    }

    getResponses();
  }, []);

  return !props.user ? null : initialLoad || props.app.isLoading ? (
    <Grid container className="my-spacing-6">
      <Grid item xs={12}>
        <div className="d-flex flex-row justify-content-center">
          <CircleLoader
            className="d-flex align-self-center"
            color={"#18a9a9"}
            loading={true}
            size={40}
          />
        </div>
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
        <Grid item md={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              Admin Reporting
            </div>
          </div>
        </Grid>
        <Grid item md={12} xl={12}>
          {mobile ? null : (
            <List
              component="div"
              className="nav-line-alt nav-line d-flex align-items-center mt-3"
            >
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-guardians-analytics`}
              >
                <span className="font-size-sm">Guardians</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-activity-analytics`}
              >
                <span className="font-size-sm">Activities</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-evaluation-analytics`}
              >
                <span className="font-size-sm">Parent Evaluations</span>
                <div className="divider" />
              </ListItem>
              <ListItem button disableRipple className="px-2" selected>
                <span className="font-size-sm">Weekly Scorecards</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to={`/admin-analytics`}
                className="pr-2"
              >
                <span className="font-size-sm">{"Reports"}</span>
                <div className="divider" />
              </ListItem>
            </List>
          )}
        </Grid>
        <Grid item md={12}>
          <div
            style={{
              marginBottom: 15,
              marginTop: 40,
            }}
          >
            <div
              className="heading-4 text-left"
              style={{
                color: "#000",
                textTransform: "none",
              }}
            >
              {`Scorecard Responses: ${totalResponses ? totalResponses : 0}`}
            </div>
          </div>
          <Card
            className="p-3"
            style={{
              borderRadius: "0.25rem",
              border: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <>
              <List component="div" className="list-group-flush">
                {scorecardAnswers.map((scorecardAnswer, index) => {
                  return (
                    <ListItem className="pt-3 pb-3 d-block" key={index}>
                      <div className="align-box-row py-1">
                        <div>
                          <div className="text-black">
                            {scorecardAnswer.question}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-row align-item-center">
                        <div
                          style={{
                            width: "100%",
                            marginRight: 16,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <LinearProgress
                            variant="determinate"
                            className="progress-bar-success w-100"
                            value={scorecardAnswer.truePercent}
                            style={{ height: ".5rem" }}
                          />
                        </div>
                        <div
                          style={{
                            minWidth: 200,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="font-size-sm text-black">Yes</div>
                          <div
                            style={{ color: "#000" }}
                            className="font-size-sm"
                          >{`${scorecardAnswer.trueAmount} (${Math.round(
                            scorecardAnswer.truePercent
                          )}%)`}</div>
                        </div>
                      </div>
                      <div className="d-flex flex-row align-item-center">
                        <div
                          style={{
                            width: "100%",
                            marginRight: 16,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <LinearProgress
                            variant="determinate"
                            className="progress-bar-danger w-100"
                            value={scorecardAnswer.falsePercent}
                            style={{ height: ".5rem" }}
                          />
                        </div>
                        <div
                          style={{
                            minWidth: 200,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="font-size-sm text-black">No</div>
                          <div
                            style={{ color: "#000" }}
                            className="font-size-sm"
                          >{`${scorecardAnswer.falseAmount} (${Math.round(
                            scorecardAnswer.falsePercent
                          )}%)`}</div>
                        </div>
                      </div>
                      <div className="d-flex flex-row align-item-center">
                        <div
                          style={{
                            width: "100%",
                            marginRight: 16,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <LinearProgress
                            variant="determinate"
                            className="progress-bar-warning w-100"
                            value={scorecardAnswer.unansweredPercent}
                            style={{ height: ".5rem" }}
                          />
                        </div>
                        <div
                          style={{
                            minWidth: 200,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="font-size-sm text-black">
                            Unanswered
                          </div>
                          <div
                            style={{ color: "#000" }}
                            className="font-size-sm"
                          >{`${scorecardAnswer.unansweredAmount} (${Math.round(
                            scorecardAnswer.unansweredPercent
                          )}%)`}</div>
                        </div>
                      </div>
                    </ListItem>
                  );
                })}
              </List>
            </>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
});

export default connect(mapStateToProps, null)(AdminScorecardAnalytics);
