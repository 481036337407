import { Functions } from "./Firebase";
import {
  Invite,
  InviteRecord,
  SchoolRecord,
  School,
  District,
  DistrictRecord,
} from "../../Entities";

export const inviteToItem = async (invite: Invite): Promise<void> => {
  const inviteToItem = Functions.httpsCallable("inviteToItem");
  await inviteToItem({ invite });
};

export const readInvite = async (inviteId: string): Promise<Invite | null> => {
  const readInvite = Functions.httpsCallable("readInvite");
  const { data }: { data: InviteRecord } = await readInvite({ inviteId });
  return new Invite(data);
};

export const acceptSchoolInvite = async (
  inviteId: string
): Promise<School | null> => {
  const acceptSchoolInvite = Functions.httpsCallable("acceptSchoolInvite");
  const { data }: { data: SchoolRecord } = await acceptSchoolInvite({
    inviteId,
  });
  if (data) {
    return new School(data);
  }
  return null;
};
export const acceptDistrictInvite = async (
  inviteId: string
): Promise<District | null> => {
  const acceptDistrictInvite = Functions.httpsCallable("acceptDistrictInvite");
  const { data }: { data: DistrictRecord } = await acceptDistrictInvite({
    inviteId,
  });
  if (data) {
    return new District(data);
  }
  return null;
};

export const cancelInvite = async (
  inviteId: string,
  itemType: "school" | "district",
  itemId: string | number
): Promise<void> => {
  const cancelInvite = Functions.httpsCallable("cancelInvite");
  await cancelInvite({ inviteId, itemType, itemId });
};
