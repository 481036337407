import AdminResourcesMarketing from "../../components/AdminResourcesMarketing";

function AdminResourcesMarketingPage() {
  return (
    <>
      <AdminResourcesMarketing />
    </>
  );
}

export default AdminResourcesMarketingPage;
