import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  sceneSelector,
  appSelector,
  userSelector,
  readSceneAction,
  ParentPartiesAPIs,
  SchoolServices,
} from "../../core";
import moment from "moment";
import { Grid, Card, List, ListItem } from "@material-ui/core";
import AGGrid from "../Grid";
import { NavLink } from "react-router-dom";

// Responsive //
import { CircleLoader } from "react-spinners";

const handleThemeMonth = (schedule, themeId) => {
  switch (themeId) {
    case 0:
      return schedule === "August" ? "August" : "September";
    case 1:
      return schedule === "August" ? "September" : "October";
    case 2:
      return schedule === "August" ? "October" : "November";
    case 3:
      return schedule === "August" ? "November" : "March";
    case 4:
      return "December";
    case 5:
      return "January";
    case 6:
      return "February";
    case 7:
      return schedule === "August" ? "March" : "April";
    case 8:
      return schedule === "August" ? "April" : "May";
    case 9:
      return schedule === "August" ? "May" : "June";
    default:
      return "";
  }
};

function dateComparator(date1, date2) {
  if (date1 === null && date2 === null) {
    return 0;
  }
  if (date1 === null) {
    return -1;
  }
  if (date2 === null) {
    return 1;
  }

  return date1 - date2;
}

const ActivieFileSection = (props) => {
  const { theme, filePool, schedule } = props;

  return (
    <Grid item md={12} xl={12}>
      <Card
        className="p-0"
        style={{
          borderRadius: "0.25rem",
          border: "1px solid rgba(0,0,0,.1)",
        }}
      >
        <div className="p-3">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div>
              <div className="inline-flex align-items-center">
                <div
                  className="heading-4 text-left text-black"
                  style={{
                    textTransform: "none",
                    marginRight: "10px",
                    marginBottom: 0,
                  }}
                >
                  {handleThemeMonth(schedule, theme.id)}
                </div>
              </div>
              <div
                className="d-block text-black-50 text-left mt-1 font-size-sm"
                style={{ paddingRight: "2rem" }}
              >
                {theme.name}
              </div>
            </div>
          </div>
        </div>
        <div className="pt-1 px-3 pb-4">
          <AGGrid
            autoHeight={true}
            search={false}
            statusBar={false}
            rowData={filePool.filter((file) =>
              (theme.fileIds || []).find((o) => o === (file ? file.id : null))
            )}
            columnDefs={[
              {
                headerName: "File Name",
                field: "name",
                sortable: true,
                filter: true,
                sort: "asc",
              },
              {
                headerName: "Upload Date",
                field: "time",
                sortable: true,
                filter: "agDateColumnFilter",
                valueFormatter: function (params) {
                  if (params.data.time) {
                    return moment(params.data.time).format("L");
                  } else {
                    return null;
                  }
                },
                comparator: dateComparator,
              },
              {
                width: 280,
                suppressSizeToFit: true,
                field: "actions",
                headerName: "Actions",
                cellRendererFramework: (params) => (
                  <div className="d-flex flex-row align-items-center">
                    <a
                      href={params.data.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained bg-dark shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                      tabIndex="0"
                      type="button"
                    >
                      <span className="MuiButton-label">
                        <span
                          className="btn-wrapper--label text-white"
                          style={{ fontSize: "12px" }}
                        >
                          Preview
                        </span>
                      </span>
                      <span className="MuiTouchRipple-root"></span>
                    </a>
                  </div>
                ),
                cellClass: "agGridCellCenter",
              },
            ]}
          />
        </div>
      </Card>
    </Grid>
  );
};

function SchoolResources(props) {
  const [initialLoad, setInitialLoad] = useState(true);
  const [filePool, setFilePool] = useState([]);
  const [curriculum, setCurriculum] = useState(null);

  const schoolCurriculumId = props.scene
    ? props.scene.data
      ? props.scene.data.curriculum
        ? props.scene.data.curriculum
        : ""
      : ""
    : "";
  useEffect(() => {
    async function getFiles() {
      const schoolCurriculum = await SchoolServices.readCurriculum(
        schoolCurriculumId
      );
      setCurriculum(schoolCurriculum);

      const files = await ParentPartiesAPIs.readCurriculumFiles(
        schoolCurriculum.fileIds || []
      );

      setFilePool(files);
      setInitialLoad(false);
    }

    getFiles();
  }, [schoolCurriculumId, props.scene]);

  return !props.user ? null : props.scene ? (
    initialLoad || props.app.isLoading ? (
      <Grid container className="my-spacing-6">
        <Grid item xs={12}>
          <div className="d-flex flex-row justify-content-center">
            <CircleLoader
              className="d-flex align-self-center"
              color={"#18a9a9"}
              loading={true}
              size={40}
            />
          </div>
        </Grid>
      </Grid>
    ) : (
      <>
        <Grid container spacing={4} style={{ marginBottom: "6rem" }}>
          <Grid item md={12}>
            <div
              style={{
                paddingTop: 10,
              }}
            >
              <div
                className="font-weight-bold display-4"
                style={{ color: "rgb(43, 39, 60)" }}
              >
                Host a Parent Party
              </div>
              <small className="font-size-md text-black-50 mt-4">
                In-School Curriculum
              </small>
            </div>
          </Grid>
          <Grid item md={12} xl={12}>
            <List
              component="div"
              className="nav-line-alt nav-line d-flex align-items-center mt-3"
            >
              <ListItem button selected className="pr-2">
                <span className="font-size-sm">Monthly</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/resources-in-person-general/s/${props.scene.id}`}
              >
                <span className="font-size-sm">General Files</span>
                <div className="divider" />
              </ListItem>
            </List>
          </Grid>
          <Grid item md={12} className="pt-3">
            <Grid container spacing={3}>
              {curriculum
                ? curriculum.themes
                  ? curriculum.themes.map((theme) => (
                      <ActivieFileSection
                        key={theme.id}
                        theme={theme}
                        filePool={filePool.filter((file) =>
                          (theme.fileIds || []).find(
                            (o) => o === (file ? file.id : null)
                          )
                        )}
                        schedule={
                          props.scene
                            ? props.scene.data
                              ? props.scene.data.schedule
                                ? props.scene.data.schedule
                                : ""
                              : ""
                            : ""
                        }
                      />
                    ))
                  : null
                : null}
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  ) : null;
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = {
  readSceneAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SchoolResources);
