import React, { useState } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";

import RoleSelection from "./roleSelection";

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function InviteDialog(props) {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const handleSetEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleRoleSelect = (role) => {
    setRole(role);
  };

  const [errors, setErrors] = useState({
    email: "",
    role: "",
  });
  const inviteUser = () => {
    let checkErrors = {};
    setErrors({
      email: "",
      role: "",
    });

    if (!email) {
      checkErrors.email = "Email address required";
    }
    if (email && !validateEmail(email)) {
      checkErrors.email = "Invalid email address";
    }
    if (!role) {
      checkErrors.role = "Role required";
    }
    if (checkErrors.email || checkErrors.role) {
      return setErrors(checkErrors);
    }

    props.onInviteUser(email, role);
  };

  const closeDialog = () => {
    setErrors({
      email: "",
      role: "",
    });
    setEmail("");
    setRole("");
    props.toggleInviteDialog();
  };

  return (
    <Dialog open={props.inviteDialog} fullWidth={true} maxWidth={"md"}>
      <DialogTitle id="alert-dialog-title">Invite User</DialogTitle>
      <DialogContent className="pt-4 pb-5">
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <label className="mb-2 font-size-sm">Email Address</label>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={email}
              onChange={handleSetEmail}
              helperText={errors.email}
              error={Boolean(errors.email)}
            />
          </Grid>
          <Grid item xs={6}>
            <label className="mb-2 font-size-sm">
              {props.inviteType === "district"
                ? "District Role"
                : "School Role"}
            </label>
            <RoleSelection
              allRoles={true}
              selectedRole={role}
              handleRoleSelect={handleRoleSelect}
              editable={true}
              inviteType={props.inviteType}
            />
            {errors.role ? (
              <div className="d-flex align-items-center text-danger mt-2">
                <span className="font-size-xs">{errors.role}</span>
              </div>
            ) : null}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} variant="text">
          Cancel
        </Button>
        <Button onClick={inviteUser} color="primary" variant="contained">
          <span className="text-white">Invite User</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default InviteDialog;
