import SchoolContactInfo from "../../components/SchoolContactInfo";

function SchoolContactInfoPage() {
  return (
    <>
      <SchoolContactInfo />
    </>
  );
}

export default SchoolContactInfo;
