import { StateType, initialState } from "./State";

const TOGGLE_CHECKOUT = "checkout/toggle";

type StateSlice = StateType["checkout"];

export interface ToggleCheckoutActionType {
  type: string;
}

export const checkoutSelector = (state: StateType): StateSlice =>
  state.checkout;
export const toggleCheckoutStateAction = (): ToggleCheckoutActionType => ({
  type: TOGGLE_CHECKOUT,
});

const updateHandler = (state: StateSlice): StateType["checkout"] => {
  return state
    ? {
        open: !state.open,
      }
    : {
        open: false,
      };
};

export const checkoutReducer = (
  checkout: StateSlice = initialState.checkout,
  action: ToggleCheckoutActionType
): StateSlice => {
  switch (action.type) {
    case TOGGLE_CHECKOUT:
      return updateHandler(checkout);
    default:
      return checkout;
  }
};
