import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";

const Footer = (props) => {
  const { footerShadow, footerBgTransparent } = props;

  return (
    <>
      <div
        className={clsx("app-footer text-black-50", {
          "app-footer--shadow": footerShadow,
          "app-footer--opacity-bg": footerBgTransparent,
        })}
      >
        <div className="app-footer--second">
          <span>Parent Parties Engage</span> &copy;{" "}
          {1900 + new Date().getYear()}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  footerShadow: state.ThemeOptions.footerShadow,
  footerBgTransparent: state.ThemeOptions.footerBgTransparent,
});

export default connect(mapStateToProps)(Footer);
