import { Auth, Functions } from "./Firebase";
import { Credential, Email } from "../../Entities";

export const signUp = async (credential: Credential): Promise<string> => {
  const authUser = await Auth.createUserWithEmailAndPassword(
    credential.email.address,
    credential.password
  );

  if (!authUser.user) {
    throw new Error("Unable to sign up. Please try again later.");
  }

  return authUser.user.uid;
};

export const sendEmailVerification = async (): Promise<void> => {
  if (!Auth.currentUser) {
    throw new Error("Unable to send email verification.");
  }

  return Auth.currentUser.sendEmailVerification();
};

export const signIn = async (credential: Credential): Promise<string> => {
  const authUser = await Auth.signInWithEmailAndPassword(
    credential.email.address,
    credential.password
  );

  if (!authUser.user) {
    throw new Error("Unable to sign in. Please try again later.");
  }

  return authUser.user.uid;
};

export const checkEmailVerified = async (): Promise<boolean> => {
  return Auth.currentUser ? Auth.currentUser.emailVerified : false;
};

export const refreshUser = async (): Promise<void> => {
  if (Auth.currentUser) {
    return Auth.currentUser.reload();
  }
};

export const signOut = async (): Promise<void> => {
  await Auth.signOut();
};

export const resetPassword = async (email: Email): Promise<void> => {
  await Auth.sendPasswordResetEmail(email.address);
};

export const listenToAuthState = (
  handler: (userId: string | null) => void
): void => {
  Auth.onAuthStateChanged((user) => handler(user ? user.uid : null));
};
