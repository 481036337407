import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Container, Card, Button, TextField } from "@material-ui/core";
import { NavLink, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

import { CircleLoader } from "react-spinners";
import { connect } from "react-redux";
import {
  sceneSelector,
  appSelector,
  inviteToItemAction,
  POSITION,
  userSelector,
} from "../../core";

// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const validationSchema = Yup.object({
  firstName: Yup.string("").required("First name is required"),
  lastName: Yup.string("").required("Last name is required"),
  email: Yup.string("")
    .email("Enter a valid email")
    .required("Email is required"),
});

const Step1 = (props) => {
  const {
    values: { firstName, lastName, email },
    errors,
    touched,
    handleChange,
    handleBlur,
    memberType,
  } = props;

  return (
    <>
      <Container>
        <form>
          <div className={props.mobile ? "py-4 px-2" : "p-4"}>
            <h5 className="font-size-xl mb-1 font-weight-bold">{`${memberType} Info`}</h5>
            <p className="text-black-50 mb-4">
              Please fill in the name and email of your team member.
            </p>
            <Grid container spacing={6}>
              <Grid item md={6}>
                <TextField
                  variant="outlined"
                  name="firstName"
                  label="First name"
                  helperText={touched.firstName ? errors.firstName : ""}
                  error={Boolean(errors.firstName) && touched.firstName}
                  fullWidth
                  value={firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  variant="outlined"
                  name="lastName"
                  helperText={touched.lastName ? errors.lastName : ""}
                  error={Boolean(errors.lastName) && touched.lastName}
                  fullWidth
                  value={lastName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Last name"
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  variant="outlined"
                  name="email"
                  helperText={touched.email ? errors.email : ""}
                  error={Boolean(errors.email) && touched.email}
                  type="email"
                  value={email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                  label="Email"
                />
              </Grid>
            </Grid>
          </div>
        </form>
      </Container>
    </>
  );
};

function getSteps() {
  return ["Add"];
}

function getStepContent(step, props) {
  switch (step) {
    case 0:
      return <Step1 {...props} />;
    default:
      return <Step1 {...props} />;
  }
}

function EditTeam(props) {
  const location = useLocation();
  const { pathname } = location;

  const params = pathname.split("/");
  const sceneType = params[2];
  const sceneId = params[3];

  const memberType = props.location.type;

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const values = {
    firstName: "",
    lastName: "",
    email: "",
  };

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const handleReset = () => {
    setActiveStep(0);
  };

  const [teamLoading, setTeamLoading] = useState(false);
  const [error, setError] = useState("");

  const onAddTeamMember = (values) => {
    // Run Loader
    if (teamLoading) {
      return;
    }
    const {
      user: { id: senderId },
      scene: { id: venueId },
    } = props;
    const { firstName, lastName, email } = values;
    const position = POSITION[memberType === "Admin" ? "Owner" : "Manager"];
    try {
      props.dispatchInviteToItem({
        senderId,
        email,
        position,
        itemId: venueId,
        itemType: "venue",
      });
      setTeamLoading(true);
    } catch (err) {
      setError(err.toString());
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  useEffect(() => {
    if (teamLoading) {
      if (!props.app.isLoading) {
        if (props.app.error) {
          setError(`Could not send invite. Error: ${props.app.error.message}`);
          setTeamLoading(false);
        } else {
          setError("");
          setTeamLoading(false);
        }
      }
    }
  }, [props.app.error, props.app.isLoading, teamLoading]);

  return (
    <Card>
      <div>
        {activeStep === steps.length ? (
          <div className="text-center p-5">
            {teamLoading ? (
              <>
                <div className="avatar-icon-wrapper rounded-circle m-0">
                  <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper m-0 d-130">
                    <CircleLoader
                      className="d-flex align-self-center"
                      color={"#18a9a9"}
                      loading={true}
                      size={50}
                    />
                  </div>
                </div>
                <h4 className="font-weight-bold mt-4">{`Sending your invite, hang tight.`}</h4>
              </>
            ) : (
              <>
                <div className="avatar-icon-wrapper rounded-circle m-0">
                  <div
                    className={clsx(
                      "d-inline-flex",
                      "justify-content-center",
                      "p-0",
                      "rounded-circle",
                      "btn-icon",
                      "avatar-icon-wrapper",
                      "m-0",
                      "d-130",
                      error ? "bg-neutral-danger" : "bg-neutral-success",
                      error ? "text-danger" : "text-success"
                    )}
                  >
                    <FontAwesomeIcon
                      icon={["far", "lightbulb"]}
                      className="d-flex align-self-center display-3"
                    />
                  </div>
                </div>
                <h4 className="font-weight-bold mt-4">
                  {error ? error : `Your invite has been sent!`}
                </h4>
                <div className="pt-4">
                  {error ? (
                    <Button
                      onClick={handleReset}
                      className="btn-primary font-weight-bold rounded hover-scale-lg mx-1"
                      size="large"
                    >
                      <span className="btn-wrapper--label">Back to Form</span>
                    </Button>
                  ) : (
                    <NavLink to={`/team/${sceneType}/${sceneId}`}>
                      <Button
                        className="btn-primary font-weight-bold rounded hover-scale-lg mx-1"
                        size="large"
                      >
                        <span className="btn-wrapper--label">
                          Back to Team page
                        </span>
                      </Button>
                    </NavLink>
                  )}
                </div>
              </>
            )}
          </div>
        ) : (
          <div>
            <Formik
              initialValues={values}
              validationSchema={validationSchema}
              onSubmit={onAddTeamMember}
              render={(formikProps) => (
                <>
                  <div>
                    {getStepContent(activeStep, {
                      ...formikProps,
                      mobile,
                      memberType,
                    })}
                  </div>
                  <div className="card-footer mt-4 p-4 d-flex align-items-center justify-content-between bg-secondary">
                    <div />
                    <Button
                      type="submit"
                      disabled={!formikProps.isValid}
                      className="btn-primary font-weight-bold"
                      onClick={formikProps.handleSubmit}
                    >
                      {"Finish"}
                    </Button>
                  </div>
                </>
              )}
            />
          </div>
        )}
      </div>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
  scene: sceneSelector(state),
});
const mapDispatchToProps = {
  dispatchInviteToItem: inviteToItemAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTeam);
