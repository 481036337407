import React, { useState, useEffect } from "react";
import projectLogo from "../../assets/Parent_Parties_Logo.png";
import { NavLink } from "react-router-dom";
import { Grid, Button, TextField } from "@material-ui/core";
import hero8 from "../../assets/Parent_Parties_BG.jpg";
import { connect } from "react-redux";
import {
  appSelector,
  resetAppAction,
  Credential,
  signUpAction,
  readInviteAction,
  inviteSelector,
  userSelector,
  ParentPartiesAPIs,
} from "../../core";
import { Formik } from "formik";
import * as Yup from "yup";
import { CircleLoader } from "react-spinners";

import Bugsnag from "@bugsnag/js";

// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const validationSchema = Yup.object({
  fullName: Yup.string("").required("Name is required"),
  email: Yup.string("")
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string("")
    .min(8, "Password must contain atleast 8 characters")
    .required("Enter your password"),
});

const Form = (props) => {
  const {
    values: { fullName, email, password },
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    isValid,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label className="font-weight-bold mb-2 font-size-sm">
          First and Last Name
        </label>
        <TextField
          variant="outlined"
          name="fullName"
          helperText={touched.fullName ? errors.fullName : ""}
          error={Boolean(errors.fullName) && touched.fullName}
          size="small"
          fullWidth
          value={fullName}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label className="font-weight-bold mb-2 font-size-sm">
          Email Address
        </label>
        <TextField
          autoComplete="email"
          variant="outlined"
          name="email"
          helperText={
            props.backendError
              ? props.backendError
              : touched.email
              ? errors.email
              : ""
          }
          error={
            (Boolean(errors.email) && touched.email) ||
            Boolean(props.backendError)
          }
          type="email"
          size="small"
          value={email}
          onBlur={handleBlur}
          onChange={handleChange}
          fullWidth
        />
      </div>
      <div className="mb-3">
        <div className="d-flex justify-content-between">
          <label className="font-weight-bold mb-2 font-size-sm">Password</label>
        </div>
        <TextField
          variant="outlined"
          autoComplete="current-password"
          name="password"
          helperText={
            touched.password
              ? errors.password
              : "Your password needs to be atleast 8 characters."
          }
          error={Boolean(errors.password) && touched.password}
          fullWidth
          size="small"
          type="password"
          value={password}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>
      <Button
        size="large"
        fullWidth
        className="btn-primary mb-2 font-weight-bold mt-4"
        type="submit"
        disabled={!isValid}
      >
        {props.app.isLoading ? (
          <div
            style={{
              position: "absolute",
              left: "50%",
              marginLeft: "-90px",
            }}
          >
            <CircleLoader color={"#fff"} loading={true} size={25} />
          </div>
        ) : null}
        <span className="ml-2">Create account</span>
      </Button>
    </form>
  );
};

function Register(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Query handle //
  const params = new URLSearchParams(props.location.search);
  const inviteId = params.get("inviteId");

  const [values, setValues] = useState({
    fullName: "",
    email: "",
    password: "",
    restaurant: "Yes",
  });
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [backendError, setBackendError] = useState(false);

  const onSignUp = (data) => {
    if (props.app.isLoading) {
      return;
    }
    const { fullName, email, password, restaurant } = data;
    const firstName =
      fullName.indexOf(" ") > -1
        ? fullName.substr(0, fullName.indexOf(" "))
        : fullName;
    const lastName =
      fullName.indexOf(" ") > -1
        ? fullName.substr(fullName.indexOf(" ") + 1)
        : "";
    try {
      props.dispatchSignUp(
        firstName,
        lastName,
        new Credential(email, password),
        false,
        inviteId ? true : false,
        restaurant,
        inviteId
      );
    } catch (err) {
      Bugsnag.notify(err);
      setBackendError(err.toString());
    }
  };

  useEffect(() => {
    if (props.app.error) {
      /* Handle internal error */
      if (props.app.error.message.includes("Invite does not exist.")) {
        if (props.user) {
          props.history.push("/dashboard");
        }
        props.history.push("/login");
      }

      Bugsnag.notify(props.app.error);

      let msg = props.app.error.message
        ? props.app.error.message.includes(
            "The email address is already in use by another account"
          )
          ? "Email already in use. If you signed up in the mobile app using this email address, please login using that email and password."
          : props.app.error.message
        : "";
      return setBackendError(msg);
    }
  }, [props.app.error, props.user]);

  useEffect(() => {
    /* Clear app errors on load */
    props.dispatchReset();
    setBackendError();
  }, [props.dispatchReset]);

  useEffect(() => {
    if (inviteId) {
      setLoadingInvite(true);
      props.dispatchReadInvite(inviteId);
    }
  }, [inviteId]);

  useEffect(() => {
    async function fetchData(inviteEmail) {
      const userEmailArray = await ParentPartiesAPIs.checkLoginByEmail(
        inviteEmail
      );
      if (userEmailArray.length) {
        return props.history.push(`/login?${params.toString()}`);
      } else {
        setValues({
          ...values,
          email: props.invite.emailAddress,
        });
        setLoadingInvite(false);
      }
    }
    if (props.invite) {
      fetchData(props.invite.emailAddress);
    }
  }, [props.invite]);

  return (
    <>
      <div className="app-wrapper bg-white">
        <div className={mobile ? "w-100" : "app-main min-vh-100"}>
          <div className={mobile ? "" : "app-content p-0"}>
            <div className={mobile ? "" : "app-inner-content-layout--main"}>
              <div
                className={
                  mobile ? "" : "flex-grow-1 w-100 d-flex align-items-center"
                }
              >
                <div className={mobile ? "" : "bg-composed-wrapper--content"}>
                  <Grid container spacing={0} className="min-vh-100">
                    <Grid
                      item
                      lg={7}
                      xl={6}
                      className="d-flex align-items-center"
                    >
                      <Grid item md={10} lg={8} xl={7} className="mx-auto">
                        <div className={mobile ? "py-4 px-3" : "py-4"}>
                          <div className="text-left">
                            <div className="app-sidebar-logo mb-4 pb-4">
                              <div
                                className="app-sidebar-logo--icon"
                                style={{ backgroundColor: "#fff" }}
                              >
                                <img
                                  alt="Parent Parties Logo"
                                  src={projectLogo}
                                />
                              </div>
                              <div className="app-sidebar-logo--text">
                                <b
                                  style={{
                                    textTransform: "none",
                                    color: "rgb(47,47,47)",
                                  }}
                                >
                                  {inviteId
                                    ? "Register Account to Accept Invite"
                                    : "Register for Parent Parties Engage"}
                                </b>
                              </div>
                            </div>
                          </div>
                          <Formik
                            enableReinitialize
                            initialValues={values}
                            validationSchema={validationSchema}
                            onSubmit={onSignUp}
                          >
                            {(formikProps) => (
                              <Form
                                {...formikProps}
                                app={props.app}
                                backendError={backendError}
                              />
                            )}
                          </Formik>
                          <div className="text-center text-black-50 mt-4">
                            Have an account?{" "}
                            <NavLink to="/login" className="text-first">
                              Login
                            </NavLink>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item lg={5} xl={6} className="d-flex">
                      <div className="hero-wrapper w-100 bg-composed-wrapper min-vh-lg-100">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div
                            className="bg-composed-wrapper--image"
                            style={{
                              backgroundImage: "url(" + hero8 + ")",
                              filter: "none",
                              opacity: 1,
                            }}
                          />
                          {/* <div className="bg-composed-wrapper--bg bg-tempting-azure opacity-6" /> */}
                          {/* <div className="bg-composed-wrapper--bg bg-tempting-azure opacity-3" /> */}
                          <div
                            className={
                              mobile
                                ? "bg-composed-wrapper--content text-center py-5"
                                : "bg-composed-wrapper--content text-center p-5"
                            }
                          >
                            {/* <div className="text-white px-0 px-lg-2 px-xl-4">
                              <h1
                                className={
                                  mobile
                                    ? "font-size-xl mb-4 font-weight-bold"
                                    : "display-3 mb-4 font-weight-bold"
                                }
                              >
                                
                              </h1>
                              <p
                                className={
                                  mobile
                                    ? "font-size-md mb-0 opacity-8"
                                    : "font-size-lg mb-0 opacity-8"
                                }
                              >
                                The premier marketing tool for restaurants.
                              </p>
                              {mobile ? null : (
                                <div className="divider mx-auto border-1 my-5 border-light opacity-2 rounded w-25" />
                              )}
                            </div> */}
                          </div>
                        </div>
                        {/* {!mobile ? (
                          <div className="hero-footer pb-4">
                            <List
                              component="div"
                              className="nav-pills nav-neutral-secondary d-flex"
                            >
                              <Tooltip title="Facebook" arrow>
                                <ListItem
                                  component="a"
                                  button
                                  href="#/"
                                  onClick={(e) => e.preventDefault()}
                                  className="font-size-lg text-white-50"
                                >
                                  <FontAwesomeIcon icon={["fab", "facebook"]} />
                                </ListItem>
                              </Tooltip>

                              <Tooltip title="Twitter" arrow>
                                <ListItem
                                  component="a"
                                  button
                                  href="#/"
                                  onClick={(e) => e.preventDefault()}
                                  className="font-size-lg text-white-50"
                                >
                                  <FontAwesomeIcon icon={["fab", "twitter"]} />
                                </ListItem>
                              </Tooltip>

                              <Tooltip title="Instagram" arrow>
                                <ListItem
                                  component="a"
                                  button
                                  href="#/"
                                  onClick={(e) => e.preventDefault()}
                                  className="font-size-lg text-white-50"
                                >
                                  <FontAwesomeIcon
                                    icon={["fab", "instagram"]}
                                  />
                                </ListItem>
                              </Tooltip>
                            </List>
                          </div>
                        ) : null} */}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  invite: inviteSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = {
  dispatchReset: resetAppAction,
  dispatchSignUp: signUpAction,
  dispatchReadInvite: readInviteAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
