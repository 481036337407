import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import { NavLink } from "react-router-dom";
import {
  appSelector,
  userSelector,
  ActivityServices,
  ParentPartiesAPIs,
  SchoolServices,
} from "../../core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  Typography,
  IconButton,
  Card,
  FormControl,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
  List,
  ListItem,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { CircleLoader } from "react-spinners";
import update from "immutability-helper";

// Responsive //
import { withStyles } from "@material-ui/core/styles";
import Bugsnag from "@bugsnag/js";

import AddFileDialog from "./addFileDialog";
import AGGrid from "../Grid";

function dateComparator(date1, date2) {
  if (date1 === null && date2 === null) {
    return 0;
  }
  if (date1 === null) {
    return -1;
  }
  if (date2 === null) {
    return 1;
  }

  return date1 - date2;
}

const styles = (theme) => ({
  rootNormal: {
    margin: 0,
    padding: theme.spacing(2),
  },
  root: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButtonNormal: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButton: {
    position: "static",
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, row, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={row ? classes.root : classes.rootNormal}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={row ? classes.closeButton : classes.closeButtonNormal}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const ActivieFileSection = (props) => {
  const {
    theme,
    filePool,
    handleAddFileDialogOpen,
    setDeleteDialogInfo,
    setDeleteDialog,
  } = props;

  return (
    <Grid item md={12} xl={12}>
      <Card
        className="p-0"
        style={{
          borderRadius: "0.25rem",
          border: "1px solid rgba(0,0,0,.1)",
        }}
      >
        <div className="p-3">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div>
              <div className="inline-flex align-items-center">
                <div
                  className="heading-4 text-left text-black"
                  style={{
                    textTransform: "none",
                    marginRight: "10px",
                    marginBottom: 0,
                  }}
                >
                  {theme.subtext}
                </div>
              </div>
              <div
                className="d-block text-black-50 text-left mt-1 font-size-sm"
                style={{ paddingRight: "2rem" }}
              >
                {theme.name}
              </div>
            </div>
            <Button
              variant="contained"
              size="medium"
              className="btn-info shadow-none ml-4"
              onClick={() => handleAddFileDialogOpen(theme.id)}
            >
              <span className="btn-wrapper--icon mr-1">
                <FontAwesomeIcon
                  style={{ fontSize: "12px" }}
                  icon={["fas", "edit"]}
                />
              </span>
              <span className="btn-wrapper--label" style={{ fontSize: "12px" }}>
                Edit Files
              </span>
            </Button>
          </div>
        </div>
        <div className="pt-1 px-3 pb-4">
          <AGGrid
            autoHeight={true}
            search={false}
            statusBar={false}
            rowData={filePool.filter((file) =>
              (theme.fileIds || []).find((o) => o === file.id)
            )}
            columnDefs={[
              {
                headerName: "File Name",
                field: "name",
                sortable: true,
                filter: true,
                sort: "asc",
              },
              {
                headerName: "Upload Date",
                field: "time",
                sortable: true,
                filter: "agDateColumnFilter",
                valueFormatter: function (params) {
                  if (params.data.time) {
                    return moment(params.data.time).format("L");
                  } else {
                    return null;
                  }
                },
                comparator: dateComparator,
              },
              {
                width: 280,
                suppressSizeToFit: true,
                field: "actions",
                headerName: "Actions",
                cellRendererFramework: (params) => (
                  <div className="d-flex flex-row align-items-center">
                    <a
                      href={params.data.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained bg-dark shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                      tabIndex="0"
                      type="button"
                    >
                      <span className="MuiButton-label">
                        <span
                          className="btn-wrapper--label text-white"
                          style={{ fontSize: "12px" }}
                        >
                          Preview
                        </span>
                      </span>
                      <span className="MuiTouchRipple-root"></span>
                    </a>
                    <button
                      className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained btn-danger shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                      tabIndex="0"
                      type="button"
                      onClick={() => {
                        setDeleteDialogInfo(params.data);
                        setDeleteDialog(true);
                      }}
                    >
                      <span className="MuiButton-label">
                        <span
                          className="btn-wrapper--label text-white"
                          style={{ fontSize: "12px" }}
                        >
                          Delete
                        </span>
                      </span>
                      <span className="MuiTouchRipple-root"></span>
                    </button>
                  </div>
                ),
                cellClass: "agGridCellCenter",
              },
            ]}
          />
        </div>
      </Card>
    </Grid>
  );
};

function AdminResources(props) {
  const [initialLoad, setInitialLoad] = useState(true);

  const [addFileDialog, setAddFileDialog] = useState(false);
  const [addFileDialogInfo, setAddFileDialogInfo] = useState(null);
  const handleAddFileDialogOpen = (themeId) => {
    setAddFileDialogInfo(themeId);
    setAddFileDialog(true);
  };
  const handleAddFileDialogClose = () => {
    setAddFileDialog(false);
  };

  const handleUpdateCurriculumThemeFiles = async (id, fileIds) => {
    const idIndex = curriculum.themes.findIndex((theme) => theme.id === id);
    const newThemes = update(curriculum.themes, {
      [idIndex]: {
        fileIds: { $set: fileIds },
      },
    });
    const newFiles = newThemes.map((theme) => theme.fileIds).flat();
    await ActivityServices.updateCurriculum(curriculum.id, {
      themes: newThemes,
      fileIds: newFiles.filter((i) => i !== undefined),
    });
    setCurriculum({ ...curriculum, themes: newThemes });
    setAddFileDialog(false);
  };

  /* Curriculum */
  const [curriculums, setCurriculums] = useState([]);
  const [curriculum, setCurriculum] = useState(null);
  const handleCurriculumChange = async (e) => {
    if (curriculums) {
      if (e.target.value) {
        // Did not change curriculum
        if (e.target.value === curriculum.id) {
          return;
        }
        // Changed curriculum
        try {
          const curriculum = curriculums.find(
            (curriculum) => curriculum.id === e.target.value
          );
          setCurriculum(curriculum);
        } catch (err) {
          console.log(err);
          Bugsnag.notify(err);
        }
      }
    }
  };

  const [filePool, setFilePool] = useState([]);

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteDialogInfo, setDeleteDialogInfo] = useState(null);
  const handleDeleteClose = () => {
    setDeleteDialog(false);
  };

  const handleDeleteFile = async () => {
    try {
      if (deleteDialogInfo) {
        if (deleteDialogInfo.id) {
          await ParentPartiesAPIs.deleteAdminResourceFile(deleteDialogInfo.id);
          setFilePool(
            filePool.filter((file) => file.id !== deleteDialogInfo.id)
          );
          setDeleteDialog(false);
        }
      }
    } catch (err) {
      console.log(err);
      Bugsnag.notify(err);
    }
  };

  useEffect(() => {
    async function getFiles() {
      const curriculums = await SchoolServices.readCurriculums();
      setCurriculums(curriculums);
      setCurriculum(curriculums[0]);

      const files = await ParentPartiesAPIs.readAdminFiles();
      const arrayOfFiles = files
        ? Object.values(files).filter((i) => i.premium === true)
        : [];
      setFilePool(arrayOfFiles);
      setInitialLoad(false);
    }

    getFiles();
  }, []);

  // Upload Admin File
  const { getInputProps, open } = useDropzone({
    accept: "image/jpeg, image/png, application/pdf",
    multiple: false,
    onDrop: async (acceptedFiles) => {
      const MAX_SIZE = 31457280;
      const bigFile = (file) => file.size > MAX_SIZE;
      if (acceptedFiles.some(bigFile) === true) {
        alert("File Size Limit: 32 MB");
        return;
      }
      const newFile = acceptedFiles[0];
      const resourceFile = {
        allSchools: true,
        file: newFile,
        name: newFile.name,
        fileType: newFile.type,
      };
      toggleDialogFileName(resourceFile);
    },
  });

  const [dialogFileName, setDialogFileName] = useState(false);
  const [dialogInfoFileName, setDialogInfoFileName] = useState(null);
  const [fileNameDialog, setFileNameDialog] = useState("");
  const toggleDialogFileName = (file) => {
    if (file) {
      setDialogInfoFileName(file);
      setFileNameDialog(file.name);
    }
    setDialogFileName(!dialogFileName);
  };
  const handleFileName = async (fileInDialog) => {
    const updatedFile = {
      ...fileInDialog,
      name: fileNameDialog,
    };
    const resource = await ParentPartiesAPIs.uploadAdminResourceFile(
      updatedFile,
      true
    );

    setFilePool(
      update(filePool, {
        $push: [resource],
      })
    );
  };

  return !props.user ? null : initialLoad || props.app.isLoading ? (
    <Grid container className="my-spacing-6">
      <Grid item xs={12}>
        <div className="d-flex flex-row justify-content-center">
          <CircleLoader
            className="d-flex align-self-center"
            color={"#18a9a9"}
            loading={true}
            size={40}
          />
        </div>
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
        <Grid item md={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              Admin Parent Parties
            </div>
            <Button
              variant="contained"
              size="medium"
              className="btn-success shadow-none ml-4"
              onClick={open}
            >
              <span className="btn-wrapper--icon mr-1">
                <FontAwesomeIcon
                  style={{ fontSize: "12px" }}
                  icon={["fas", "plus"]}
                />
              </span>
              <span className="btn-wrapper--label" style={{ fontSize: "12px" }}>
                Add File
              </span>
            </Button>
          </div>
        </Grid>
        <Grid item md={12} xl={12}>
          <List
            component="div"
            className="nav-line-alt nav-line d-flex align-items-center mt-3"
          >
            <ListItem button selected className="pr-2">
              <span className="font-size-sm">By Curriculum</span>
              <div className="divider" />
            </ListItem>
            <ListItem
              button
              disableRipple
              className="px-2"
              component={NavLink}
              to={`/admin-resources-general`}
            >
              <span className="font-size-sm">General Files</span>
              <div className="divider" />
            </ListItem>
          </List>
        </Grid>
        {curriculum ? (
          <Grid item md={12} className="mt-2 pt-4">
            <div className="text-black">
              <div className="d-flex flex-row align-items-center">
                <div className="d-flex flex-row align-items-center">
                  <div>Curriculum: </div>
                  <FormControl size="small" variant="outlined" className="ml-2">
                    <Select
                      value={curriculum.id}
                      onChange={(e) => {
                        handleCurriculumChange(e);
                      }}
                    >
                      {curriculums.map((curriculum) => (
                        <MenuItem value={curriculum.id}>
                          {curriculum.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </Grid>
        ) : null}
        {/* Themes */}
        <Grid item md={12} className="mt-4 pt-4">
          <Grid container spacing={3}>
            {curriculum
              ? curriculum.themes
                ? curriculum.themes.map((theme) => (
                    <ActivieFileSection
                      key={theme.id}
                      theme={theme}
                      filePool={filePool.filter((file) =>
                        (theme.fileIds || []).find((o) => o === file.id)
                      )}
                      handleAddFileDialogOpen={handleAddFileDialogOpen}
                      setDeleteDialogInfo={setDeleteDialogInfo}
                      setDeleteDialog={setDeleteDialog}
                    />
                  ))
                : null
              : null}
          </Grid>
        </Grid>
      </Grid>
      <AddFileDialog
        filePool={filePool}
        addFileDialog={addFileDialog}
        addFileDialogInfo={addFileDialogInfo}
        handleAddFileDialogClose={handleAddFileDialogClose}
        themes={curriculum.themes}
        handleUpdateCurriculumThemeFiles={handleUpdateCurriculumThemeFiles}
      />
      {/* Needed for file upload */}
      <input {...getInputProps()} style={{ display: "none" }} />
      <Dialog
        open={deleteDialog}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
        PaperProps={{
          className: "p-3",
        }}
      >
        <DialogTitle
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            color: "rgb(42, 42, 47)",
            borderBottom: "none",
          }}
        >
          {`Are you sure you want to delete file${
            deleteDialogInfo ? `: ${deleteDialogInfo.name}` : ""
          }?`}
        </DialogTitle>
        <DialogActions style={{ borderTop: "none" }} className="mt-4">
          <Button onClick={handleDeleteClose}>Cancel</Button>
          <Button
            className="btn-danger"
            onClick={() => handleDeleteFile()}
            autoFocus
          >
            {"Delete File"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={dialogFileName}
        onClose={() => toggleDialogFileName()}
        classes={{ paper: "modal-content rounded-lg pt-4" }}
      >
        <div className="px-3 pb-1 text-left font-size-md font-weight-bold">
          {`Add file name`}
        </div>
        <div className="divider my-2" />
        <div className="p-3">
          <Grid container spacing={6}>
            <Grid item md={12}>
              <FormControl fullWidth variant="outlined">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={fileNameDialog}
                    label="Menu Name"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      onChange: (e) => {
                        setFileNameDialog(e.target.value);
                      },
                    }}
                  />
                </div>
                <FormHelperText>Ex: Marketing Flyer</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <div className="pt-4">
            <Button
              onClick={() => toggleDialogFileName()}
              className="btn-neutral-dark btn-pill mx-1"
            >
              <span className="btn-wrapper--label">Cancel</span>
            </Button>
            <Button
              onClick={async () => {
                toggleDialogFileName();
                handleFileName(dialogInfoFileName);
              }}
              className={"btn-pill mx-1 btn-success"}
            >
              <span className="btn-wrapper--label">Save</span>
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
});

export default connect(mapStateToProps, null)(AdminResources);
