import React from "react";
import SchoolResourcesFileHosting from "../../components/SchoolResourcesFileHosting";

function SchoolResourcesFileHostingPage() {
  return (
    <>
      <SchoolResourcesFileHosting />
    </>
  );
}

export default SchoolResourcesFileHostingPage;
