import { PremiumDBService } from "../UseCases/Premium";
import { PremiumServices } from "./Backend";

export class PremiumService implements PremiumDBService {
  async fetchPremium(
    venueId: string
  ): Promise<{
    productIds: string[];
    status: string;
    current_period_end: number;
    subscriptionId: string;
  } | null> {
    const premiumRecord = await PremiumServices.fetchPremium(venueId);
    return premiumRecord;
  }
}
