import { channel } from "redux-saga";
import { all, put, take } from "redux-saga/effects";
import {
  Credential,
  Email,
  SchoolUser,
  School,
  Scene,
  District,
} from "../../Entities";
import {
  updateAppAction,
  updateUserStateAction,
  updateUserDistrictStateAction,
  updateInviteStateAction,
  updateUserSchoolStateAction,
  updatePremiumStateAction,
  updateSceneStateAction,
} from "../Redux";
import {
  SchoolUserService,
  InviteService,
  PremiumService,
  SchoolService,
} from "../../Services";
import {
  SignUpInteractor,
  SignInInteractor,
  ResetPasswordInteractor,
  SignOutInteractor,
  AuthStateListener,
  UpdateUserInteractor,
  SendVerificationEmailInteractor,
  VerifyEmailInteractor,
  FetchPremiumInteractor,
  AcceptInviteSchoolInteractor,
  AcceptInviteDistrictInteractor,
  ReadInviteInteractor,
  ReadUserInteractor,
  ReadSchoolInteractor,
} from "../../UseCases";

export const SIGN_UP = "user/saga/sign_up";
export const SIGN_IN = "user/saga/sign_in";
export const RESET_PASSWORD = "user/saga/reset_password";
export const SIGN_OUT = "user/saga/sign_out";
export const LISTEN_TO_AUTH_STATE = "user/saga/listen_to_auth_state";
export const UPDATE_USER = "user/saga/update_user";
export const SEND_EMAIL_VERIFICATION_EMAIL =
  "user/saga/send_verification_email";
export const VERIFY_EMAIL = "user/saga/verify_email";

interface SignUpActionType {
  type: string;
  firstName: string;
  lastName: string;
  credential: Credential;
  emailVerified: boolean;
  emailConfirmed: boolean;
  question: string;
  inviteId?: string;
  itemId?: string;
  itemType?: string;
}

export const signUpAction = (
  firstName: string,
  lastName: string,
  credential: Credential,
  emailVerified: boolean,
  emailConfirmed: boolean,
  question: string,
  inviteId?: string,
  itemId?: string,
  itemType?: string
): SignUpActionType => ({
  type: SIGN_UP,
  credential,
  firstName,
  lastName,
  emailVerified,
  emailConfirmed,
  question,
  inviteId,
  itemId,
  itemType,
});

export function* signUpSaga(action: SignUpActionType) {
  /// Handle accept invite ////
  const inviteService = new InviteService();
  const readInviteInteractor = new ReadInviteInteractor(inviteService);
  const inviteSchoolInteractor = new AcceptInviteSchoolInteractor(
    inviteService
  );
  const inviteDistrictInteractor = new AcceptInviteDistrictInteractor(
    inviteService
  );
  const { inviteId } = action;

  yield put(updateAppAction({ isLoading: true }));

  const {
    firstName,
    lastName,
    credential,
    emailVerified,
    emailConfirmed,
    question,
  } = action;

  const service = new SchoolUserService();
  const interactor = new SignUpInteractor(service);
  const premiumService = new PremiumService();
  const premiumInteractor = new FetchPremiumInteractor(premiumService);
  const readInteractor = new ReadUserInteractor(service);

  try {
    const user: SchoolUser = yield interactor.signUp(
      firstName,
      lastName,
      credential,
      emailVerified,
      emailConfirmed,
      question
    );
    // InviteId Found
    if (inviteId) {
      const invite = yield readInviteInteractor.readInvite(inviteId);
      if (invite.itemType === "school") {
        const schoolAccepted: School = yield inviteSchoolInteractor.acceptSchoolInvite(
          inviteId
        );
        const premiumObject = yield premiumInteractor.fetchPremium(
          schoolAccepted.id as any
        );
        const updatedScene: Scene = {
          id: schoolAccepted.id,
          type: "school",
          data: schoolAccepted,
        };

        yield all([
          put(updateUserStateAction(user)),
          put(updateUserSchoolStateAction(schoolAccepted)),
          put(updateSceneStateAction(updatedScene)),
          put(updatePremiumStateAction(premiumObject || undefined)),
          put(updateAppAction({ isLoading: false, authChecked: true })),
        ]);
      }
      if (invite.itemType === "district") {
        const districtAccepted: District = yield inviteDistrictInteractor.acceptDistrictInvite(
          inviteId
        );
        const updatedUser: SchoolUser = yield readInteractor.readUser(
          user.id as string
        );
        const updatedScene: Scene = {
          id: districtAccepted.id,
          type: "district",
          data: districtAccepted,
        };

        yield all([
          put(updateUserStateAction(updatedUser)),
          put(updateUserDistrictStateAction(districtAccepted)),
          put(updateSceneStateAction(updatedScene)),
          put(updatePremiumStateAction(undefined)),
          put(updateAppAction({ isLoading: false, authChecked: true })),
        ]);
      }
    } else {
      yield all([
        put(updateUserStateAction(user)),
        put(updateAppAction({ isLoading: false, authChecked: true })),
      ]);
    }
  } catch (error) {
    // @ts-ignore
    yield put(updateAppAction({ isLoading: false, error, authChecked: true }));
  }
}

interface SignInActionType {
  type: string;
  credential: Credential;
  inviteId?: string;
  itemId?: string;
  itemType?: string;
}

export const signInAction = (
  credential: Credential,
  inviteId?: string,
  itemId?: string,
  itemType?: string
): SignInActionType => ({
  type: SIGN_IN,
  credential,
  inviteId,
  itemId,
  itemType,
});

export function* signInSaga(action: SignInActionType) {
  /// Handle accept invite ////
  const inviteService = new InviteService();
  const readInviteInteractor = new ReadInviteInteractor(inviteService);
  const inviteSchoolInteractor = new AcceptInviteSchoolInteractor(
    inviteService
  );
  const inviteDistrictInteractor = new AcceptInviteDistrictInteractor(
    inviteService
  );
  const { inviteId, itemId, itemType } = action;

  yield put(updateAppAction({ isLoading: true }));

  const { credential } = action;

  const service = new SchoolUserService();
  const interactor = new SignInInteractor(service);
  const premiumService = new PremiumService();
  const premiumInteractor = new FetchPremiumInteractor(premiumService);
  const readInteractor = new ReadUserInteractor(service);

  try {
    const user: SchoolUser = yield interactor.signIn(credential);

    // InviteId Found
    if (inviteId) {
      const invite = yield readInviteInteractor.readInvite(inviteId);
      if (invite.itemType === "school") {
        const schoolAccepted: School = yield inviteSchoolInteractor.acceptSchoolInvite(
          inviteId
        );
        if (schoolAccepted) {
          const premiumObj = yield premiumInteractor.fetchPremium(
            schoolAccepted.id as any
          );
          const updatedScene: Scene = {
            id: schoolAccepted.id,
            type: "school",
            data: schoolAccepted,
          };

          yield all([
            put(updateUserStateAction(user)),
            put(updateUserSchoolStateAction(schoolAccepted)),
            put(updateSceneStateAction(updatedScene)),
            put(updatePremiumStateAction(premiumObj || undefined)),
            put(updateInviteStateAction()),
            put(updateAppAction({ isLoading: false, authChecked: true })),
          ]);
        } else {
          yield put(
            updateAppAction({
              isLoading: false,
              error: { name: "error", message: "Invite does not exist." },
              authChecked: true,
            })
          );
        }
      }
      if (invite.itemType === "district") {
        const districtAccepted: District = yield inviteDistrictInteractor.acceptDistrictInvite(
          inviteId
        );
        if (districtAccepted) {
          const updatedUser: SchoolUser = yield readInteractor.readUser(
            user.id as string
          );
          const updatedScene: Scene = {
            id: districtAccepted.id,
            type: "district",
            data: districtAccepted,
          };

          yield all([
            put(updateUserStateAction(updatedUser)),
            put(updateUserDistrictStateAction(districtAccepted)),
            put(updateSceneStateAction(updatedScene)),
            put(updatePremiumStateAction(undefined)),
            put(updateInviteStateAction()),
            put(updateAppAction({ isLoading: false, authChecked: true })),
          ]);
        } else {
          yield put(
            updateAppAction({
              isLoading: false,
              error: {
                name: "error",
                message: "Invite does not exist.",
              },
              authChecked: true,
            })
          );
        }
      }
    }
    // No Invite Id Found
    else {
      /// Load specific school or district
      if (itemId) {
        if (itemType === "school") {
          const productIds = user.schools
            ? Object.values(user.schools).length
              ? yield premiumInteractor.fetchPremium(itemId)
              : undefined
            : undefined;
          const findSchool = user.schools
            ? Object.values(user.schools).length
              ? Object.values(user.schools).find(
                  (obj) => (obj.id as number | string).toString() === itemId
                )
              : undefined
            : undefined;
          const updatedScene: Scene = {
            id: findSchool ? findSchool.id : undefined,
            type: findSchool ? "school" : "none",
            data: findSchool ? findSchool : null,
          };

          yield all([
            put(updateSceneStateAction(updatedScene)),
            put(updatePremiumStateAction(productIds)),
            put(updateUserStateAction(user || null)),
            put(updateAppAction({ isLoading: false, authChecked: true })),
          ]);
        }
        if (itemType === "district") {
          const findDistrict = user.districts
            ? Object.values(user.districts).length
              ? Object.values(user.districts).find(
                  (obj) => (obj.id as number | string).toString() === itemId
                )
              : undefined
            : undefined;
          const updatedScene: Scene = {
            id: findDistrict ? findDistrict.id : undefined,
            type: findDistrict ? "district" : "none",
            data: findDistrict ? findDistrict : null,
          };

          yield all([
            put(updateSceneStateAction(updatedScene)),
            put(updatePremiumStateAction(undefined)),
            put(updateUserStateAction(user || null)),
            put(updateAppAction({ isLoading: false, authChecked: true })),
          ]);
        }
      }
      /// Load first school or first district
      else {
        // Multiple schools
        if (user.schools ? Object.keys(user.schools).length > 1 : false) {
          const firstDistrict = user.districts
            ? Object.values(user.districts).length
              ? Object.values(user.districts)[0]
              : undefined
            : undefined;
          const updatedScene: Scene = {
            id: firstDistrict ? firstDistrict.id : undefined,
            type: firstDistrict ? "district" : "none",
            data: firstDistrict ? firstDistrict : null,
          };

          yield all([
            put(updateUserStateAction(user)),
            put(updateSceneStateAction(updatedScene)),
            put(updatePremiumStateAction(undefined)),
            put(updateAppAction({ isLoading: false, authChecked: true })),
          ]);
        } else {
          const premiumObj = user.schools
            ? Object.values(user.schools).length
              ? Object.values(user.schools)[0].id
                ? yield premiumInteractor.fetchPremium(
                    Object.values(user.schools)[0].id as any
                  )
                : undefined
              : undefined
            : undefined;
          const firstSchool = user.schools
            ? Object.values(user.schools).length
              ? Object.values(user.schools)[0]
              : undefined
            : undefined;
          const updatedScene: Scene = {
            id: firstSchool ? firstSchool.id : undefined,
            type: firstSchool ? "school" : "none",
            data: firstSchool ? firstSchool : null,
          };

          yield all([
            put(updateUserStateAction(user)),
            put(updateSceneStateAction(updatedScene)),
            put(updatePremiumStateAction(premiumObj || undefined)),
            put(updateAppAction({ isLoading: false, authChecked: true })),
          ]);
        }
      }
    }
  } catch (error) {
    yield put(
      updateAppAction({
        isLoading: false,
        // @ts-ignore
        error: new Error(
          // @ts-ignore
          error.message || "Something went wrong. Please contact support."
        ),
        authChecked: true,
      })
    );
  }
}

interface ResetPasswordActionType {
  type: string;
  email: Email;
}

export const resetPasswordAction = (email: Email): ResetPasswordActionType => ({
  type: RESET_PASSWORD,
  email,
});

export function* resetPasswordSaga(action: ResetPasswordActionType) {
  yield put(updateAppAction({ isLoading: true }));

  const { email } = action;

  const service = new SchoolUserService();
  const interactor = new ResetPasswordInteractor(service);

  try {
    yield interactor.resetPassword(email);
    yield put(updateAppAction({ isLoading: false }));
  } catch (error) {
    // @ts-ignore
    yield put(updateAppAction({ isLoading: false, error }));
  }
}

interface SignOutActionType {
  type: string;
}

export const signOutAction = (): SignOutActionType => ({
  type: SIGN_OUT,
});

export function* signOutSaga() {
  yield put(updateAppAction({ isLoading: true }));

  const service = new SchoolUserService();
  const interactor = new SignOutInteractor(service);

  const updatedScene: Scene = {
    id: undefined,
    type: "none",
    data: null,
  };

  try {
    yield interactor.signOut();
    yield all([
      put(updateUserStateAction()),
      put(updateSceneStateAction(updatedScene)),
      put(updatePremiumStateAction(undefined)),
      put(updateAppAction({ isLoading: false })),
    ]);
  } catch (error) {
    // @ts-ignore
    yield put(updateAppAction({ isLoading: false, error }));
  }
}

interface AuthStateActionType {
  type: string;
  inviteId?: string;
  itemId?: string | number;
  itemType?: string;
}

export const listenToAuthStateAction = (
  inviteId: string,
  itemId: string | number,
  itemType: string
): AuthStateActionType => ({
  type: LISTEN_TO_AUTH_STATE,
  inviteId,
  itemId,
  itemType,
});

export function* listenToAuthStateSaga(action: AuthStateActionType) {
  yield put(updateAppAction({ isLoading: true }));

  /// Handle accept invite ////
  const inviteService = new InviteService();
  const inviteSchoolInteractor = new AcceptInviteSchoolInteractor(
    inviteService
  );
  const inviteDistrictInteractor = new AcceptInviteDistrictInteractor(
    inviteService
  );
  const { inviteId, itemId, itemType } = action;

  const authChannel = channel();

  const service = new SchoolUserService();
  const interactor = new AuthStateListener(service);
  const premiumService = new PremiumService();
  const premiumInteractor = new FetchPremiumInteractor(premiumService);
  const readInteractor = new ReadUserInteractor(service);

  const readSchoolService = new SchoolService();
  const readSchoolInteractor = new ReadSchoolInteractor(readSchoolService);

  interactor.onAuthStateChange((user: SchoolUser | null) =>
    authChannel.put(user || false)
  );

  const user: SchoolUser = yield take(authChannel);
  // InviteId Found
  if (inviteId && user) {
    if (itemType === "school") {
      const schoolAccepted: School = yield inviteSchoolInteractor.acceptSchoolInvite(
        inviteId
      );
      if (schoolAccepted) {
        const premiumObj = yield premiumInteractor.fetchPremium(
          schoolAccepted.id as any
        );

        const updatedScene: Scene = {
          id: schoolAccepted.id,
          type: "school",
          data: schoolAccepted,
        };

        yield all([
          put(updateUserStateAction(user)),
          put(updateUserSchoolStateAction(schoolAccepted)),
          put(updateSceneStateAction(updatedScene)),
          put(updatePremiumStateAction(premiumObj || undefined)),
          put(updateInviteStateAction()),
          put(updateAppAction({ isLoading: false, authChecked: true })),
        ]);
      } else {
        yield put(
          updateAppAction({
            isLoading: false,
            error: { name: "error", message: "Invite does not exist." },
            authChecked: true,
          })
        );
      }
    }
    if (itemType === "district") {
      const districtAccepted: District = yield inviteDistrictInteractor.acceptDistrictInvite(
        inviteId
      );
      if (districtAccepted) {
        const updatedUser: SchoolUser = yield readInteractor.readUser(
          user.id as string
        );
        const updatedScene: Scene = {
          id: districtAccepted.id,
          type: "district",
          data: districtAccepted,
        };

        yield all([
          put(updateUserStateAction(updatedUser)),
          put(updateUserDistrictStateAction(districtAccepted)),
          put(updateSceneStateAction(updatedScene)),
          put(updatePremiumStateAction(undefined)),
          put(updateInviteStateAction()),
          put(updateAppAction({ isLoading: false, authChecked: true })),
        ]);
      } else {
        yield put(
          updateAppAction({
            isLoading: false,
            error: { name: "error", message: "Invite does not exist." },
            authChecked: true,
          })
        );
      }
    }
  }
  // No Invite Id Found
  else {
    /// Load specific school
    if (itemId) {
      if (itemType === "school") {
        const productIds = user.schools
          ? Object.values(user.schools).length
            ? yield premiumInteractor.fetchPremium(itemId as string)
            : undefined
          : undefined;
        let findSchool: any = null;
        findSchool = user.schools
          ? Object.values(user.schools).length
            ? Object.values(user.schools).find(
                (obj) =>
                  (obj.id as number | string).toString() === (itemId as string)
              )
            : undefined
          : undefined;
        if (!findSchool && user.adminSupport) {
          findSchool = yield readSchoolInteractor.readSchool(itemId as string);
        }

        const updatedScene: Scene = {
          id: findSchool ? findSchool.id : undefined,
          type: findSchool ? "school" : "none",
          data: findSchool ? findSchool : null,
        };

        yield all([
          put(updateUserStateAction(user || null)),
          put(updateSceneStateAction(updatedScene)),
          put(updatePremiumStateAction(productIds)),
          put(updateAppAction({ isLoading: false, authChecked: true })),
        ]);
      }
      if (itemType === "district") {
        const findDistrict = user.districts
          ? Object.values(user.districts).length
            ? Object.values(user.districts).find(
                (obj) => (obj.id as number | string).toString() === itemId
              )
            : undefined
          : undefined;
        const updatedScene: Scene = {
          id: findDistrict ? findDistrict.id : undefined,
          type: findDistrict ? "district" : "none",
          data: findDistrict ? findDistrict : null,
        };

        yield all([
          put(updateUserStateAction(user || null)),
          put(updateSceneStateAction(updatedScene)),
          put(updatePremiumStateAction(undefined)),
          put(updateAppAction({ isLoading: false, authChecked: true })),
        ]);
      }
    }
    /// Load first school or first district
    else {
      // Multiple schools
      if (user.schools ? Object.keys(user.schools).length > 1 : false) {
        const firstDistrict = user.districts
          ? Object.values(user.districts).length
            ? Object.values(user.districts)[0]
            : undefined
          : undefined;
        const updatedScene: Scene = {
          id: firstDistrict ? firstDistrict.id : undefined,
          type: firstDistrict ? "district" : "none",
          data: firstDistrict ? firstDistrict : null,
        };

        yield all([
          put(updateUserStateAction(user)),
          put(updateSceneStateAction(updatedScene)),
          put(updatePremiumStateAction(undefined)),
          put(updateAppAction({ isLoading: false, authChecked: true })),
        ]);
      } else {
        const premiumObj = user.schools
          ? Object.values(user.schools).length
            ? Object.values(user.schools)[0].id
              ? yield premiumInteractor.fetchPremium(
                  Object.values(user.schools)[0].id as any
                )
              : undefined
            : undefined
          : undefined;
        const firstSchool = user.schools
          ? Object.values(user.schools).length
            ? Object.values(user.schools)[0]
            : undefined
          : undefined;
        const updatedScene: Scene = {
          id: firstSchool ? firstSchool.id : undefined,
          type: firstSchool ? "school" : "none",
          data: firstSchool ? firstSchool : null,
        };

        yield all([
          put(updateUserStateAction(user)),
          put(updateSceneStateAction(updatedScene)),
          put(updatePremiumStateAction(premiumObj || undefined)),
          put(updateAppAction({ isLoading: false, authChecked: true })),
        ]);
      }
    }
  }
}

interface UserActionType {
  type: string;
  user: SchoolUser;
}

export const updateUserAction = (user: SchoolUser): UserActionType => ({
  type: UPDATE_USER,
  user,
});

export function* updateUserSaga(action: UserActionType) {
  yield put(updateAppAction({ isLoading: true }));

  const { user } = action;

  const service = new SchoolUserService();
  const interactor = new UpdateUserInteractor(service);

  try {
    const updatedUser = yield interactor.updateUser(user);
    yield put(updateUserStateAction(updatedUser));
    yield put(updateAppAction({ isLoading: false }));
  } catch (error) {
    // @ts-ignore
    yield put(updateAppAction({ isLoading: false, error }));
  }
}

interface SendVerificationEmailActionType {
  type: string;
}

export const sendVerificationEmailAction = (): SendVerificationEmailActionType => ({
  type: SEND_EMAIL_VERIFICATION_EMAIL,
});

export function* sendVerificationEmailSaga(
  action: SendVerificationEmailActionType
) {
  yield put(updateAppAction({ isLoading: true }));

  const service = new SchoolUserService();
  const interactor = new SendVerificationEmailInteractor(service);

  try {
    yield interactor.sendVerificationEmail();
    yield put(updateAppAction({ isLoading: false }));
  } catch (error) {
    yield put(
      updateAppAction({
        isLoading: false,
        error: new Error(
          `Could not send email verification. Please try again later.`
        ),
      })
    );
  }
}

interface VerifyEmailActionType {
  type: string;
  user: SchoolUser;
}

export const verifyEmailAction = (user: SchoolUser): VerifyEmailActionType => ({
  type: VERIFY_EMAIL,
  user,
});

export function* verifyEmailSaga(action: VerifyEmailActionType) {
  yield put(updateAppAction({ isLoading: true }));

  const { user } = action;

  const service = new SchoolUserService();
  const interactor = new VerifyEmailInteractor(service);

  try {
    const updatedUser = yield interactor.verifyEmail(user);
    if (!updatedUser.emailVerified) {
      throw new Error(
        `Email: ${updatedUser.emailAddress} has not been verified yet.`
      );
    }
    yield all([
      put(updateUserStateAction(updatedUser || null)),
      put(updateAppAction({ isLoading: false })),
    ]);
  } catch (error) {
    // @ts-ignore
    yield put(updateAppAction({ isLoading: false, error }));
  }
}
