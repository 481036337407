import { Button, Grid } from "@material-ui/core";
import hero8 from "../../assets/Parent_Parties_BG.jpg";
import { connect } from "react-redux";
import { userSelector, signOutAction } from "../../core";
import { useHistory } from "react-router-dom";

// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { logout } from "../../utils/Analytics";

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

function ApprovalStage(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const history = useHistory();

  return (
    <>
      <div className="app-wrapper min-vh-100 bg-white">
        <div className={mobile ? "w-100" : "app-main min-vh-100"}>
          <div className={mobile ? "" : "app-content p-0"}>
            <div className={mobile ? "" : "app-inner-content-layout--main"}>
              <div
                className={
                  mobile ? "" : "flex-grow-1 w-100 d-flex align-items-center"
                }
              >
                <div className={mobile ? "" : "bg-composed-wrapper--content"}>
                  <Grid container spacing={0} className="min-vh-100">
                    <Grid
                      item
                      lg={7}
                      xl={6}
                      className="d-flex align-items-center"
                    >
                      <Grid item md={10} lg={8} xl={7} className="mx-auto">
                        <div className={mobile ? "py-4 px-3" : "py-4"}>
                          <div className="text-left mb-5">
                            <h1 className="display-4 mb-4 font-weight-bold text-black">
                              Awaiting Approval
                            </h1>
                            {props.user ? (
                              props.user.email ? (
                                props.user.email.address ? (
                                  <div
                                    className="mt-2"
                                    style={{ color: "#0073bb" }}
                                  >
                                    {props.user.email.address}
                                  </div>
                                ) : null
                              ) : null
                            ) : null}
                            <div className="mt-2 text-black">
                              Your account has been successfully created! Our
                              staff is adding you to the appropriate schools
                              now.
                            </div>
                          </div>
                          <div className="text-center mt-5 pt-5 font-size-sm text-black-50">
                            Wrong email?{" "}
                            <Button
                              variant="text"
                              className="text-black-50 p-0"
                              style={{ textDecoration: "underline" }}
                              onClick={async () => {
                                logout();
                                props.signOutAction();

                                await sleep(500);
                                history.push("/login");
                              }}
                            >
                              Return to Login
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item lg={5} xl={6} className="d-flex">
                      <div className="hero-wrapper w-100 bg-composed-wrapper min-vh-lg-100">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div
                            className="bg-composed-wrapper--image"
                            style={{
                              backgroundImage: "url(" + hero8 + ")",
                              filter: "none",
                              opacity: 1,
                            }}
                          />
                          <div
                            className={
                              mobile
                                ? "bg-composed-wrapper--content text-center py-5"
                                : "bg-composed-wrapper--content text-center p-5"
                            }
                          ></div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: userSelector(state),
});

const mapDispatchToProps = {
  signOutAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalStage);
