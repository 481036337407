import React from "react";
import DistrictGuardianAnalytics from "../../components/DistrictGuardianAnalytics";

function DistrictGuardianAnalyticsPage() {
  return (
    <>
      <DistrictGuardianAnalytics />
    </>
  );
}

export default DistrictGuardianAnalyticsPage;
