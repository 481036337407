import React from "react";
import AdminActivityAnalytics from "../../components/AdminActivityAnalytics";

function AdminActivityAnalyticsPage() {
  return (
    <>
      <AdminActivityAnalytics />
    </>
  );
}

export default AdminActivityAnalyticsPage;
