import React from "react";
import SchoolActivityAnalytics from "../../components/SchoolActivityAnalytics";

function SchoolActivityAnalyticsPage() {
  return (
    <>
      <SchoolActivityAnalytics />
    </>
  );
}

export default SchoolActivityAnalyticsPage;
