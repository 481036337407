export interface PremiumDBService {
  fetchPremium: (
    venueId: string
  ) => Promise<{
    productIds: string[];
    status: string;
    current_period_end: number;
    subscriptionId: string;
  } | null>;
}

class PremiumUseCase {
  premiumService: PremiumDBService;

  constructor(premiumService: PremiumDBService) {
    this.premiumService = premiumService;
  }
}

export class FetchPremiumInteractor extends PremiumUseCase {
  constructor(premiumService: PremiumDBService) {
    super(premiumService);
  }

  async fetchPremium(
    venueId: string
  ): Promise<{
    productIds: string[];
    status: string;
    current_period_end: number;
    subscriptionId: string;
  } | null> {
    const premiumRecord = this.premiumService.fetchPremium(venueId);
    return premiumRecord;
  }
}
