import SchoolActivities from "../../components/SchoolActivities";

function SchoolActivitiesPage() {
  return (
    <>
      <SchoolActivities />
    </>
  );
}

export default SchoolActivitiesPage;
