import React from "react";
import SchoolGuardiansLeaderboard from "../../components/SchoolGuardiansLeaderboard";

function SchoolGuardiansLeaderboardPage() {
  return (
    <>
      <SchoolGuardiansLeaderboard />
    </>
  );
}

export default SchoolGuardiansLeaderboardPage;
