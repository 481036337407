import { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { withStyles } from "@material-ui/core/styles";
import { Button, TextField, InputAdornment } from "@material-ui/core";
import debounce from "../../utils/debounce";
import { Search } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const stylesFilter = {
  filterBox: {
    padding: ".75em .75em .75em .5em",
    fontSize: ".9em",
  },
  inputBG: {
    background: "#FFFFFF",
  },
};

function QuickFilter(props) {
  const handleQuickFilter = debounce((value) => {
    props.gridApi.setQuickFilter(value);
  }, 300);

  const onQuickFilterText = (event) => {
    handleQuickFilter(event.target.value);
  };
  const { classes } = props;
  return (
    <div>
      <TextField
        fullWidth
        placeholder={"Search"}
        variant="outlined"
        InputProps={{
          className: classes.inputBG,
          classes: {
            input: classes.filterBox,
          },
          startAdornment: (
            <InputAdornment position="end">
              <Search style={{ color: "rgba(0,0,0,.6)" }} />
            </InputAdornment>
          ),
        }}
        className={classes.textField}
        onChange={onQuickFilterText}
      />
    </div>
  );
}

const StyledQuickFilter = withStyles(stylesFilter)(QuickFilter);

class AGGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: null,
      quickFilter: null,
    };
  }

  onBtnExport = () => {
    if (this.props.exportType === "guardians") {
      this.state.gridApi.exportDataAsCsv({
        allColumns: true,
        fileName: `PPE-Guardians-Export`,
        processCellCallback: (params) => {
          if (
            params.column.colId === "lastSession" ||
            params.column.colId === "metadata.creationTime"
          ) {
            return moment(params.value).format("L");
          }
          if (params.column.colId === "children") {
            if (params.value) {
              let fix = params.value.filter((i) => i.name);
              if (fix.length) {
                return `${fix.map((child) => `${child.name}`).join(", ")}`;
              } else {
                return "";
              }
            } else {
              return "";
            }
          }
          if (params.column.colId === "gradeLevels") {
            if (params.value) {
              let fix = params.value.filter((i) => i.gradeLevel);
              if (fix.length) {
                return `${fix
                  .map((child) => `${child.gradeLevel}`)
                  .join(", ")}`;
              } else {
                return "";
              }
            } else {
              return "";
            }
          }
          if (params.column.colId === "teachers") {
            if (params.value) {
              let fix = params.value.filter((i) => i.teacher);
              if (fix.length) {
                return `${fix.map((child) => `${child.teacher}`).join(", ")}`;
              } else {
                return "";
              }
            } else {
              return "";
            }
          }
          return params.value;
        },
      });
    }
    if (this.props.exportType === "schools") {
      this.state.gridApi.exportDataAsCsv({
        fileName: `PPE-Schools-Export`,
      });
    }
    if (this.props.exportType === "districts") {
      this.state.gridApi.exportDataAsCsv({
        fileName: `PPE-Districts-Export`,
      });
    }
    if (this.props.exportType === "leaderboard") {
      this.state.gridApi.exportDataAsCsv({
        fileName: `PPE-Leaderboard-Export`,
        processCellCallback: (params) => {
          if (params.column.colId === "children") {
            if (params.value) {
              let fix = params.value.filter((i) => i.name);
              if (fix.length) {
                return `${fix.map((child) => `${child.name}`).join(", ")}`;
              } else {
                return "";
              }
            } else {
              return "";
            }
          }
          if (params.column.colId === "gradeLevels") {
            if (params.value) {
              let fix = params.value.filter((i) => i.gradeLevel);
              if (fix.length) {
                return `${fix
                  .map((child) => `${child.gradeLevel}`)
                  .join(", ")}`;
              } else {
                return "";
              }
            } else {
              return "";
            }
          }
          if (params.column.colId === "teachers") {
            if (params.value) {
              let fix = params.value.filter((i) => i.teacher);
              if (fix.length) {
                return `${fix.map((child) => `${child.teacher}`).join(", ")}`;
              } else {
                return "";
              }
            } else {
              return "";
            }
          }
          return params.value;
        },
      });
    }
    if (this.props.exportType === "leaderboardByTeachers") {
      this.state.gridApi.exportDataAsCsv({
        fileName: `PPE-Leaderboard-Teachers-Export`,
      });
    }
  };

  render() {
    const { classes, pageHeaderPresent } = this.props;
    return (
      <div className={pageHeaderPresent ? classes.container : ""}>
        <div
          className="ag-theme-alpine"
          style={{
            height: this.props.autoHeight ? "100%" : "500px",
            width: "100%",
          }}
        >
          <div
            style={
              this.props.titleGrid
                ? this.props.smallTitle
                  ? {
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: 15,
                      marginTop: 40,
                    }
                  : {
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingTop: 10,
                      paddingBottom: 10,
                      marginBottom: "2rem",
                    }
                : {}
            }
          >
            {this.props.titleGrid ? (
              <div>
                {this.props.smallTitle ? (
                  <div
                    style={{
                      color: "#000",
                      fontSize: "16px",
                      lineHeight: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    {this.props.titleGrid}
                  </div>
                ) : (
                  <div
                    className="font-weight-bold display-4"
                    style={{ color: "rgb(43, 39, 60)" }}
                  >
                    {this.props.titleGrid}
                  </div>
                )}
              </div>
            ) : null}
            {this.props.titleGrid ? (
              <div className="d-flex flex-row align-items-center">
                {this.props.search ? (
                  <div style={{ width: 400 }}>
                    <StyledQuickFilter gridApi={this.state.gridApi} />
                  </div>
                ) : null}
                <div className="d-flex flex-row align-items-center">
                  {this.props.exportButton ? (
                    <Button
                      className="btn-primary shadow-none ml-4"
                      size="medium"
                      style={{ whiteSpace: "nowrap" }}
                      onClick={this.onBtnExport}
                    >
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon
                          style={{ fontSize: "12px" }}
                          icon={["fas", "file-export"]}
                        />
                      </span>
                      <span
                        className="btn-wrapper--label"
                        style={{ fontSize: "12px" }}
                      >
                        Export
                      </span>
                    </Button>
                  ) : null}
                  {this.props.createButton ? this.props.createButton : null}
                  {this.props.customButton ? this.props.customButton : null}
                </div>
              </div>
            ) : (
              <>
                {this.props.search ? (
                  <div style={{ width: 400 }}>
                    <StyledQuickFilter gridApi={this.state.gridApi} />
                  </div>
                ) : null}
                <div>
                  {this.props.exportButton ? this.props.exportButton : null}
                  {this.props.createButton ? this.props.createButton : null}
                </div>
              </>
            )}
          </div>
          <AgGridReact
            animateRows={true}
            onGridReady={(params) => {
              this.setState({
                gridApi: params.api,
              });
              params.api.sizeColumnsToFit();

              if (this.props.updateSelected) {
                params.api.forEachNode((node) => {
                  if (this.props.updateSelected.includes(node.data.id)) {
                    node.setSelected(true);
                  }
                });
              }
            }}
            columnDefs={this.props.columnDefs}
            defaultColDef={{
              resizable: true,
            }}
            rowHeight={this.props.rowHeight ? this.props.rowHeight : undefined}
            domLayout={this.props.autoHeight ? "autoHeight" : undefined}
            rowData={this.props.rowData}
            suppressContextMenu={this.props.suppressContextMenu}
            suppressCellSelection={true}
            suppressRowHoverHighlight={true}
            suppressRowClickSelection={true}
            statusBar={
              this.props.statusBar
                ? {
                    statusPanels: [
                      {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left",
                      },
                    ],
                  }
                : undefined
            }
            overlayNoRowsTemplate={"<span></span>"}
            masterDetail={this.props.masterDetail}
            detailCellRendererParams={this.props.detailCellRendererParams}
            frameworkComponents={this.props.frameworkComponents}
            extraProps={this.props.extraProps}
            rowSelection={
              this.props.rowSelection ? this.props.rowSelection : undefined
            }
            onRowSelected={
              this.props.onRowSelected ? this.props.onRowSelected : undefined
            }
          />
        </div>
      </div>
    );
  }
}

export default AGGrid;
