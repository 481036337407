import React from "react";
import SchoolScorecardAnalytics from "../../components/SchoolScorecardAnalytics";

function SchoolScorecardAnalyticsPage() {
  return (
    <>
      <SchoolScorecardAnalytics />
    </>
  );
}

export default SchoolScorecardAnalyticsPage;
