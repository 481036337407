import { Activity } from "../Entities/Activity";

export interface ActivityDBService {
  createActivity: (activity: Activity, themeChoice?: any) => Promise<Activity>;
  readActivity: (activityId: string) => Promise<Activity>;
  updateActivity: (activity: Activity, themeChoice?: any) => Promise<Activity>;
  deleteActivity: (activity: Activity) => Promise<void>;
}

class ActivityUseCase {
  activityService: ActivityDBService;

  constructor(activityService: ActivityDBService) {
    this.activityService = activityService;
  }
}

export class CreateActivityInteractor extends ActivityUseCase {
  async createActivity(
    activity: Activity,
    themeChoice?: any
  ): Promise<Activity> {
    return this.activityService.createActivity(activity, themeChoice);
  }
}

export class ReadActivityInteractor extends ActivityUseCase {
  async readActivity(activityId: string): Promise<Activity> {
    return this.activityService.readActivity(activityId);
  }
}

export class UpdateActivityInteractor extends ActivityUseCase {
  async updateActivity(
    activity: Activity,
    themeChoice?: any
  ): Promise<Activity> {
    return this.activityService.updateActivity(activity, themeChoice);
  }
}

export class DeleteActivityInteractor extends ActivityUseCase {
  async deleteActivity(activity: Activity): Promise<void> {
    return this.activityService.deleteActivity(activity);
  }
}
