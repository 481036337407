import clsx from "clsx";
import { connect } from "react-redux";

import { setSidebarToggleMobile, userSelector } from "../../core";

import HeaderDots from "../../layout-components/HeaderDots";
import HeaderUserbox from "../../layout-components/HeaderUserbox";
import HeaderSelection from "../../layout-components/HeaderSelection";

const Header = (props) => {
  const {
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile,
    setSidebarToggleMobile,
  } = props;

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  return (
    <div
      className={clsx("app-header", {
        "app-header--shadow": headerShadow,
        "app-header--opacity-bg": headerBgTransparent,
      })}
    >
      <div className="app-header--pane fullWidthMobile">
        <button
          className={clsx(
            "navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn",
            { "is-active": sidebarToggleMobile }
          )}
          onClick={toggleSidebarMobile}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
        <HeaderSelection />
      </div>
      <div className="app-header--pane">
        <HeaderDots />
        <HeaderUserbox />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  user: userSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
