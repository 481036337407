import { AppState } from "./App";
import { SchoolUser, Invite } from "../../Entities";

export interface StateType {
  readonly app: AppState;
  readonly user: SchoolUser | null;
  readonly school: any | null;
  readonly scene: any | null;
  readonly invite: Invite | null;
  readonly checkout: {
    open: boolean;
  } | null;
  readonly premium: {
    productIds: string[];
    status: string;
    current_period_end: number;
    subscriptionId: string;
  } | null;
  readonly grid: {
    params: any;
  };
}

export const initialState: StateType = {
  app: { isLoading: false, error: null, authChecked: false },
  user: null,
  school: null,
  scene: null,
  invite: null,
  checkout: {
    open: false,
  },
  premium: null,
  grid: {
    params: null,
  },
};
