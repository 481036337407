import { Invite } from "../../Entities";
import { StateType, initialState } from "./State";

const UPDATE_INVITE = "invite/update";

type StateSlice = StateType["invite"];

export interface UpdateInviteActionType {
    type: string;
    invite: Invite | null;
}

export const inviteSelector = (state: StateType): StateSlice => state.invite;
export const updateInviteStateAction = (invite?: Invite): UpdateInviteActionType => ({
    type: UPDATE_INVITE,
    invite: invite || null
});

const updateHandler = (
    state: StateSlice,
    action: UpdateInviteActionType
): StateType['invite'] => action.invite;

export const inviteReducer = (
    invite: StateSlice = initialState.invite,
    action: UpdateInviteActionType
): StateSlice => {
    switch (action.type) {
        case UPDATE_INVITE:
            return updateHandler(invite, action);
        default:
            return invite;
    }
};