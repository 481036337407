import SchoolParentResponses from "../../components/SchoolParentResponses";

function SchoolParentResponsesPage() {
  return (
    <>
      <SchoolParentResponses />
    </>
  );
}

export default SchoolParentResponsesPage;
