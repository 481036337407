import { Employee, EmployeeRecord, POSITION } from "./SchoolUser";
import { Invite, InviteRecord, INVITE_STATUS } from "./Invite";
import { School, SchoolRecord } from "./School";

export interface DistrictRecord {
  id?: string;
  name: string;
  team?: {
    [schoolUserId: string]:
      | EmployeeRecord
      | POSITION
      | InviteRecord
      | INVITE_STATUS;
  };
  schoolIds: { [schoolId: string]: Boolean };
  defaultDistrict: Boolean;
  schoolData?: { [schoolId: string]: SchoolRecord };
}

export class District {
  readonly id?: number | string;
  readonly name: string;
  readonly team: { [schoolUserId: string]: Employee | Invite };
  readonly schoolIds: { [schoolId: string]: Boolean };
  readonly defaultDistrict: Boolean;
  readonly schoolData?: { [schoolId: string]: SchoolRecord };

  constructor(district: DistrictRecord) {
    const { id, name, team, schoolIds, defaultDistrict, schoolData } = district;

    this.id = id;
    this.name = name;
    this.schoolIds = schoolIds;
    this.defaultDistrict = defaultDistrict;
    this.team = Object.values(
      (team as { [id: string]: EmployeeRecord | InviteRecord }) || {}
    ).reduce(
      (store, record) =>
        record.id
          ? {
              ...store,
              [record.id]: (record as InviteRecord).status
                ? new Invite(record as InviteRecord)
                : new Employee(record as EmployeeRecord),
            }
          : store,
      {}
    );
    this.schoolData = Object.values(
      (schoolData as { [venueId: number]: SchoolRecord }) || {}
    ).reduce(
      (store, schoolRecord) =>
        schoolRecord.id
          ? { ...store, [schoolRecord.id]: new School(schoolRecord) }
          : store,
      {}
    );
  }
}
