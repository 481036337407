import SchoolMonthlyAnalytics from "../../components/SchoolMonthlyAnalytics";

function SchoolMonthlyAnalyticsPage() {
  return (
    <>
      <SchoolMonthlyAnalytics />
    </>
  );
}

export default SchoolMonthlyAnalyticsPage;
