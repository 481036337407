import { SchoolDBService } from "../UseCases/School";
import { SchoolRecord, School, District } from "../Entities";
import { SchoolServices } from "./Backend";

export class SchoolService implements SchoolDBService {
  async createSchool(
    school: SchoolRecord
  ): Promise<{ school: School; district?: District | null }> {
    return SchoolServices.createSchool(school);
  }

  async readSchool(schoolId: number | string): Promise<School> {
    return SchoolServices.readSchool(schoolId);
  }

  async updateSchool(school: SchoolRecord): Promise<School> {
    return SchoolServices.updateSchool(school);
  }

  async fetchSchoolAnalytics(
    schoolId: string,
    timeFrame: string,
    dealIds: string[]
  ): Promise<any> {
    return SchoolServices.fetchSchoolAnalytics(schoolId, timeFrame, dealIds);
  }

  async removeFromSchool(
    employeeId: string,
    schoolId: string | number
  ): Promise<void> {
    return SchoolServices.removeFromSchool(employeeId, schoolId);
  }
}
