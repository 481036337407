import React from "react";
import DistrictActivityAnalytics from "../../components/DistrictActivityAnalytics";

function DistrictActivityAnalyticsPage() {
  return (
    <>
      <DistrictActivityAnalytics />
    </>
  );
}

export default DistrictActivityAnalyticsPage;
