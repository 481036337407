import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, InputAdornment, Button, TextField } from "@material-ui/core";
import MailOutlineTwoToneIcon from "@material-ui/icons/MailOutlineTwoTone";
import LockTwoToneIcon from "@material-ui/icons/LockTwoTone";
import hero8 from "../../assets/Parent_Parties_BG.jpg";
import { connect } from "react-redux";
import {
  appSelector,
  resetAppAction,
  Credential,
  signInAction,
} from "../../core";
import { CircleLoader } from "react-spinners";
import projectLogo from "../../assets/Parent_Parties_Logo.png";

import Bugsnag from "@bugsnag/js";

// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function Login(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const params = new URLSearchParams(props.location.search);

  // Handle invites
  const inviteId = params.get("inviteId");
  // Handle redirect to info
  const itemId = params.get("itemId");
  const itemType = params.get("itemType");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const onSignIn = () => {
    if (props.app.isLoading) {
      return;
    }
    try {
      props.dispatchReset();
      props.dispatchSignIn(
        new Credential(email, password),
        inviteId,
        itemId,
        itemType
      );
    } catch (err) {
      Bugsnag.notify(err);
      setError(err.message);
    }
  };

  useEffect(() => {
    if (props.app.error) {
      /* Handle internal error */
      if (props.app.error.message.includes("Invite does not exist.")) {
        if (props.user) {
          props.history.push("/dashboard");
        }
        props.dispatchReset();
        setError(false);
        props.history.push("/login");
      }
      if (
        props.app.error.message.includes(
          "The password is invalid or the user does not have a password"
        )
      ) {
        return setError("Invalid password.");
      }
      if (
        props.app.error.message.includes(
          "There is no user record corresponding to this identifier."
        )
      ) {
        return setError(`We could not find that email address.`);
      }

      Bugsnag.notify(props.app.error);
      return setError(props.app.error.message);
    }
  }, [props.app.error]);

  useEffect(() => {
    /* Clear app errors on load */
    if (props.app.error) {
      props.dispatchReset();
      setError(false);
    }
  }, [props.dispatchReset, props.signOutAction]);

  return (
    <>
      <div className="app-wrapper min-vh-100 bg-white">
        <div className={mobile ? "w-100" : "app-main min-vh-100"}>
          <div className={mobile ? "" : "app-content p-0"}>
            <div className={mobile ? "" : "app-inner-content-layout--main"}>
              <div
                className={
                  mobile ? "" : "flex-grow-1 w-100 d-flex align-items-center"
                }
              >
                <div className={mobile ? "" : "bg-composed-wrapper--content"}>
                  <Grid container spacing={0} className="min-vh-100">
                    <Grid
                      item
                      lg={7}
                      xl={6}
                      sm={12}
                      className="d-flex align-items-center"
                    >
                      <Grid item md={10} lg={8} xl={7} className="mx-auto">
                        <div className={mobile ? "py-4 px-3" : "py-4"}>
                          <div className="text-center">
                            <div className="app-sidebar-logo mb-4 pb-4">
                              <div
                                className="app-sidebar-logo--icon"
                                style={{ backgroundColor: "#fff" }}
                              >
                                <img
                                  alt="Parent Parties Logo"
                                  src={projectLogo}
                                />
                              </div>
                              <div className="app-sidebar-logo--text">
                                <b
                                  style={{
                                    textTransform: "none",
                                    color: "rgb(47,47,47)",
                                  }}
                                >
                                  {inviteId
                                    ? "Login to Accept Invite"
                                    : "Login to Parent Parties Engage"}
                                </b>
                              </div>
                            </div>
                          </div>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              onSignIn();
                            }}
                          >
                            <div className="mb-4">
                              <TextField
                                fullWidth
                                variant="outlined"
                                id="textfield-email"
                                label="Email address"
                                InputProps={{
                                  onChange: (e) => setEmail(e.target.value),
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <MailOutlineTwoToneIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                            <div className="mb-3">
                              <TextField
                                fullWidth
                                variant="outlined"
                                id="textfield-password"
                                label="Password"
                                type="password"
                                InputProps={{
                                  onChange: (e) => setPassword(e.target.value),
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <LockTwoToneIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                            <div className="d-flex justify-content-between align-items-center font-size-md">
                              <div className="d-flex align-items-center text-danger">
                                {error ? (
                                  <>
                                    <FontAwesomeIcon
                                      style={{ fontSize: "0.70rem" }}
                                      icon={["fas", "exclamation-triangle"]}
                                    />
                                    <span className="ml-2 font-size-xs">
                                      {error}
                                    </span>
                                  </>
                                ) : null}
                              </div>
                              <div>
                                <NavLink
                                  to="/recover"
                                  className="text-first font-size-sm"
                                >
                                  Recover password
                                </NavLink>
                              </div>
                            </div>
                            <div className="text-center py-4">
                              <Button
                                className="btn-primary font-weight-bold w-50 my-2"
                                // onClick={() => onSignIn()}
                                type="submit"
                              >
                                {props.app.isLoading ? (
                                  <div
                                    style={{
                                      position: "absolute",
                                      left: "50%",
                                      marginLeft: "-60px",
                                    }}
                                  >
                                    <CircleLoader
                                      color={"#fff"}
                                      loading={true}
                                      size={25}
                                    />
                                  </div>
                                ) : null}
                                {props.app.isLoading ? (
                                  <span className="ml-2">Login</span>
                                ) : (
                                  <span>Login</span>
                                )}
                              </Button>
                            </div>
                             <div className="text-center text-black-50 mt-3">
                              Don't have an account?{" "}
                              <NavLink to="/register" className="text-first">
                                Sign up
                              </NavLink>
                            </div>
                          </form>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item lg={5} xl={6} sm={12} className="d-flex">
                      <div className="hero-wrapper w-100 bg-composed-wrapper min-vh-lg-100">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div
                            className="bg-composed-wrapper--image"
                            style={{
                              backgroundImage: "url(" + hero8 + ")",
                              filter: "none",
                              opacity: 1,
                            }}
                          />
                          {/* <div className="bg-composed-wrapper--bg bg-tempting-azure opacity-6" /> */}
                          {/* <div className="bg-composed-wrapper--bg bg-tempting-azure opacity-3" /> */}
                          <div
                            className={
                              mobile
                                ? "bg-composed-wrapper--content text-center py-5"
                                : "bg-composed-wrapper--content text-center p-5"
                            }
                          ></div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
});

const mapDispatchToProps = {
  dispatchReset: resetAppAction,
  dispatchSignIn: signInAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
