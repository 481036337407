import React from "react";
import AdminGuardians from "../../components/AdminGuardians";

function AdminGuardiansPage() {
  return (
    <>
      <AdminGuardians />
    </>
  );
}

export default AdminGuardiansPage;
