import { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import update from "immutability-helper";
import { connect } from "react-redux";
import { appSelector, userSelector, ParentPartiesAPIs } from "../../core";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Button,
  ListItem,
  List,
  Typography,
  IconButton,
  DialogActions,
  Dialog,
  TextField,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import AGGrid from "../Grid";
import { NavLink } from "react-router-dom";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";

// Responsive //
import { useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Bugsnag from "@bugsnag/js";

function dateComparator(date1, date2) {
  if (date1 === null && date2 === null) {
    return 0;
  }
  if (date1 === null) {
    return -1;
  }
  if (date2 === null) {
    return 1;
  }

  return date1 - date2;
}

const styles = (theme) => ({
  rootNormal: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButtonNormal: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, row, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.rootNormal} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButtonNormal}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function AdminResources(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [files, setFiles] = useState([]);

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteDialogInfo, setDeleteDialogInfo] = useState(null);
  const handleDeleteClose = () => {
    setDeleteDialog(false);
  };

  const handleDeleteFile = async () => {
    try {
      if (deleteDialogInfo) {
        if (deleteDialogInfo.id) {
          await ParentPartiesAPIs.deleteAdminResourceFile(deleteDialogInfo.id);
          setFiles(files.filter((file) => file.id !== deleteDialogInfo.id));
          setDeleteDialog(false);
        }
      }
    } catch (err) {
      console.log(err);
      Bugsnag.notify(err);
    }
  };

  useEffect(() => {
    async function getFiles() {
      const files = await ParentPartiesAPIs.readAdminFiles();
      const arrayOfFiles = files
        ? Object.values(files).filter((i) => i.premium === false)
        : [];
      setFiles(arrayOfFiles);
    }

    getFiles();
  }, []);

  // Upload Admin File
  const { getInputProps, open } = useDropzone({
    accept: "image/jpeg, image/png, application/pdf",
    multiple: false,
    onDrop: async (acceptedFiles) => {
      const MAX_SIZE = 31457280;
      const bigFile = (file) => file.size > MAX_SIZE;
      if (acceptedFiles.some(bigFile) === true) {
        alert("File Size Limit: 32 MB");
        return;
      }
      const newFile = acceptedFiles[0];
      const resourceFile = {
        allSchools: true,
        file: newFile,
        name: newFile.name,
        fileType: newFile.type,
      };
      toggleDialogFileName(resourceFile);
    },
  });

  const [dialogFileName, setDialogFileName] = useState(false);
  const [dialogInfoFileName, setDialogInfoFileName] = useState(null);
  const [fileNameDialog, setFileNameDialog] = useState("");
  const toggleDialogFileName = (file) => {
    if (file) {
      setDialogInfoFileName(file);
      setFileNameDialog(file.name);
    }
    setDialogFileName(!dialogFileName);
  };
  const handleFileName = async (fileInDialog) => {
    const updatedFile = {
      ...fileInDialog,
      name: fileNameDialog,
    };
    const resource = await ParentPartiesAPIs.uploadAdminResourceFile(
      updatedFile,
      false
    );

    setFiles(
      update(files, {
        $push: [resource],
      })
    );
  };

  return !props.user ? null : (
    <>
      <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
        <Grid item md={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              Admin Resources
            </div>
          </div>
        </Grid>
        <Grid item md={12} xl={12}>
          {mobile ? null : (
            <List
              component="div"
              className="nav-line-alt nav-line d-flex align-items-center mt-3"
            >
              <ListItem button disableRipple className="px-2" selected>
                <span className="font-size-sm">
                  Parent Onboarding Resources
                </span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-resources-onboarding-video`}
              >
                <span className="font-size-sm">Onboarding Video</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-resources-file-hosting`}
              >
                <span className="font-size-sm">File Hosting</span>
                <div className="divider" />
              </ListItem>
            </List>
          )}
        </Grid>
        <Grid item md={12}>
          <AGGrid
            smallTitle={true}
            titleGrid={
              <div>
                <div>Parent Onboarding Resources</div>
              </div>
            }
            search={true}
            statusBar={false}
            rowData={files}
            createButton={
              <Button
                variant="contained"
                size="medium"
                className="btn-success shadow-none ml-4"
                onClick={open}
              >
                <span className="btn-wrapper--icon mr-1">
                  <FontAwesomeIcon
                    style={{ fontSize: "12px" }}
                    icon={["fas", "plus"]}
                  />
                </span>
                <span
                  className="btn-wrapper--label"
                  style={{ fontSize: "12px" }}
                >
                  Add File
                </span>
              </Button>
            }
            columnDefs={[
              {
                headerName: "File Name",
                field: "name",
                sortable: true,
                filter: true,
              },
              {
                headerName: "Upload Date",
                field: "time",
                sortable: true,
                filter: "agDateColumnFilter",
                valueFormatter: function (params) {
                  if (params.data.time) {
                    return moment(params.data.time).format("L");
                  } else {
                    return null;
                  }
                },
                comparator: dateComparator,
              },
              {
                width: 280,
                suppressSizeToFit: true,
                field: "actions",
                headerName: "Actions",
                cellRendererFramework: (params) => (
                  <div className="d-flex flex-row align-items-center">
                    <a
                      href={params.data.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained bg-dark shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                      tabIndex="0"
                      type="button"
                    >
                      <span className="MuiButton-label">
                        <span
                          className="btn-wrapper--label text-white"
                          style={{ fontSize: "12px" }}
                        >
                          Preview
                        </span>
                      </span>
                      <span className="MuiTouchRipple-root"></span>
                    </a>
                    <button
                      className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained btn-danger shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                      tabIndex="0"
                      type="button"
                      onClick={() => {
                        setDeleteDialogInfo(params.data);
                        setDeleteDialog(true);
                      }}
                    >
                      <span className="MuiButton-label">
                        <span
                          className="btn-wrapper--label text-white"
                          style={{ fontSize: "12px" }}
                        >
                          Delete
                        </span>
                      </span>
                      <span className="MuiTouchRipple-root"></span>
                    </button>
                  </div>
                ),
                cellClass: "agGridCellCenter",
              },
            ]}
          />
        </Grid>
      </Grid>
      {/* Needed for file upload */}
      <input {...getInputProps()} style={{ display: "none" }} />
      <Dialog
        open={deleteDialog}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
        PaperProps={{
          className: "p-3",
        }}
      >
        <DialogTitle
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            color: "rgb(42, 42, 47)",
            borderBottom: "none",
          }}
        >
          {`Are you sure you want to delete file${
            deleteDialogInfo ? `: ${deleteDialogInfo.name}` : ""
          }?`}
        </DialogTitle>
        <DialogActions style={{ borderTop: "none" }} className="mt-4">
          <Button onClick={handleDeleteClose}>Cancel</Button>
          <Button
            className="btn-danger"
            onClick={() => handleDeleteFile()}
            autoFocus
          >
            {"Delete File"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={dialogFileName}
        onClose={() => toggleDialogFileName()}
        classes={{ paper: "modal-content rounded-lg pt-4" }}
      >
        <div className="px-3 pb-1 text-left font-size-md font-weight-bold">
          {`Add file name`}
        </div>
        <div className="divider my-2" />
        <div className="p-3">
          <Grid container spacing={6}>
            <Grid item md={12}>
              <FormControl fullWidth variant="outlined">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={fileNameDialog}
                    label="Menu Name"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      onChange: (e) => {
                        setFileNameDialog(e.target.value);
                      },
                    }}
                  />
                </div>
                <FormHelperText>Ex: Marketing Flyer</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <div className="pt-4">
            <Button
              onClick={() => toggleDialogFileName()}
              className="btn-neutral-dark btn-pill mx-1"
            >
              <span className="btn-wrapper--label">Cancel</span>
            </Button>
            <Button
              onClick={async () => {
                toggleDialogFileName();
                handleFileName(dialogInfoFileName);
              }}
              className={"btn-pill mx-1 btn-success"}
            >
              <span className="btn-wrapper--label">Save</span>
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
});

export default connect(mapStateToProps, null)(AdminResources);
