import { put } from "redux-saga/effects";
import { updateGridStateAction } from "../Redux";

export const UPDATE_GRID = "grid/saga/update";

interface GridActionType {
  type: string;
  params: any;
}

export const updateGridAction = (params: any): GridActionType => ({
  type: UPDATE_GRID,
  params,
});

export function* updateGridSaga(action: GridActionType) {
  yield put(updateGridStateAction(action.params));
}
