import SchoolPrizePlan from "../../components/SchoolPrizePlan";

function SchoolPrizePlanPage() {
  return (
    <>
      <SchoolPrizePlan />
    </>
  );
}

export default SchoolPrizePlanPage;
