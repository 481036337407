import React from "react";
import AdminResourcesOnboardingVideo from "../../components/AdminResourcesOnboardingVideo";

function AdminResourcesOnboardingVideoPage() {
  return (
    <>
      <AdminResourcesOnboardingVideo />
    </>
  );
}

export default AdminResourcesOnboardingVideoPage;
