import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Button } from "@material-ui/core";
import hero6 from "../../assets/images/hero-bg/hero-4.jpg";
import { connect } from "react-redux";
import { ParentPartiesAPIs, appSelector, resetAppAction } from "../../core";

function Recover(props) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userId = params.get("uid");
  const itemId = params.get("itemId");
  const itemName = params.get("itemName");
  const emailType = params.get("type");

  const [error, setError] = useState(false);
  const [unsubWorked, setUnsubWorked] = useState(false);

  useEffect(() => {
    if (props.app.error) {
      if (props.app.error.message.includes("INTERNAL")) {
        return setError(`We couldn't find that email. Please try again.`);
      }
      return setError(props.app.error.message);
    }
  }, [props.app.error]);

  const { dispatchReset, app } = props;
  useEffect(() => {
    /* Clear app errors on load */
    if (app.isLoading) {
      dispatchReset();
    }
  }, [app, dispatchReset]);

  const unsubscribeFromEmail = () => {
    if (props.app.isLoading) {
      return;
    }
    if (!userId || !emailType) {
      return;
    }
    try {
      ParentPartiesAPIs.unsubscribeFromEmail(userId, emailType, itemId);
      setUnsubWorked(true);
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
  };

  return (
    <>
      <div className="app-wrapper min-vh-100 bg-white">
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <Grid container spacing={0} className="min-vh-100">
                    <Grid
                      item
                      lg={7}
                      xl={6}
                      className="d-flex align-items-center"
                    >
                      {unsubWorked ? (
                        <Grid item md={10} lg={8} xl={7} className="mx-auto">
                          <div className="d-flex align-items-center">
                            <h1 className="display-4 mb-1 font-weight-bold mr-2">
                              Unsubscribe
                            </h1>
                            <FontAwesomeIcon
                              style={{ fontSize: "1rem", color: "#1bc943" }}
                              icon={["fas", "check-circle"]}
                            />
                          </div>
                          <p className="font-size-lg mb-0 text-black-50">
                            You've been unsubscribed from this type of email.
                          </p>
                        </Grid>
                      ) : (
                        <Grid item md={10} lg={8} xl={7} className="mx-auto">
                          <div className="py-4">
                            <div className="text-center mb-5">
                              <h1 className="display-4 mb-1 font-weight-bold">
                                Unsubscribe
                              </h1>
                              <p className="font-size-lg mb-0 text-black-50">
                                Press the button below to unsubscribe from{" "}
                                {emailType === "analytics"
                                  ? `monthly analytics emails for ${itemName}.`
                                  : "this email."}
                              </p>
                            </div>
                            {error ? (
                              <div className="d-flex align-items-center text-danger mt-2">
                                <FontAwesomeIcon
                                  style={{ fontSize: "0.70rem" }}
                                  icon={["fas", "exclamation-triangle"]}
                                />
                                <span className="ml-2 font-size-xs">
                                  {error}
                                </span>
                              </div>
                            ) : null}
                            <div className="text-center mb-4">
                              <Button
                                onClick={() => unsubscribeFromEmail()}
                                fullWidth
                                className="font-weight-bold font-size-sm mt-4 btn-primary"
                              >
                                <span className="ml-2">Unsubscribe</span>
                              </Button>
                            </div>
                            <div className="text-center text-black-50 mt-5 font-size-sm">
                              <NavLink to="/login" className="text-first">
                                Return to Login
                              </NavLink>
                            </div>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item lg={5} xl={6} className="d-flex">
                      <div className="hero-wrapper w-100 bg-composed-wrapper bg-happy-green min-vh-lg-100">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div
                            className="bg-composed-wrapper--image opacity-7"
                            style={{ backgroundImage: "url(" + hero6 + ")" }}
                          />
                          <div className="bg-composed-wrapper--bg bg-tempting-azure opacity-6" />
                          <div className="bg-composed-wrapper--bg bg-tempting-azure opacity-3" />
                          <div className="bg-composed-wrapper--content text-center p-5"></div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
});
const mapDispatchToProps = {
  dispatchReset: resetAppAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Recover);
