import React, { useState } from "react";
import { connect } from "react-redux";
import { useDropzone } from "react-dropzone";
import { sceneSelector, appSelector, userSelector } from "../../core";
import { Grid, List, ListItem, Button } from "@material-ui/core";
import { NavLink, useLocation } from "react-router-dom";
import { CircleLoader } from "react-spinners";
// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
import CloudUploadTwoToneIcon from "@material-ui/icons/CloudUploadTwoTone";
import CheckIcon from "@material-ui/icons/Check";
import { ParentPartiesAPIs } from "../../core";

const VideoDropzone = (props) => {
  const { videoUpload, userEmail } = props;
  const [loading, setLoading] = useState(false);

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: "video/mp4,video/x-m4v,video/*",
    multiple: false,
    onDrop: async (acceptedFiles) => {
      const videoFile = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )[0];

      // Set Loading
      try {
        setLoading(true);
        await ParentPartiesAPIs.submitVideoShort(videoFile, userEmail);
        setLoading(false);
        // Hide loading

        videoUpload(videoFile);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    },
  });

  return (
    <div className="dropzone">
      <div
        {...getRootProps({
          className: "dropzone-upload-wrapper",
        })}
      >
        <input {...getInputProps()} />
        <div className="dropzone-inner-wrapper">
          {isDragAccept && (
            <div>
              <div className="d-100 btn-icon mb-3 hover-scale-lg bg-success shadow-success-sm rounded-circle text-white">
                <CheckIcon className="d-50" />
              </div>
              <div className="font-size-sm text-success">
                Video will be uploaded!
              </div>
            </div>
          )}
          {isDragReject && (
            <div>
              <div className="d-100 btn-icon mb-3 hover-scale-lg bg-danger shadow-danger-sm rounded-circle text-white">
                <CloseTwoToneIcon className="d-50" />
              </div>
              <div className="font-size-sm text-danger">
                File will be rejected!
              </div>
            </div>
          )}
          {!isDragActive &&
            (loading ? (
              <div>
                <CircleLoader
                  className="d-flex align-self-center"
                  color={"#18a9a9"}
                  loading={true}
                  size={50}
                />
              </div>
            ) : (
              <div>
                <div className="d-100 btn-icon mb-3 hover-scale-lg bg-white shadow-light-sm rounded-circle text-primary">
                  <CloudUploadTwoToneIcon className="d-50" />
                </div>
                <div className="font-size-sm">
                  Drag and drop video here{" "}
                  <span className="font-size-xs text-dark">(MP4)</span>
                </div>
              </div>
            ))}
          {loading ? null : (
            <>
              <small className="py-2 text-black-50">or</small>
              <div>
                <Button className="btn-primary hover-scale-sm font-weight-bold btn-pill px-4">
                  <span className="px-2">Browse Files</span>
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

function SchoolSubmitVideoShort(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const location = useLocation();
  const { pathname } = location;
  const params = pathname.split("/");
  const sceneType = params[2];
  const sceneId = params[3];

  const [video, setVideo] = useState(null);

  return !props.user ? null : props.scene ? (
    <>
      <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
        <Grid item md={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              Video Shorts
            </div>
          </div>
        </Grid>
        <Grid item md={12} xl={12}>
          {mobile ? null : (
            <List
              component="div"
              className="nav-line-alt nav-line d-flex align-items-center mt-3"
            >
              <ListItem
                button
                component={NavLink}
                to={`/video-shorts/${sceneType}/${sceneId}`}
                className="pr-2"
              >
                <span className="font-size-sm">{"Video Shorts"}</span>
                <div className="divider" />
              </ListItem>
              <ListItem button disableRipple className="px-2" selected>
                <span className="font-size-sm">Submit Video Short</span>
                <div className="divider" />
              </ListItem>
            </List>
          )}
        </Grid>
        <Grid item md={12} xl={12}>
          <div className="mt-4">
            {video ? (
              <div>
                <div className="mt-4 pt-4 text-black">
                  Thank you for your submission! Our team will review your video
                  short and let you know if we can add it to our library.
                </div>
              </div>
            ) : (
              <div className="mt-2">
                <VideoDropzone
                  videoUpload={setVideo}
                  userEmail={
                    props.user
                      ? props.user.email
                        ? props.user.email.address
                        : ""
                      : ""
                  }
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  ) : null;
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  user: userSelector(state),
});

export default connect(mapStateToProps, null)(SchoolSubmitVideoShort);
