import EditDistrict from "../../components/EditDistrict";

function EditDistrictPage(props) {
  return (
    <>
      <EditDistrict {...props} />
    </>
  );
}

export default EditDistrictPage;
