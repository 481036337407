import { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { CircleLoader } from "react-spinners";
import {
  sceneSelector,
  appSelector,
  userSelector,
  ParentPartiesAPIs,
} from "../../core";
import { Grid, Button } from "@material-ui/core";
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import { NavLink, useLocation } from "react-router-dom";

import { Document, PDFViewer, pdf } from "@react-pdf/renderer";
import ReportGenerator from "./components/reportGenerator";

const RenderPDF = (props) => {
  return useMemo(
    () => (
      <PDFViewer
        key={Math.random()}
        style={{
          width: "100%",
          height: "2000px",
        }}
      >
        <Document>
          <ReportGenerator
            schoolName={props.reportData ? props.reportData.schoolName : ""}
            schoolYear={props.reportData ? props.reportData.schoolYear : ""}
            schoolLogo={props.reportData ? props.reportData.schoolLogo : ""}
            monthlyEngagement={
              props.reportData ? props.reportData.monthlyEngagement : []
            }
            numberOfParentsThisYear={
              props.reportData
                ? props.reportData.numberOfParentsThisYear
                  ? props.reportData.numberOfParentsThisYear
                  : 0
                : 0
            }
            numberOfSessionsThisYear={
              props.reportData
                ? props.reportData.numberOfSessionsThisYear
                  ? props.reportData.numberOfSessionsThisYear
                  : 0
                : 0
            }
            numberOfInSchoolActivities={
              props.reportData
                ? props.reportData.numberOfInSchoolActivities
                  ? props.reportData.numberOfInSchoolActivities
                  : 0
                : 0
            }
            averageAttendanceInSchool={
              props.reportData
                ? props.reportData.averageAttendanceInSchool
                  ? props.reportData.averageAttendanceInSchool
                  : 0
                : 0
            }
            activityViewsChart={props.activityViewsChart}
            activityBreakdownChart={props.activityBreakdownChart}
            languageChart={props.languageChart}
            gradeLevelChart={props.gradeLevelChart}
            ethnicityChart={props.ethnicityChart}
            averageEvaluations={
              props.reportData
                ? props.reportData.averageEvaluations
                  ? props.reportData.averageEvaluations
                  : []
                : []
            }
          />
        </Document>
      </PDFViewer>
    ),
    [props]
  );
};

/* Charts */
let chartOptions = {
  stroke: {
    show: true,
    curve: "smooth",
    width: 2,
  },
  chart: {
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: true,
    },
    animations: {
      enabled: false,
    },
  },
  fill: {
    opacity: 1,
  },
  xaxis: {
    type: "datetime",
    labels: {
      trim: false,
      rotate: 0,
      minHeight: 40,
      hideOverlappingLabels: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    strokeDashArray: "5",
    borderColor: "rgba(125, 138, 156, 0.3)",
  },
  legend: {
    show: true,
  },
  markers: {
    size: 0,
  },
  tooltip: {
    x: {
      format: "MMMM yyyy",
    },
  },
};
const ActivityViewChart = (props) => (
  <Chart
    options={{
      ...chartOptions,
      chart: {
        ...chartOptions.chart,
        id: "activity-views-chart",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
      },
    }}
    series={[
      {
        name: "Activity Views",
        data: props.reportData
          ? props.reportData.activityViewsChart
            ? props.reportData.activityViewsChart
            : []
          : [],
      },
    ]}
    type="line"
    height={300}
  />
);
const ActivityBreakdownChart = (props) => (
  <Chart
    options={{
      ...chartOptions,
      chart: {
        ...chartOptions.chart,
        id: "activity-breakdown-chart",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
      },
      tooltip: {
        ...chartOptions.tooltip,
        y: {
          formatter: function (value) {
            return Math.round(value);
          },
        },
      },
      colors: ["#018FFB", "#01E396", "#FEB019", "#FF4460", "#785DD0", "#000"],
    }}
    series={[
      {
        name: "Video",
        data: props.reportData
          ? props.reportData.activityBreakdownChart
            ? props.reportData.activityBreakdownChart.activity0
              ? props.reportData.activityBreakdownChart.activity0
              : []
            : []
          : [],
      },
      {
        name: "Trivia",
        data: props.reportData
          ? props.reportData.activityBreakdownChart
            ? props.reportData.activityBreakdownChart.activity1
              ? props.reportData.activityBreakdownChart.activity1
              : []
            : []
          : [],
      },
      {
        name: "Engage at Home",
        data: props.reportData
          ? props.reportData.activityBreakdownChart
            ? props.reportData.activityBreakdownChart.activity2
              ? props.reportData.activityBreakdownChart.activity2
              : []
            : []
          : [],
      },
      {
        name: "Reading Rewards",
        data: props.reportData
          ? props.reportData.activityBreakdownChart
            ? props.reportData.activityBreakdownChart.activity3
              ? props.reportData.activityBreakdownChart.activity3
              : []
            : []
          : [],
      },
      {
        name: "School Activity",
        data: props.reportData
          ? props.reportData.activityBreakdownChart
            ? props.reportData.activityBreakdownChart.activity4
              ? props.reportData.activityBreakdownChart.activity4
              : []
            : []
          : [],
      },
      {
        name: "Link",
        data: props.reportData
          ? props.reportData.activityBreakdownChart
            ? props.reportData.activityBreakdownChart.activity5
              ? props.reportData.activityBreakdownChart.activity5
              : []
            : []
          : [],
      },
      {
        name: "Fast Facts",
        data: props.reportData
          ? props.reportData.activityBreakdownChart
            ? props.reportData.activityBreakdownChart.activity6
              ? props.reportData.activityBreakdownChart.activity6
              : []
            : []
          : [],
      },
      {
        name: "More to Explore",
        data: props.reportData
          ? props.reportData.activityBreakdownChart
            ? props.reportData.activityBreakdownChart.activity7
              ? props.reportData.activityBreakdownChart.activity7
              : []
            : []
          : [],
      },
    ]}
    type="bar"
    height={300}
  />
);
const GradeLevelChart = (props) => (
  <Chart
    options={{
      chart: {
        id: "grade-level-chart",
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#1A9A9A"],
      grid: {
        strokeDashArray: "5",
        borderColor: "rgba(125, 138, 156, 0.3)",
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      legend: {
        show: false,
      },
      labels: props.reportData
        ? props.reportData.gradeLevelChart
          ? props.reportData.gradeLevelChart.map((item) => item.label)
          : []
        : [],
    }}
    series={
      props.reportData
        ? props.reportData.gradeLevelChart
          ? [
              {
                name: "Guardians",
                data: props.reportData.gradeLevelChart.map(
                  (item) => item.amount
                ),
              },
            ]
          : []
        : []
    }
    type="bar"
    height={280}
  />
);
const LanguageChart = (props) => (
  <Chart
    options={{
      chart: {
        id: "language-chart",
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#1A9A9A"],
      grid: {
        strokeDashArray: "5",
        borderColor: "rgba(125, 138, 156, 0.3)",
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      legend: {
        show: false,
      },
      labels: props.reportData
        ? props.reportData.languageChart
          ? props.reportData.languageChart.map((item) => item.label)
          : []
        : [],
    }}
    series={
      props.reportData
        ? props.reportData.languageChart
          ? [
              {
                name: "Guardians",
                data: props.reportData.languageChart.map((item) => item.amount),
              },
            ]
          : []
        : []
    }
    type="bar"
    height={280}
  />
);
const EthnicityChart = (props) => (
  <Chart
    options={{
      chart: {
        id: "ethnicity-chart",
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                color: "#000",
                fontFamily: "Heebo",
              },
            },
          },
        },
      },
      colors: [
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#3F51B5",
        "#775DD0",
        "#A5978B",
        "#546E7A",
        "#FF4560",
      ],
      labels: props.reportData
        ? props.reportData.ethnicityChart
          ? props.reportData.ethnicityChart.map((item) => item.label)
          : []
        : [],
      legend: {
        show: true,
        position: "right",
        formatter: function (seriesName, opts) {
          return `${seriesName} (${opts.w.globals.series[opts.seriesIndex]})`;
        },
      },
    }}
    series={
      props.reportData
        ? props.reportData.ethnicityChart
          ? props.reportData.ethnicityChart.map((item) => item.amount)
          : []
        : []
    }
    type="donut"
    width="600"
  />
);

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
function SchoolGenerateReport(props) {
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState(null);
  const [activityViewsChart, setActivityViewsChart] = useState("");
  const [activityBreakdownChart, setActivityBreakdownChart] = useState("");
  const [ethnicityChart, setEthnicityChart] = useState("");
  const [gradeLevelChart, setGradeLevelChart] = useState("");
  const [languageChart, setLanguageChart] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    async function getReportData() {
      if (props.scene) {
        const theData = await ParentPartiesAPIs.getReportData(props.scene.id);
        setReportData(theData);

        await sleep(2000);
        try {
          ApexCharts.exec("ethnicity-chart", "dataURI").then(({ imgURI }) => {
            setEthnicityChart(imgURI);
          });
          ApexCharts.exec("language-chart", "dataURI").then(({ imgURI }) => {
            setLanguageChart(imgURI);
          });
          ApexCharts.exec("grade-level-chart", "dataURI").then(({ imgURI }) => {
            setGradeLevelChart(imgURI);
          });
          ApexCharts.exec("activity-views-chart", "dataURI").then(
            ({ imgURI }) => {
              setActivityViewsChart(imgURI);
            }
          );
          ApexCharts.exec("activity-breakdown-chart", "dataURI").then(
            ({ imgURI }) => {
              setActivityBreakdownChart(imgURI);
            }
          );
        } catch (err) {
          console.log(err);
        }

        setLoading(false);
      }
    }

    getReportData();
  }, []);

  const fetchDocument = (props) => (
    <Document>
      <ReportGenerator
        schoolName={props.reportData ? props.reportData.schoolName : ""}
        schoolYear={props.reportData ? props.reportData.schoolYear : ""}
        schoolLogo={props.reportData ? props.reportData.schoolLogo : ""}
        monthlyEngagement={
          props.reportData ? props.reportData.monthlyEngagement : []
        }
        numberOfParentsThisYear={
          props.reportData
            ? props.reportData.numberOfParentsThisYear
              ? props.reportData.numberOfParentsThisYear
              : 0
            : 0
        }
        numberOfSessionsThisYear={
          props.reportData
            ? props.reportData.numberOfSessionsThisYear
              ? props.reportData.numberOfSessionsThisYear
              : 0
            : 0
        }
        numberOfInSchoolActivities={
          props.reportData
            ? props.reportData.numberOfInSchoolActivities
              ? props.reportData.numberOfInSchoolActivities
              : 0
            : 0
        }
        averageAttendanceInSchool={
          props.reportData
            ? props.reportData.averageAttendanceInSchool
              ? props.reportData.averageAttendanceInSchool
              : 0
            : 0
        }
        activityViewsChart={props.activityViewsChart}
        activityBreakdownChart={props.activityBreakdownChart}
        languageChart={props.languageChart}
        gradeLevelChart={props.gradeLevelChart}
        ethnicityChart={props.ethnicityChart}
        averageEvaluations={
          props.reportData
            ? props.reportData.averageEvaluations
              ? props.reportData.averageEvaluations
              : []
            : []
        }
      />
    </Document>
  );

  const finishReport = async () => {
    try {
      setLoading(true);

      /* Document to Blob */
      const document = fetchDocument({
        gradeLevelChart,
        activityViewsChart,
        activityBreakdownChart,
        ethnicityChart,
        languageChart,
        reportData,
      });
      const blob = await pdf(document).toBlob();

      /* Navigate */
      await ParentPartiesAPIs.addGeneratedReport({
        schoolYear: reportData.schoolYear,
        schoolId: sceneId,
        schoolName: props.scene
          ? props.scene.data
            ? props.scene.data.name
            : ""
          : "",
        file: blob,
      });

      setSuccess(true);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const location = useLocation();
  const { pathname } = location;
  const params = pathname.split("/");
  const sceneType = params[2];
  const sceneId = params[3];

  return !props.user ? null : props.scene ? (
    <>
      <Grid container spacing={4} style={{ marginBottom: "6rem" }}>
        <Grid item md={12}>
          <div
            style={{
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              Generate Report
            </div>
            <div
              style={{
                color: "#000",
                fontSize: "16px",
                lineHeight: "16px",
                marginTop: "8px",
              }}
            >
              Get a summary of your parent engagements efforts easily for the
              current school year.
            </div>
          </div>
        </Grid>
        {success && !loading ? (
          <Grid item md={8}>
            <div
              style={{
                color: "#000",
                fontSize: "16px",
                lineHeight: "16px",
                marginTop: "8px",
              }}
            >
              Successfully generated report!
            </div>
            <Button
              variant="contained"
              size="large"
              className="btn-primary shadow-none mt-4"
              component={NavLink}
              to={{
                pathname: `/analytics/${sceneType}/${sceneId}`,
              }}
            >
              <span className="btn-wrapper--label" style={{ fontSize: "12px" }}>
                Go to Reports
              </span>
            </Button>
          </Grid>
        ) : (
          <>
            <Grid item md={12} sm={12}>
              {loading ? (
                <CircleLoader
                  className="d-flex align-self-center"
                  color={"#18A9A9"}
                  loading={true}
                  size={40}
                />
              ) : null}
            </Grid>
            <Grid item md={8}>
              {loading ? null : (
                <div
                  style={{
                    color: "#000",
                    fontSize: "16px",
                    lineHeight: "16px",
                    marginBottom: "20px",
                  }}
                >
                  PDF Preview
                </div>
              )}
              {activityBreakdownChart &&
              activityViewsChart &&
              gradeLevelChart &&
              ethnicityChart &&
              languageChart &&
              reportData ? (
                <RenderPDF
                  gradeLevelChart={gradeLevelChart}
                  activityViewsChart={activityViewsChart}
                  activityBreakdownChart={activityBreakdownChart}
                  ethnicityChart={ethnicityChart}
                  languageChart={languageChart}
                  reportData={reportData}
                />
              ) : null}
            </Grid>
            <Grid item md={12}>
              {loading ? null : activityBreakdownChart &&
                activityViewsChart &&
                gradeLevelChart &&
                ethnicityChart &&
                languageChart &&
                reportData ? (
                <>
                  <div className="d-flex flex-row align-items-center mt-2">
                    <Button
                      variant="contained"
                      size="large"
                      className="btn-dark shadow-none mr-4"
                      component={NavLink}
                      to={{
                        pathname: `/analytics/${sceneType}/${sceneId}`,
                      }}
                    >
                      <span
                        className="btn-wrapper--label"
                        style={{ fontSize: "12px" }}
                      >
                        Go back
                      </span>
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      className="btn-primary shadow-none"
                      onClick={finishReport}
                    >
                      <span
                        className="btn-wrapper--label"
                        style={{ fontSize: "12px" }}
                      >
                        {`Generate Report`}
                      </span>
                    </Button>
                  </div>
                </>
              ) : null}
              <div style={{ opacity: 0, position: "absolute" }}>
                <EthnicityChart reportData={reportData} />
                <ActivityViewChart reportData={reportData} />
                <ActivityBreakdownChart reportData={reportData} />
                <GradeLevelChart reportData={reportData} />
                <LanguageChart reportData={reportData} />
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </>
  ) : null;
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  user: userSelector(state),
});

export default connect(mapStateToProps, null)(SchoolGenerateReport);
