import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { appSelector, userSelector, ParentPartiesAPIs } from "../../core";
import {
  Grid,
  ImageList,
  ImageListItem,
  Divider,
  Button,
} from "@material-ui/core";
import { CircleLoader } from "react-spinners";

function AdminResourcesUploads(props) {
  const [initialLoad, setInitialLoad] = useState(true);
  const [uploads, setUploads] = useState([]);
  const [selectedUpload, setSelectedUpload] = useState(null);

  const handlePickImage = async (image) => {
    await ParentPartiesAPIs.setSelectedUpload(image);
    setSelectedUpload(image);
  };

  const handleRemoveImage = async (image) => {
    await ParentPartiesAPIs.removeSelectedUpload(image);
    setSelectedUpload(null);
  };

  useEffect(() => {
    async function getUploads() {
      const resources = await ParentPartiesAPIs.readAllUploads(true);
      const arrayOfUploads = resources
        .map((resource) => (resource ? Object.values(resource) : null))
        .flat()
        .filter((i) => i !== null);
      const sortArrayUpload = arrayOfUploads.sort((a, b) => {
        return b.time - a.time;
      });
      setUploads(sortArrayUpload);
      setInitialLoad(false);
    }
    async function getSelectedUpload() {
      const selected = await ParentPartiesAPIs.readSelectedUpload();
      setSelectedUpload(selected);
    }

    getUploads();
    getSelectedUpload();
  }, []);

  return !props.user ? null : initialLoad || props.app.isLoading ? (
    <Grid container className="my-spacing-6">
      <Grid item xs={12}>
        <div className="d-flex flex-row justify-content-center">
          <CircleLoader
            className="d-flex align-self-center"
            color={"#18a9a9"}
            loading={true}
            size={40}
          />
        </div>
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
        <Grid item md={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              Admin Email
            </div>
          </div>
        </Grid>
        <Grid item md={12}>
          <div className="my-4 py-4">
            <div
              className="heading-4 text-left mb-0"
              style={{
                color: "#000",
                textTransform: "none",
              }}
            >
              Selected upload will be displayed on Monthly analytic email to
              schools. Resets each month.
            </div>
            <div>
              {selectedUpload ? (
                <div className="d-flex flex-row align-items-end">
                  <img
                    src={selectedUpload.url}
                    loading="lazy"
                    alt="parent upload"
                    style={{
                      height: "400px",
                      width: "400px",
                      objectFit: "contain",
                      border: "1px solid #d3d3d3",
                    }}
                    className="mt-3"
                  />
                  <Button
                    className="ml-4 button bg-danger"
                    onClick={handleRemoveImage}
                    variant="contained"
                    style={{ color: "#fff" }}
                  >
                    Clear
                  </Button>
                </div>
              ) : (
                <div className="text-black mt-2">
                  No upload selected currently.
                </div>
              )}
            </div>
          </div>
          <Divider className="my-4" />
          <div className="mt-4">
            <div className="d-flex flex-row mb-4 align-items-center justify-content-between">
              <div>
                <div
                  className="heading-4 text-left mb-0"
                  style={{
                    color: "#000",
                    textTransform: "none",
                  }}
                >
                  Parent uploads this month
                </div>
                <small className="text-black-50">
                  {uploads
                    ? uploads.length
                      ? `(${uploads.length} upload${
                          uploads.length > 1 ? "s" : ""
                        })`
                      : ""
                    : ""}
                </small>
              </div>
            </div>
            <ImageList variant="woven" cols={3} gap={8} rowHeight={400}>
              {uploads.map((item) => (
                <ImageListItem
                  key={item.url}
                  onClick={() => {
                    handlePickImage(item);
                  }}
                >
                  <img src={item.url} loading="lazy" alt="parent upload" />
                </ImageListItem>
              ))}
            </ImageList>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
});

export default connect(mapStateToProps, null)(AdminResourcesUploads);
