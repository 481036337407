import React from "react";
import AdminVideoShorts from "../../components/AdminVideoShorts";

function AdminVideoShortsPage() {
  return (
    <>
      <AdminVideoShorts />
    </>
  );
}

export default AdminVideoShortsPage;
