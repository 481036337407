import { DistrictDBService } from "../UseCases/District";
import { DistrictRecord, District } from "../Entities";
import { DistrictServices } from "./Backend";

export class DistrictService implements DistrictDBService {
  async createDistrict(
    // schoolUserId: string,
    district: DistrictRecord
  ): Promise<District | Error> {
    return DistrictServices.createDistrict(
      // schoolUserId,
      district
    );
  }

  async readDistrict(districtId: string): Promise<District | Error> {
    return DistrictServices.readDistrict(districtId);
  }

  async updateDistrict(district: DistrictRecord): Promise<District | Error> {
    return DistrictServices.updateDistrict(district);
  }

  async removeFromDistrict(
    employeeId: string,
    districtId: string
  ): Promise<void> {
    return DistrictServices.removeFromDistrict(employeeId, districtId);
  }
}
