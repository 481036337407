import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { withStyles } from "@material-ui/core/styles";
import { TextField, InputAdornment } from "@material-ui/core";
import debounce from "../../../utils/debounce";
import { Search } from "@material-ui/icons";

import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const stylesFilter = {
  filterBox: {
    padding: ".75em .75em .75em .5em",
    fontSize: ".9em",
  },
  inputBG: {
    background: "#FFFFFF",
  },
};

function QuickFilter(props) {
  const handleQuickFilter = debounce((value) => {
    props.gridApi.setQuickFilter(value);
  }, 300);

  const onQuickFilterText = (event) => {
    handleQuickFilter(event.target.value);
  };
  const { classes } = props;
  return (
    <div>
      <TextField
        fullWidth
        placeholder={"Search"}
        variant="outlined"
        InputProps={{
          className: classes.inputBG,
          classes: {
            input: classes.filterBox,
          },
          startAdornment: (
            <InputAdornment position="end">
              <Search style={{ color: "rgba(0,0,0,.6)" }} />
            </InputAdornment>
          ),
        }}
        className={classes.textField}
        onChange={onQuickFilterText}
      />
    </div>
  );
}

const StyledQuickFilter = withStyles(stylesFilter)(QuickFilter);

class SupportGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: null,
      quickFilter: null,
      columnDefs: [
        {
          checkboxSelection: true,
          headerName: "School",
          field: "name",
          sortable: true,
          filter: true,
          pinned: "left",
        },
        {
          headerName: "Address",
          field: "location.address",
          sortable: true,
          filter: true,
        },
        {
          headerName: "City",
          field: "location.city",
          sortable: true,
          filter: true,
        },
        {
          headerName: "State",
          field: "location.state",
          sortable: true,
          filter: true,
        },
      ],
      rowsSet: false,
    };
  }

  componentDidUpdate() {
    if (this.props.selectedSchools && this.state.rowsSet === false) {
      const schoolIds = Object.keys(this.props.selectedSchools);
      if (schoolIds.length && this.state.gridApi) {
        this.state.gridApi.forEachNode(function (node) {
          node.setSelected(schoolIds.includes(node.data.id));
        });
        this.setState({
          rowsSet: true,
        });
      }
    }
  }

  render() {
    const { classes, pageHeaderPresent } = this.props;
    return (
      <div className={pageHeaderPresent ? classes.container : ""}>
        <div
          className="ag-theme-alpine pb-4"
          style={{
            height: "300px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <div style={{ width: 400 }}>
              <StyledQuickFilter gridApi={this.state.gridApi} />
            </div>
          </div>
          <AgGridReact
            animateRows={true}
            onGridReady={(params) =>
              this.setState({
                gridApi: params.api,
              })
            }
            columnDefs={this.state.columnDefs}
            defaultColDef={{
              resizable: true,
            }}
            onRowSelected={this.props.onSelectSchool}
            rowSelection={"multiple"}
            rowData={this.props.rowData}
          />
        </div>
      </div>
    );
  }
}

export default SupportGrid;
