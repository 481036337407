import React, { useState, useEffect } from "react";
import update from "immutability-helper";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import {
  sceneSelector,
  appSelector,
  userSelector,
  updateSchoolAction,
  School,
} from "../../core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  Tooltip,
  Button,
  TextField,
  Switch,
  List,
  ListItem,
  FormHelperText,
} from "@material-ui/core";
import { CircleLoader } from "react-spinners";

const useStyles = makeStyles((theme) => ({
  input: {
    lineHeight: 1.5,
    fontSize: "14px",
  },
  inputDisabled: {
    lineHeight: 1.5,
    fontSize: "14px",
    color: "rgba(0,0,0,.5)",
  },
}));

function SchoolPrizePlan(props) {
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (props.scene) {
      if (props.scene.data && props.scene.type === "school") {
        if (props.scene.data.prizePlanActive) {
          setPrizePlanActive(true);
        }
        if (props.scene.data.prizePlan) {
          setPrizePlan(props.scene.data.prizePlan);
        } else {
          setPrizePlan([
            "Example: Get a special rewards package including games, candies, and a personal thank you note from the principals office.",
            "Example: Recieve a three reading books for you and child to read together.",
            "Example: Get a pat on the back for a good try.",
          ]);
        }
        if (props.scene.data.prizePlanDescription) {
          setPrizePlanDescription(props.scene.data.prizePlanDescription);
        }
        setInitialLoad(false);
      }
    }
  }, [props.scene]);

  const [prizePlanActive, setPrizePlanActive] = useState(false);
  const [prizePlan, setPrizePlan] = useState([]);
  const [prizePlanDescription, setPrizePlanDescription] = useState("");
  const [edit, setEdit] = useState(null);
  const [editText, setEditText] = useState("");

  const addPlacement = () => {
    const updatedPrizePlan = update(prizePlan, {
      $push: ["Placement description"],
    });
    setPrizePlan(updatedPrizePlan);
    setEdit(updatedPrizePlan.length - 1);
    setEditText("Placement description");

    const newSchool = new School({
      ...props.scene.data,
      prizePlan: updatedPrizePlan,
    });
    props.dispatchUpdateSchool(newSchool);
  };

  const removePlacement = (indexToRemove) => {
    const updatedPrizePlan = update(prizePlan, {
      $splice: [[indexToRemove, 1]],
    });
    setPrizePlan(updatedPrizePlan);
    setEdit(null);
    setEditText("");

    const newSchool = new School({
      ...props.scene.data,
      prizePlan: updatedPrizePlan,
    });
    props.dispatchUpdateSchool(newSchool);
  };

  const handleSave = () => {
    const updatedPrizePlan = update(prizePlan, {
      [edit]: {
        $set: editText,
      },
    });
    setPrizePlan(updatedPrizePlan);
    setEdit(null);
    setEditText("");

    const newSchool = new School({
      ...props.scene.data,
      prizePlanActive,
      prizePlan: updatedPrizePlan,
    });
    props.dispatchUpdateSchool(newSchool);
  };

  const handleCancel = () => {
    setEdit(null);
    setEditText("");
  };

  function nth(n) {
    return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
  }

  const handleActiveChange = (target) => {
    setPrizePlanActive(target);

    const newSchool = new School({
      ...props.scene.data,
      prizePlanActive: target,
      prizePlan,
    });
    props.dispatchUpdateSchool(newSchool);
  };

  const handleDescriptionChange = (e) => {
    setPrizePlanDescription(e.target.value);

    const newSchool = new School({
      ...props.scene.data,
      prizePlanActive,
      prizePlan,
      prizePlanDescription: e.target.value,
    });
    props.dispatchUpdateSchool(newSchool);
  };

  const classes = useStyles();

  return !props.user ? null : props.scene ? (
    initialLoad || props.app.isLoading ? (
      <Grid container className="my-spacing-6">
        <Grid item xs={12}>
          <div className="d-flex flex-row justify-content-center">
            <CircleLoader
              className="d-flex align-self-center"
              color={"#18a9a9"}
              loading={true}
              size={40}
            />
          </div>
        </Grid>
      </Grid>
    ) : (
      <>
        <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
          <Grid item md={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: 13,
                paddingBottom: 13,
              }}
            >
              <div
                className="font-weight-bold display-4"
                style={{ color: "rgb(43, 39, 60)" }}
              >
                Mobile App
              </div>
            </div>
          </Grid>
          <Grid item md={12}>
            <List
              component="div"
              className="nav-line-alt nav-line d-flex align-items-center mt-3"
            >
              <ListItem
                button
                disableRipple
                className="pr-2"
                component={NavLink}
                to={`/push-notification/s/${props.scene.id}`}
              >
                <span className="font-size-sm">Push Notification</span>
                <div className="divider" />
              </ListItem>
              <ListItem button disableRipple className="px-2" selected>
                <span className="font-size-sm">Prize Plan</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/teachers/s/${props.scene.id}`}
              >
                <span className="font-size-sm">Teachers</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/resources-file-hosting/s/${props.scene.id}`}
              >
                <span className="font-size-sm">Guardian Resources</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="pr-2"
                component={NavLink}
                to={`/more-info/s/${props.scene.id}`}
              >
                <span className="font-size-sm">Contact Info</span>
                <div className="divider" />
              </ListItem>
            </List>
          </Grid>
          <Grid item md={12}>
            <div className="pt-2">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingTop: 13,
                  paddingBottom: 13,
                }}
                className="mt-4"
              >
                <div
                  className="font-weight-bold"
                  style={{ color: "rgb(43, 39, 60)" }}
                >
                  Prize Plan Active
                </div>
                <Switch
                  onChange={(e) => {
                    handleActiveChange(e.target.checked);
                  }}
                  checked={prizePlanActive}
                  className="switch-small toggle-switch-success ml-3"
                />
              </div>
              <div className="mt-1 mb-4">
                <div className="text-black-50 font-size-sm">
                  Show a prize plan to guardians on the leaderboard page in
                  mobile app.
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={12}>
            <Card
              className="p-4 mt-4"
              style={{
                borderRadius: "0.25rem",
                border: "1px solid rgba(0,0,0,.1)",
              }}
            >
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <div
                    className={
                      prizePlanActive
                        ? "font-weight-bold text-black mb-3"
                        : "font-weight-bold text-black-50 mb-3"
                    }
                    style={{ fontSize: "14px" }}
                  >
                    Description (optional)
                  </div>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    type="text"
                    multiline
                    value={prizePlanDescription}
                    onChange={handleDescriptionChange}
                    inputProps={{
                      style: { minHeight: "50px" },
                    }}
                  />
                  <FormHelperText>
                    {
                      "Ex: Prizes reset at the beginning of each semester and monthly prizes will also be given out."
                    }
                  </FormHelperText>
                </Grid>
                <Grid item md={2}>
                  <div
                    className={
                      prizePlanActive
                        ? "font-weight-bold text-black"
                        : "font-weight-bold text-black-50"
                    }
                    style={{ fontSize: "14px" }}
                  >
                    Placement
                  </div>
                </Grid>
                <Grid item md={10}>
                  <div
                    className={
                      prizePlanActive
                        ? "font-weight-bold text-black"
                        : "font-weight-bold text-black-50"
                    }
                    style={{ fontSize: "14px" }}
                  >
                    Prize Description
                  </div>
                </Grid>
              </Grid>
              <div className="divider my-2" />
              <Grid container spacing={4} className="mt-2">
                {prizePlan.map((desc, index) => (
                  <React.Fragment key={index}>
                    <Grid item md={2}>
                      <div
                        className={
                          prizePlanActive
                            ? "font-weight-bold text-black"
                            : "font-weight-bold text-black-50"
                        }
                        style={{ fontSize: "14px" }}
                      >
                        {`${index + 1}${nth(index + 1)} place`}
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={10}
                      className="d-flex flex-row align-items-center"
                    >
                      <Card
                        className="p-4 w-100"
                        style={{
                          borderRadius: "0.25rem",
                          border: "1px solid rgba(0,0,0,.1)",
                        }}
                      >
                        {edit === index ? (
                          <div className="text-black">
                            <TextField
                              multiline={true}
                              fullWidth
                              type="text"
                              value={editText}
                              onChange={(e) => {
                                setEditText(e.target.value);
                              }}
                              InputProps={{
                                classes: {
                                  root: classes.input,
                                },
                              }}
                            />
                            <div className="d-flex flex-row align-items-center mt-4">
                              <Button
                                variant="outlined"
                                size="small"
                                className="btn shadow-none mr-2"
                                onClick={() => handleCancel()}
                              >
                                <span
                                  className="btn-wrapper--label"
                                  style={{ fontSize: "14px" }}
                                >
                                  Cancel
                                </span>
                              </Button>
                              <Button
                                variant="contained"
                                size="small"
                                className="btn-primary shadow-none"
                                onClick={() => handleSave()}
                              >
                                <span
                                  className="btn-wrapper--label"
                                  style={{ fontSize: "14px" }}
                                >
                                  Save Changes
                                </span>
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div
                            className={
                              prizePlanActive ? "text-black" : "text-black-50"
                            }
                            style={{ fontSize: "14px" }}
                          >
                            {desc}
                          </div>
                        )}
                      </Card>
                      {prizePlanActive && (
                        <Tooltip title={"Edit"}>
                          <Button
                            onClick={() => {
                              setEdit(index);
                              setEditText(desc);
                            }}
                            className="btn-icon btn-pill d-50 p-0 mx-2"
                          >
                            <span className="btn-wrapper--icon">
                              <FontAwesomeIcon
                                icon={["fas", "edit"]}
                                className="font-size-sm"
                                color="#18A9A9"
                              />
                            </span>
                          </Button>
                        </Tooltip>
                      )}
                      {prizePlan.length > 1 && prizePlanActive ? (
                        <Tooltip title={"Delete"}>
                          <Button
                            onClick={() => {
                              removePlacement(index);
                            }}
                            className="btn-icon btn-pill d-50 p-0 mx-2"
                          >
                            <span className="btn-wrapper--icon">
                              <FontAwesomeIcon
                                icon={["fas", "trash"]}
                                className="font-size-sm"
                                style={{ color: "gray" }}
                              />
                            </span>
                          </Button>
                        </Tooltip>
                      ) : null}
                    </Grid>
                  </React.Fragment>
                ))}
                <Grid item md={2} />
                <Grid
                  item
                  md={10}
                  className="d-flex flex-row align-items-center"
                >
                  <Button
                    variant="contained"
                    size="small"
                    className="btn-success shadow-none mt-2 py-2"
                    onClick={() => {
                      addPlacement();
                    }}
                    disabled={prizePlanActive ? false : true}
                  >
                    <span className="btn-wrapper--icon mr-1">
                      <FontAwesomeIcon
                        style={{ fontSize: "14px" }}
                        icon={["fas", "plus"]}
                      />
                    </span>
                    <span
                      className="btn-wrapper--label"
                      style={{ fontSize: "14px" }}
                    >
                      Add Placement
                    </span>
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </>
    )
  ) : null;
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = {
  dispatchUpdateSchool: updateSchoolAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SchoolPrizePlan);
