import SchoolReports from "../../components/SchoolReports";

function SchoolReportsPage() {
  return (
    <>
      <SchoolReports />
    </>
  );
}

export default SchoolReportsPage;
