import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  InputAdornment,
  Button,
  TextField,
} from "@material-ui/core";
import MailOutlineTwoToneIcon from "@material-ui/icons/MailOutlineTwoTone";
import hero8 from "../../assets/Parent_Parties_BG.jpg";
import { connect } from "react-redux";
import {
  resetAppAction,
  Email,
  resetPasswordAction,
  appSelector,
} from "../../core";
import { CircleLoader } from "react-spinners";

// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function Recover(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [passwordResetSent, setPasswordResetSent] = useState(false);

  useEffect(() => {
    /* Clear app errors on load */
    if (props.app.error) {
      props.dispatchReset();
      setError(false);
    }
  }, [props.dispatchReset]);

  const onResetPassword = () => {
    if (props.app.isLoading || resetLoading) {
      return;
    }
    if (props.app.error) {
      props.dispatchReset();
      setError(false);
    }
    try {
      props.dispatchResetPassword(new Email({ address: email }));
      setResetLoading(true);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    if (resetLoading) {
      if (!props.app.isLoading) {
        if (props.app.error) {
          if (props.app.error.message.includes("INTERNAL")) {
            setError(`We couldn't find that email. Please try again.`);
          } else {
            setError(props.app.error.message);
          }
          setResetLoading(false);
        } else {
          setPasswordResetSent(true);
          setResetLoading(false);
        }
      }
    }
  }, [props.app.error, props.app.isLoading]);

  return (
    <>
      <div className="app-wrapper min-vh-100 bg-white">
        <div className={mobile ? "w-100" : "app-main min-vh-100"}>
          <div className={mobile ? "" : "app-content p-0"}>
            <div className={mobile ? "" : "app-inner-content-layout--main"}>
              <div
                className={
                  mobile ? "" : "flex-grow-1 w-100 d-flex align-items-center"
                }
              >
                <div className={mobile ? "" : "bg-composed-wrapper--content"}>
                  <Grid container spacing={0} className="min-vh-100">
                    <Grid
                      item
                      lg={7}
                      xl={6}
                      className="d-flex align-items-center"
                    >
                      {passwordResetSent ? (
                        <Grid item md={10} lg={8} xl={7} className="mx-auto">
                          <div className={mobile ? "px-3 text-center" : ""}>
                            <div
                              className={
                                mobile
                                  ? "d-flex align-items-center justify-content-center"
                                  : "d-flex align-items-center"
                              }
                            >
                              <h1 className="display-4 mb-1 font-weight-bold mr-2">
                                Recover Password
                              </h1>
                              <FontAwesomeIcon
                                style={{ fontSize: "1rem", color: "#1bc943" }}
                                icon={["fas", "check-circle"]}
                              />
                            </div>
                            <p className="font-size-lg mb-0 text-black-50">
                              Please check your email to reset your password.
                            </p>
                          </div>
                        </Grid>
                      ) : (
                        <Grid item md={10} lg={8} xl={7} className="mx-auto">
                          <div className={mobile ? "py-4 px-3" : "py-4"}>
                            <div className="text-left mb-5">
                              <h1 className="display-4 mb-1 font-weight-bold">
                                Password Reset
                              </h1>
                            </div>
                            <div>
                              <TextField
                                fullWidth
                                variant="outlined"
                                id="textfield-email"
                                label="Email address"
                                InputProps={{
                                  onChange: (e) => setEmail(e.target.value),
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <MailOutlineTwoToneIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                            {error ? (
                              <div className="d-flex align-items-center text-danger mt-2">
                                <FontAwesomeIcon
                                  style={{ fontSize: "0.70rem" }}
                                  icon={["fas", "exclamation-triangle"]}
                                />
                                <span className="ml-2 font-size-xs">
                                  {error}
                                </span>
                              </div>
                            ) : null}
                            <div className="text-center mb-4">
                              <Button
                                onClick={() => onResetPassword()}
                                fullWidth
                                className="font-weight-bold font-size-sm mt-4 btn-primary"
                              >
                                {props.app.isLoading ? (
                                  <div
                                    style={{
                                      position: "absolute",
                                      left: "50%",
                                      marginLeft: "-80px",
                                    }}
                                  >
                                    <CircleLoader
                                      color={"#fff"}
                                      loading={true}
                                      size={25}
                                    />
                                  </div>
                                ) : null}
                                <span className="ml-2">
                                  Send Password Reset
                                </span>
                              </Button>
                            </div>
                            <div className="text-center text-black-50 mt-5 font-size-sm">
                              <NavLink to="/login" className="text-first">
                                Return to Login
                              </NavLink>
                            </div>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item lg={5} xl={6} className="d-flex">
                      <div className="hero-wrapper w-100 bg-composed-wrapper min-vh-lg-100">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div
                            className="bg-composed-wrapper--image"
                            style={{
                              backgroundImage: "url(" + hero8 + ")",
                              filter: "none",
                              opacity: 1,
                            }}
                          />
                          {/* <div className="bg-composed-wrapper--bg bg-tempting-azure opacity-6" /> */}
                          {/* <div className="bg-composed-wrapper--bg bg-tempting-azure opacity-3" /> */}
                          <div
                            className={
                              mobile
                                ? "bg-composed-wrapper--content text-center py-5"
                                : "bg-composed-wrapper--content text-center p-5"
                            }
                          ></div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
});
const mapDispatchToProps = {
  dispatchReset: resetAppAction,
  dispatchResetPassword: resetPasswordAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Recover);
