import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  sceneSelector,
  appSelector,
  userSelector,
  VideoShortServices,
} from "../../core";
import { Grid, List, ListItem } from "@material-ui/core";
import AGGrid from "../Grid";
import { NavLink, useLocation } from "react-router-dom";

// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function SchoolVideoShorts(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [videoShorts, setVideoShorts] = useState([]);

  useEffect(() => {
    async function getAdminVideoShorts() {
      const videoShortsResult = await VideoShortServices.readAllAdminVideoShorts();
      setVideoShorts(videoShortsResult);
    }

    getAdminVideoShorts();
  }, []);

  const location = useLocation();
  const { pathname } = location;
  const params = pathname.split("/");
  const sceneType = params[2];
  const sceneId = params[3];

  return !props.user ? null : props.scene ? (
    <>
      <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
        <Grid item md={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              Video Shorts
            </div>
          </div>
        </Grid>
        <Grid item md={12} xl={12}>
          {mobile ? null : (
            <List
              component="div"
              className="nav-line-alt nav-line d-flex align-items-center mt-3"
            >
              <ListItem button selected className="pr-2">
                <span className="font-size-sm">{"Video Shorts"}</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/submit-video-short/${sceneType}/${sceneId}`}
              >
                <span className="font-size-sm">Submit Video Short</span>
                <div className="divider" />
              </ListItem>
            </List>
          )}
        </Grid>
        <Grid item md={12} className="mt-4 pt-4">
          <AGGrid
            rowData={videoShorts}
            search={false}
            rowHeight={80}
            autoHeight={false}
            suppressContextMenu={true}
            columnDefs={[
              {
                headerName: "Title",
                field: "name",
                sortable: true,
                filter: true,
                cellRendererFramework: (params) => (
                  <div className="d-flex flex-row align-items-center">
                    <div
                      style={{
                        width: "85px",
                        height: "50px",
                        backgroundImage: `url("${params.data.thumbnail}")`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        border: "1px solid rgba(0,0,0,.1)",
                        borderRadius: "3px",
                      }}
                      alt={params.data.name}
                    />
                    <div className="ml-4">{params.data.name}</div>
                  </div>
                ),
                cellClass: "agGridCellCenter",
              },
              {
                width: 280,
                suppressSizeToFit: true,
                field: "actions",
                headerName: "Actions",
                cellRendererFramework: (params) => (
                  <div className="d-flex flex-row align-items-center">
                    <a
                      className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained bg-dark shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                      href={params.data.video}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <span className="MuiButton-label">
                        <span
                          className="btn-wrapper--label text-white"
                          style={{ fontSize: "12px" }}
                        >
                          View
                        </span>
                      </span>
                      <span className="MuiTouchRipple-root"></span>
                    </a>
                  </div>
                ),
                cellClass: "agGridCellCenter",
              },
            ]}
          />
        </Grid>
      </Grid>
    </>
  ) : null;
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  user: userSelector(state),
});

export default connect(mapStateToProps, null)(SchoolVideoShorts);
