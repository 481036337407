import { applyMiddleware, combineReducers, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import { rootReducer } from "../Adapters/Redux";
import { rootSaga } from "../Adapters/Saga";

export const configureStore = () => {
  const middleware: any[] = [];

  if (process.env.NODE_ENV !== "production") {
    middleware.push(createLogger());
  }

  const sagaMiddleware = createSagaMiddleware();
  middleware.push(sagaMiddleware);

  const store = createStore(
    combineReducers(rootReducer),
    applyMiddleware(...middleware)
  );

  sagaMiddleware.run(rootSaga);
  return store;
};
