import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { appSelector, userSelector, VideoShortServices } from "../../core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Button,
  List,
  ListItem,
  Dialog,
  DialogActions,
  Typography,
  IconButton,
  DialogContent,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { NavLink } from "react-router-dom";
import AGGrid from "../Grid";
import moment from "moment";

// Responsive //
import { useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Bugsnag from "@bugsnag/js";

const styles = (theme) => ({
  rootNormal: {
    margin: 0,
    padding: theme.spacing(2),
  },
  root: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButtonNormal: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButton: {
    position: "static",
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, row, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={row ? classes.root : classes.rootNormal}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={row ? classes.closeButton : classes.closeButtonNormal}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function AdminVideoShorts(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [videoShorts, setVideoShorts] = useState([]);
  const [viewDialog, setViewDialog] = useState(false);
  const [viewDialogInfo, setViewDialogInfo] = useState(null);
  const handleViewClose = () => {
    setViewDialog(false);
  };
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteDialogInfo, setDeleteDialogInfo] = useState(null);
  const handleDeleteClose = () => {
    setDeleteDialog(false);
  };

  const handleDeleteVideoShort = async () => {
    try {
      if (deleteDialogInfo) {
        if (deleteDialogInfo.id) {
          await VideoShortServices.deleteVideoShort(deleteDialogInfo);
          setVideoShorts(
            videoShorts.filter(
              (videoShort) => videoShort.id !== deleteDialogInfo.id
            )
          );
          setDeleteDialog(false);
        }
      }
    } catch (err) {
      console.log(err);
      Bugsnag.notify(err);
    }
  };

  useEffect(() => {
    async function getAdminVideoShorts() {
      const videoShortsResult = await VideoShortServices.readAllAdminVideoShorts();
      setVideoShorts(videoShortsResult);
    }

    getAdminVideoShorts();
  }, []);

  return !props.user ? null : (
    <>
      <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
        <Grid item md={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              Admin Video Shorts
            </div>
            <Button
              variant="contained"
              size="medium"
              className="btn-success shadow-none ml-4"
              component={NavLink}
              to={{
                pathname: "/admin-add-video-short",
                type: "add",
              }}
            >
              <span className="btn-wrapper--icon mr-1">
                <FontAwesomeIcon
                  style={{ fontSize: "12px" }}
                  icon={["fas", "plus"]}
                />
              </span>
              <span className="btn-wrapper--label" style={{ fontSize: "12px" }}>
                Add Video
              </span>
            </Button>
          </div>
        </Grid>
        <Grid item md={12} xl={12}>
          {mobile ? null : (
            <List
              component="div"
              className="nav-line-alt nav-line d-flex align-items-center mt-3"
            >
              <ListItem button selected className="pr-2">
                <span className="font-size-sm">{"Video Shorts"}</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-submitted-video-shorts`}
              >
                <span className="font-size-sm">Submitted Video Shorts</span>
                <div className="divider" />
              </ListItem>
            </List>
          )}
        </Grid>
        <Grid item md={12} className="mt-4 pt-4">
          <AGGrid
            rowData={videoShorts}
            search={false}
            rowHeight={80}
            autoHeight={false}
            suppressContextMenu={true}
            columnDefs={[
              {
                headerName: "Title",
                field: "name",
                sortable: true,
                filter: true,
                cellRendererFramework: (params) => (
                  <div className="d-flex flex-row align-items-center">
                    <div
                      style={{
                        width: "85px",
                        height: "50px",
                        backgroundImage: `url("${params.data.thumbnail}")`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        border: "1px solid rgba(0,0,0,.1)",
                        borderRadius: "3px",
                      }}
                      alt={params.data.name}
                    />
                    <div className="ml-4">{params.data.name}</div>
                  </div>
                ),
                cellClass: "agGridCellCenter",
              },
              {
                headerName: "Last Modified",
                field: "lastUpdated",
                sortable: true,
                filter: true,
                valueGetter: function (params) {
                  if (params.data.lastUpdated !== undefined) {
                    return `${moment(params.data.lastUpdated).format(
                      "L"
                    )} ${moment(params.data.lastUpdated).format("hh:mm A")}`;
                  } else {
                    return null;
                  }
                },
                cellClass: "agGridCellCenter",
              },
              {
                width: 280,
                suppressSizeToFit: true,
                field: "actions",
                headerName: "Actions",
                cellRendererFramework: (params) => (
                  <div className="d-flex flex-row align-items-center">
                    <a
                      className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained bg-dark shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                      href={params.data.video}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <span className="MuiButton-label">
                        <span
                          className="btn-wrapper--label text-white"
                          style={{ fontSize: "12px" }}
                        >
                          View
                        </span>
                      </span>
                      <span className="MuiTouchRipple-root"></span>
                    </a>
                    <NavLink
                      to={{
                        pathname: `/admin-edit-video-short`,
                        type: "edit",
                        videoData: params.data,
                      }}
                      style={{ lineHeight: 0 }}
                    >
                      <button
                        className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained btn-primary shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                        tabIndex="0"
                        type="button"
                      >
                        <span className="MuiButton-label">
                          <span
                            className="btn-wrapper--label text-white"
                            style={{ fontSize: "12px" }}
                          >
                            Edit
                          </span>
                        </span>
                        <span className="MuiTouchRipple-root"></span>
                      </button>
                    </NavLink>
                    <button
                      className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained btn-danger shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                      tabIndex="0"
                      type="button"
                      onClick={() => {
                        setDeleteDialogInfo(params.data);
                        setDeleteDialog(true);
                      }}
                    >
                      <span className="MuiButton-label">
                        <span
                          className="btn-wrapper--label text-white"
                          style={{ fontSize: "12px" }}
                        >
                          Delete
                        </span>
                      </span>
                      <span className="MuiTouchRipple-root"></span>
                    </button>
                  </div>
                ),
                cellClass: "agGridCellCenter",
              },
            ]}
          />
        </Grid>
      </Grid>
      <Dialog
        open={deleteDialog}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
        PaperProps={{
          className: "p-3",
        }}
      >
        <DialogTitle
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            color: "rgb(42, 42, 47)",
            borderBottom: "none",
          }}
        >
          {`Are you sure you want to delete video short${
            deleteDialogInfo ? `: ${deleteDialogInfo.name}` : ""
          }?`}
        </DialogTitle>
        <DialogActions style={{ borderTop: "none" }} className="mt-4">
          <Button onClick={handleDeleteClose}>Cancel</Button>
          <Button
            className="btn-danger"
            onClick={() => handleDeleteVideoShort()}
            autoFocus
          >
            {"Delete Video Short"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
});

export default connect(mapStateToProps, null)(AdminVideoShorts);
