import { Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import OpenSans from "../../../assets/reportFonts/OpenSans-Light.ttf";
import Poppins from "../../../assets/reportFonts/Poppins-Bold.ttf";
import Parent_Parties_Login from "../../../assets/Parent_Parties_Login.png";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: Poppins,
      fontWeight: 600,
    },
  ],
});
Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: OpenSans,
      fontWeight: 300,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: "30 0 30 0",
  },
  /* Header */
  pageHeader: {
    height: "30pt",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  pageHeaderLeftContainer: {
    display: "flex",
    flexDirection: "row",
  },
  pageHeaderLeft: {
    width: "20pt",
    backgroundColor: "#000",
  },
  pageHeaderLeft2: {
    width: "30pt",
    backgroundColor: "#1aa9a9",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    fontSize: "11pt",
  },
  pageHeaderLeftSchoolContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "8pt",
  },
  pageHeaderLeftSchool: {
    color: "#000",
    fontSize: "11pt",
  },
  pageHeaderLineContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  pageHeaderLine: {
    backgroundColor: "#1aa9a9",
    height: "2pt",
    width: "250pt",
  },
  /* Main Container */
  mainContainer: {
    padding: "50 60 50 60",
  },
  pageTitle: {
    fontFamily: "Poppins",
    fontSize: "38pt",
    fontWeight: "600",
  },
  subTitle: {
    fontFamily: "Poppins",
    fontSize: "14pt",
    fontWeight: "600",
  },
  subSection: {
    marginTop: "10pt",
  },
  lightText: {
    fontFamily: "Open Sans",
    fontSize: "10pt",
    marginBottom: "6pt",
  },
  imageContainer: {
    marginTop: "10pt",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  frontMain: {
    color: "#fff",
    fontWeight: 100,
    fontSize: "25pt",
  },
});

const Page1 = (props) => {
  return (
    <Page size="A4" style={styles.page}>
      <View
        style={{
          paddingTop: 20,
          paddingBottom: 20,
          paddingLeft: 50,
          paddingRight: 50,
        }}
      >
        {props.schoolLogo ? (
          <Image
            style={{
              width: 60,
              height: 60,
              position: "absolute",
              right: 40,
              top: 20,
              border: "1pt solid #d3d3d3",
            }}
            src={props.schoolLogo}
          />
        ) : null}
        <View
          style={{
            border: "3pt solid #000",
            width: 400,
            height: 600,
            position: "absolute",
            top: 120,
            left: 150,
          }}
        />
        <View
          style={{
            height: "100%",
            width: "70%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#1aa9a9",
          }}
        >
          <View
            style={{
              marginTop: "130pt",
              marginLeft: "40pt",
            }}
          >
            <Image
              style={{
                width: 400,
                height: 250,
                border: "1pt solid #1aa9a9",
              }}
              src={Parent_Parties_Login}
            />
            <View
              style={{
                width: 400,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <View
                style={{
                  backgroundColor: "#000",
                  width: 150,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <Text
                  style={{
                    color: "#fff",
                    fontSize: "16pt",
                    textAlign: "center",
                  }}
                >
                  {props.schoolYear}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              marginLeft: "40pt",
              marginTop: "50pt",
              width: 275,
            }}
          >
            <Text style={styles.frontMain}>
              {props.schoolName ? props.schoolName : ""}
            </Text>
            <Text
              style={{
                ...styles.frontMain,
                marginTop: "10pt",
              }}
            >
              Engagement Report
            </Text>
          </View>
          <View
            style={{
              backgroundColor: "#fff",
              height: "3px",
              width: "50px",
              marginTop: "30px",
              marginBottom: "30px",
              marginLeft: "40pt",
            }}
          />
          <Text
            style={{
              color: "#fff",
              fontFamily: "Open Sans",
              fontSize: "12pt",
              marginLeft: "40pt",
            }}
          >
            Presented by Parent Parties Engage
          </Text>
        </View>
      </View>
    </Page>
  );
};
const Page2 = (props) => {
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.pageHeader}>
        <View style={styles.pageHeaderLeftContainer}>
          <View style={styles.pageHeaderLeft} />
          <View style={styles.pageHeaderLeft2}>
            <Text render={({ pageNumber }) => `${pageNumber}`} />
          </View>
          <View style={styles.pageHeaderLeftSchoolContainer}>
            <Text style={styles.pageHeaderLeftSchool}>
              {props.schoolName ? props.schoolName : ""}
            </Text>
          </View>
        </View>
        <View style={styles.pageHeaderLineContainer}>
          <View style={styles.pageHeaderLine} />
        </View>
      </View>
      <View style={styles.mainContainer}>
        <Text style={styles.pageTitle}>Total Engagement</Text>
        <View style={{ marginTop: "40pt" }}>
          <Text style={styles.subTitle}>Mobile App</Text>
          <View style={styles.subSection}>
            <Text style={styles.lightText}>
              Number of Sessions: {props.numberOfSessionsThisYear}
            </Text>
            <Text style={styles.lightText}>
              Number of Parents: {props.numberOfParentsThisYear}
            </Text>
          </View>
        </View>
        <View style={{ marginTop: "40pt" }}>
          <Text style={styles.subTitle}>In-School Events</Text>
          <View style={styles.subSection}>
            <Text style={styles.lightText}>
              Number of Events: {props.numberOfInSchoolActivities}
            </Text>
            <Text style={styles.lightText}>
              Average Attendance Per Event: {props.averageAttendanceInSchool}
            </Text>
          </View>
        </View>
        <View style={{ marginTop: "40pt" }}>
          <Text style={styles.subTitle}>Engagement Over Time</Text>
        </View>
        <View style={styles.imageContainer}>
          <Image
            style={{ width: 450, height: 250 }}
            src={props.activityViewsChart}
          />
        </View>
      </View>
    </Page>
  );
};
const Page3 = (props) => {
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.pageHeader}>
        <View style={styles.pageHeaderLeftContainer}>
          <View style={styles.pageHeaderLeft} />
          <View style={styles.pageHeaderLeft2}>
            <Text render={({ pageNumber }) => `${pageNumber}`} />
          </View>
          <View style={styles.pageHeaderLeftSchoolContainer}>
            <Text style={styles.pageHeaderLeftSchool}>
              {props.schoolName ? props.schoolName : ""}
            </Text>
          </View>
        </View>
        <View style={styles.pageHeaderLineContainer}>
          <View style={styles.pageHeaderLine} />
        </View>
      </View>
      <View style={styles.mainContainer}>
        <Text style={styles.pageTitle}>Guardian Analytics</Text>
        <Text style={styles.lightText}>
          See the breakdown of race/ethncity, language, and grade levels for
          guardians.
        </Text>
        <View style={{ marginTop: "30pt" }}>
          <Text style={styles.subTitle}>Race / ethnicity</Text>
        </View>
        <View style={styles.imageContainer}>
          <Image
            style={{ width: 450, height: 250 }}
            src={props.ethnicityChart}
          />
        </View>
        <View style={{ marginTop: "30pt" }}>
          <Text style={styles.subTitle}>Language</Text>
        </View>
        <View style={styles.imageContainer}>
          <Image
            style={{ width: 450, height: 200 }}
            src={props.languageChart}
          />
        </View>
      </View>
    </Page>
  );
};
const Page4 = (props) => {
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.pageHeader}>
        <View style={styles.pageHeaderLeftContainer}>
          <View style={styles.pageHeaderLeft} />
          <View style={styles.pageHeaderLeft2}>
            <Text render={({ pageNumber }) => `${pageNumber}`} />
          </View>
          <View style={styles.pageHeaderLeftSchoolContainer}>
            <Text style={styles.pageHeaderLeftSchool}>
              {props.schoolName ? props.schoolName : ""}
            </Text>
          </View>
        </View>
        <View style={styles.pageHeaderLineContainer}>
          <View style={styles.pageHeaderLine} />
        </View>
      </View>
      <View style={styles.mainContainer}>
        <View style={{ marginTop: "40pt" }}>
          <Text style={styles.subTitle}>Grade levels</Text>
        </View>
        <View style={styles.imageContainer}>
          <Image
            style={{ width: 450, height: 200 }}
            src={props.gradeLevelChart}
          />
        </View>
      </View>
    </Page>
  );
};
const Page5 = (props) => {
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.pageHeader}>
        <View style={styles.pageHeaderLeftContainer}>
          <View style={styles.pageHeaderLeft} />
          <View style={styles.pageHeaderLeft2}>
            <Text render={({ pageNumber }) => `${pageNumber}`} />
          </View>
          <View style={styles.pageHeaderLeftSchoolContainer}>
            <Text style={styles.pageHeaderLeftSchool}>
              {props.schoolName ? props.schoolName : ""}
            </Text>
          </View>
        </View>
        <View style={styles.pageHeaderLineContainer}>
          <View style={styles.pageHeaderLine} />
        </View>
      </View>
      <View style={styles.mainContainer}>
        <Text style={styles.pageTitle}>Activity Analytics</Text>
        <Text style={styles.lightText}>
          Number of mobile app activity views and breakdown by most popular
          activity types.
        </Text>
        <View style={{ marginTop: "30pt" }}>
          <Text style={styles.subTitle}>Activity Views</Text>
        </View>
        <View style={styles.imageContainer}>
          <Image
            style={{ width: 450, height: 250 }}
            src={props.activityViewsChart}
          />
        </View>
        <View style={{ marginTop: "30pt" }}>
          <Text style={styles.subTitle}>Breakdown by Activity Type</Text>
        </View>
        <View style={styles.imageContainer}>
          <Image
            style={{ width: 450, height: 200 }}
            src={props.activityBreakdownChart}
          />
        </View>
      </View>
    </Page>
  );
};
const MonthlyEngagementPage = (props) => {
  const inAppEthnicity = props.inAppEngagement.ethnicBreakdown
    ? props.inAppEngagement.ethnicBreakdown
        .filter((i) => i.label && i.amount)
        .sort((a, b) => a.label.localeCompare(b.label))
    : [];

  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.pageHeader}>
        <View style={styles.pageHeaderLeftContainer}>
          <View style={styles.pageHeaderLeft} />
          <View style={styles.pageHeaderLeft2}>
            <Text render={({ pageNumber }) => `${pageNumber}`} />
          </View>
          <View style={styles.pageHeaderLeftSchoolContainer}>
            <Text style={styles.pageHeaderLeftSchool}>
              {props.schoolName ? props.schoolName : ""}
            </Text>
          </View>
        </View>
        <View style={styles.pageHeaderLineContainer}>
          <View style={styles.pageHeaderLine} />
        </View>
      </View>
      <View style={styles.mainContainer}>
        <Text style={styles.pageTitle}>{props.monthName} Engagement</Text>
        <View style={{ marginTop: "40pt" }}>
          <Text style={styles.subTitle}>Mobile App</Text>
          <View style={styles.subSection}>
            <Text style={styles.lightText}>
              Theme: {props.inAppEngagement.theme}
            </Text>
            <Text style={styles.lightText}>
              Number of Parents: {props.inAppEngagement.numberOfParents}
            </Text>
            <Text style={styles.lightText}>
              Number of Sessions: {props.inAppEngagement.numberOfSessions}
            </Text>
          </View>
          <View style={{ marginTop: "15pt" }}>
            <Text style={styles.lightText}>Race / ethnicity</Text>
            {inAppEthnicity.map((ethnicity) => (
              <Text
                key={`${props.inAppEngagement.theme}-${ethnicity.label}`}
                style={styles.lightText}
                wrap={false}
              >
                {ethnicity.label}: {ethnicity.amount}
              </Text>
            ))}
          </View>
        </View>
        {props.inSchoolEngagement.length ? (
          <View style={{ marginTop: "40pt" }}>
            <Text style={styles.subTitle}>In-School Events</Text>
            {props.inSchoolEngagement.map((event) => {
              const filteredBreakdown = event.ethnicityBreakdown
                ? event.ethnicityBreakdown
                    .filter((i) => i.label && i.value)
                    .sort((a, b) => a.label.localeCompare(b.label))
                : [];
              return (
                <>
                  <View style={styles.subSection}>
                    <Text style={styles.lightText} wrap={false}>
                      {`Date: ${event.date}`}
                    </Text>
                    <Text style={styles.lightText} wrap={false}>
                      {`Topic: ${event.topic}`}
                    </Text>
                    <Text style={styles.lightText} wrap={false}>
                      {`Activity: ${event.activity}`}
                    </Text>
                    <Text style={styles.lightText} wrap={false}>
                      {`Attendees: ${event.attendees}`}
                    </Text>
                  </View>
                  {filteredBreakdown ? (
                    <View style={{ marginTop: "15pt" }}>
                      <Text style={styles.lightText}>Race / ethnicity</Text>
                      {filteredBreakdown.map((ethnicity) => (
                        <Text
                          key={`${event.id}-${ethnicity.label}`}
                          style={styles.lightText}
                          wrap={false}
                        >
                          {ethnicity.label}: {ethnicity.value}
                        </Text>
                      ))}
                    </View>
                  ) : null}
                </>
              );
            })}
          </View>
        ) : null}
      </View>
    </Page>
  );
};
const MonthlyEvidencePage = (props) => {
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.pageHeader}>
        <View style={styles.pageHeaderLeftContainer}>
          <View style={styles.pageHeaderLeft} />
          <View style={styles.pageHeaderLeft2}>
            <Text render={({ pageNumber }) => `${pageNumber}`} />
          </View>
          <View style={styles.pageHeaderLeftSchoolContainer}>
            <Text style={styles.pageHeaderLeftSchool}>
              {props.schoolName ? props.schoolName : ""}
            </Text>
          </View>
        </View>
        <View style={styles.pageHeaderLineContainer}>
          <View style={styles.pageHeaderLine} />
        </View>
      </View>
      <View style={styles.mainContainer}>
        <Text style={styles.pageTitle}>{props.monthName} Evidence</Text>
        {props.flattenedEvidence
          ? props.flattenedEvidence.map((file) => (
              <View key={file.id} wrap={false}>
                <View style={{ marginTop: "30pt" }}>
                  <Text style={styles.subTitle}>{file.fileName}</Text>
                  <View style={{ marginTop: "5px" }}>
                    <Text style={styles.lightText}>
                      Activity: {file.activity}
                    </Text>
                    <Text style={styles.lightText}>Date: {file.date}</Text>
                  </View>
                </View>
                <View style={styles.imageContainer}>
                  <Image
                    style={{
                      width: 400,
                      height: 450,
                      border: "1pt solid #d3d3d3",
                    }}
                    src={file.fileSrc}
                  />
                </View>
              </View>
            ))
          : null}
      </View>
    </Page>
  );
};
const MonthlyEngagementPages = (props) => {
  return (
    <>
      {props.monthlyEngagement.map((month) => {
        let flattenedEvidence = [];
        const evidenceEvents = month.inSchoolEngagement.filter(
          (event) => event.evidenceFiles
        );
        evidenceEvents.map((event) =>
          event.evidenceFiles.map((file) => {
            flattenedEvidence.push({
              activity: event.activity,
              date: event.date,
              id: `${file.name}-${file.id}`,
              fileName: file.name,
              fileSrc: file.preview,
            });

            return file;
          })
        );

        return (
          <>
            <MonthlyEngagementPage {...month} schoolName={props.schoolName} />
            {evidenceEvents.length ? (
              <MonthlyEvidencePage
                {...month}
                schoolName={props.schoolName}
                flattenedEvidence={flattenedEvidence}
              />
            ) : null}
          </>
        );
      })}
    </>
  );
};
const MonthlyEvaluations = (props) => {
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.pageHeader}>
        <View style={styles.pageHeaderLeftContainer}>
          <View style={styles.pageHeaderLeft} />
          <View style={styles.pageHeaderLeft2}>
            <Text render={({ pageNumber }) => `${pageNumber}`} />
          </View>
          <View style={styles.pageHeaderLeftSchoolContainer}>
            <Text style={styles.pageHeaderLeftSchool}>
              {props.schoolName ? props.schoolName : ""}
            </Text>
          </View>
        </View>
        <View style={styles.pageHeaderLineContainer}>
          <View style={styles.pageHeaderLine} />
        </View>
      </View>
      <View style={styles.mainContainer}>
        <Text style={styles.pageTitle}>Monthly Evaluations</Text>
        <Text style={styles.lightText}>
          Evaluations are sent at the end of every month to measure engagement
          and get feedback from guardians.
        </Text>
        {props.averageEvaluations ? (
          props.averageEvaluations.length ? (
            <>
              <View style={{ marginTop: "30pt", marginBottom: "5pt" }}>
                <Text style={styles.subTitle}>
                  Total Evaluation Responses:{" "}
                  {props.averageEvaluations[0].totalResponses}
                </Text>
                <Text style={styles.lightText}>
                  The table below shows the average response rating for each of
                  your current parent evaluation questions.
                </Text>
              </View>
              <View style={styles.subSection}>
                <View style={styles.tableRow}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      flex: 3,
                    }}
                  >
                    <Text style={styles.lightText}>Quesitons</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      flex: 1,
                    }}
                  >
                    <Text style={styles.lightText}>Rating (1 - 5)</Text>
                  </View>
                </View>
                <View
                  style={{
                    backgroundColor: "#d3d3d3",
                    width: "100%",
                    height: "1pt",
                  }}
                />
                <View style={{ marginTop: "10pt" }}>
                  {props.averageEvaluations.map((evaluation, index, arr) => (
                    <View
                      style={{
                        ...styles.tableRow,
                        alignItems: "flex-start",
                        marginBottom: arr.length - 1 === index ? "0pt" : "15pt",
                      }}
                      key={index}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          flex: 3,
                          marginRight: "25pt",
                        }}
                      >
                        <Text style={styles.lightText}>
                          {evaluation.question}
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          flex: 1,
                        }}
                      >
                        <Text style={styles.lightText}>
                          {evaluation.average} Average rating
                        </Text>
                        <Text style={styles.lightText}>
                          {evaluation.totalResponses} Responses
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            </>
          ) : null
        ) : null}
      </View>
    </Page>
  );
};

const Main = (props) => {
  return (
    <>
      <Page1 {...props} />
      <Page2 {...props} />
      <Page3 {...props} />
      <Page4 {...props} />
      <Page5 {...props} />
      <MonthlyEngagementPages {...props} />
      <MonthlyEvaluations {...props} />
    </>
  );
};

export default Main;
