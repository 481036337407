import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { appSelector, userSelector, ParentPartiesAPIs } from "../../core";
import {
  Grid,
  ListItem,
  List,
  Dialog,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core";
import AGGrid from "../Grid";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import { NavLink } from "react-router-dom";
import Bugsnag from "@bugsnag/js";

// Responsive //
import { useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { CircleLoader } from "react-spinners";

function dateComparator(date1, date2) {
  if (date1 === null && date2 === null) {
    return 0;
  }
  if (date1 === null) {
    return -1;
  }
  if (date2 === null) {
    return 1;
  }

  return date1 - date2;
}

const styles = (theme) => ({
  rootNormal: {
    margin: 0,
    padding: theme.spacing(2),
  },
  root: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButtonNormal: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButton: {
    position: "static",
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, row, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={row ? classes.root : classes.rootNormal}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={row ? classes.closeButton : classes.closeButtonNormal}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function AdminResourcesUploads(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [initialLoad, setInitialLoad] = useState(true);
  const [uploads, setUploads] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteDialogInfo, setDeleteDialogInfo] = useState(null);
  const handleDeleteClose = () => {
    setDeleteDialog(false);
  };

  const handleDeleteUpload = async () => {
    try {
      if (deleteDialogInfo) {
        if (deleteDialogInfo.id) {
          await ParentPartiesAPIs.deleteUpload(deleteDialogInfo);
          setUploads(
            uploads.filter((upload) => upload.id !== deleteDialogInfo.id)
          );
          setDeleteDialog(false);
        }
      }
    } catch (err) {
      console.log(err);
      Bugsnag.notify(err);
    }
  };

  useEffect(() => {
    async function getUploads() {
      const resources = await ParentPartiesAPIs.readAllUploads();
      const arrayOfUploads = resources
        ? Object.values(resources)
            .filter((resource) => resource.uploads)
            .map((resource) => Object.values(resource.uploads))
            .flat()
        : [];
      setUploads(arrayOfUploads);
      setInitialLoad(false);
    }

    getUploads();
  }, []);

  return !props.user ? null : initialLoad || props.app.isLoading ? (
    <Grid container className="my-spacing-6">
      <Grid item xs={12}>
        <div className="d-flex flex-row justify-content-center">
          <CircleLoader
            className="d-flex align-self-center"
            color={"#18a9a9"}
            loading={true}
            size={40}
          />
        </div>
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
        <Grid item md={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              Admin Activities
            </div>
          </div>
        </Grid>
        <Grid item md={12} xl={12}>
          {mobile ? null : (
            <List
              component="div"
              className="nav-line-alt nav-line d-flex align-items-center mt-3"
            >
              <ListItem
                button
                component={NavLink}
                to={`/admin-activities`}
                className="pr-2"
              >
                <span className="font-size-sm">{"Activities"}</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-parent-evaluations`}
              >
                <span className="font-size-sm">Parent Evaluations</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-weekly-scorecard`}
              >
                <span className="font-size-sm">Weekly Scorecard</span>
                <div className="divider" />
              </ListItem>
              <ListItem button disableRipple className="px-2" selected>
                <span className="font-size-sm">Guardian Uploads</span>
                <div className="divider" />
              </ListItem>
              {/* <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-archived-activities`}
              >
                <span className="font-size-sm">Archive</span>
                <div className="divider" />
              </ListItem> */}
            </List>
          )}
        </Grid>
        <Grid item md={12}>
          <AGGrid
            smallTitle={true}
            titleGrid={"User Uploads from Schools"}
            search={false}
            statusBar={false}
            rowData={uploads}
            columnDefs={[
              {
                headerName: "Username",
                field: "userName",
                sortable: true,
                filter: true,
              },
              {
                headerName: "School Name",
                field: "schoolName",
                sortable: true,
                filter: true,
              },
              {
                headerName: "Upload Date",
                field: "time",
                sortable: true,
                filter: "agDateColumnFilter",
                valueFormatter: function (params) {
                  if (params.data.time) {
                    return moment(params.data.time).format("L");
                  } else {
                    return null;
                  }
                },
                comparator: dateComparator,
              },
              {
                width: 300,
                suppressSizeToFit: true,
                field: "actions",
                headerName: "Actions",
                cellRendererFramework: (params) => (
                  <div className="d-flex flex-row align-items-center">
                    <a
                      href={params.data.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained bg-dark shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                      tabIndex="0"
                      type="button"
                    >
                      <span className="MuiButton-label">
                        <span
                          className="btn-wrapper--label text-white"
                          style={{ fontSize: "12px" }}
                        >
                          Preview
                        </span>
                      </span>
                      <span className="MuiTouchRipple-root"></span>
                    </a>
                    {/* <button
                      onClick={() => downloadFile(params.data.url)}
                      className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained bg-primary shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                      tabIndex="0"
                      type="button"
                      // onClick={() => {
                      //   setPreviewDialogInfo(params.data);
                      //   setPreviewDialog(true);
                      // }}
                    >
                      <span className="MuiButton-label">
                        <span
                          className="btn-wrapper--label text-white"
                          style={{ fontSize: "12px" }}
                        >
                          Download
                        </span>
                      </span>
                      <span className="MuiTouchRipple-root"></span>
                    </button> */}
                    <button
                      className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained btn-danger shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                      tabIndex="0"
                      type="button"
                      onClick={() => {
                        setDeleteDialogInfo(params.data);
                        setDeleteDialog(true);
                      }}
                    >
                      <span className="MuiButton-label">
                        <span
                          className="btn-wrapper--label text-white"
                          style={{ fontSize: "12px" }}
                        >
                          Delete
                        </span>
                      </span>
                      <span className="MuiTouchRipple-root"></span>
                    </button>
                  </div>
                ),
                cellClass: "agGridCellCenter",
              },
            ]}
          />
        </Grid>
      </Grid>
      <Dialog
        open={deleteDialog}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
        PaperProps={{
          className: "p-3",
        }}
      >
        <DialogTitle
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            color: "rgb(42, 42, 47)",
            borderBottom: "none",
          }}
        >
          {`Are you sure you want to delete this upload?`}
        </DialogTitle>
        <DialogActions style={{ borderTop: "none" }} className="mt-4">
          <Button onClick={handleDeleteClose}>Cancel</Button>
          <Button
            className="btn-danger"
            onClick={() => handleDeleteUpload()}
            autoFocus
          >
            {"Delete Upload"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
});

export default connect(mapStateToProps, null)(AdminResourcesUploads);
