import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/storage";
import "firebase/analytics";

const configs = Object.freeze({
  development: {
    apiKey: "AIzaSyDbHnoE6SdVnpBP1BB4R5i8WyoKheOrtqA",
    authDomain: "parent-parties-development.firebaseapp.com",
    databaseURL:
      "https://parent-parties-development-default-rtdb.firebaseio.com",
    projectId: "parent-parties-development",
    storageBucket: "parent-parties-development.appspot.com",
    messagingSenderId: "51343418737",
    appId: "1:51343418737:web:04e2941d8bf1536db0c2d0",
    measurementId: "G-7XYS7DP15P",
  },
  production: {
    apiKey: "AIzaSyBE4sYZb8rhUb0GAMGbvKE4C0Yt4wSPmuU",
    authDomain: "parent-parties-production.firebaseapp.com",
    databaseURL:
      "https://parent-parties-production-default-rtdb.firebaseio.com",
    projectId: "parent-parties-production",
    storageBucket: "parent-parties-production.appspot.com",
    messagingSenderId: "486787744530",
    appId: "1:486787744530:web:a86990bbd1710818fee9cb",
    measurementId: "G-4B2FP4348Q",
  },
});
const config = Object.freeze(configs.production);
firebase.initializeApp(config);
firebase.analytics();

export const Database = firebase.database();
export const Storage = firebase.storage();
export const Auth = firebase.auth();
export const Functions = firebase.functions();
export const Analytics = firebase.analytics();
