import { StateType, initialState } from "./State";

const UPDATE_PREMIUM = "premium/update";

type StateSlice = StateType["premium"];

export interface PremiumUpdateActionType {
  type: string;
  premiumObj:
    | {
        productIds: string[];
        status: string;
        current_period_end: number;
        subscriptionId: string;
      }
    | undefined;
}

export const premiumSelector = (state: StateType): StateSlice => state.premium;
export const updatePremiumStateAction = (
  premiumObj:
    | {
        productIds: string[];
        status: string;
        current_period_end: number;
        subscriptionId: string;
      }
    | undefined
): PremiumUpdateActionType => ({
  type: UPDATE_PREMIUM,
  premiumObj,
});

const updateHandler = (
  premiumObj:
    | {
        productIds: string[];
        status: string;
        current_period_end: number;
        subscriptionId: string;
      }
    | undefined
): StateType["premium"] => {
  return premiumObj ? premiumObj : null;
};

export const premiumReducer = (
  premium: StateSlice = initialState.premium,
  action: PremiumUpdateActionType
): StateSlice => {
  switch (action.type) {
    case UPDATE_PREMIUM:
      return updateHandler(action.premiumObj ? action.premiumObj : undefined);
    default:
      return premium;
  }
};
