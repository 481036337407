import React from "react";
import AdminActivities from "../../components/AdminActivities";

function AdminActivitiesPage() {
  return (
    <>
      <AdminActivities />
    </>
  );
}

export default AdminActivitiesPage;
