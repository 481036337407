import React from "react";
import AdminParentEvaluations from "../../components/AdminParentEvaluations";

function AdminParentEvaluationsPage() {
  return (
    <>
      <AdminParentEvaluations />
    </>
  );
}

export default AdminParentEvaluationsPage;
