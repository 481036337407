import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { connect } from "react-redux";
import { appSelector, userSelector, ParentPartiesAPIs } from "../../core";
import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
import CloudUploadTwoToneIcon from "@material-ui/icons/CloudUploadTwoTone";
import CheckIcon from "@material-ui/icons/Check";
import { Grid, Button, ListItem, List } from "@material-ui/core";
import { CircleLoader } from "react-spinners";
import { NavLink } from "react-router-dom";

// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const VideoDropzone = (props) => {
  const { videoUpload, type } = props;
  const [loading, setLoading] = useState(false);

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: "video/mp4,video/x-m4v,video/*",
    multiple: false,
    onDrop: async (acceptedFiles) => {
      const videoFile = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )[0];

      // Set Loading
      try {
        setLoading(true);
        await ParentPartiesAPIs.uploadOnboardingVideo(videoFile, type);
        setLoading(false);
        // Hide loading

        videoUpload(videoFile);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    },
  });

  return (
    <div className="dropzone">
      <div
        {...getRootProps({
          className: "dropzone-upload-wrapper",
        })}
      >
        <input {...getInputProps()} />
        <div className="dropzone-inner-wrapper">
          {isDragAccept && (
            <div>
              <div className="d-100 btn-icon mb-3 hover-scale-lg bg-success shadow-success-sm rounded-circle text-white">
                <CheckIcon className="d-50" />
              </div>
              <div className="font-size-sm text-success">
                Video will be uploaded!
              </div>
            </div>
          )}
          {isDragReject && (
            <div>
              <div className="d-100 btn-icon mb-3 hover-scale-lg bg-danger shadow-danger-sm rounded-circle text-white">
                <CloseTwoToneIcon className="d-50" />
              </div>
              <div className="font-size-sm text-danger">
                File will be rejected!
              </div>
            </div>
          )}
          {!isDragActive &&
            (loading ? (
              <div>
                <CircleLoader
                  className="d-flex align-self-center"
                  color={"#18a9a9"}
                  loading={true}
                  size={50}
                />
              </div>
            ) : (
              <div>
                <div className="d-100 btn-icon mb-3 hover-scale-lg bg-white shadow-light-sm rounded-circle text-primary">
                  <CloudUploadTwoToneIcon className="d-50" />
                </div>
                <div className="font-size-sm">
                  Drag and drop video here{" "}
                  <span className="font-size-xs text-dark">(MP4)</span>
                </div>
              </div>
            ))}
          {loading ? null : (
            <>
              <small className="py-2 text-black-50">or</small>
              <div>
                <Button className="btn-primary hover-scale-sm font-weight-bold btn-pill px-4">
                  <span className="px-2">Browse Files</span>
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

function AdminResourcesOnboardingVideo(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [initialLoad, setInitialLoad] = useState(true);
  const [video, setVideo] = useState(null);

  useEffect(() => {
    async function getAppSettings() {
      const appSettings = await ParentPartiesAPIs.readAppSettings();
      if (appSettings) {
        if (appSettings.onboardingVideoEnglish) {
          setVideo(appSettings.onboardingVideoEnglish);
        }
      }
      setInitialLoad(false);
    }

    getAppSettings();
  }, []);

  return !props.user ? null : initialLoad || props.app.isLoading ? (
    <Grid container className="my-spacing-6">
      <Grid item xs={12}>
        <div className="d-flex flex-row justify-content-center">
          <CircleLoader
            className="d-flex align-self-center"
            color={"#18a9a9"}
            loading={true}
            size={40}
          />
        </div>
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container spacing={0} style={{ marginBottom: "4rem" }}>
        <Grid item md={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              Admin Resources
            </div>
          </div>
        </Grid>
        <Grid item md={12} xl={12}>
          {mobile ? null : (
            <List
              component="div"
              className="nav-line-alt nav-line d-flex align-items-center mt-3"
            >
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-resources-marketing`}
              >
                <span className="font-size-sm">
                  Parent Onboarding Resources
                </span>
                <div className="divider" />
              </ListItem>
              <ListItem button disableRipple className="px-2" selected>
                <span className="font-size-sm">Onboarding Video</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-resources-file-hosting`}
              >
                <span className="font-size-sm">File Hosting</span>
                <div className="divider" />
              </ListItem>
            </List>
          )}
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "6rem" }}>
        <Grid item md={8}>
          <div
            style={{
              color: "#000",
              fontSize: "16px",
              lineHeight: "16px",
              fontWeight: "bold",
            }}
          >
            Onboarding Video
          </div>
          {video ? (
            <div className="py-2 d-flex flex-column align-items-start">
              <video
                key={video.preview ? video.preview : video}
                controls
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  border: "1px solid #d3d3d3",
                }}
                className="rounded"
              >
                <source
                  src={video.preview ? video.preview : video}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <Button
                style={{ width: "200px" }}
                variant="contained"
                className="btn-pill mt-3 btn-danger"
                onClick={() => setVideo(null)}
              >
                Change Video
              </Button>
            </div>
          ) : (
            <VideoDropzone videoUpload={setVideo} type={"English"} />
          )}
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
});

export default connect(mapStateToProps, null)(AdminResourcesOnboardingVideo);
