import React from "react";
import SchoolGuardiansAnalytics from "../../components/SchoolGuardiansAnalytics";

function SchoolGuardiansAnalyticsPage() {
  return (
    <>
      <SchoolGuardiansAnalytics />
    </>
  );
}

export default SchoolGuardiansAnalyticsPage;
