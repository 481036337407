import React from "react";
import Dashboard from "../../components/Dashboard";

function DashboardPage(props) {
  return (
    <>
      <Dashboard />
    </>
  );
}

export default DashboardPage;
