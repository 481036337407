import { ActivityDBService } from "../UseCases/Activity";
import { Activity } from "../Entities/Activity";
import { ActivityServices } from "./Backend";

export class ActivityService implements ActivityDBService {
  async createActivity(
    activity: Activity,
    themeChoice?: any
  ): Promise<Activity> {
    return ActivityServices.createActivity(activity, themeChoice);
  }
  async readActivity(activityId: string): Promise<Activity> {
    return ActivityServices.readActivity(activityId);
  }
  async updateActivity(
    activity: Activity,
    themeChoice?: any
  ): Promise<Activity> {
    return ActivityServices.updateActivity(activity, themeChoice);
  }
  async deleteActivity(activity: Activity): Promise<void> {
    return ActivityServices.deleteActivity(activity);
  }
}
