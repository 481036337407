import { District, SchoolRecord, School } from "../Entities";

export interface SchoolDBService {
  createSchool: (
    school: SchoolRecord
  ) => Promise<{ school: School; district?: District | null }>;
  readSchool: (schoolId: number | string) => Promise<School>;
  updateSchool: (school: SchoolRecord) => Promise<School>;
  fetchSchoolAnalytics: (
    schoolId: string,
    timeFrame: string,
    dealIds: string[]
  ) => Promise<any>;
  removeFromSchool: (
    employeeId: string,
    venueId: string | number
  ) => Promise<void>;
}

class SchoolUseCase {
  schoolService: SchoolDBService;

  constructor(schoolService: SchoolDBService) {
    this.schoolService = schoolService;
  }
}

export class CreateSchoolInteractor extends SchoolUseCase {
  async createSchool(
    school: SchoolRecord
  ): Promise<{ school: School; district?: District | null }> {
    return this.schoolService.createSchool(school);
  }
}

export class ReadSchoolInteractor extends SchoolUseCase {
  readSchool(schoolId: number | string): Promise<School> {
    return this.schoolService.readSchool(schoolId);
  }
}

export class UpdateSchoolInteractor extends SchoolUseCase {
  updateSchool(school: SchoolRecord): Promise<School> {
    return this.schoolService.updateSchool(school);
  }
}

/* Fetch Analytics */
export class FetchSchoolAnalyticsInteractor extends SchoolUseCase {
  fetchVenueAnalytics(
    schoolId: string,
    timeFrame: string,
    dealIds: string[]
  ): Promise<any> {
    return this.schoolService.fetchSchoolAnalytics(
      schoolId,
      timeFrame,
      dealIds
    );
  }
}

export class RemoveFromSchoolInteractor extends SchoolUseCase {
  async removeFromSchool(
    employeeId: string,
    schoolId: string | number
  ): Promise<void> {
    return this.schoolService.removeFromSchool(employeeId, schoolId);
  }
}
