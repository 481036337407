import React from "react";
import SchoolParentEvaluations from "../../components/SchoolParentEvaluations";

function SchoolParentEvaluationsPage() {
  return (
    <>
      <SchoolParentEvaluations />
    </>
  );
}

export default SchoolParentEvaluationsPage;
