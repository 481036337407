import { Coordinates, CoordinatesRecord } from "./Coordinates";

export interface LocationRecord {
  address: string;
  city: string;
  state: string;
  zipcode: string;
  secondary?: string;
  coordinates?: CoordinatesRecord;
  neighborhood?: number;
  metroArea?: string;
  timezone?: string;
  country?: string;
}

export class Location {
  private address: string;
  private city: string;
  private state: string;
  private zipcode: string;
  readonly secondary?: string;
  readonly metroArea?: string;
  readonly coordinates?: Coordinates;
  readonly neighborhood?: number;
  readonly timezone?: string;
  readonly country?: string;

  constructor(location: LocationRecord) {
    const {
      address,
      city,
      state,
      zipcode,
      coordinates,
      neighborhood,
      metroArea,
      timezone,
      country,
      secondary,
    } = location;

    if (
      this.isEmptyOrNull(address) ||
      this.isEmptyOrNull(city) ||
      this.isEmptyOrNull(state) ||
      this.isEmptyOrNull(zipcode)
    ) {
      throw new Error("Address cannot be empty");
    }
    // else if (this.isInvalidState(state)) {
    //   throw new Error("State must be abbreviated (eg. AL)");
    // }
    // else if (this.isInvalidZipcode(zipcode)) {
    //   throw new Error("Zipcode must be five-digit format (eg. 54321)");
    // }

    this.address = address;
    this.city = city;
    this.state = state;
    this.zipcode = zipcode;
    this.metroArea = metroArea || "";
    this.coordinates = coordinates ? new Coordinates(coordinates) : undefined;
    this.neighborhood = neighborhood;
    this.timezone = timezone || "";
    this.country = country || "";
    this.secondary = secondary || "";
  }

  get formattedAddress(): string {
    return `${this.address} ${this.city}, ${this.state} ${this.zipcode}`;
  }

  // private isInvalidState(state: string): boolean {
  //   const stateRegex = /^[A-Z]{2}$/g;
  //   return !stateRegex.test(state);
  // }

  // private isInvalidZipcode(zipcode: string): boolean {
  //   const zipcodeRegex = /^[0-9]{5}$/g;
  //   return !zipcodeRegex.test(zipcode);
  // }

  private isEmptyOrNull(str: string): boolean {
    return !str || str.trim().length === 0;
  }
}
