import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { appSelector, userSelector, ParentPartiesAPIs } from "../../core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  List,
  ListItem,
  Card,
  Button,
  TextField,
  IconButton,
  Divider,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import moment from "moment";
import update from "immutability-helper";
import { CircleLoader } from "react-spinners";

// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function AdminParentEvaluations(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [initialLoad, setInitialLoad] = useState(true);
  const [englishQuestions, setEnglishQuestions] = useState([]);
  const [editingQuestions, setEditingQuestions] = useState(null);

  useEffect(() => {
    async function getQuestions() {
      const appSettings = await ParentPartiesAPIs.readAppSettings();
      if (appSettings.parentEvaluationQuestionsEnglish) {
        setEnglishQuestions(
          appSettings.parentEvaluationQuestionsEnglish
            ? appSettings.parentEvaluationQuestionsEnglish
            : []
        );
      }
      setInitialLoad(false);
    }

    getQuestions();
  }, []);

  const saveCurrentQuestions = async () => {
    const updatedQuestions = await ParentPartiesAPIs.updateCurrentEvaluationQuestions(
      editingQuestions,
      "English"
    );
    await ParentPartiesAPIs.updateCurrentEvaluationTranslationTargets();

    setEnglishQuestions(updatedQuestions);

    setEditingQuestions(null);
  };

  return !props.user ? null : initialLoad || props.app.isLoading ? (
    <Grid container className="my-spacing-6">
      <Grid item xs={12}>
        <div className="d-flex flex-row justify-content-center">
          <CircleLoader
            className="d-flex align-self-center"
            color={"#18a9a9"}
            loading={true}
            size={40}
          />
        </div>
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
        <Grid item md={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: 13,
              paddingBottom: 13,
            }}
          >
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              Admin Activities
            </div>
          </div>
        </Grid>
        <Grid item md={12} xl={12}>
          {mobile ? null : (
            <List
              component="div"
              className="nav-line-alt nav-line d-flex align-items-center mt-3"
            >
              <ListItem
                button
                className="pr-2"
                component={NavLink}
                to={`/admin-activities`}
              >
                <span className="font-size-sm">{"Activities"}</span>
                <div className="divider" />
              </ListItem>
              <ListItem button disableRipple className="px-2" selected>
                <span className="font-size-sm">Parent Evaluations</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-weekly-scorecard`}
              >
                <span className="font-size-sm">Weekly Scorecard</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-resources-uploads`}
              >
                <span className="font-size-sm">Guardian Uploads</span>
                <div className="divider" />
              </ListItem>
              {/* <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-archived-activities`}
              >
                <span className="font-size-sm">Archive</span>
                <div className="divider" />
              </ListItem> */}
            </List>
          )}
        </Grid>
        <Grid item md={12}>
          <div
            style={{
              marginTop: 40,
            }}
            className="mb-4"
          >
            <div
              className="heading-4 text-left mb-0"
              style={{
                color: "#000",
                textTransform: "none",
              }}
            >
              Parent Evaluation Questions
            </div>
            <div>
              <small className="text-black-50">
                Guardians answer questions on scale of 1 to 5. Strongly Disagree
                to Strongly Agree.
              </small>
            </div>
          </div>
          <Card
            className="p-3 mt-4"
            style={{
              borderRadius: "0.25rem",
              border: "1px solid rgba(0,0,0,.1)",
            }}
          >
            {editingQuestions ? (
              <>
                <List component="div" className="list-group-flush">
                  {editingQuestions.map((question, index) => (
                    <ListItem className="py-4 d-block" key={index}>
                      <div className="d-flex flex-row align-items-center">
                        <TextField
                          size="small"
                          variant={"outlined"}
                          fullWidth
                          hiddenLabel={true}
                          value={question.question}
                          placeholder="Question"
                          type="text"
                          onChange={(e) => {
                            setEditingQuestions(
                              update(editingQuestions, {
                                [index]: {
                                  $set: {
                                    ...editingQuestions[index],
                                    question: e.target.value,
                                  },
                                },
                              })
                            );
                          }}
                        />
                        <div className="ml-4">
                          <IconButton
                            size="medium"
                            aria-label="close"
                            onClick={() => {
                              setEditingQuestions(
                                update(editingQuestions, {
                                  $splice: [[index, 1]],
                                })
                              );
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["fas", "trash"]}
                              className="font-size-sm"
                            />
                          </IconButton>
                        </div>
                      </div>
                    </ListItem>
                  ))}
                </List>
                <div className="px-4 py-3 d-flex flex-row align-items-center justify-content-end">
                  <Button
                    variant="contained"
                    size="small"
                    className={"btn-success shadow-none mt-2 py-2"}
                    onClick={() => {
                      setEditingQuestions(
                        update(editingQuestions, {
                          $push: [
                            {
                              id: moment().valueOf(),
                              question: `Question ${
                                editingQuestions.length + 1
                              }?`,
                            },
                          ],
                        })
                      );
                    }}
                  >
                    <span className="btn-wrapper--icon mr-1">
                      <FontAwesomeIcon
                        style={{ fontSize: "12px" }}
                        icon={["fas", "plus"]}
                      />
                    </span>
                    <span
                      className="btn-wrapper--label"
                      style={{ fontSize: "12px" }}
                    >
                      {`Add Question`}
                    </span>
                  </Button>
                </div>
                <Divider className="my-4" />
                <div className="px-4 py-3 d-flex flex-row align-items-center justify-content-between">
                  <div className="d-flex flex-row align-items-center">
                    <Button
                      variant="contained"
                      size="small"
                      className={"btn-dark shadow-none mt-2 py-2 mr-2"}
                      onClick={() => setEditingQuestions(null)}
                    >
                      <span
                        className="btn-wrapper--label"
                        style={{ fontSize: "12px" }}
                      >
                        {`Cancel`}
                      </span>
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      className={"btn-primary shadow-none mt-2 py-2"}
                      onClick={saveCurrentQuestions}
                      disabled={!editingQuestions.length}
                    >
                      <span
                        className="btn-wrapper--label"
                        style={{ fontSize: "12px" }}
                      >
                        {`Save Questions`}
                      </span>
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <List component="div" className="list-group-flush">
                  {englishQuestions.map((question, index) => (
                    <ListItem className="py-4 d-block" key={index}>
                      <div className="text-black">{question.question}</div>
                    </ListItem>
                  ))}
                </List>
                <div className="py-3 px-4 d-flex flex-row align-items-center">
                  <Button
                    variant="contained"
                    size="small"
                    className={"btn-primary shadow-none mt-2 py-2"}
                    onClick={() => setEditingQuestions(englishQuestions)}
                  >
                    <span className="btn-wrapper--icon mr-1">
                      <FontAwesomeIcon
                        style={{ fontSize: "12px" }}
                        icon={["fas", "edit"]}
                      />
                    </span>
                    <span
                      className="btn-wrapper--label"
                      style={{ fontSize: "12px" }}
                    >
                      {`Edit Questions`}
                    </span>
                  </Button>
                </div>
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
});

export default connect(mapStateToProps, null)(AdminParentEvaluations);
