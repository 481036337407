import React from "react";
import { CircleLoader } from "react-spinners";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  makeStyles,
  Slide,
} from "@material-ui/core";
import red from "@material-ui/core/colors/red";

const useStyles = makeStyles(() => ({
  dialog: {
    borderRadius: 0,
  },
  button: {
    borderRadius: 0,
    textTransform: "none",
    padding: 5,
  },
  logout: {
    color: "#fff",
    backgroundColor: "#e65454",
    "&:hover": {
      backgroundColor: "#e65454",
    },
  },
  countdown: {
    color: red[700],
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RefreshDialog = ({ open, countdown, onLogout, onContinue }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.dialog }}
      TransitionComponent={Transition}
    >
      <DialogTitle
        disableTypography
        className="d-flex flex-row align-items-center"
      >
        <Typography variant="h6">Refreshing Session</Typography>
        <div className="ml-2">
          <CircleLoader size={20} color="#18a9a9" />
        </div>
      </DialogTitle>
      <DialogContent className="py-4">
        <Typography variant="body2">
          Your current session has expired! We're reloading your session now.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default RefreshDialog;
