import { useState, useEffect } from "react";
import Bugsnag from "@bugsnag/js";
import { connect } from "react-redux";
import { CircleLoader } from "react-spinners";
import { appSelector, userSelector, ParentPartiesAPIs } from "../../core";
import {
  Grid,
  Button,
  List,
  ListItem,
  Dialog,
  DialogActions,
  Typography,
  IconButton,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import AGGrid from "../Grid";
import moment from "moment";
import { NavLink } from "react-router-dom";

// Responsive //
import { useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const styles = (theme) => ({
  rootNormal: {
    margin: 0,
    padding: theme.spacing(2),
  },
  root: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButtonNormal: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButton: {
    position: "static",
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, row, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={row ? classes.root : classes.rootNormal}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={row ? classes.closeButton : classes.closeButtonNormal}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function AdminAnalytics(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [initialLoad, setInitialLoad] = useState(true);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    async function getReports() {
      const reportData = await ParentPartiesAPIs.getAllGeneratedReports();
      setReports(reportData);
      setInitialLoad(false);
    }
    getReports();
  }, []);

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteDialogInfo, setDeleteDialogInfo] = useState(null);
  const handleDeleteClose = () => {
    setDeleteDialog(false);
  };
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleDeleteReport = async () => {
    try {
      if (deleteDialogInfo) {
        if (deleteDialogInfo.id) {
          setDeleteLoading(true);
          await ParentPartiesAPIs.deleteGeneratedReport(
            deleteDialogInfo.schoolId,
            deleteDialogInfo.id
          );
          setReports(
            reports.filter((report) => report.id !== deleteDialogInfo.id)
          );
          setDeleteLoading(false);
          setDeleteDialog(false);
        }
      }
    } catch (err) {
      setDeleteLoading(false);
      console.log(err);
      Bugsnag.notify(err);
    }
  };

  return !props.user ? null : initialLoad || props.app.isLoading ? (
    <Grid container className="my-spacing-6">
      <Grid item xs={12}>
        <div className="d-flex flex-row justify-content-center">
          <CircleLoader
            className="d-flex align-self-center"
            color={"#18a9a9"}
            loading={true}
            size={40}
          />
        </div>
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
        <Grid item md={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              Admin Reporting
            </div>
          </div>
        </Grid>
        <Grid item md={12} xl={12}>
          {mobile ? null : (
            <List
              component="div"
              className="nav-line-alt nav-line d-flex align-items-center mt-3"
            >
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-guardians-analytics`}
              >
                <span className="font-size-sm">Guardians</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-activity-analytics`}
              >
                <span className="font-size-sm">Activities</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-evaluation-analytics`}
              >
                <span className="font-size-sm">Parent Evaluations</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-weekly-scorecard-analytics`}
              >
                <span className="font-size-sm">Weekly Scorecards</span>
                <div className="divider" />
              </ListItem>
              <ListItem button selected className="pr-2">
                <span className="font-size-sm">{"Reports"}</span>
                <div className="divider" />
              </ListItem>
            </List>
          )}
        </Grid>
        <div
          className="w-100 d-flex flex-row align-items-center justify-content-between"
          style={{
            marginTop: "3.5rem",
            marginBottom: "2rem",
          }}
        >
          <div
            style={{
              color: "#000",
              fontSize: "16px",
              lineHeight: "16px",
              fontWeight: "bold",
            }}
          >
            Reports
          </div>
        </div>
        <Grid item md={12}>
          <AGGrid
            rowData={reports}
            search={false}
            autoHeight={false}
            suppressContextMenu={true}
            columnDefs={[
              {
                headerName: "School Name",
                field: "schoolName",
                sortable: true,
                filter: true,
                cellClass: "agGridCellCenter",
              },
              {
                headerName: "School Year",
                field: "schoolYear",
                sortable: true,
                filter: true,
                cellClass: "agGridCellCenter",
              },
              {
                headerName: "Generated On",
                field: "date",
                sortable: true,
                filter: true,
                valueGetter: function (params) {
                  if (params.data.date !== undefined) {
                    return `${moment(params.data.date).format("MM/DD/YYYY")}`;
                  } else {
                    return null;
                  }
                },
                cellClass: "agGridCellCenter",
              },
              {
                width: 280,
                suppressSizeToFit: true,
                field: "actions",
                headerName: "Actions",
                cellRendererFramework: (params) => (
                  <div className="d-flex flex-row align-items-center">
                    <a
                      className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained bg-dark shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                      href={params.data.url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <span className="MuiButton-label">
                        <span
                          className="btn-wrapper--label text-white"
                          style={{ fontSize: "12px" }}
                        >
                          View
                        </span>
                      </span>
                      <span className="MuiTouchRipple-root"></span>
                    </a>
                    <button
                      className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained btn-danger shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                      tabIndex="0"
                      type="button"
                      onClick={() => {
                        setDeleteDialogInfo(params.data);
                        setDeleteDialog(true);
                      }}
                    >
                      <span className="MuiButton-label">
                        <span
                          className="btn-wrapper--label text-white"
                          style={{ fontSize: "12px" }}
                        >
                          Delete
                        </span>
                      </span>
                      <span className="MuiTouchRipple-root"></span>
                    </button>
                  </div>
                ),
                cellClass: "agGridCellCenter",
              },
            ]}
          />
        </Grid>
      </Grid>
      <Dialog
        open={deleteDialog}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
        PaperProps={{
          className: "p-3",
        }}
      >
        <DialogTitle
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            color: "rgb(42, 42, 47)",
            borderBottom: "none",
          }}
        >
          {`Are you sure you want to this report?`}
        </DialogTitle>
        <DialogActions style={{ borderTop: "none" }} className="mt-4">
          <Button onClick={handleDeleteClose}>Cancel</Button>
          <Button
            className="btn-danger"
            onClick={() => handleDeleteReport()}
            autoFocus
            disabled={deleteLoading}
          >
            {deleteLoading && (
              <div className="mr-3">
                <CircleLoader color="#fff" size={15} />
              </div>
            )}
            <span>Delete Report</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
});

export default connect(mapStateToProps, null)(AdminAnalytics);
