import React from "react";
import { connect } from "react-redux";
import { Container, Button } from "@material-ui/core";
import { sceneSelector } from "../../core";

// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const HeaderSelection = (props) => {
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down("md"));

  const handleStyles = (plan) => {
    if (plan === "Basic") {
      return {
        buttonBG: "bg-skim-blue",
        buttonStyle: {
          textShadow: "#5990bf 1px 1px 5px",
          cursor: "default",
        },
      };
    }
    if (plan === "In-school Curriculum") {
      return {
        buttonBG: "bg-skim-blue",
        buttonStyle: {
          textShadow: "#5990bf 1px 1px 5px",
          cursor: "default",
        },
      };
    }
    if (plan === "Demo - Premium") {
      return {
        buttonBG: "bg-plum-plate",
        buttonStyle: {
          cursor: "default",
        },
      };
    }
    if (plan === "Premium") {
      return {
        buttonBG: "bg-sunny-morning",
        buttonStyle: {
          textShadow: "0px 1px 5px rgb(206 134 45)",
          cursor: "default",
        },
      };
    }
    return {
      buttonBG: "bg-grow-early",
      buttonStyle: {
        cursor: "default",
      },
    };
  };

  const businessPlanBanner = () => {
    if (tablet) {
      return null;
    }
    if (!props.scene) {
      return null;
    }
    if (props.scene) {
      if (props.scene.type === "district" || props.scene.type === "none") {
        return null;
      }
      if (!props.scene.data) {
        return null;
      }
    }
    const schoolPlan = props.scene.data.plan;
    const stylesObj = handleStyles(schoolPlan);

    return (
      <Button
        size="small"
        variant="contained"
        disableRipple
        className={`mx-2 shadow-none btn-transition-none ${stylesObj.buttonBG}`}
        style={stylesObj.buttonStyle}
      >
        <span
          className="btn-wrapper--label text-white font-weight-bold text-uppercase"
          style={{ fontSize: "11px" }}
        >
          {schoolPlan ? schoolPlan : "None"}
        </span>
      </Button>
    );
  };

  return null;
};

const mapStateToProps = (state) => ({
  scene: sceneSelector(state),
});

export default connect(mapStateToProps, null)(HeaderSelection);
