import React from "react";
import AdminSchools from "../../components/AdminSchools";

function AdminSchoolsPage() {
  return (
    <>
      <AdminSchools />
    </>
  );
}

export default AdminSchoolsPage;
