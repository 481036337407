import { Database, Functions } from "./Firebase";
import {
  uploadSchoolActivityFile,
  uploadEvidenceFile,
  uploadActivityThumbnail,
  uploadActivityVideo,
} from "./ParentPartiesAPIs";
import { Activity, ActivityRecord } from "../../Entities";
import * as _ from "lodash";
import { readCurriculum } from "./School";
const moment = require("moment");
const { v4: uuidv4 } = require("uuid");

const activitiesRef = Database.ref(`activities`);
const schoolsRef = Database.ref(`schools`);

export const createActivity = async (
  activity: Activity,
  themeChoice?: any
): Promise<Activity> => {
  /* Remove tempId */
  delete (activity as any).tempId;

  /* Removed undefined */
  const removeEmpty = (obj: ActivityRecord) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === "object") removeEmpty(obj[key]);
      // recurse
      else if (obj[key] == null) delete obj[key]; // delete
    });
  };
  removeEmpty(activity);

  const activityRef = await activitiesRef.push();
  (activity as any).id = activityRef.key;

  activity.lastUpdated = moment().valueOf();

  await activityRef.set(activity);

  // Add Activity to Specific School
  if (!activity.allSchools) {
    await schoolsRef
      .child(`${activity.schoolId}/activities/${activity.id}`)
      .set(true);
  }

  // Thumbnail
  if (activity.thumbnail) {
    if (
      activity.thumbnail instanceof File ||
      activity.thumbnail instanceof Blob ||
      activity.thumbnail._data
    ) {
      let activityThumbnail = activity.thumbnail;
      const activityThumbnailUrl = await uploadActivityThumbnail(
        activity.id as string,
        activityThumbnail
      );
      activity.thumbnail = activityThumbnailUrl;
    }
  }

  // Video
  if (activity.video) {
    if (
      activity.video instanceof File ||
      activity.video instanceof Blob ||
      activity.video._data
    ) {
      let activityVideo = activity.video;
      const activityVideoUrl = await uploadActivityVideo(
        activity.id as string,
        activityVideo
      );
      activity.video = activityVideoUrl;
    }
  }

  // School Activity
  if (activity.schoolFiles) {
    if (activity.schoolFiles.length) {
      activity.schoolFiles = await Promise.all(
        activity.schoolFiles.map(async (schoolFile: any) => {
          if (schoolFile.file) {
            if (
              schoolFile.file instanceof File ||
              schoolFile.file instanceof Blob ||
              schoolFile.file._data
            ) {
              const fileUrl = await uploadSchoolActivityFile(
                activity.id as string,
                schoolFile.file
              );
              return {
                id: uuidv4(),
                name: schoolFile.name,
                fileType: schoolFile.fileType,
                preview: fileUrl,
              };
            }
            return schoolFile;
          }
          return schoolFile;
        })
      );
    }
  }

  // School Activity

  await activityRef.update(activity);

  if (themeChoice) {
    const schoolThemes = await Database.ref(
      `schools/${activity.schoolId}/schoolThemes`
    )
      .once("value")
      .then((snapshot) => snapshot.val());

    if (schoolThemes) {
      const newSchoolThemes = schoolThemes;
      newSchoolThemes[themeChoice.idIndex].activityIds = [
        ...(newSchoolThemes[themeChoice.idIndex].activityIds || []),
        activity.id,
      ];

      await Database.ref(`schools/${activity.schoolId}/schoolThemes`).set(
        newSchoolThemes
      );
    }
    // Create school themes
    else {
      const schoolCurriculum = await Database.ref("schools")
        .child(activity.schoolId as string)
        .child("curriculum")
        .once("value")
        .then((snapshot) => snapshot.val());
      const curriculumData = await readCurriculum(schoolCurriculum);
      const createSchoolThemes = curriculumData.themes.map(
        (theme: any, currThemeIndex: any) => ({
          id: theme.id,
          name: theme.name,
          subtext: theme.subtext,
          activityIds:
            currThemeIndex === themeChoice.idIndex ? [activity.id] : [],
        })
      );

      await Database.ref(`schools/${activity.schoolId}/schoolThemes`).set(
        createSchoolThemes
      );
    }
  }

  const activitySnapshot = await new Promise<any>((resolve) =>
    activityRef.once("value", resolve)
  );
  const data = activitySnapshot.val() as any;

  const activityTranslateCall = Functions.httpsCallable("activityTranslate");
  activityTranslateCall({ activityId: activity.id });

  return new Activity(data);
};

export const readActivity = async (activityId: string): Promise<Activity> => {
  const activityRef = activitiesRef.child(activityId);
  const activitySnapshot = await new Promise<any>((resolve) =>
    activityRef.once("value", resolve)
  );
  const data = activitySnapshot.val() as any;

  return new Activity(data);
};

export const updateActivity = async (
  activity: Activity,
  themeChoice?: any
): Promise<Activity> => {
  if (!activity.id) {
    throw new Error("Invalid activity ID");
  }

  /* Removed undefined */
  const removeEmpty = (obj: ActivityRecord) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === "object") removeEmpty(obj[key]);
      // recurse
      else if (obj[key] == null) delete obj[key]; // delete
    });
  };
  removeEmpty(activity);

  // Thumbnail
  if (activity.thumbnail) {
    if (
      activity.thumbnail instanceof File ||
      activity.thumbnail instanceof Blob ||
      activity.thumbnail._data
    ) {
      let activityThumbnail = activity.thumbnail;
      const activityThumbnailUrl = await uploadActivityThumbnail(
        activity.id as string,
        activityThumbnail
      );
      activity.thumbnail = activityThumbnailUrl;
    }
  }

  // Video
  if (activity.video) {
    if (
      activity.video instanceof File ||
      activity.video instanceof Blob ||
      activity.video._data
    ) {
      let activityVideo = activity.video;
      const activityVideoUrl = await uploadActivityVideo(
        activity.id as string,
        activityVideo
      );
      activity.video = activityVideoUrl;
    }
  }

  // School Activity
  if (activity.schoolFiles) {
    if (activity.schoolFiles.length) {
      activity.schoolFiles = await Promise.all(
        activity.schoolFiles.map(async (schoolFile: any) => {
          if (schoolFile.file) {
            if (
              schoolFile.file instanceof File ||
              schoolFile.file instanceof Blob ||
              schoolFile.file._data
            ) {
              const fileUrl = await uploadSchoolActivityFile(
                activity.id as string,
                schoolFile.file
              );
              return {
                id: uuidv4(),
                name: schoolFile.name,
                fileType: schoolFile.fileType,
                preview: fileUrl,
              };
            }
            return schoolFile;
          }
          return schoolFile;
        })
      );
    }
  }

  activity.lastUpdated = moment().valueOf();

  const activityRef = activitiesRef.child(activity.id);
  await activityRef.update(activity);

  if (themeChoice) {
    const schoolThemes = await Database.ref(
      `schools/${activity.schoolId}/schoolThemes`
    )
      .once("value")
      .then((snapshot) => snapshot.val());

    const newSchoolThemes = schoolThemes;
    newSchoolThemes[themeChoice.idIndex].activityIds = [
      ...(newSchoolThemes[themeChoice.idIndex].activityIds || []),
      activity.id,
    ];

    await Database.ref(`schools/${activity.schoolId}/schoolThemes`).set(
      newSchoolThemes
    );
  }

  const activitySnapshot = await new Promise<any>((resolve) =>
    activityRef.once("value", resolve)
  );

  const data = activitySnapshot.val() as any;

  const activityTranslateCall = Functions.httpsCallable("activityTranslate");
  activityTranslateCall({ activityId: activity.id });

  return data;
};

export const deleteActivity = async (activity: Activity): Promise<void> => {
  const activityRef = activitiesRef.child(activity.id as string);
  activityRef.remove();
  if (activity.schoolId) {
    // Remove from school
    await Database.ref(
      `schools/${activity.schoolId}/activities/${activity.id}`
    ).remove();

    // Remove from school themes
    const schoolThemes = await Database.ref(
      `schools/${activity.schoolId}/schoolThemes`
    )
      .once("value")
      .then((snapshot) => snapshot.val());
    schoolThemes.map(async (theme: any) => {
      if (theme.activityIds) {
        const newActivityIds = theme.activityIds.filter(
          (activityId: any) => activityId !== activity.id
        );
        await Database.ref(
          `schools/${activity.schoolId}/schoolThemes/${theme.id}/activityIds`
        ).set(newActivityIds);
      }
    });
  }
};

export const readAllAdminActivities = async (): Promise<any> => {
  try {
    let activities = await Database.ref(`activities`)
      .orderByChild("allSchools")
      .equalTo(true)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val();
        return snapshotVal ? Object.values(snapshotVal) : [];
      });
    return activities;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const readAllCurriculumActivities = async (
  activityIds: any
): Promise<any> => {
  try {
    let activities = await Promise.all(
      activityIds.map(async (activityId: any) => {
        const activity = await Database.ref(`activities/${activityId}`)
          .once("value")
          .then(function (snapshot) {
            const snapshotVal = snapshot.val();
            return snapshotVal ? snapshotVal : null;
          });
        return activity;
      })
    );

    return activities.filter((z: any) => z !== null);
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const readSchoolActivities = async (
  schoolId: string,
  activityIds?: any
): Promise<any> => {
  try {
    const schoolGradeLevels = await Database.ref(
      `schools/${schoolId}/gradeLevels`
    )
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val();
        return snapshotVal ? Object.values(snapshotVal) : [];
      });

    let adminActivites = [];
    if (activityIds) {
      adminActivites = await readAllCurriculumActivities(activityIds);
    } else {
      adminActivites = await readAllAdminActivities();
    }
    const activities = await Database.ref(`activities`)
      .orderByChild("schoolId")
      .equalTo(schoolId)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val();
        return snapshotVal
          ? Object.values(snapshotVal).filter(
              (schoolAct: any) => !schoolAct.archived
            )
          : [];
      });

    let combinedActivities = [...activities, ...adminActivites];

    combinedActivities = combinedActivities.filter((activity: any) =>
      activity.gradeLevels
        ? activity.gradeLevels.some(
            (r: any) => schoolGradeLevels.indexOf(r) >= 0
          )
        : true
    );

    return combinedActivities;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const readArchivedSchoolActivities = async (
  schoolId: string
): Promise<any> => {
  try {
    const archivedActivities = await Database.ref(`activities`)
      .orderByChild("schoolId")
      .equalTo(schoolId)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val();
        return snapshotVal
          ? Object.values(snapshotVal).filter(
              (schoolAct: any) => schoolAct.archived
            )
          : [];
      });

    const groupedBySchoolYear = _.groupBy(archivedActivities, "schoolYear");
    const fixedItems = Object.keys(groupedBySchoolYear).map(
      (schoolYear: any) => ({
        schoolYear,
        activities: groupedBySchoolYear[schoolYear],
      })
    );
    return fixedItems.sort((a: any, b: any) => a.theme - b.theme);
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const readArchivedAdminActivities = async (): Promise<any> => {
  try {
    let archivedActivities = await Database.ref(`activities`)
      .orderByChild("allSchools")
      .equalTo(true)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val();
        return snapshotVal
          ? Object.values(snapshotVal).filter(
              (adminAct: any) => adminAct.archived
            )
          : [];
      });
    const groupedBySchoolYear = _.groupBy(archivedActivities, "schoolYear");
    const fixedItems = Object.keys(groupedBySchoolYear).map(
      (schoolYear: any) => ({
        schoolYear,
        activities: groupedBySchoolYear[schoolYear],
      })
    );
    return fixedItems.sort((a: any, b: any) => a.theme - b.theme);
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const addActivityToCurrent = async (activity: any) => {
  /* Duplicates activity to current school year */
  const activityRef = await activitiesRef.push();
  (activity as any).id = activityRef.key;

  activity.lastUpdated = moment().valueOf();

  await activityRef.set({ ...activity, schoolYear: null, archived: null });

  // Add Activity to Specific School
  if (!activity.allSchools) {
    await schoolsRef
      .child(`${activity.schoolId}/activities/${activity.id}`)
      .set(true);
  }
};

export const addInSchoolActivity = async (
  inSchoolActivity: any,
  schoolId: any
) => {
  const inSchoolActivityRef = await Database.ref(
    `inSchoolActivities/${schoolId}`
  ).push();
  (inSchoolActivity as any).id = inSchoolActivityRef.key;

  inSchoolActivity.date = inSchoolActivity.date
    ? moment(inSchoolActivity.date).format("MM-DD-YYYY")
    : null;

  if (inSchoolActivity.evidenceFiles) {
    if (inSchoolActivity.evidenceFiles.length) {
      inSchoolActivity.evidenceFiles = await Promise.all(
        inSchoolActivity.evidenceFiles.map(async (evidenceFile: any) => {
          if (evidenceFile.file) {
            if (
              evidenceFile.file instanceof File ||
              evidenceFile.file instanceof Blob ||
              evidenceFile.file._data
            ) {
              const fileUrl = await uploadEvidenceFile(
                inSchoolActivity.id as string,
                evidenceFile.file
              );
              return {
                id: uuidv4(),
                name: evidenceFile.name,
                fileType: evidenceFile.fileType,
                preview: fileUrl,
              };
            }
            return evidenceFile;
          }
          return evidenceFile;
        })
      );
    }
  }

  await inSchoolActivityRef.set({ ...inSchoolActivity, schoolId });
  const result = await inSchoolActivityRef
    .once("value")
    .then((snapshot) => snapshot.val());
  return result;
};

export const editInSchoolActivity = async (
  inSchoolActivity: any,
  schoolId: any
) => {
  const inSchoolActivityRef = Database.ref(
    `inSchoolActivities/${schoolId}/${inSchoolActivity.id}`
  );

  inSchoolActivity.date = inSchoolActivity.date
    ? moment(inSchoolActivity.date).format("MM-DD-YYYY")
    : null;

  if (inSchoolActivity.evidenceFiles) {
    if (inSchoolActivity.evidenceFiles.length) {
      inSchoolActivity.evidenceFiles = await Promise.all(
        inSchoolActivity.evidenceFiles.map(async (evidenceFile: any) => {
          if (evidenceFile.file) {
            if (
              evidenceFile.file instanceof File ||
              evidenceFile.file instanceof Blob ||
              evidenceFile.file._data
            ) {
              const fileUrl = await uploadEvidenceFile(
                inSchoolActivity.id as string,
                evidenceFile.file
              );
              return {
                id: uuidv4(),
                name: evidenceFile.name,
                fileType: evidenceFile.fileType,
                preview: fileUrl,
              };
            }
            return evidenceFile;
          }
          return evidenceFile;
        })
      );
    }
  }

  await inSchoolActivityRef.update(inSchoolActivity);
  const result = await inSchoolActivityRef
    .once("value")
    .then((snapshot) => snapshot.val());
  return result;
};

export const readInSchoolActivities = async (schoolId: any) => {
  const inSchoolActivitiesRef = Database.ref(`inSchoolActivities/${schoolId}`);
  const result = await inSchoolActivitiesRef
    .once("value")
    .then((snapshot) =>
      snapshot.exists() ? Object.values(snapshot.val()) : []
    );
  return result;
};

export const deleteInSchoolActivity = async (
  schoolId: any,
  activityId: any
) => {
  const inSchoolActivityRef = Database.ref(
    `inSchoolActivities/${schoolId}/${activityId}`
  );

  await inSchoolActivityRef.remove();
};

export const updateActivityTranslations = async (
  activityId,
  translationData
): Promise<any> => {
  if (!activityId) {
    throw new Error("Invalid activity ID");
  }

  const activityTranslationsRef = activitiesRef.child(
    `${activityId}/translationData`
  );
  await activityTranslationsRef.update(translationData);
};

export const createCurriculum = async (curriculum: any): Promise<any> => {
  const curriculumRef = await Database.ref(`curriculums`).push();
  (curriculum as any).id = curriculumRef.key;

  await curriculumRef.set({
    ...curriculum,
    activities: curriculum.themes.map((theme: any) => theme.activityIds).flat(),
  });
};

export const updateCurriculum = async (
  curriculumId: any,
  updates: any
): Promise<any> => {
  const curriculumRef = Database.ref(`curriculums/${curriculumId}`);

  await curriculumRef.update(updates);
};

export const deleteCurriculum = async (curriculum: any): Promise<any> => {
  const curriculumRef = Database.ref(`curriculums/${curriculum.id}`);
  await curriculumRef.remove();
};

export const handleCurrs = async () => {
  const schoolsRef = Database.ref(`schools`);
  const schools = await schoolsRef.once("value").then((snapshot) => {
    return snapshot.val();
  });
  const schoolsIds = Object.keys(schools);

  await Promise.all(
    schoolsIds.map(async (schoolId) => {
      const schoolRef = Database.ref(`schools/${schoolId}`);
      await schoolRef.update({ curriculum: "-Ncb3u98dJQ_SLS8hz7k" });
    })
  );
};
