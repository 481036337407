import { Credential, Email, SchoolUser, SchoolUserRecord } from "../Entities";

export interface AuthService {
  signUp: (
    user: SchoolUserRecord,
    credential: Credential,
    emailConfirmed: Boolean,
    restaurant: string
  ) => Promise<SchoolUser>;
  signIn: (credential: Credential) => Promise<SchoolUser | null>;
  sendVerificationEmail: () => Promise<void>;
  resetPassword: (email: Email) => Promise<void>;
  signOut: () => Promise<void>;
  onAuthStateChange: (handler: (user: SchoolUser | null) => void) => void;
  updateUser: (user: SchoolUser) => Promise<SchoolUser>;
  verifyEmail: (user: SchoolUser) => Promise<SchoolUser>;
  readUser: (userId: string) => Promise<SchoolUser | null>;
}

class UserUsecase {
  authService: AuthService;

  constructor(authService: AuthService) {
    this.authService = authService;
  }
}

export class SignUpInteractor extends UserUsecase {
  async signUp(
    firstName: string,
    lastName: string,
    credential: Credential,
    emailVerified: boolean,
    emailConfirmed: boolean,
    restaurant: string
  ): Promise<SchoolUser> {
    const userRecord: SchoolUserRecord = {
      firstName,
      lastName,
      email: credential.email,
      emailVerified,
    };
    return this.authService.signUp(
      userRecord,
      credential,
      emailConfirmed,
      restaurant
    );
  }
}

export class SignInInteractor extends UserUsecase {
  async signIn(credential: Credential): Promise<SchoolUser | null> {
    return this.authService.signIn(credential);
  }
}

export class ResetPasswordInteractor extends UserUsecase {
  async resetPassword(email: Email): Promise<void> {
    return this.authService.resetPassword(email);
  }
}

export class SendVerificationEmailInteractor extends UserUsecase {
  async sendVerificationEmail(): Promise<void> {
    return this.authService.sendVerificationEmail();
  }
}

export class VerifyEmailInteractor extends UserUsecase {
  async verifyEmail(user: SchoolUser): Promise<SchoolUser> {
    return this.authService.verifyEmail(user);
  }
}

export class SignOutInteractor extends UserUsecase {
  async signOut(): Promise<void> {
    return this.authService.signOut();
  }
}

export class AuthStateListener extends UserUsecase {
  onAuthStateChange(handler: (user: SchoolUser | null) => void): void {
    this.authService.onAuthStateChange((user) => handler(user));
  }
}

export class UpdateUserInteractor extends UserUsecase {
  async updateUser(user: SchoolUser): Promise<SchoolUser> {
    return this.authService.updateUser(user);
  }
}

export class ReadUserInteractor extends UserUsecase {
  async readUser(userId: string): Promise<SchoolUser | null> {
    return this.authService.readUser(userId);
  }
}
