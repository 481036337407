import AdminEmail from "../../components/AdminEmail";

function AdminEmailPage() {
  return (
    <>
      <AdminEmail />
    </>
  );
}

export default AdminEmailPage;
