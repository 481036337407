import { InviteDBService } from "../UseCases/Invite";
import { Invite, School, District } from "../Entities";
import { InviteServices } from "./Backend";

export class InviteService implements InviteDBService {
  async inviteToItem(invite: Invite): Promise<void> {
    return InviteServices.inviteToItem(invite);
  }

  async readInvite(inviteId: string): Promise<Invite | null> {
    return InviteServices.readInvite(inviteId);
  }

  async acceptSchoolInvite(inviteId: string): Promise<School | null> {
    return InviteServices.acceptSchoolInvite(inviteId);
  }

  async acceptDistrictInvite(inviteId: string): Promise<District | null> {
    return InviteServices.acceptDistrictInvite(inviteId);
  }

  async cancelInvite(
    inviteId: string,
    itemType: "school" | "district",
    itemId: string | number
  ): Promise<void> {
    return InviteServices.cancelInvite(inviteId, itemType, itemId);
  }
}
