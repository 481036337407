import { put } from "redux-saga/effects";
import { updatePremiumStateAction } from "../Redux";

export const UPDATE_PREMIUM = "premium/saga/update";

interface PremiumActionType {
  type: string;
  premiumObj:
    | {
        productIds: string[];
        status: string;
        current_period_end: number;
        subscriptionId: string;
      }
    | undefined;
}

export const updatePremiumAction = (
  premiumObj:
    | {
        productIds: string[];
        status: string;
        current_period_end: number;
        subscriptionId: string;
      }
    | undefined
): PremiumActionType => ({
  type: UPDATE_PREMIUM,
  premiumObj,
});

export function* updatePremiumSaga(action: PremiumActionType) {
  yield put(updatePremiumStateAction(action.premiumObj));
}
