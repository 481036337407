import PropTypes from "prop-types";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Checkbox,
  Button,
  Tooltip,
} from "@material-ui/core";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles";

// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const headCells = [
  {
    id: "school",
    numeric: false,
    disablePadding: true,
    label: "School",
    align: "left",
  },
  {
    id: "analytics",
    numeric: false,
    disablePadding: false,
    label: "Analytics",
    align: "center",
    tooltip: "Summary of Parent Parties Engage analytics each month",
  },
];

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    textAlign: "center",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount, mobile } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all schools" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.tooltip && !mobile ? (
              <HtmlTooltip title={`${headCell.tooltip}`}>
                <div className="d-flex flex-row align-items-center">
                  {headCell.label}
                  <FontAwesomeIcon
                    icon={["fas", "info-circle"]}
                    className="ml-2 text-dark"
                  />
                </div>
              </HtmlTooltip>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    numTotal,
    setEditDialog,
    selected,
    setDialogValue,
    mobile,
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        // [classes.highlight]: numSelected > 0,
      })}
      style={
        mobile
          ? {
              flexDirection: "column",
              alignItems: "flex-start",
              paddingBottom: "10px",
            }
          : {}
      }
    >
      <div style={{ height: "41px" }}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1" component="div">
            {numSelected} out of {numTotal} businesses selected
          </Typography>
        ) : (
          <Typography color="inherit" variant="subtitle1" component="div">
            Select each business you want to edit notifications on
          </Typography>
        )}
      </div>

      {numSelected > 0 ? (
        <Button
          className="btn-primary mr-2"
          onClick={() => {
            const firstItemSelected = selected[0];
            setDialogValue({
              analytics: firstItemSelected.analytics,
            });
            setEditDialog(true);
          }}
        >
          <span>Edit your notifications</span>
        </Button>
      ) : null}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function LivePreviewExample(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();
  const { selected, setSelected } = props;

  const rows = props.rows;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows;
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.findIndex((item) => item.id === id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(
        selected,
        rows.find((item) => item.id === id)
      );
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => {
    return selected.findIndex((item) => item.id === id) !== -1;
  };

  return (
    <>
      <div>
        <EnhancedTableToolbar
          numSelected={selected.length}
          numTotal={rows.length}
          setEditDialog={props.setEditDialog}
          setSelectedBusinesses={props.setSelectedBusinesses}
          selected={selected}
          setDialogValue={props.setDialogValue}
          setSelected={setSelected}
          mobile={mobile}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
              mobile={mobile}
            />
            <TableBody>
              {rows.map((row) => {
                if (row.name === "Unknown") {
                  return null;
                }
                const isItemSelected = isSelected(row.id);

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox checked={isItemSelected} />
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">
                      {row.analytics ? (
                        <FontAwesomeIcon
                          className="text-success"
                          icon={["fas", "check"]}
                          style={{
                            fontSize: "16px",
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="text-black-50"
                          icon={["fas", "times"]}
                          style={{
                            fontSize: "16px",
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell align="left" />
                    <TableCell align="left" />
                    <TableCell align="left" />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
