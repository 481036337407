import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Card,
  Button,
  FormControl,
  MenuItem,
  Select,
  OutlinedInput,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import Chart from "react-apexcharts";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  appSelector,
  userSelector,
  ParentPartiesAPIs,
  sceneSelector,
} from "../../core";
import { connect } from "react-redux";
import { CircleLoader } from "react-spinners";
import moment from "moment";
import { DayPickerRangeController } from "react-dates";

// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

const Accordion = withStyles({
  root: {
    width: "100%",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    backgroundColor: "rgba(0, 0, 0, 0)",
    paddingLeft: 0,
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    minHeight: 40,
    "&$expanded": {
      minHeight: 40,
    },
    paddingLeft: 0,
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
  },
}))(MuiAccordionDetails);

function AccordionComponent(props) {
  const { dates } = props;

  return (
    <Accordion square expanded={props.expanded} onChange={props.handleExpanded}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="d-flex flex-row align-items-center">
          <div
            style={{
              fontWeight: 400,
              color: "#272e39",
              fontSize: "15px",
            }}
          >
            Filters
          </div>
          <FontAwesomeIcon
            color="#272e39"
            icon={props.expanded ? ["fas", "angle-up"] : ["fas", "angle-down"]}
            className="font-size-md accordion-icon ml-2"
          />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl
          variant="outlined"
          margin="dense"
          style={{ boxShadow: "none" }}
          fullWidth
        >
          <Select
            value={props.timeFrame}
            onChange={(e) => {
              props.handleFilter(e.target.value);
            }}
            classes={{
              select: props.classes.selectStyles,
            }}
            input={
              <OutlinedInput
                classes={{
                  root: props.classes.fixInput,
                  focused: props.classes.focused,
                  notchedOutline: props.classes.notchedOutline,
                }}
              />
            }
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {dates.map((date) => (
              <MenuItem value={date} dense key={date}>
                {moment(date).format("MMMM")}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
}

const useStyles = makeStyles((theme) => ({
  noBoxShadow: {
    boxShadow: "none !important",
  },
  selectStyles: {
    fontSize: "14px",
    background: "white",
    "&:focus": {
      backgroundColor: "white !important",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "125px",
    },
  },
  fixInput: {
    borderWidth: "1px !important",
    "& $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
    "&:hover $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
    "&$focused $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
  },
  focused: {},
  notchedOutline: {},
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "4px",
    color: theme.palette.grey[500],
    padding: "2px",
  },
}));

let chartOptions = {
  stroke: {
    show: true,
    curve: "smooth",
    width: 2,
  },
  chart: {
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: true,
    },
    animations: {
      enabled: false,
    },
  },
  fill: {
    opacity: 1,
  },
  // labels: dateRange,
  xaxis: {
    type: "datetime",
    labels: {
      trim: false,
      rotate: 0,
      minHeight: 40,
      hideOverlappingLabels: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    strokeDashArray: "5",
    borderColor: "rgba(125, 138, 156, 0.3)",
  },
  legend: {
    show: true,
  },
  markers: {
    size: 0,
  },
  tooltip: {
    x: {
      format: "dd MMMM yyyy",
    },
  },
};

function Dashboard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const sceneType = props.scene ? props.scene.type : "s";

  const [expanded, setExpanded] = useState(false);
  const handleExpanded = () => {
    setExpanded(!expanded);
  };
  const [timeFrame, setTimeFrame] = useState("");
  const [initialLoad, setInitialLoad] = useState(false);

  const [dateRangeDialog, setDateRangeDialog] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState({
    startDate: null,
    endDate: null,
  });
  const [dateRangeFocus, setDateRangeFocus] = useState("startDate");
  const [analytics, setAnalytics] = useState(null);
  const [chartView, setChartView] = useState("Sessions");

  const history = useHistory();

  useEffect(() => {
    // Handle in-school
    setInitialLoad(true);

    if (props.scene) {
      if (props.scene.data) {
        if (props.scene.data.plan) {
          if (props.scene.data.plan === "In-school Curriculum") {
            history.push(`/resources-in-person/s/${props.scene.data.id}`);
            return;
          }
        }

        async function fetchData(scene, dateRange) {
          try {
            const goal = moment().set({ date: 15 }).valueOf();
            const closestMonth = dateRange.reduce((prev, curr) =>
              Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev
            );

            setTimeFrame(closestMonth);

            const startDate = moment(closestMonth).startOf("month");
            const endDate = moment(closestMonth).endOf("month");

            const sceneType = props.scene.type;
            if (sceneType === "school") {
              const result = await ParentPartiesAPIs.fetchSchoolDashboard(
                scene.id,
                startDate,
                endDate
              );
              setAnalytics(result);
              setInitialLoad(false);
            }
            if (sceneType === "district") {
              const schoolIds = scene
                ? scene.data
                  ? Object.keys(scene.data.schoolIds)
                  : []
                : [];
              const result = await ParentPartiesAPIs.fetchDistrictDashboard(
                schoolIds,
                startDate,
                endDate
              );
              setAnalytics(result);
              setInitialLoad(false);
            }
          } catch (err) {
            console.log(err);
          }
        }

        if (props.app.dateRange && props.scene) {
          /* Closest date */
          fetchData(props.scene, props.app.dateRange);
        }
      }
    }
  }, [props.app.dateRange, props.scene]);

  const changeChartView = (chartType) => {
    setChartView(chartType);
  };
  const changeTimeFrame = async (timeFrame) => {
    const sceneId = props.scene ? props.scene.id : null;
    if (!sceneId) {
      return;
    }
    if (timeFrame === "Custom" || timeFrame === "School Year") {
      return;
    }
    // Reset Date Range
    else {
      setDateRangeValue({
        startDate: null,
        endDate: null,
      });
    }
    setTimeFrame(timeFrame);
    const startDate = moment(timeFrame).startOf("month");
    const endDate = moment(timeFrame).endOf("month");

    const sceneType = props.scene.type;
    if (sceneType === "school") {
      const result = await ParentPartiesAPIs.fetchSchoolDashboard(
        sceneId,
        startDate,
        endDate
      );

      setAnalytics(result);
    }
    if (sceneType === "district") {
      const schoolIds = props.scene
        ? props.scene.data
          ? Object.keys(props.scene.data.schoolIds)
          : []
        : [];
      const result = await ParentPartiesAPIs.fetchDistrictDashboard(
        schoolIds,
        startDate,
        endDate
      );

      setAnalytics(result);
    }
  };

  // const handlePercent = (number) => {
  //   if (number === Infinity) {
  //     return null;
  //   }
  //   if (number) {
  //     const actualNum = number - 100;
  //     if (Math.sign(actualNum) <= 0) {
  //       return (
  //         <span style={{ textTransform: "none" }}>
  //           <strong className="text-warning">{`${Math.round(
  //             actualNum
  //           )}%`}</strong>{" "}
  //         </span>
  //       );
  //     } else {
  //       return (
  //         <span style={{ textTransform: "none" }}>
  //           <strong className="text-success">{`+${Math.round(
  //             actualNum
  //           )}%`}</strong>{" "}
  //         </span>
  //       );
  //     }
  //   }
  // };

  const handleCloseDateRange = () => {
    setDateRangeDialog(false);
    setDateRangeValue({
      startDate: null,
      endDate: null,
    });
  };
  const handleDateRangePick = async () => {
    if (props.scene.id) {
      setTimeFrame("Custom");

      const startDate = dateRangeValue.startDate
        ? moment(dateRangeValue.startDate).startOf("day")
        : dateRangeValue.endDate;
      const endDate = dateRangeValue.endDate
        ? moment(dateRangeValue.endDate).endOf("day")
        : moment(dateRangeValue.startDate).endOf("day");

      if (startDate === null && endDate === null) {
        return handleCloseDateRange();
      }

      const result = await ParentPartiesAPIs.fetchSchoolDashboard(
        props.scene.id,
        startDate,
        endDate
      );

      setAnalytics(result);
      setDateRangeDialog(false);
    }
  };
  const handleSchoolYearPick = async () => {
    setDateRangeValue({
      startDate: null,
      endDate: null,
    });
    setTimeFrame("School Year");

    const startDate = props.app.dateRange
      ? props.app.dateRange[0]
        ? moment(props.app.dateRange[0]).startOf("month")
        : null
      : null;
    const endDate = props.app.dateRange
      ? props.app.dateRange.length
        ? moment(props.app.dateRange[props.app.dateRange.length - 1]).endOf(
            "month"
          )
        : null
      : null;

    if (sceneType === "school") {
      const result = await ParentPartiesAPIs.fetchSchoolDashboard(
        props.scene.id,
        startDate,
        endDate
      );
      setAnalytics(result);
    }
    if (sceneType === "district") {
      const schoolIds = props.scene
        ? props.scene.data
          ? Object.keys(props.scene.data.schoolIds)
          : []
        : [];
      const result = await ParentPartiesAPIs.fetchDistrictDashboard(
        schoolIds,
        startDate,
        endDate
      );
      setAnalytics(result);
    }
  };

  const handleRenderChart = (chartView) => {
    switch (chartView) {
      case "Sessions":
        return (
          <Chart
            options={{
              ...chartOptions,
              chart: {
                ...chartOptions.chart,
                toolbar: {
                  show: mobile ? false : true,
                },
                zoom: {
                  enabled: mobile ? false : true,
                },
                animations: {
                  enabled: true,
                },
              },
              tooltip: {
                ...chartOptions.tooltip,
                y: {
                  formatter: function (value) {
                    return Math.round(value);
                  },
                },
              },
            }}
            series={[
              {
                name: "Sessions",
                data: analytics
                  ? analytics.sessions
                    ? analytics.sessions.data
                    : []
                  : [],
              },
            ]}
            type="bar"
            height={300}
          />
        );
      case "Points":
        return (
          <Chart
            options={{
              ...chartOptions,
              chart: {
                ...chartOptions.chart,
                toolbar: {
                  show: mobile ? false : true,
                },
                zoom: {
                  enabled: mobile ? false : true,
                },
                animations: {
                  enabled: true,
                },
              },
            }}
            series={[
              {
                name: "Points",
                data: analytics
                  ? analytics.points
                    ? analytics.points.data
                    : []
                  : [],
              },
            ]}
            type="line"
            height={300}
          />
        );
      case "Activities Completed":
        return (
          <Chart
            options={{
              ...chartOptions,
              chart: {
                ...chartOptions.chart,
                toolbar: {
                  show: mobile ? false : true,
                },
                zoom: {
                  enabled: mobile ? false : true,
                },
                animations: {
                  enabled: true,
                },
              },
              tooltip: {
                ...chartOptions.tooltip,
                y: {
                  formatter: function (value) {
                    return Math.round(value);
                  },
                },
              },
            }}
            series={[
              {
                name: "Activities Completed",
                data: analytics
                  ? analytics.activitiesCompleted
                    ? analytics.activitiesCompleted.data
                    : []
                  : [],
              },
            ]}
            type="bar"
            height={300}
          />
        );
      case "New Guardians Enrolled":
        return (
          <Chart
            options={{
              ...chartOptions,
              chart: {
                ...chartOptions.chart,
                toolbar: {
                  show: mobile ? false : true,
                },
                zoom: {
                  enabled: mobile ? false : true,
                },
                animations: {
                  enabled: true,
                },
              },
              tooltip: {
                ...chartOptions.tooltip,
                y: {
                  formatter: function (value) {
                    return Math.round(value);
                  },
                },
              },
            }}
            series={[
              {
                name: "New Guardians Enrolled",
                data: analytics
                  ? analytics.newGuardiansEnrolled
                    ? analytics.newGuardiansEnrolled.data
                    : []
                  : [],
              },
            ]}
            type="bar"
            height={300}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {props.app.isLoading || initialLoad ? (
        <Grid container className="my-spacing-6">
          <Grid item xs={12}>
            <div className="d-flex flex-row justify-content-center">
              <CircleLoader
                className="d-flex align-self-center"
                color={"#18a9a9"}
                loading={true}
                size={40}
              />
            </div>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container spacing={4}>
            {mobile ? (
              <Grid item md={12} xl={12}>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div
                    className="font-weight-bold display-4"
                    style={{ color: "rgb(43, 39, 60)" }}
                  >
                    {"Dashboard"}
                  </div>
                </div>
                <div className="mt-4">
                  <AccordionComponent
                    expanded={expanded}
                    handleExpanded={handleExpanded}
                    handleFilter={changeTimeFrame}
                    timeFrame={timeFrame}
                    classes={classes}
                    dates={props.app.dateRange ? props.app.dateRange : []}
                  />
                </div>
              </Grid>
            ) : (
              <>
                <Grid item md={12} xl={12}>
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div
                      className="font-weight-bold display-4"
                      style={{ color: "rgb(43, 39, 60)" }}
                    >
                      {sceneType === "district"
                        ? "District Dashboard"
                        : "Dashboard"}
                    </div>
                    {props.app.dateRange ? (
                      <FormControl
                        variant="outlined"
                        margin="dense"
                        style={{ boxShadow: "none" }}
                      >
                        <Select
                          autoWidth={false}
                          value={timeFrame}
                          renderValue={(value) => {
                            return value === "Custom"
                              ? `${
                                  dateRangeValue.startDate
                                    ? moment(dateRangeValue.startDate).format(
                                        "ll"
                                      )
                                    : ""
                                }${
                                  dateRangeValue.endDate
                                    ? `${
                                        dateRangeValue.startDate
                                          ? ` - ${moment(
                                              dateRangeValue.endDate
                                            ).format("ll")}`
                                          : moment(
                                              dateRangeValue.endDate
                                            ).format("ll")
                                      }`
                                    : ""
                                }`
                              : value === "School Year"
                              ? "School Year"
                              : moment(value).format("MMMM YYYY");
                          }}
                          onChange={(e) => {
                            changeTimeFrame(e.target.value);
                          }}
                          classes={{
                            select: classes.selectStyles,
                          }}
                          input={
                            <OutlinedInput
                              classes={{
                                root: classes.fixInput,
                                focused: classes.focused,
                                notchedOutline: classes.notchedOutline,
                              }}
                            />
                          }
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          {(props.app.dateRange ? props.app.dateRange : []).map(
                            (date) => (
                              <MenuItem value={date} dense key={date}>
                                {moment(date).format("MMMM YYYY")}
                              </MenuItem>
                            )
                          )}
                          <MenuItem
                            value={"School Year"}
                            dense
                            onClick={() => {
                              handleSchoolYearPick();
                            }}
                          >
                            School Year
                          </MenuItem>
                          <MenuItem
                            value={"Custom"}
                            dense
                            onClick={() => {
                              setDateRangeDialog(true);
                            }}
                          >
                            Custom
                          </MenuItem>
                        </Select>
                      </FormControl>
                    ) : null}
                  </div>
                </Grid>
              </>
            )}
            <Grid item md={12}>
              <Grid container spacing={3}>
                <Grid item md={6} xl={3}>
                  <Card
                    className="p-3"
                    style={{
                      borderRadius: "0.25rem",
                      border: "1px solid rgba(0,0,0,.1)",
                    }}
                  >
                    <div className="text-black-50 pb-2 font-size-sm">
                      Sessions
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-between">
                      <h3 className="display-4 mb-0 text-black font-weight-bold">
                        {analytics
                          ? analytics.sessions
                            ? analytics.sessions.value
                            : 0
                          : 0}
                      </h3>
                      <div className="font-size-sm ml-2">
                        {/* {analytics
                              ? analytics.reviews
                                ? handlePercent(
                                    analytics.reviews.rating.percentDiff
                                  )
                                : ""
                              : ""} */}
                      </div>
                    </div>
                  </Card>
                </Grid>
                <Grid item md={6} xl={3}>
                  <Card
                    className="p-3"
                    style={{
                      borderRadius: "0.25rem",
                      border: "1px solid rgba(0,0,0,.1)",
                    }}
                  >
                    <div className="text-black-50 pb-2 font-size-sm">
                      Points
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-between">
                      <h3 className="display-4 mb-0 text-black font-weight-bold">
                        {analytics
                          ? analytics.points
                            ? analytics.points.value
                            : 0
                          : 0}
                      </h3>
                      <div className="font-size-sm ml-2">
                        {/* {analytics
                              ? analytics.menu
                                ? handlePercent(
                                    analytics.menu.views.percentDiff
                                  )
                                : ""
                              : ""} */}
                      </div>
                    </div>
                  </Card>
                </Grid>
                <Grid item md={6} xl={3}>
                  <Card
                    className="p-3"
                    style={{
                      borderRadius: "0.25rem",
                      border: "1px solid rgba(0,0,0,.1)",
                    }}
                  >
                    <div className="text-black-50 pb-2 font-size-sm">
                      Activities Completed
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-between">
                      <h3 className="display-4 mb-0 text-black font-weight-bold">
                        {analytics
                          ? analytics.activitiesCompleted
                            ? analytics.activitiesCompleted.value
                            : 0
                          : 0}
                      </h3>
                      <div className="font-size-sm ml-2">
                        {/* {analytics
                              ? analytics.deals
                                ? handlePercent(
                                    analytics.deals.clicks.percentDiff
                                  )
                                : ""
                              : ""} */}
                      </div>
                    </div>
                  </Card>
                </Grid>
                <Grid item md={6} xl={3}>
                  <Card
                    className="p-3"
                    style={{
                      borderRadius: "0.25rem",
                      border: "1px solid rgba(0,0,0,.1)",
                    }}
                  >
                    <div className="text-black-50 pb-2 font-size-sm">
                      New Guardians Enrolled
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-between">
                      <h3 className="display-4 mb-0 text-black font-weight-bold">
                        {analytics
                          ? analytics.newGuardiansEnrolled
                            ? analytics.newGuardiansEnrolled.value
                            : 0
                          : 0}
                      </h3>
                      <div className="font-size-sm ml-2">
                        {/* {analytics
                              ? analytics.reviews
                                ? handlePercent(
                                    analytics.reviews.reviews.percentDiff
                                  )
                                : ""
                              : ""} */}
                      </div>
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Card
                className="mb-spacing-6-x2 p-3"
                style={{
                  borderRadius: "0.25rem",
                  border: "1px solid rgba(0,0,0,.1)",
                }}
              >
                <FormControl
                  variant="outlined"
                  margin="dense"
                  style={{ boxShadow: "none" }}
                >
                  <Select
                    value={chartView}
                    onChange={(e) => {
                      changeChartView(e.target.value);
                    }}
                    classes={{
                      select: classes.selectStyles,
                    }}
                    input={
                      <OutlinedInput
                        classes={{
                          root: classes.fixInput,
                          focused: classes.focused,
                          notchedOutline: classes.notchedOutline,
                        }}
                      />
                    }
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value={"Sessions"} dense>
                      Sessions
                    </MenuItem>
                    <MenuItem value={"Points"} dense>
                      Points
                    </MenuItem>
                    <MenuItem value={"Activities Completed"} dense>
                      Activities Completed
                    </MenuItem>
                    <MenuItem value={"New Guardians Enrolled"} dense>
                      New Guardians Enrolled
                    </MenuItem>
                  </Select>
                </FormControl>
                {handleRenderChart(chartView)}
              </Card>
            </Grid>
          </Grid>
          <Dialog
            open={dateRangeDialog}
            onClose={handleCloseDateRange}
            fullWidth={true}
            maxWidth={"md"}
          >
            <DialogTitle
              style={{
                fontWeight: "bold",
                fontSize: "24px",
                color: "rgb(42, 42, 47)",
                // borderBottom: "none",
              }}
            >
              Select Date Range
            </DialogTitle>
            <DialogContent
              dividers={false}
              className="d-flex flex-row justify-content-center my-4"
            >
              <DayPickerRangeController
                numberOfMonths={2}
                onDatesChange={(dates) => {
                  setDateRangeValue({
                    startDate: dates.startDate,
                    endDate: dates.endDate,
                  });
                }}
                startDate={dateRangeValue.startDate}
                endDate={dateRangeValue.endDate}
                focusedInput={dateRangeFocus}
                onFocusChange={(focusedInput) => {
                  setDateRangeFocus(!focusedInput ? "startDate" : focusedInput);
                }}
                hideKeyboardShortcutsPanel={true}
              />
            </DialogContent>
            <DialogActions className="p-3">
              <Button onClick={handleCloseDateRange}>Cancel</Button>
              <Button
                className="btn-primary"
                onClick={handleDateRangePick}
                autoFocus
              >
                Finish
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
  scene: sceneSelector(state),
});

export default connect(mapStateToProps, null)(Dashboard);
