import { useState } from "react";
import { connect } from "react-redux";
import { userSelector, signOutAction } from "../../core";
import { Menu, Button, List, ListItem, Tooltip } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import { withStyles, makeStyles } from "@material-ui/core/styles";

const StyledMenu = withStyles({
  paper: {
    width: "210px",
    borderRadius: "4px",
    backgroundColor: "#fff",
    boxShadow: "0 0 18px rgb(0 0 0 / 15%)",
    marginTop: "5px",
  },
  list: {
    padding: 0,
    color: "#232323",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    disableScrollLock={true}
    {...props}
  />
));

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#000",
  },
  tooltip: {
    backgroundColor: "#000",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const HeaderDots = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <BootstrapTooltip title={"Support"}>
        <Button
          variant="text"
          onClick={handleClick}
          className="btn-transition-none text-left p-0 bg-transparent d-flex align-items-center"
          disableRipple
        >
          <div className="d-block p-0 avatar-icon-wrapper rounded">
            <div className="btn-transition-none font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative">
              <span>
                <HelpOutlineIcon />
              </span>
            </div>
          </div>
        </Button>
      </BootstrapTooltip>
      <StyledMenu
        id="support-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className="overflow-hidden p-0">
          <List component="nav" dense className="px-2">
            <ListItem component={"div"} className="px-2">
              <div>
                <p
                  className="mb-0"
                  style={{
                    color: "#2b273c",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  Looking for help?
                </p>
                <div style={{ marginTop: "8px", marginBottom: "8px" }}>
                  <span
                    style={{
                      color: "#2b273c",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    Call
                  </span>
                  <span
                    style={{
                      color: "#00838f",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "20px",
                      paddingLeft: "4px",
                    }}
                  >
                    256-384-6776
                  </span>
                </div>
                <div style={{ marginTop: "8px", marginBottom: "8px" }}>
                  <div>
                    <span
                      style={{
                        color: "#2b273c",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      Email
                    </span>
                    <div
                      style={{
                        color: "#00838f",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      engage@parentparties.com
                    </div>
                  </div>
                </div>
                <p
                  style={{
                    color: "#bbbac0",
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: 400,
                  }}
                  className="mb-0"
                >
                  M-F 8:00 AM - 4:00 PM CST
                </p>
              </div>
            </ListItem>
          </List>
        </div>
      </StyledMenu>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: userSelector(state),
});

const mapDispatchToProps = {
  signOutAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDots);
