import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  appSelector,
  sceneSelector,
  userSelector,
  cancelInviteAction,
  removeFromSchoolAction,
  removeFromDistrictAction,
  inviteToItemAction,
  updateDistrictAction,
  DistrictServices,
  SchoolServices,
  readSceneAction,
} from "../../core";
import CloseIcon from "@material-ui/icons/Close";
import {
  Grid,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
  Table,
  List,
  ListItem,
  Snackbar,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useLocation, NavLink } from "react-router-dom";
import RoleSelection from "./components/roleSelection";
import InviteDialog from "./components/inviteDialog";

// Responsive //
import { makeStyles } from "@material-ui/core/styles";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#000",
  },
  tooltip: {
    backgroundColor: "#000",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

function Team(props) {
  const location = useLocation();
  const { pathname } = location;
  const params = pathname.split("/");
  const sceneType = params[2];
  const sceneId = params[3];

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [snackBarType, setSnackBarType] = useState("success");

  // const [dialog, setDialog] = useState(false);
  // const toggleDialog = (member) => {
  //   if (member) {
  //     setDialogInfo(member);
  //   }
  //   setDialog(!dialog);
  // };

  const [dialogInfo, setDialogInfo] = useState(null);
  // Invite
  const [inviteDialog, setInviteDialog] = useState(false);
  const toggleInviteDialog = () => {
    setInviteDialog(!inviteDialog);
  };

  // Delete
  const [deleteDialog, setDeleteDialog] = useState(false);
  const handleDeleteOpen = (member) => {
    if (member) {
      setDialogInfo(member);
    }
    setDeleteDialog(true);
  };
  const handleDeleteClose = () => {
    setDeleteDialog(false);
    setDialogInfo(null);
  };

  // Link Open
  const [linkDialog, setLinkDialog] = useState(false);
  const handleLinkOpen = (member) => {
    if (member) {
      setDialogInfo(member);
    }
    setLinkDialog(true);
  };
  const handleLinkClose = () => {
    setLinkDialog(false);
    setDialogInfo(null);
  };

  const [teamLoading, setTeamLoading] = useState(false);

  const onInviteUser = (email, position) => {
    const itemType = props.scene ? props.scene.type : "";
    const itemId = props.scene ? props.scene.id : "";
    const {
      user: { id: senderId },
    } = props;

    try {
      props.dispatchInviteToItem({
        senderId,
        email,
        position,
        itemId,
        itemType,
      });
      setTeamLoading(true);
      toggleInviteDialog();
    } catch (err) {}
  };

  useEffect(() => {
    if (teamLoading) {
      if (!props.app.isLoading) {
        if (props.app.error) {
          setSnackBarType("error");
          setToastMessage(`Error inviting user.`);
          setToastOpen(true);
          setTeamLoading(false);
        } else {
          setSnackBarType("success");
          setToastMessage(`Invite sent.`);
          setToastOpen(true);
          setTeamLoading(false);
        }
      }
    }
  }, [props.app.error, props.app.isLoading, teamLoading]);

  const teamList = props.scene
    ? props.scene.data
      ? props.scene.data.team
        ? Object.values(props.scene.data.team)
        : []
      : []
    : [];
  const currentUserRole = props.user
    ? props.user.adminSupport
      ? "Owner"
      : props.scene
      ? props.scene.data
        ? props.scene.data.team
          ? props.scene.data.team[props.user.id]
            ? props.scene.data.team[props.user.id].position
            : null
          : null
        : null
      : null
    : null;
  const adminSupport = props.user ? props.user.adminSupport : false;

  const editAvailable = (member) => {
    if (props.user.id === member.id) {
      return false;
    }
    // Owner
    if (currentUserRole === "Owner") {
      return true;
    }
    // Admin
    if (currentUserRole === "Admin") {
      if (member.position !== "Owner" && currentUserRole === "Admin") {
        return true;
      }
      return false;
    }
  };

  const updateTeamRole = async (role, member) => {
    if (member && role) {
      if (props.scene) {
        if (props.scene.type === "school") {
          const schoolId = props.scene.data.id;
          const memberId = member.id;
          try {
            await SchoolServices.updateSchoolTeamRole(schoolId, memberId, role);
            props.readSceneAction("s", `${schoolId}`);
            setSnackBarType("success");
            setToastMessage(`Team role updated.`);
            setToastOpen(true);
          } catch (err) {
            console.log(err);
            setSnackBarType("error");
            setToastMessage(`Error updating team role.`);
            setToastOpen(true);
          }
        }
        if (props.scene.type === "district") {
          const districtId = props.scene.data.id;
          const memberId = member.id;
          try {
            const updatedDistrict = await DistrictServices.updateDistrictTeamRole(
              districtId,
              memberId,
              role
            );
            props.updateDistrictAction(updatedDistrict);
            setSnackBarType("success");
            setToastMessage(`Team role updated.`);
            setToastOpen(true);
          } catch {
            setSnackBarType("error");
            setToastMessage(`Error updating team role.`);
            setToastOpen(true);
          }
        }
      }
    }
  };

  const [cancelLoading, setCancelLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleDeleteUser = (member) => {
    if (member) {
      if (member.status === "Pending") {
        try {
          props.dispatchCancelInvite(member.id, member.itemType, member.itemId);
          setCancelLoading(true);
          handleDeleteClose();
        } catch (err) {
          console.log(err);
        }
      } else {
        if (props.scene) {
          const sceneId = props.scene.id;
          if (props.scene.type === "school") {
            props.dispatchRemoveFromSchool(member.id, sceneId);
            setDeleteLoading(true);
            handleDeleteClose();
          }
          if (props.scene.type === "district") {
            props.dispatchRemoveFromDistrict(member.id, sceneId);
            setDeleteLoading(true);
            handleDeleteClose();
          }
        }
      }
    }
  };

  // Cancel loading handler
  useEffect(() => {
    if (cancelLoading) {
      if (!props.app.isLoading) {
        if (props.app.error) {
          setSnackBarType("error");
          setToastMessage(`Error canceling invite.`);
          setToastOpen(true);
          setCancelLoading(false);
        } else {
          setSnackBarType("success");
          setToastMessage(`Invite canceled.`);
          setToastOpen(true);
          setCancelLoading(false);
        }
      }
    }
  }, [props.app.error, props.app.isLoading, cancelLoading]);

  // Delete loading handler
  useEffect(() => {
    if (deleteLoading) {
      if (!props.app.isLoading) {
        if (props.app.error) {
          setSnackBarType("error");
          setToastMessage(`Error removing user.`);
          setToastOpen(true);
          setDeleteLoading(false);
        } else {
          setSnackBarType("success");
          setToastMessage(`User removed.`);
          setToastOpen(true);
          setDeleteLoading(false);
        }
      }
    }
  }, [props.app.error, props.app.isLoading, deleteLoading]);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item md={12} xl={12}>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              {`${
                props.scene
                  ? props.scene.type === "district"
                    ? "District Team"
                    : "Get Started"
                  : ""
              }`}
            </div>
            {["Owner", "Admin"].includes(currentUserRole) ? (
              <Button
                variant="outlined"
                className="btn-outline-primary border-1"
                style={{ borderRadius: "6px", fontSize: "14px" }}
                size="small"
                onClick={toggleInviteDialog}
              >
                <FontAwesomeIcon icon={["fas", "plus"]} className="mr-2" />
                <span className="btn-wrapper--label">Invite User</span>
              </Button>
            ) : null}
          </div>
        </Grid>
        <Grid item md={12} xl={12}>
          {mobile ? null : (
            <List
              component="div"
              className="nav-line-alt nav-line d-flex align-items-center mt-3"
            >
              <ListItem
                button
                component={NavLink}
                to={`/resources/${sceneType}/${sceneId}`}
                className="px-2"
              >
                <span className="font-size-sm">{"Onboard Parents"}</span>
                <div className="divider" />
              </ListItem>
              <ListItem button className="pr-2" selected>
                <span className="font-size-sm">{"Admin Team"}</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to={`/teachers/${sceneType}/${sceneId}`}
                className="pr-2"
              >
                <span className="font-size-sm">{"Teacher List"}</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to={`/more-info/${sceneType}/${sceneId}`}
                className="pr-2"
              >
                <span className="font-size-sm">{"Contact Info"}</span>
                <div className="divider" />
              </ListItem>
            </List>
          )}
        </Grid>
        <Grid item md={12}>
          <div className="table-responsive-md mb-spacing-6-x2 mt-4 pt-4">
            <Table
              className="table table-striped table-bordered"
              style={{
                tableLayout: "fixed",
              }}
            >
              <thead className="thead-light">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Role</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {teamList.map((member) => (
                  <tr style={{ height: "60px" }}>
                    <td style={{ overFlow: "hidden", fontSize: "14px" }}>
                      {member.status === "Pending"
                        ? ``
                        : `${member.firstName} ${member.lastName}`}
                    </td>
                    <td style={{ overFlow: "hidden", fontSize: "14px" }}>
                      {member.email.address}
                    </td>
                    <td>
                      {member.status === "Pending" ? (
                        <span
                          style={{ fontSize: "14px" }}
                        >{`${member.position} (Invite Pending)`}</span>
                      ) : (
                        <RoleSelection
                          handleRoleSelect={updateTeamRole}
                          currentUserRole={currentUserRole}
                          selectedRole={member.position}
                          editable={editAvailable(member)}
                          member={member}
                        />
                      )}
                    </td>
                    <th scope="row">
                      {editAvailable(member) ? (
                        <div className="d-flex flex-row justify-content-center">
                          {adminSupport && member.status === "Pending" ? (
                            <BootstrapTooltip
                              title={"Invite Link"}
                              placement="left"
                            >
                              <Button
                                variant="outlined"
                                className="btn-outline-info mr-2"
                                style={{
                                  borderRadius: "6px",
                                  fontSize: "14px",
                                }}
                                size="small"
                                onClick={() => handleLinkOpen(member)}
                              >
                                <FontAwesomeIcon icon={["fas", "link"]} />
                              </Button>
                            </BootstrapTooltip>
                          ) : null}
                          <BootstrapTooltip
                            title={
                              member.status === "Pending"
                                ? "Cancel invite"
                                : "Delete user"
                            }
                            placement="left"
                          >
                            <Button
                              variant="outlined"
                              className="btn-outline-danger"
                              style={{ borderRadius: "6px", fontSize: "14px" }}
                              size="small"
                              onClick={() => handleDeleteOpen(member)}
                            >
                              <FontAwesomeIcon icon={["fas", "trash"]} />
                            </Button>
                          </BootstrapTooltip>
                        </div>
                      ) : null}
                    </th>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Grid>
      </Grid>
      <InviteDialog
        inviteDialog={inviteDialog}
        toggleInviteDialog={toggleInviteDialog}
        onInviteUser={onInviteUser}
        inviteType={props.scene ? props.scene.type : "school"}
      />
      <Dialog
        open={deleteDialog}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            color: "rgb(42, 42, 47)",
            borderBottom: "none",
          }}
        >
          {dialogInfo
            ? dialogInfo.status === "Pending"
              ? `Are you sure you want to cancel the invite for ${dialogInfo.email.address}?`
              : `Are you sure you want to delete ${dialogInfo.firstName} ${dialogInfo.lastName}?`
            : ""}
        </DialogTitle>
        <DialogContent dividers={false}>
          <DialogContentText
            style={{ fontSize: "14px", color: "rgb(42, 42, 47)" }}
          >
            {dialogInfo
              ? dialogInfo.status === "Pending"
                ? ` This invite to the district will be canceled. This action is non-reversible.`
                : props.scene
                ? props.scene.type === "district"
                  ? ` This user will be removed from the district, and the district's
            schools. This action is non-reversible.`
                  : ` This user will be removed from this school, and any districts
            this school is a part of. This action is non-reversible.`
                : ""
              : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ borderTop: "none" }}>
          <Button onClick={handleDeleteClose}>Cancel</Button>
          <Button
            className="btn-danger"
            onClick={() => handleDeleteUser(dialogInfo)}
            autoFocus
          >
            {dialogInfo
              ? dialogInfo.status === "Pending"
                ? "Cancel Invite"
                : "Delete User"
              : "Delete User"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={linkDialog}
        onClose={handleLinkClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            color: "rgb(42, 42, 47)",
            borderBottom: "none",
          }}
        >
          {"Invite Link"}
        </DialogTitle>
        <DialogContent dividers={false}>
          <DialogContentText
            style={{ fontSize: "14px", color: "rgb(42, 42, 47)" }}
          >
            {dialogInfo
              ? `https://app.parentpartiesengage.com/register?inviteId=${dialogInfo.id}&senderId=${dialogInfo.senderId}&itemType=${dialogInfo.itemType}&itemId=${dialogInfo.itemId}`
              : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ borderTop: "none" }}>
          <Button onClick={handleLinkClose}>Close</Button>
        </DialogActions>
      </Dialog>
      {/* {Snackbar} */}
      <Snackbar
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={toastOpen}
        classes={{
          root: snackBarType === "error" ? `toastr-danger` : `toastr-success`,
        }}
        onClose={() => setToastOpen(false)}
        message={toastMessage}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setToastOpen(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
  scene: sceneSelector(state),
});

const mapDispatchToProps = {
  dispatchCancelInvite: cancelInviteAction,
  dispatchRemoveFromSchool: removeFromSchoolAction,
  dispatchRemoveFromDistrict: removeFromDistrictAction,
  dispatchInviteToItem: inviteToItemAction,
  updateDistrictAction,
  readSceneAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Team);
